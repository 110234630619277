import React from "react";
import i18n from "#translate/i18n";
import { Trans } from "react-i18next"
import quintalkRemoved from '#images/events_icons/quintalk_removed.svg';
import liveCodingRemoved from '#images/events_icons/live_code_removed.svg';
import competitionsRemoved from '#images/events_icons/competitions_removed.svg';
import partyKitRemoved from '#images/events_icons/party_kit_removed.svg';
import onboardingRemoved from '#images/events_icons/onboarding_removed.svg';

export default function BonusRewardRemoved({ eventData }) {
  const data = eventData?.data || {}
  const adminName = data.responsible?.full_name
  const rewardType = data.reward_type
  const icons = {
    quintalk: quintalkRemoved,
    live_coding: liveCodingRemoved,
    competitions: competitionsRemoved,
    party_kit: partyKitRemoved,
    onboarding: onboardingRemoved,
  }

  return (
    <div className="relative flex-1 my-5 bg-white">
      <div className="flex items-center">
        {icons[rewardType] && (
          <div className='rounded-full mr-[6px] h-fit min-w-fit' >
            <img
              src={icons[rewardType]}
              alt="event icon"
              className='w-[52px] h-[52px] drop-shadow-[0.3333333432674408px_0.3333333432674408px_1.3333333730697632px_rgba(0,0,0,0.15)]
                         fill-[var(--suporte-erro-background,#F9E0E0)]'
            />
          </div>
        )}
        <div className="relative z-20 font-inter text-sm leading-[22px] text-info-gray flex-1" id={`bonus_reward_removed_${rewardType}`}>
          <p className="font-bold inline-block">
            {i18n.t('timelineEvents.bonusRewardRemoved.title')}
          </p>
          <p className="font-normal">
            <Trans
              i18nKey={`${i18n.t("timelineEvents.bonusRewardRemoved.description")}`}
              values={{ admin: adminName, rewardType: i18n.t(`timelineEvents.bonusRewardRemoved.${rewardType}`) }}
            />
          </p>
        </div>
      </div>
    </div>
  );
}
