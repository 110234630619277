import axios from "axios";
import toFormData from "#requests/helpers/toFormData";
import formatDate from "#services/formatDate";

export default function createOrUpdateExpenseAssistance({data, expenseId, model, user}) {
  const action = expenseId ? "PUT" : "POST";
  const url = expenseId ? `/api/v1/expense_assistances/${expenseId}` : "/api/v1/expense_assistances";

  const formatPayload = (data) => {
    let new_data = { expense_assistance: data.expense_assistance }
    let new_date = { payment_start_date: formatDate(data.expense_assistance.payment_start_date) }
    let keys = Object.keys(new_date)
    keys.map(x => { new_data.expense_assistance[x] = new_date[x] })

    const sentData = {
      ...new_data.expense_assistance,
      user_id: user.id,
    };

    return sentData;
  };

  const requestOptions = () => {
    const csrf = document
      .querySelector("meta[name='csrf-token']")
      .getAttribute("content");

    return {
      method: action,
      headers: { "X-CSRF-Token": csrf },
      data: toFormData(formatPayload(data), model),
    };
  };

  const options = requestOptions();

  return axios({url, ...options})
}

