import React from "react";
import CustomizedFieldsOptions from "#components/listings/people/data/drawers/CustomizedFieldsOptions"

export default function BasicCompanyHistoryData({fieldsCompanyHistoryDataOptions}) {
  return (
    <CustomizedFieldsOptions
      options={fieldsCompanyHistoryDataOptions?.basic_company_history_data}
      prefix={'company_history_data.basic_company_history_data'}
    />
  );
}