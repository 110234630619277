import React, { useContext } from "react";
import Registration from "#images/registration.svg";
import Preferences from "#images/preferences.svg";
import Upload from "#images/upload.svg";
import i18n from "#translate/i18n";

import { UserFormsContext } from "#providers/UserFormsProvider"

const Welcome = ({ user }) => {
  const { name } = user;
  const { openUserEditForms, setOpenUserEditForms } = useContext(UserFormsContext)

  return (
    <div className={`w-full h-full ${openUserEditForms ? 'hidden' : ''}`}>
      <div className="mt-14.3 ml-9.4">
        <h1
          data-testid="title"
          className="text-3.5xl font-bold text-elPaso mb-3"
        >
          {i18n.t("welcome.title", { name })}
        </h1>
        <h3
          data-testid="subtitle"
          className="text-xl font-bold text-elPaso mb-8.7"
        >
          {i18n.t("welcome.subtitle")}
        </h3>
        <p data-testid="message" className="max-w-3.1xl mb-20.3">
          {i18n.t("welcome.message")}
        </p>
      </div>

      <div className="flex justify-evenly flex-wrap">
        <div className="flex flex-col items-center">
          <img
            className="w-20.1 h-20.4"
            src={Registration}
            alt={i18n.t("welcome.imgData")}
          />
          <p className="w-48.5 mb-4.1 mt-4.1 text-center">
            {i18n.t("welcome.addData")}
          </p>
          <button
            onClick={() => setOpenUserEditForms(true)}
            data-testid="add-data"
            className="uppercase mt-auto font-sans Helvetica Neue font-bold py-2.58 px-17.7
                      bg-strong-blue text-gray-100 text-lg rounded-md focus:border-4
                      border-blue-300"
          >
            <span>{i18n.t("buttons.registration")}</span>
          </button>
        </div>

        <div className="flex flex-col items-center">
          <img
            className="w-20.1 h-20.4"
            src={Preferences}
            alt={i18n.t("welcome.imgDescription")}
          />
          <p className="w-48.5 mt-4.1 text-center">
            {i18n.t("welcome.addDescription")}
          </p>
          <button
            data-testid="add-description"
            className="uppercase mt-auto font-sans Helvetica Neue font-bold py-2.58 px-17.7
                      bg-strong-blue text-gray-100 text-lg rounded-md focus:border-4
                      border-blue-300"
          >
            <span>{i18n.t("buttons.moreInfos")}</span>
          </button>
        </div>

        <div className="flex flex-col items-center">
          <img
            className="w-20.1 h-20.4"
            src={Upload}
            alt={i18n.t("welcome.imgFiles")}
          />
          <p className="w-48.5 mt-4.1 text-center">
            {i18n.t("welcome.addFiles")}
          </p>
          <button
            data-testid="add-files"
            className="uppercase mt-auto font-sans Helvetica Neue font-bold py-2.58 px-17.7
                      bg-strong-blue text-gray-100 text-lg rounded-md focus:border-4
                      border-blue-300"
          >
            <span>{i18n.t("buttons.upload")}</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
