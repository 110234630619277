import moment from "moment"

export default function isMonthBygone(date) {
  if(!date) { return false }

  const beginningOfInputMonth = moment(date, 'YYYY-MM-DD').toDate()
  const beginningOfCurrentMonth = moment(new Date()).startOf("month").startOf('day')

  return beginningOfCurrentMonth > beginningOfInputMonth
}
