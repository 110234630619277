import * as Yup from "yup";
import i18n from "#translate/i18n";

Yup.setLocale(i18n.t("yup.schemas", { returnObjects: true }));

const CompanyHistoryRecordSchema = Yup.object()
  .shape(
    {
      project_name: Yup.string().nullable().when(["client_id", "squad_name", "start_date", "end_date", "adjourning_motivation", "tools_and_permissions"], {
        is: (client_id, squad_name, start_date, end_date, adjourning_motivation, tools_and_permissions) => client_id || squad_name || start_date || end_date || adjourning_motivation || tools_and_permissions,
        then: Yup.string().required(),
      }),
      client_id: Yup.string().nullable().when(["project_name", "squad_name", "start_date", "end_date", "adjourning_motivation", "tools_and_permissions"], {
        is: (project_name, squad_name, start_date, end_date, adjourning_motivation, tools_and_permissions) => project_name || squad_name || start_date || end_date || adjourning_motivation || tools_and_permissions,
        then: Yup.string().required(),
      }),
      start_date: Yup.string().nullable().when(["project_name", "client_id", "squad_name", "end_date", "adjourning_motivation", "tools_and_permissions"], {
        is: (project_name, client_id, squad_name, end_date, adjourning_motivation, tools_and_permissions) => project_name || client_id || squad_name || end_date || adjourning_motivation || tools_and_permissions,
        then: Yup.string().required(),
      }),
      tools_and_permissions: Yup.string().nullable(),
      end_date: Yup.string().nullable().when(["end_date", "start_date", "adjourning_motivation"], (end_date, start_date, adjourning_motivation, schema) => {
        if (start_date && end_date && (end_date < start_date)) {
          return schema.min(start_date, `${i18n.t("yup.schemas.date.min_date")}`);
        }
        if (adjourning_motivation) return schema.required()

        return schema;
      }),
      on_going: Yup.boolean().when(["project_name", "client_id", "squad_name", "tools_and_permissions", "end_date"], {
        is: (project_name, client_id, squad_name, tools_and_permissions, end_date) => (project_name || client_id || squad_name || tools_and_permissions) && !end_date,
        then: Yup.boolean().oneOf([true], i18n.t("userForm.messageForm.error.company_history_record.on_going")),
      }),
      adjourning_motivation: Yup.string().nullable().when("end_date", (end_date, schema) => {
        if (end_date) return schema.required()
        return schema;
      }),
    },
    [
      //cycle dependencies
      ["project_name", "client_id"],
      ["project_name", "squad_name"],
      ["project_name", "start_date"],
      ["project_name", "end_date"],
      ["project_name", "adjourning_motivation"],
      ["project_name", "tools_and_permissions"],
      ["client_id", "squad_name"],
      ["client_id", "start_date"],
      ["client_id", "end_date"],
      ["client_id", "adjourning_motivation"],
      ["client_id", "tools_and_permissions"],
      ["squad_name", "start_date"],
      ["squad_name", "end_date"],
      ["squad_name", "adjourning_motivation"],
      ["squad_name", "tools_and_permissions"],
      ["start_date", "end_date"],
      ["start_date", "adjourning_motivation"],
      ["start_date", "tools_and_permissions"],
      ["end_date", "adjourning_motivation"],
      ["end_date", "tools_and_permissions"],
      ["adjourning_motivation", "tools_and_permissions"],
      ["end_date", "end_date"],
    ]
  )
  .from("company_history_records_id", "id")
  .from("destroy", "_destroy");

const CompanyHistoryRecordListSchema = Yup.array().of(
  CompanyHistoryRecordSchema
);

export { CompanyHistoryRecordSchema, CompanyHistoryRecordListSchema };