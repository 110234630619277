import React from "react";
import i18n from "#translate/i18n";
import SelectOptions from "#components/finances/SelectOptions"

export default function PsychologicalSupportSessionsInput({ model, name, registeredAllowances }) {
  const selectionOptions = () => {
    const options = []
    const currentAllowance = registeredAllowances.find(({ name }) => name === 'psychological_support')
    const maxSessions = (currentAllowance.attributes.max_monthly_sessions);

    const sessionsOptions = Array.from({ length: maxSessions - 1 }, (value, index) => index + 2)

    options.push(
      {
        id: 'psychological_support_1_session',
        name: '1 sessão',
        value: 1,
      }
    )

    sessionsOptions.forEach((session) => {
      options.push(
            {
              id: `psychological_support_${session}_session`,
              name: `${session} sessões`,
              value: session
            }
          )
    });

    return options
  }

  return (
    <SelectOptions
      model={model}
      name={name}
      title={i18n.t("psychological_support.monthly_sessions")}
      options={selectionOptions()}
      defaultValue={selectionOptions()[0]['value']}
      className={'w-full'}
      requiredField={true}
    />
  );
}
