import React from "react"
import Loading from '#images/loading.svg'
import i18n from '#translate/i18n';

export default function LoadingPage({ customHeight, customWidth, customImageClass, customTextClass }) {

  return (
    <div className={`flex flex-col items-center justify-center bg-white ${customHeight ? customHeight : 'h-screen'} ${customWidth}`}>
      <img className={`${customImageClass || "w-[89px] h-[89px]"}`} src={Loading} alt='loading' />
      <span className={`mt-5 font-semibold leading-6 text-soft-light-gray ${customTextClass || "text-xl"}`}>
        {i18n.t('navigation.loading')}
      </span>
    </div>
  );
}