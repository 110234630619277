const onboardingFields = {
  user: {
    nickname: "",
    birth_date: "",
  },
  picture: {
    face_picture: "",
    body_picture: "",
  },
  clothing: {
    t_shirt_size: "",
    sweatshirt_size: "",
    pants_size: "",
  },
  delivery_address: {
    zip_code: "",
    street: "",
    house_number: "",
    complement: "",
    country: "",
    state: "",
    city: "",
    neighborhood: "",
    reference_point: "",
  },
  personal_preference: {
    hobby_ids: [],
  },
};

export { onboardingFields };
