import * as Yup from "yup";
import i18n from "#translate/i18n";
import YupPhone from "#utils/yup/types/phone";

Yup.setLocale(i18n.t("yup.schemas", { returnObjects: true }));

const EmergencyContactSchema = Yup.object()
  .shape({
    name: Yup.string().required(),
    relationship: Yup.string().required(),
    DDD_landline: Yup.string(),
    landline: YupPhone().landline(),
    DDD_phone_number: Yup.string().required(),
    phone_number: YupPhone().mobilePhone().required(),
    observations: Yup.string().nullable(),
  })
  .from("emergency_contacts_id", "id")
  .from("destroy", "_destroy");

const EmergencyContactListSchema = Yup.array().of(EmergencyContactSchema);

export { EmergencyContactSchema, EmergencyContactListSchema };