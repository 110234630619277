import React, { useContext } from 'react'
import i18n from "#translate/i18n"
import moment from 'moment';
import 'moment/locale/pt-br';

import expenseAssistanceIsUpdatable from "#services/finances/expenseAssistanceIsUpdatable"

import { UserContext } from "#providers/UserContextProvider"

import moneyIcon from '#images/profile_drawer/money-icon.svg';
import calendarIcon from '#images/calendar-blue.svg';
import ExpenseAssistanceIcon from '#images/events_icons/expense_assistance.svg'

import OptionsMenu from '#components/user/profile/remunerationTab/OptionsMenu';

export default function ExpenseAssistance({
  userData,
  expenseAssistanceData,
  closeDrawers,
  handleOpenedDrawersFromProfile,
  setOpenRemovalConfirmation,
  setChosenObjectRemoval,
}) {
  const { setObjectId } = useContext(UserContext)
  const todayStart = moment(new Date()).startOf('day')
  const frequency = expenseAssistanceData.frequency
  const reason = expenseAssistanceData.reason
  const rewardType = expenseAssistanceData.payment_type
  const paymentValue = expenseAssistanceData.assistance_monthly_value || 0
  const isUpdatable = expenseAssistanceIsUpdatable({data: expenseAssistanceData, refDate: todayStart})

  return (
    <div id={`user-expense-assistance-${rewardType}`} className='w-full py-4 px-0 rounded border border-solitude gap-4 grid' >
      <div className='py-0 px-4 gap-3 flex' >
        <div className='gap-3 flex w-full' >
          <div className='h-[50px] w-[50px] flex justify-center items-center mt-auto mb-auto' >
            <div className='rounded-full shadow-timeline-icons'>
              <img
                src={ExpenseAssistanceIcon}
                alt="event icon"
                className='w-12 h-12 p-1'
              />
            </div>
          </div>
          <div className='w-[90%]'>
            <p className='font-sans text-lg/[30px] font-semibold text-left text-midnight w-[90%] absolute' >
              {reason}
            </p>
            <OptionsMenu
              setOpenRemovalConfirmation={setOpenRemovalConfirmation}
              setObjectId={setObjectId}
              setChosenObjectRemoval={setChosenObjectRemoval}
              chosenObjectData={expenseAssistanceData}
              objectType='ExpenseAssistance'
              isRemovable={isUpdatable}
              isUpdatable={isUpdatable}
              handleOpenedDrawersFromProfile={handleOpenedDrawersFromProfile}
              userData={userData}
              closeDrawers={closeDrawers}
            />
            <div className='flex gap-2 items-center mt-8' >
              <div className='flex gap-2 mr-2'>
                <img src={moneyIcon} alt='money_image' />
                <p className='font-sans text-sm/6 font-normal text-left text-soft-light-gray' >
                  {Number(paymentValue).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                </p>
              </div>
              <div className='flex gap-2'>
                <img src={calendarIcon} alt='calendar_image' />
                <p className='font-sans text-sm/6 font-normal text-left text-soft-light-gray' >
                  {i18n.t(`userProfile.remuneration_tab.expense_assistances.frequency.${frequency}`)}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
