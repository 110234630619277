export default function formatPhoneNumber(phoneNumber){
  const PHONE_REGEX = /\((\d{2})\)\s?(\d*-?\d*)|(\d{2})\s?(\d*-?\d*)/;
  // (11) 1111-1111 | 11 1111-1111
  // group1 group2 | group3 group4
  const group = phoneNumber.match(PHONE_REGEX);

  if (group) {
    const areaCode = group[1] || group[3];
    const number = group[2] || group[4];
    return `(${areaCode.substring(0,2)}) ${number}`;
  }

  return phoneNumber;
};