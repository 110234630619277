/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react";
import { useFormContext } from "react-hook-form";

export default function ElementsSearchButton({ defaultText, buttonId }) {
  const [selected, setSelected] = useState(false)
  const { register, setValue, getValues } = useFormContext();

  useEffect(() => {
    setSelected(getValues(buttonId))
  }, [])

  return (
    <div className={`flex gap-2 items-center py-1 px-[0.625rem] font-medium text-smh-8 border border-grey rounded-2xl
      ${selected && selected != 'false' ? 'text-white bg-rebase-blue' : 'text-black-report bg-white'}`}
      id={`${buttonId.split('.')[1]}`}
    >
      <button
        {...register(buttonId)}
        type='button'
        id={buttonId}
        onClick={() => {
          if([false, 'false'].includes(getValues(buttonId))) {
            setValue(buttonId, true)
            setSelected(true)
          } else {
            setValue(buttonId, false)
            setSelected(false)
          }
        }}
      >
        {defaultText}
      </button>
    </div>
  );
}
