import React from "react";
import i18n from "#translate/i18n";

export default function HobbiesList({ hobbies }) {
  return (
    <div className="flex flex-wrap gap-4 p-4 w-full bg-white">
      {hobbies.map((hobby) => {
        if (hobby) {
          return (
            <div
              key={hobby.id}
              className="flex gap-2 items-center py-1 px-[0.625rem] font-medium text-sm text-hobby-gray h-8 border border-alice-blue rounded-2xl shadow-custom"
            >
              <img
                src={hobby.picture_path}
                alt={`${i18n.t("userProfile.hobbies")} ${hobby.name}`}
                width="24"
                height="24"
              />
              {hobby.name}
            </div>
          );
        }
        return null;
      })}
    </div>
  );
}