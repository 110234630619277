import React, { useEffect, useState } from "react"
import i18n from "#translate/i18n"
import SearchButton from "#components/buttons/medium/ElementsSearchButton"
import fetchWorkFields from "#requests/fetchWorkFields";

export default function WorkField() {
  const [workFields, setWorkFields] = useState([]);

  useEffect(() => {
    const loadFormData = async () => {

      fetchWorkFields()
      .then((parsedResponse) => {
        setWorkFields(parsedResponse["work_fields"]);
    });
    };
    loadFormData()
  }, []);

  return (
    <div className="m-5">
      <p className="text-black-report text-lg font-semibold mb-6">{i18n.t('peopleListing.workFields.title')}</p>
      <div className="flex flex-wrap gap-x-4 gap-y-6">
        {workFields.map((workField) => {
          return (
            <SearchButton defaultText={i18n.t(`peopleListing.workFields.${workField}`)} buttonId={`work_field.${workField}`} key={workField} />
          )
        })}
      </div>
    </div>
  )
}