const translations = {
  title: "Olá, boas vindas ao Rebase Pessoas!",
  subtitle: "Complete os campos e continue o seu cadastro",
  name: "Nome completo",
  email: "Email",
  password: "Crie uma senha",
  password_confirmation: "Confirme a senha",
  access: "Acessar"
}

export { translations }
