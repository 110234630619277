import React from "react";
import i18n from "#translate/i18n";

export default function Clothes({ clothing }) {
  return (
    <div className="text-info-gray font-sans text-sm leading-[26px] tracking-normal text-left p-4 px-5">
      {clothing?.t_shirt_size && (
        <p><span className='font-bold'>{i18n.t('userProfile.shirt')}: </span>{i18n.t(`userProfile.clothing.t_shirt_size.${clothing.t_shirt_size}`)}</p>
      )}
      {/* {clothing?.pants_size && (
        <p><span className='font-bold'>{i18n.t('userProfile.pants')}: </span>{i18n.t(`userProfile.clothing.pants_size.${clothing.pants_size}`)}</p>
      )}
      {clothing?.sweatshirt_size && (
        <p><span className='font-bold'>{i18n.t('userProfile.sweatshirt')}: </span> {i18n.t(`userProfile.clothing.sweatshirt_size.${clothing.sweatshirt_size}`)}</p>
      )} */}
      {clothing?.shoe_size && (
        <p><span className='font-bold'>{i18n.t('userProfile.shoe')}: </span>{i18n.t(`userProfile.clothing.shoe_size.${clothing.shoe_size}`)}</p>
      )}
    </div>
  )
}