import isDateString from '#services/isDateString'

// date format from  'Thu May 16 2024 16:33:56 GMT-0300 (Brasilia Standard Time)' to '2024-05-16T16:33:56-03:00'
export default function formatDateString(dateString){
  if (typeof dateString === 'string' && isDateString(dateString)) {
    let datePattern = /(\w{3}) (\w{3}) (\d{2}) (\d{4}) (\d{2}):(\d{2}):(\d{2}) GMT([+-]\d{4})/;
    let match = dateString.match(datePattern);
    // Extracted components
    let [, , month, day, year, hour, minute, second, timezone] = match;

    // Convert month name to month number
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    let monthNumber = monthNames.indexOf(month) + 1;

    // Create a formatted date string in ISO format
    let formattedDateString = `${year}-${String(monthNumber).padStart(2, '0')}-${day}T${hour}:${minute}:${second}${timezone.slice(0, 3)}:${timezone.slice(3, 5)}`;
    return formattedDateString
  } else {
    return dateString
  }
}
