import React from 'react';
import { registerLocale } from 'react-datepicker';
import pt from 'date-fns/locale/pt';
import RangeDate from '#components/RangeDate'
registerLocale('pt', pt);

export default function ReportRangeDate({startDate, endDate, setDateRange, ...props}) {
  const filterDate = (date) => {
    if(startDate) { return isMonthLastDay(date)}
    return isMonthFirstDay(date)
  }
  function isMonthFirstDay(date) {
    const currentDate = new Date(date)
    const day = currentDate.getDate()
    return day === 1
  };
  function isMonthLastDay(date) {
    if(!startDate) { return false }
    const currentDate = new Date(date)
    const lastDay = new Date(currentDate.getFullYear(), currentDate.getMonth()+1, 0);
    return currentDate > startDate && currentDate.getTime() === lastDay.getTime()
  };

  return (
    <div id='report-range-date' className='relative z-[300]' data-testid='report-range-date'>
      <RangeDate startDate={startDate} endDate={endDate} setDateRange={setDateRange} filterDate={filterDate} {...props} />
    </div>
  );
}
