export default function downloadSpreadSheetml({ response }) {
  const contentDisposition = response.headers['content-disposition'];
  const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
  const fileNameMatches = filenameRegex.exec(contentDisposition);
  let filename = (fileNameMatches != null && fileNameMatches[1]) ? fileNameMatches[1].replace(/['"]/g, '') :'customized_data.xlsx';

  const fileBlob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

  const tempLink = document.createElement('a');
  tempLink.href = window.URL.createObjectURL(fileBlob);
  tempLink.download = filename;

  tempLink.click();

  window.URL.revokeObjectURL(tempLink.href);
}
