import * as Yup from "yup";
import i18n from "#translate/i18n";
import { uniqueValue, listAttributeValuePresence } from "#utils/schemas";

const { errors: errorLabel } = i18n.t("yup.forms", { returnObjects: true });
Yup.setLocale(i18n.t("yup.schemas", { returnObjects: true }));

const BankAccountSchema = Yup.object()
  .shape({
    bank_id: Yup.string().required().nullable(),
    agency_number: Yup.string().required(),
    account_number: Yup.string().required(),
    beneficiary: Yup.string().required(),
    beneficiary_name: Yup.string().when("beneficiary", {
      is: 'other',
      then: (schema) => schema.required(),
    }),
    beneficiary_document_number: Yup.string().when("beneficiary", {
      is: 'other',
      then: (schema) => schema.required(),
    }),
    rg_of_holder: Yup.string().when("beneficiary", {
      is: 'other',
      then: (schema) => schema.required(),
    }),
    account_type: Yup.string().required(),
    account_status: Yup.string().required(),
    pix_key: Yup.string().required(),
  })
  .from("bank_accounts_id", "id")
  .from("destroy", "_destroy");

Yup.addMethod(Yup.object, "uniquePropertyValue", uniqueValue);
Yup.addMethod(Yup.array, "checkListActivePresence", listAttributeValuePresence);

const BankAccountListSchema = Yup.array().of(
    BankAccountSchema.uniquePropertyValue(
        "account_status",
        "active",
        errorLabel.unique_active_bank_account
      )
    ).checkListActivePresence(
        "account_status",
        "active",
        errorLabel.active_bank_account
      );

export { BankAccountListSchema, BankAccountSchema };