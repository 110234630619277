import axios from "axios";
import toFormData from "#requests/helpers/toFormData"

export default function createOrUpdateMealAllowance({ data, user, objectId }) {
  const action = objectId ? "PUT" : "POST";
  const url = objectId ? `/api/v1/users/${user.id}/allowances/${objectId}` : `/api/v1/users/${user.id}/allowances`

  const fetchPayloadData = (data) => {
    const result = {
      allowance_name: 'meal',
      notes: data.meal_allowance['notes'],
      user_id: user.id,
    }
    return result
  }

  const formatPayload = (data) => {
    const sentData = fetchPayloadData(data)
    return sentData;
  };

  const requestOptions = () => {
    const csrf = document
      .querySelector("meta[name='csrf-token']")
      .getAttribute("content");

    return {
      method: action,
      headers: { "X-CSRF-Token": csrf },
      data: toFormData(formatPayload(data), 'allowance'),
    };
  };

  const options = requestOptions();

  return axios({ url, ...options })
}
