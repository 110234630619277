import React from 'react';
import i18n from '#translate/i18n';
import SelectOptions from '#components/finances/SelectOptions'

export default function YearEndPaymentWayInput({ model, name, registeredAllowances, user, handleInstallmentChoice, disabled }) {
  const selectionOptions = () => {
    const options = []
    const currentAllowance = registeredAllowances.find(({ name }) => name === 'year_end_bonus')
    const currentYearWorkRatio = currentAllowance.attributes.worked_bonus_ratio
    const oneInstallmentValue = ((user.salary * 100) * currentYearWorkRatio * (currentAllowance.attributes.salary_percentage)/10000).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    const twoInstallmentsValue = ((user.salary * 100  * 0.5) * currentYearWorkRatio * (currentAllowance.attributes.salary_percentage)/10000).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    options.push(
      {
        id: 'year_end_bonus_one_installment',
        name: `1x ${oneInstallmentValue}`,
        value: 'one_installment',
      },
      {
        id: 'year_end_bonus_two_installments',
        name: `2x ${twoInstallmentsValue}`,
        value: 'two_installments',
      }
    )
    return options
  }

  return (
    <SelectOptions
      model={model}
      name={name}
      title={i18n.t('yearEndBonus.payment_way')}
      options={selectionOptions()}
      onChange={handleInstallmentChoice}
      defaultValue={selectionOptions()[0]['value']}
      className='w-full'
      disabled={disabled}
    />
  );
}
