import * as Yup from "yup";
import i18n from "#translate/i18n";

Yup.setLocale(i18n.t("yup.schemas", { returnObjects: true }));

const clothingSchema = Yup.object().shape({
  t_shirt_size: Yup.string().nullable().required(),
  // sweatshirt_size: Yup.string().nullable().required(),
  // pants_size: Yup.string().nullable().required(),
  shoe_size: Yup.string().nullable().required()
});

export default clothingSchema;
