const fieldsSearchData = {
  dates: {
    hiring_search_start_date: '',
    hiring_search_end_date: ''
  },
  status: {
    active: false,
    dismissed: false,
    on_parental_leave: false,
    on_vacation: false,
    on_work_leave: false,
  },
  project: {
    ActLog: false,
    Brainn: false,
    'Banco BV': false,
    'Campus Code': false,
    'C&A': false,
    Finbits: false,
    Gonddo: false,
    KingKont: false,
    LetzPlay: false,
    Locaweb: false,
    Natus: false,
    Petbrands: false,
    Rebase: false,
    'Satisfied Vagabonds': false,
    SmartFit: false,
    Vagas: false,
    VetWork: false,
    VideoCamp: false,
    Vindi: false,
    Yapay: false,
    Youse: false,
    '99Jobs': false,
  },
  work_field: {
    DEV: false,
    COMMS: false,
    MKT: false,
    SPE: false,
    HR: false,
    UX: false,
    INTERN: false,
    FINANCES: false,
    THIRD_PARTY: false,
    PROJECTS: false,
    ALAN: false,
    EDERSON: false,
    JOAO: false,
  }
};

export { fieldsSearchData };
