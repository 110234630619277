import React from 'react';
import i18n from '#translate/i18n';

export default function BankAccountData({ row }) {
  const allData = row.original ? row.original : {}
  const id = allData.id
  return (
    <div className='flex items-stretch h-[180px] pr-2.5 pt-2'>
      <div className='flex-shrink-0'>
        <div id={`report_bank_name_user_${id}`} className='h-5 font-sans not-italic font-semibold text-sm text-default-gray-2'>{allData.bank_name} - {allData.bank_code}</div>
        <div>
          <span className='h-[18px] font-sans not-italic font-semibold text-xs text-default-gray-2'>{i18n.t('reportDashboard.payroll.bank_data.agency')} </span>
          <span id={`report_bank_agency_user_${id}`} className='font-sans not-italic font-normal text-xs text-[#96A0AA]'>{allData.bank_agency_number}</span>
          <span className='h-[18px] font-sans not-italic font-semibold text-xs text-default-gray-2'> {i18n.t('reportDashboard.payroll.bank_data.account')} </span>
          <span id={`report_bank_account_user_${id}`} className='font-sans not-italic font-normal text-xs text-[#96A0AA]'>{allData.bank_account_number}</span>
        </div>
        <div>
          <span className='h-[18px] font-sans not-italic font-semibold text-xs text-default-gray-2'>{i18n.t('reportDashboard.payroll.bank_data.pix')} </span>
          <span id={`report_bank_pix_key_user_${id}`} className='font-sans not-italic font-normal text-xs text-[#96A0AA]'>{allData.bank_pix_key}</span>
        </div>
        <div>
          <span className='h-[18px] font-sans not-italic font-semibold text-xs text-default-gray-2'>{i18n.t('reportDashboard.payroll.bank_data.beneficiary')} </span>
          <span id={`report_bank_beneficiary_user_${id}`} className='font-sans not-italic font-normal text-xs text-[#96A0AA]'>{allData.bank_beneficiary_name}</span>
        </div>
        <div>
          <span className='h-[18px] font-sans not-italic font-semibold text-xs text-default-gray-2'>{i18n.t('reportDashboard.payroll.bank_data.cpf')} </span>
          <span id={`report_bank_cpf_user_${id}`} className='font-sans not-italic font-normal text-xs text-[#96A0AA]'>{allData.bank_beneficiary_document_number}</span>
        </div>
      </div>
    </div>
  );
}
