import * as Yup from "yup";
import i18n from "#translate/i18n";

Yup.setLocale(i18n.t("yup.schemas", { returnObjects: true }));

const today = new Date();
today.setHours(0, 0, 0, 0)

const SalaryReadjustmentBaseSchema = Yup.object().shape({
  current_value: Yup.number()
                    .typeError(i18n.t("salary_readjustment.forms_error.presence"))
                    .required(i18n.t("salary_readjustment.forms_error.presence")),
  new_value: Yup.number()
                .typeError(i18n.t("salary_readjustment.forms_error.presence"))
                .required(i18n.t("salary_readjustment.forms_error.presence"))
                .when("current_value", (current_value, schema) => {
                  if(current_value){
                    return schema.min(current_value, i18n.t("salary_readjustment.forms_error.value_added"))
                  }
                }),
  valid_from: Yup.date()
                  .nullable()
                  .typeError(i18n.t("salary_readjustment.forms_error.presence"))
                  .required(i18n.t("salary_readjustment.forms_error.presence"))
                  .when("first_readjustment", (first_readjustment, schema) => {
                    const error_message = first_readjustment ? i18n.t("salary_readjustment.forms_error.first_time_date_error") :
                                                               i18n.t("salary_readjustment.forms_error.date_error") 
                    return schema.min(today, error_message)
                  }),
  readjustment: Yup.string(),
  notes: Yup.string(),
});

export default SalaryReadjustmentBaseSchema;
