const translations = {
  usersStatuses: {
    total_not_dismissed: {
      title: "Total de pessoas",
      subtitle: "REBASERS ATIVOS",
    },
    total_on_vacation: {
      title: "Desc. remunerado",
      subtitle: "QTDE. DE PESSOAS",
    },
    total_on_parental_leave: {
      title: "Presença parental",
      subtitle: "QTDE. DE PESSOAS",
    },
    total_on_work_leave: {
      title: "Pessoas afastadas",
      subtitle: "QTDE. DE PESSOAS",
    },
    growth_rate_relative_to_last_month: "vs. mês passado",
  },
  remunerations: {
    payroll: {
      xAxis: "Período",
      title: "Evolução da folha de pagamento",
      subtitle: "Evolução dos investimentos em salário e benefícios",
      legends: {
        salary: "Salário",
        allowances: "Benefícios",
        expense_assistances: "Ajuda de custo",
        rewards: "Premiações",
      },
    },
    allowances: {
      title: "Investimento por benefícios",
      subtitle: "Percentual de investimento por benefício",
      healthcare: 'Prêmio saúde',
      meal: 'Aux. Alimentação',
      home_office: 'Aux. Home Office',
      year_end_bonus: 'Bônus fim de ano',
      vacation: 'Desc. rem.',
    },
    readjustments: {
      title: "Reajustes por período",
      subtitle: "Quantidade de reajustes nos últimos seis meses",
      xAxis: "Período",
      yAxis: "Qtde. de reajustes",
      legends: {
        sum_readjustments: "Quantidade de reajustes",
      }
    },
    healthcare_adhesion: {
      title: "Adesão ao prêmio saúde",
      subtitle: "Cobertura de Rebasers com prêmio saúde",
      legends: {
        ratio_with_healthcare: "Possui plano",
        ratio_without_healthcare: "Sem plano",
      }
    },
    work_field_distribution: {
      title: "Atuação dos Rebasers",
      subtitle: "Distribuição dos Rebasers por atuação",
      legends: {
        dev: "Pessoa desenvolvedora",
        ux: "UX",
        spe: "Especialistas",
        copartner: "Sócios",
        hr: "Time Pessoas",
        comms_mkt: "Comunicação e MKT",
        finances: "Financeiro",
        third_party: "Terceirizada",
        intern: "Estagiário",
        projects: "Projetos",
      }
    },
  },
  upcomingEvents: {
    sectionTitle: "Próximos eventos",
    birth_date: "Aniversário",
    company_anniversary: "Aniversário de empresa",
    start_vacation: "Início Descanso Remunerado",
    return_vacation: "Retorno Descanso Remunerado",
    start_parental_leave: "Início Presença parental",
    return_parental_leave: "Retorno Presença parental",
    start_event_description: "{{startDay}}/{{startMonth}} até {{returnDay}}/{{returnMonth}}",
    company_anniversary_description: " `{{years_in_the_company}} anos em {{day}}/{{month}}"
  },
  absenceListing: {
    distribution: {
      title: "Ausências por período",
      subtitle: "Pessoas ausentes no período",
      xAxis: "Período",
      yAxis: "Qtde.",
      vacation: "Desc. remunerado",
      parental_leave: "Pres. parental",
      work_leave: "Afastamento",
    },
    sectionTitle: "Ausências planejadas",
    title: "Ausências",
    subtitle: "Pessoas ausentes neste momento",
    user: "PESSOAS",
    client: "CLIENTE",
    reason: "MOTIVO",
    startDate: "DATA SAÍDA",
    returnDate: "DATA RETORNO",
    absenceReason: {
      vacation: "Descanso remunerado",
      parental_leave: "Presença parental",
      work_leave: "Afastamento",
    },
  },
  month: {
    January: 'Jan',
    February: 'Fev',
    March: 'Mar',
    April: 'Abr',
    May: 'Mai',
    June: 'Jun',
    July: 'Jul',
    August: 'Ago',
    September: 'Set',
    October: 'Out',
    November: 'Nov',
    December: 'Dez',
  },
}

export { translations }
