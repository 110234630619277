import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import People from "#components/People";
import HomePage from "#components/HomePage";
import Onboarding from "#components/user/Onboarding";
import FillProgress from "#components/user/FillProgress";
import LoginUser from "#components/LoginUser";
import Reports from "#components/finances/dashboards/Reports";
import ProtectedRoutes from "./ProtectedRoutes";
import FirstGuestAccess from "#components/FirstGuestAccess";
// import { SignUp } from "#components/"; <<Desativado temporariamente>>
import { UserContextProvider } from "#providers/UserContextProvider"
import { UserFormsProvider } from "#providers/UserFormsProvider"
import { FeatureFlagsProvider } from "#providers/FeatureFlagsProvider";

export default function IndexRoutes() {
  return (
    <UserContextProvider >
      <UserFormsProvider>
        <FeatureFlagsProvider>
          <Router>
            <Routes>
              <Route element={<ProtectedRoutes />}>
                <Route path="/" element={<People />} />
                <Route path="/pessoas" element={<People />} />
                <Route path="/home_page" element={<HomePage />} />
                <Route path="/onboarding" element={<Onboarding />} />
                <Route path="/user/fill-progress/:userId" element={<FillProgress />} />
                <Route path="/reports" element={<Reports />} />
              </Route>
              <Route path="/login" element={<LoginUser />} />
              <Route path="/first_guest_access" element={<FirstGuestAccess />} />
              {/* <Route path="/signup" element={<SignUp />} /> */}
            </Routes>
          </Router>
        </FeatureFlagsProvider>
      </UserFormsProvider>
    </UserContextProvider>
  );
}
