import * as Yup from "yup";
import {
  ExpenseAssistanceBaseSchema,
} from "./schemas";

const expenseAssistanceSchema = Yup.object().shape({
  expense_assistance: ExpenseAssistanceBaseSchema,
});

export default expenseAssistanceSchema;
