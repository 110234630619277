import * as Yup from "yup";
import {
  VacationBaseSchema,
} from "./schemas";

const addVacationSchema = Yup.object().shape({
  vacation: VacationBaseSchema,
});

export default addVacationSchema;
