import React from 'react';

export default function BasicMonetaryValue({ value }) {
  function valueToCurrency(value) {
    const newValue = value ? +value : 0
    return newValue.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
  }
  return (
    <div className='flex items-center py-1.5 px-3'>
      <div className='flex-shrink-0 w-full'>
        <div className='text-center h-5 font-sans not-italic font-normal text-sm text-default-gray-2'>{valueToCurrency(value)}</div>
      </div>
    </div>
  );
}
