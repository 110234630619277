import React from "react";
import i18n from "#translate/i18n";
import arrowDownBlue from "#images/arrow-down-blue.svg";
import arrowDownGray from "#images/arrow-down-gray.svg";

export default function BtnActions({ disabled }) {

  return (
    <div className="relative">
      <button
        disabled={disabled}
        className={`w-[109px] h-10 flex-none font-sans Helvetica Neue rounded border flex items-center px-3 py-2 
        ${disabled ? 'bg-border-gray border-border-gray text-placeholder-gray' : 'text-rebase-blue border-rebase-blue bg-transparent	'}`}
        data-testid="actions-button"
      >
        <div className="flex items-center justify-center mx-3">
        <h3 className="font-semibold text-base leading-24">
          {i18n.t("buttons.actions")}
        </h3>
        <img
            className="w-3 h-3 ml-3.5"
            src={disabled ? arrowDownGray : arrowDownBlue}
            alt={i18n.t("dropdown_arrow_img")}/>
        </div>
      </button>
    </div>
  );
}
