const translations = {
  dashboard: "Dashboard",
  people: "Pessoas",
  charts: "Relatórios",
  configuration: "Configurações",
  addPerson: "Adicionar",
  expandSidebar: "Expandir menu lateral",
  edit: "Editar perfil",
  perfil: "Ver perfil",
  logout: "Sair do Rebase Pessoas",
  loading: "Buscando informações...",
  notFoundTitle: "Nenhum resultado encontrado",
  notFoundDesc: "Não encontramos nenhum perfil que corresponda a sua busca. Por favor, verifique os filtros utilizados e faça uma nova tentativa."

}

export { translations }
