import * as Yup from "yup";
import {
  HealthcareBaseSchema,
} from "./schemas";

const addHealthcareSchema = Yup.object().shape({
  healthcare: HealthcareBaseSchema,
});

export default addHealthcareSchema;
