/*
  Define validação para o campo ser o único com o valor específico. Ex:

  Yup.addMethod(Yup.object, 'uniquePropertyValue', uniqueValue)

  const mySchema = Yup.array().of(
    Yup.object().shape({
      user: Yup.string(),
      account_status: Yup.string(),
    }).uniquePropertyValue(
      'account_status',
      'active',
      'Apenas uma conta pode estar ativa'
    )
  )
*/
function uniqueValue(propertyName, propertyValue, message) {
  return this.test("unique", message, function (object) {
    if (object[propertyName] !== propertyValue) return true;

    const filtered = this.parent.filter(
      (item) => item[propertyName] === propertyValue
    );

    if (filtered.length === 1) return true;

    return this.createError({
      path: `${this.path}.${propertyName}`,
      message,
    });
  });
}

function listUniqueValues(propertyName, message) {
  return this.test('unique', message, function (value) {
    if (!value || !value[propertyName]) {
      return true;
    }

    const { path } = this;
    const options = [...this.parent];
    const currentIndex = options.indexOf(value);

    const subOptions = options.slice(0, currentIndex);

    if (subOptions.some((option) => option[propertyName] === value[propertyName])) {
      throw this.createError({
        path: `${path}.${propertyName}`,
        message,
      });
    }

    return true;
  })
}

function listAttributeValuePresence(objectAttribute, mandatoryValue, errorMessage) {
  return this.test('checkListMandatoryValue', errorMessage, function (list) {
    const mapper = x => x[objectAttribute]
    const listObjectsAttributeValues = list.map(mapper)
    const isPresent = listObjectsAttributeValues.includes(mandatoryValue)
    if (isPresent) return true;

    const isStatusEmpty = (value) => !value;
    if (listObjectsAttributeValues.every(isStatusEmpty)) return true;

    const idx = list.findIndex((l) => mapper(l) !== mandatoryValue);
    return this.createError({ path: `${this.path}[${idx}].${objectAttribute}`, errorMessage });
  });
};

export { validateOnlyOne, phoneValidation } from "./phones";
export {
  buildDependency,
  validateDateStringMax,
  validateDateStringMin,
  checkListMinimumAttributePresence,
  validateValueNumber,
  validateRejectStartAndEndDatesInterval,
  validateListPresence,
  isValidCPF,
} from "./field_dependencies";
export { validateFileSize, validateFileType } from "./files";
export { uniqueValue };
export { listAttributeValuePresence };
export { listUniqueValues };
export { validateVacationDateStringMin } from "./validateVacationDates";