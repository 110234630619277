import React from "react";
import SalaryUpdateRequestRemovedIcon from '#images/events_icons/salary_update_request_removed.svg';
import i18n from "#translate/i18n";
import { Trans } from "react-i18next"

export default function SalaryUpdateRequestRemoved({ eventData }) {
  const data = eventData?.data
  const adminName = data?.responsible?.full_name
  if(!data) { return }
  const newValue = data?.new_value
  const previousValue = data?.current_value

  const readjustmentValue = data ? (newValue - previousValue).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 0

  return (
    <div className="relative flex-1 my-5 bg-white" id='salary-update-request-removed'>
      <div className="flex items-center">
        <div className='rounded-full mr-[6px] h-fit min-w-fit'>
          <img
            src={SalaryUpdateRequestRemovedIcon}
            alt="event icon"
            className='w-[52px] h-[52px] drop-shadow-[0.3333333432674408px_0.3333333432674408px_1.3333333730697632px_rgba(0,0,0,0.15)]
            fill-[var(--suporte-erro-background,#F9E0E0)]'
          />
        </div>
        <div className="relative z-20 font-inter text-sm leading-[22px] text-info-gray flex-1">
          <p className="font-bold inline-block">
            {`${i18n.t("timelineEvents.salaryUpdateRequestRemoved.title")}`}
          </p>
          <p className="font-normal">
            <Trans
              i18nKey={`${i18n.t("timelineEvents.salaryUpdateRequestRemoved.description")}`}
              values={{ admin: adminName, readjustmentValue: readjustmentValue, }}
            />
          </p>
        </div>
      </div>
    </div>
  );
}
