import React from "react";
import i18n from "#translate/i18n";

export default function DocumentsData({ userData }) {
  const showData = (data) => data ?? i18n.t('userProfile.missing_data')

  return (
    <div className="text-info-gray font-sans text-sm leading-[26px] tracking-normal text-left p-4 px-5" >
      <p>
        <span className="font-bold">
          { i18n.t('userProfile.documents_tab.documents_data.rg_of_holder') }
        </span>
        { showData(userData.rg) }
      </p>

      <p>
        <span className="font-bold">
          { i18n.t('userProfile.documents_tab.documents_data.cpf_of_holder') }
        </span>
        { showData(userData.cpf) }
      </p>
      <p>
        <span className="font-bold">
          {i18n.t('userProfile.documents_tab.documents_data.cnpj')}
        </span>
        { showData(userData.company_information?.cnpj) }
      </p>
    </div>
  )
}
