import React, {useContext} from "react";
import { NavLink } from "react-router-dom";
import i18n from "#translate/i18n";
import arrow from "#images/arrow-top-menu.svg"

import { UserFormsContext } from "#providers/UserFormsProvider"

function BreadCrumbsNested(props) {
  const { closeForms } = useContext(UserFormsContext)

  return (
    <>
      <div id='reports_navlink' className={`pt-3 pb-5 mt-0 text-sm font-medium flex items-center ${props.context?.update || props.context?.register ? 'ml-0' : 'ml-6'}`} >
        <NavLink className="text-default-gray-2" to={"/"} onClick={closeForms}>
          {i18n.t("home")}
        </NavLink>
        <img className="px-1" src={arrow} alt="arrow" />
        <NavLink className="text-default-gray-2" to={"/"} onClick={closeForms}>
          {i18n.t("navigation.people")}
        </NavLink>
        <img className="px-1" src={arrow} alt="arrow" />
        <div className="text-rebase-blue">{props.name}</div>
      </div>
    </>
  );
}

export default BreadCrumbsNested;
