import * as Yup from "yup";
import i18n from "#translate/i18n";

Yup.setLocale(i18n.t("yup.schemas", { returnObjects: true }));

const { errors: errorLabel } = i18n.t("yup.forms", { returnObjects: true });
const today = new Date();
today.setHours(0, 0, 0, 0)

const sanitizeDate = (value, originalValue) => (
  (originalValue && Object.prototype.toString.call(value) === '[object Date]') ? value : null
)

const getNextMonth = refDate => new Date(refDate.getFullYear(), refDate.getMonth() + 1, 1)

const YearEndBonusBaseSchema = Yup.object().shape({
  payment_way: Yup.string().typeError(errorLabel.select_option).required(i18n.t("yearEndBonus.forms_error.presence")),
  first_payment_date_past: Yup.string().required(),
  first_payment_date: Yup.date()
                         .when("first_payment_date_past", {
                            is: (val) => val !== "true",
                            then: Yup.date()
                                     .transform(sanitizeDate)
                                     .required(i18n.t("yearEndBonus.forms_error.presence"))
                                     .nullable()
                                     .min(today, i18n.t("yearEndBonus.forms_error.firstInstallmentPastDate")),
                            otherwise: Yup.date()
                            .transform(sanitizeDate)
                            .required(i18n.t("yearEndBonus.forms_error.presence"))
                            .nullable()
                         }),
  second_payment_date: Yup.date()
                          .transform(sanitizeDate)
                          .nullable()
                          .when("payment_way", (payment_way, schema) => {
                            if(payment_way === 'two_installments'){
                              return schema.required(i18n.t("yearEndBonus.forms_error.presence"))
                            }
                          }).when("first_payment_date", (first_payment_date, schema) => {
                            if (first_payment_date) {
                              return schema.min(getNextMonth(first_payment_date), i18n.t("yearEndBonus.forms_error.secondInstallmentMinDate"))
                            }
                          }),
  notes: Yup.string(),
});

export default YearEndBonusBaseSchema;
