function loginValidation() {
  const url = `/api/v1/login_validation`;
  const csrf = document
    .querySelector("meta[name='csrf-token']")
    .getAttribute("content");
  const headers = { headers: { "X-CSRF-Token": csrf } };
  const loginValidationRequest = async () => {
    const responseReq = await fetch(url, headers).then(async (response) => {
      if (response.ok) {
        return response.json();
      }

      return response;
    });

    return responseReq;
  };

  return loginValidationRequest();
}

export default loginValidation;
