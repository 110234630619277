const translations = {
  header: {
    title: "Exportação de dados",
    subtitle: "Gere uma lista em excel apenas com os dados que selecionar"
  },
  selections: {
    title: "Selecione os dados dos Rebasers que deseja exportar",
    subtitle: "Pesquise ou navegue pelas categorias para encontrar e selecionar as informações desejadas",
    peopleExporting: "Exportando dados de:",
    sections: {
      search: {
        title: "Buscar dados",
        placeholder: "Encontre a opção desejada",
      },
      personal_data: {
        title: "Dados pessoais",
        socioeconomicTitle: 'DADOS SOCIOECONÔMICOS',
        contact_data_title: 'DADOS DE CONTATO',
        addressTitle: 'ENDEREÇO RESIDÊNCIA',
        deliveryAddressTitle: 'ENDEREÇO DE ENTREGA',
        clothingTitle: "VESTUÁRIO",
        GeneralObservationsTitle: "OBSERVAÇÕES GERAIS",
        delivery_address_data: {
          delivery_street: 'Rua',
          delivery_zip_code: 'CEP',
          delivery_house_number: 'Número',
          delivery_complement: 'Complemento',
          delivery_country: 'País',
          delivery_state: 'Estado',
          delivery_city: 'Cidade',
          delivery_neighborhood: 'Bairro',
          delivery_reference_point: 'Ponto de referência',
        },
        basic_personal_data: {
          has_social_name: 'Possui nome social',
          social_name: 'Nome social',
          full_name: 'Nome completo',
          nickname: 'Como gostaria de ser chamado',
          pronouns: 'Pronomes',
          marital_status: 'Estado civil',
          birth_date: 'Data de nascimento',
          lgbtqia_plus: 'Pertence à comunidade LGBTQIAPN+',
          gender_identity: 'Gênero que se identifica',
          cpf: 'Número do CPF',
          rg: 'Número do RG',
        },
        social_economic_data: {
          sharing_home_with: 'Quantas pessoas moram com você',
          race_color: 'Etnia',
          medical_follow_up: 'Acompanhamento médico',
          special_medical_condition: 'Condição médica',
          human_children: 'Possui filhos',
          children_name_birthdate: 'Nome e data de nascimento dos filhos',
        },
        contact_data: {
          landline: 'Telefone fixo',
          phone_number: 'Telefone celular',
          personal_email: 'E-mail pessoal',
          corporate_email: 'E-mail corporativo',
          emergency_contact_name: 'Nome contato de emergência',
          emergency_contact_relationship: 'Relacionamento contato de emergência',
          emergency_contact_landline: 'Telefone fixo (emergência)',
          emergency_contact_phone_number: 'Telefone celular (emergência)',
          emergency_contact_observations: 'Observações (emergência)',
        },
        address_data: {
          street: 'Rua',
          zip_code: 'CEP',
          house_number: 'Número',
          complement: 'Complemento',
          country: 'País',
          state: 'Estado',
          city: 'Cidade',
          neighborhood: 'Bairro',
          reference_point: 'Ponto de referência',
        },
        clothing_data: {
          t_shirt_size: "Tamanho de camiseta",
          shoe_size: "Tamanho de calçado",
        },
        general_observations_data: {
          health_notes: "Possui alguma particularidade",
          general_notes: "Observações gerais",
          allergies: "Possui alguma alergia",
        },
      },
      bank_account_data: {
        title: "Dados bancários",
        bank_code_and_name: "Código e nome do banco",
        agency_number: "Número da agência",
        account_number: "Número da conta",
        pix_key: "Chave PIX da conta",
        beneficiary: "Beneficiário",
        beneficiary_name: "Nome do beneficiário",
        rg_of_holder: "Número do RG do titular",
        beneficiary_document_number: "Número do CPF do titular",
        account_status: "Status da conta",
      },
      company_history_data: {
        title: "Histórico na Rebase",
        AssetControlTitle: "CONTROLE DE ATIVOS",
        projectHistoryTitle: "HISTÓRICO DE PROJETOS",
        basic_company_history_data: {
          hiring_date: "Data de início na Rebase",
          company: "Empresa em que atua",
          work_field: "Atuação",
          initial_remuneration: "Remuneração inicial",
          current_remuneration: "Remuneração atual",
          graduate_class: "Turma Campus Code",
          mattermost_username: "Usuário Mattermost",
          github_user: "Usuário Github",
        },
        asset_control_data: {
          service_tag: "Numeração Service Tag",
        },
        projects_history_data: {
          project_name: "Nome do projeto",
          client_name: "Parceiro",
          squad_name: "Nome do time (squad)",
          start_date: "Início no projeto",
          end_date: "Término do projeto",
          adjourning_motivation: "Motivo do término",
          tools_and_permissions: "Ferramentas/acessos"
        }
      },
      company_data: {
        title: "Informações da empresa",
        addressTitle: "ENDEREÇO DA EMPRESA",
        basic_company_data: {
          corporate_name: "Razão social da empresa",
          cnpj: "CNPJ da empresa",
          type_corporate: "Tipo de empresa",
          municipal_registration: "Inscrição municipal",
          state_registration: "Inscrição estadual",
          collect_iss: "Recolhe ISS"
        },
        company_address_data: {
          email: "E-mail",
          company_street: "Rua",
          company_zip_code: "CEP",
          company_house_number: "Número",
          company_complement: "Complemento",
          company_country: "País",
          company_state: "Estado",
          company_city: "Cidade",
          company_neighborhood: "Bairro"
        }
      },
      personal_preferences_data: {
        title: "Preferências",
        hobbies: "Hobbies",
        preferred_movies_series: "Filmes, séries e desenhos favoritos",
        preferred_bands_singers: "Cantores e bandas preferidas",
        best_trip: "Melhor viagem da sua vida",
        wish_list: "Coisas que gosta de ganhar",
        personal_collections: "Coleciona algo",
        likes_social_media: "Curte compartilhar conteúdo nas redes",
        favorite_color: "Cor favorita",
        day_night_preference: "Pessoa diurna ou noturna",
        about_you: "Fale sobre você",
        preferred_sport: "Esperte favorito",
        preferred_club: "Time do coração",
        preferred_videogame: "Joga videogame/Jogos preferidos",
        preferred_foods: "Tipo de comida que gosta de comer",
        preferred_beers: "Gosta de cerveja",
        preferred_juices: "Sucos que mais gosta de tomar",
        preferred_fruits: "Frutas favoritas",
        preferred_beverages: "Bebidas preferidas (em geral)",
        avoided_foods: "Alimento que não gosta e/ou restrição",
      },
    },
    select_all: "Selecionar todos",
    select_clean: "Limpar seleção",
  },
  image: {
    personHeader: "Pessoa observando dados",
    personHeaderBackgroud: "Fundo verde",
    notes: "Imagem dados",
  },
  file_name: {
    placeholder: "Ex: planilha_camisetas",
    title: "Nome do arquivo",
  }
}

export { translations }
