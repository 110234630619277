import React from "react";

function TermsOfUseModal() {
  const openModal = () => {
    const modal = document.getElementById("terms-of-use-modal");
    modal.classList.remove("hidden");
  };

  const closeModal = () => {
    const modal = document.getElementById("terms-of-use-modal");
    modal.classList.add("hidden");
  };

  return (
    <>
      <button
        type="button"
        id="terms-of-use-button"
        className="ml-1 text-rebase-navy-blue decoration-solid hover:cursor-pointer font-semibold underline"
        onClick={openModal}
      >
        Termos de Uso
      </button>

      <div
        id="terms-of-use-modal"
        className="fixed hidden z-50 inset-0 bg-gray-900 bg-opacity-60 overflow-y-auto h-full w-full px-4"
      >
        <div className="relative top-40 mx-auto shadow-lg rounded-md bg-white max-w-md">
          <div className="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
            <h5
              className="text-xl font-medium leading-normal text-gray-800"
              id="mattermostModalLabel"
            >
              Termos de Uso
            </h5>
            <button
              type="button"
              className="btn-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
              onClick={closeModal}
            />
          </div>

          <div name="modal-body" className="max-h-48 overflow-y-scroll p-4">
            Em construção
          </div>

          <div className="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">
            <button
              type="button"
              id="quit-button"
              className="px-6 py-2.5 bg-red-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-purple-700 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
              onClick={closeModal}
            >
              Fechar
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default TermsOfUseModal;
