// import i18n from "#translate/i18n";
import axios from "axios";
import toFormData from "#requests/helpers/toFormData"
import formatDate from "#services/formatDate";

export default function createOrUpdateWorkLeave({ data, model, user, objectId }) {
  const EmptyFile = class {};

  const action = objectId ? "PUT" : "POST";

  const createUrl = `/api/v1/users/${user.id}/work_leaves`
  const updateUrl = `/api/v1/users/${user.id}/work_leaves/${objectId}`

  const url = objectId ? updateUrl : createUrl

  const getFileData = (source, attribute) => {
    const fileData = source[attribute]
    if (fileData instanceof File) return fileData;
    if (fileData instanceof FileList) return (fileData[0] || new EmptyFile());
    if (fileData instanceof Blob) return fileData;
    if (fileData === undefined && source[`${attribute}_update`]) return ''

    return new EmptyFile();
  };


  const removeEmptyFile = (data) => {
    Object.values(data).forEach((obj) => {
      Object.entries(obj).forEach(([key, value]) => {
        if (value instanceof EmptyFile) delete obj[key];
      });
    });
  };

  const formatPayload = (data) => {
    let new_data = { work_leave: data.work_leave }
    let formated_data = {
      start_date: formatDate(data.work_leave.start_date),
      foreseen_return_date: formatDate(data.work_leave.foreseen_return_date),
      documentation: getFileData(data.work_leave, 'documentation')
    }
    let keys = Object.keys(formated_data)
    keys.map(x => { new_data.work_leave[x] = formated_data[x] })
    removeEmptyFile(new_data);
    const sentData = {
      ...new_data.work_leave,
      user_id: user.id,
    };

    return sentData;
  };

  const requestOptions = () => {
    const csrf = document
      .querySelector("meta[name='csrf-token']")
      .getAttribute("content");

    return {
      method: action,
      headers: { "X-CSRF-Token": csrf },
      data: toFormData(formatPayload(data), model),
    };
  };

  const options = requestOptions();

  return axios({url, ...options })
}
