import React from "react";
import BankAccountFields from "../fields/BankAccountFields";
import CompanyInformationFields from "../fields/CompanyInformationFields";
import CompanyOwnerFields from "../fields/CompanyOwnerFields";
import DivisionLine from "../../DivisionLine";

export default function BankAndCompanyForm({ selectedForm, bankingInstitutions }) {
  const hidden = selectedForm === "bank-company-header" ? "" : "hidden";

  return (
    <div id="bank_and_company" className={hidden}>
      <BankAccountFields bankingInstitutions={bankingInstitutions}/>
      <CompanyInformationFields />

      <DivisionLine />

      <CompanyOwnerFields />
    </div>
  );
}
