import React from "react";
import i18n from "#translate/i18n";
import DateInput from "#components/formComponents/DateInput";
import SelectInput from "#components/formComponents/SelectInput";
import CurrencyInput from "#components/formComponents/CurrencyInput";
import InputText from "#components/formComponents/InputText";

export default function SingleExpenseAssistance({
  paymentTypes,
  model,
  customStyles,
  chosenFrequency
}) {

  return (
    <>
      <div className={`${chosenFrequency === 'recurring' ? "hidden" : ""}`}>
        <InputText
          model={model}
          title={i18n.t("expense_assistance.category")}
          name={`${chosenFrequency === 'recurring' ? "category_hidden" : "category"}`}
          placeholder={i18n.t("expense_assistance.category_placeholder")}
          className='w-full'
        />
      </div>

      <div className={`${chosenFrequency === 'recurring' ? "hidden" : ""}`}>
        <InputText
          model={model}
          title={i18n.t("expense_assistance.reason")}
          name={`${chosenFrequency === 'recurring' ? "reason_hidden" : "reason"}`}
          placeholder={i18n.t("expense_assistance.reason_placeholder")}
          className='w-full'
          requiredField={true}
        />
      </div>

      <div className={`${chosenFrequency === 'recurring' ? "hidden" : ""}`}>
        <SelectInput
          model={model}
          name={`${chosenFrequency === 'recurring' ? "payment_type_hidden" : "payment_type"}`}
          options={paymentTypes}
          title={i18n.t("expense_assistance.payment_type")}
          liststyle={customStyles}
          className='w-full'
          requiredField={true}
        />
      </div>

      <div className={`${chosenFrequency === 'recurring' ? "hidden" : ""}`}>
        <CurrencyInput
          model={model}
          name={`${chosenFrequency === 'recurring' ? "assistance_monthly_value_hidden" : "assistance_monthly_value"}`}
          title={i18n.t("expense_assistance.assistance_monthly_value")}
          requiredField={true}
        />
      </div>

      <div className={`${chosenFrequency === 'recurring' ? "hidden" : ""}`}>
        <DateInput
          model={model}
          name={`${chosenFrequency === 'recurring' ? "payment_start_date_hidden" : "payment_start_date"}`}
          title={i18n.t("expense_assistance.payment_start_date")}
          classStyle='w-full'
          requiredField={true}
        />
      </div>
    </>
  );
}
