import React, { useState, useEffect } from 'react'
import StatusComponent from "#components/homePage/usersStatuses/StatusComponent"
import fetchUsersStatuses from "#requests/users/fetchUsersStatuses"

export default function PeopleStatuses() {
  const [usersStatuses, setUsersStatuses] = useState([]);
  const [loading, setloading] = useState(true);

  const statusesOrder = [
    "total_not_dismissed", "total_on_vacation", "total_on_parental_leave", "total_on_work_leave"
  ]

  useEffect(() => {
    fetchUsersStatuses()
      .then((response) => response.json())
      .then((parsedResponse) => {
        setUsersStatuses(parsedResponse)
        setloading(false)
      });
  }, []);

  return(
    <div className="grid grid-cols-4 gap-x-[22px]">
      {
        !loading && usersStatuses && statusesOrder.map((status) => (
          <div key={status} >
            <StatusComponent status={status} usersStatuses={usersStatuses} />
          </div>
        ))
      }
    </div>
  )
}
