import React from "react";
import i18n from "#translate/i18n";
import ColorIcon from '#images/color-icon.svg';
import DislikeFoodIcon from '#images/dislike-food-icon.svg';
import FoodIcon from '#images/food-icon.svg';
import DrinkIcon from '#images/drink-icon.svg';
import GameIcon from '#images/game-icon.svg';
import GiftIcon from '#images/gift-icon.svg';
import PlayIcon from '#images/play-icon.svg';
import RoutineIcon from '#images/routine-icon.svg';
import ShareIcon from '#images/share-icon.svg';
import SportIcon from '#images/sport-icon.svg';
import PhoneIcon from '#images/phone-icon.svg';
import BestTripIcon from '#images/best-trip-icon.svg';

export default function PreferenceItens({ personalPreference }) {
  if (!personalPreference) {
    return null;
  }
  const obj = [
    { icon: PlayIcon, translate: 'prefer_movies', keyList: ['preferred_movies_series'] },
    { icon: PhoneIcon, translate: 'prefer_singers', keyList: ['preferred_bands_singers'] },
    { icon: GiftIcon, translate: 'prefer_gifts', keyList: ['wish_list', 'personal_collections'] },
    { icon: ShareIcon, translate: 'share_social_media', keyList: ['likes_social_media'] },
    { icon: ColorIcon, translate: 'favorite_color', keyList: ['favorite_color'] },
    { icon: RoutineIcon, translate: 'routine.title', keyList: ['day_night_preference'] },
    { icon: SportIcon, translate: 'prefer_sport', keyList: ['preferred_sport', 'preferred_club'] },
    { icon: GameIcon, translate: 'prefer_games', keyList: ['preferred_videogame'] },
    { icon: FoodIcon, translate: 'favorite_food', keyList: ['preferred_foods', 'preferred_fruits'] },
    { icon: DrinkIcon, translate: 'favorite_beverages', keyList: ['preferred_beers', 'preferred_juices', 'preferred_beverages',] },
    { icon: DislikeFoodIcon, translate: 'avoid_food', keyList: ['avoided_foods'] },
    { icon: BestTripIcon, translate: 'best_trip', keyList: ['best_trip'] },
  ];
  return (
    <>
      {obj.map((item, index) => {
        const preferences = item.keyList;
        const contentList = [];

        preferences.forEach((key) => {
          if (personalPreference[key] && key !== "day_night_preference") {
            contentList.push(personalPreference[key]);
          } else if (personalPreference[key] && key === "day_night_preference" && personalPreference[key] !== "empty") {
            contentList.push(i18n.t(`userProfile.routine.routineOption.${personalPreference[key]}`))
          }
        });

        if (contentList.length > 0) {
          return (
            <div className="flex mx-5 mb-5 pt-4 border-t" key={index}>
              <img className="flex w-5 h-5" src={item.icon} alt="preference logo" />
              <div className="pl-4">
                <p className="flex items-center text-info-gray text-base font-bold leading-[22px] tracking-normal text-left">
                  {i18n.t(`userProfile.${item.translate}`)}
                </p>
                {contentList.map((content, index) => (
                  <p className="text-info-gray text-sm font-normal leading-[26px] tracking-wide text-left" key={index}>
                    {content}
                  </p>
                ))}
              </div>
            </div>
          );
        }
        return null;
      })}
    </>
  );
}