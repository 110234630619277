const yearEndBonusData = {
  year_end_bonus: {
    notes: "",
    payment_date: "",
    first_payment_date: "",
    second_payment_date: "",
  }
};

export { yearEndBonusData };
