import i18n from "#translate/i18n";
const { errors: errorLabel } = i18n.t("yup.forms", { returnObjects: true });

/*
  Valida um numero de telefone com DDD e adiciona erro no campo ${nome_campo}_complete
*/
const isEmptyValue = (dddNumber, value) => (
  (dddNumber.match(/^\s*$/) || (dddNumber === undefined)) && 
  value.match(/^\s*$/)
  );

function phoneValidation({
  dddField,
  length,
  required = false,
  schema = null,
  context = null,
}) {
  const currentSchema = schema || this || context?.schema;
  const currentContext = context || this;
  return currentSchema

  .test("Required", "Número é obrigatório", function (value, context) {
    const parent = context.parent || currentContext.parent;
    
    if (!required) return true;
    
    const dddNumber = parent[dddField]?.replace(/\(0__\)/, "");
    const phoneNumber = value.replace(/_*-_*/, "");
    
    if (dddNumber && phoneNumber) return true;
    
    const path = context.path || currentContext.path;
    const errorPath = `${path}_complete`;
    return this.createError({
      path: errorPath,
      message: errorLabel.required_number,
    });
  })
  .test("DDD", "DDD inválido", function (value, context) {
    const parent = context.parent || currentContext.parent;
    const path = context.path || currentContext.path;
    const dddRegex = /\(\d\d\)|\d{2}/;
    const dddNumber = parent[dddField];
    if ( isEmptyValue(dddNumber, value) || (dddNumber?.match(dddRegex)) ) return true;

    const errorPath = `${path}_complete`;
    return this.createError({
      path: errorPath,
      message: errorLabel.invalid_ddd,
    });
    })
    .test("phoneLength", "Número inválido", function (value, context) {
      const parent = context.parent || currentContext.parent;
      const path = context.path || currentContext.path;
      const phoneFirstHalf = Math.floor(length / 2) + (length % 2 ? 1 : 0);
      const phoneSecondHalf = length - phoneFirstHalf;
      const expression = String.raw`\d{${phoneFirstHalf}}-\d{${phoneSecondHalf}}`;
      const dddNumber = parent[dddField];

      if (value.match(expression) || isEmptyValue(dddNumber, value)) return true;

      const errorPath = `${path}_complete`;
      return this.createError({
        path: errorPath,
        message: errorLabel.invalid_number,
      });
    });
}

/*
  Aplica validação apenas no enezimo item.
  Ex: phoneName: contacts[0].phone_number
      validationFunction: (value, schema) => console.log("oi!")
*/
function validateOnlyOne(phoneName, validationFunction) {
  return this.test("phoneListValidation", "", (value, context) => {
    if (phoneName !== context.path) return true;

    return validationFunction(context.schema, context).validate(value);
  });
}

export { validateOnlyOne, phoneValidation };