import axios from "axios";
import toFormData from "./helpers/toFormData";

export default function createOrUpdateUserRequest(values, userId) {
  const EmptyFile = class {};

  const postData = JSON.parse(JSON.stringify(values));

  const action = userId ? "PUT" : "POST";
  const url = action === "PUT" ? `/api/v1/users/${userId}` : "/api/v1/users";

  const getFileData = (source, attribute) => {
    const data = source[attribute]
    if (data instanceof File) return data;
    if (data instanceof FileList) return (data[0] || new EmptyFile());
    if (data instanceof Blob) return data;
    if(data === undefined && source[`${attribute}_update`]) return null

    return new EmptyFile();
  };

  const getFile = (data) => {
    data.user.rg_front = getFileData(values.user, 'rg_front');
    data.user.rg_back = getFileData(values.user, 'rg_back');
    data.user.cpf_front = getFileData(values.user, 'cpf_front');
    data.user.cpf_back = getFileData(values.user, 'cpf_back');
    data.user.residence_proof = getFileData(values.user, 'residence_proof');
    data.user.service_contract = getFileData(values.user, 'service_contract');
    data.user.cnpj_card = getFileData(values.user, 'cnpj_card');
    data.user.lgpd_contract = getFileData(values.user, 'lgpd_contract');
    data.user.image_usage_agreement = getFileData(values.user, 'image_usage_agreement');
    data.picture.face_picture = getFileData(values.picture, 'face_picture');
    data.picture.body_picture = getFileData(values.picture, 'body_picture');
  };

  const removeEmptyFile = (data) => {
    Object.values(data).forEach((obj) => {
      Object.entries(obj).forEach(([key, value]) => {
        if (value instanceof EmptyFile) delete obj[key];
      });
    });
  };

  const removeEmptyChild = (data) => {
    if (!data.descendants) { return [] }

    const descendants = data.descendants.map((child) => {
      if (child.full_name || child.birthdate) { return child }
    })
    data.descendants = descendants.filter(n => n)
  }

  const removeEmptyCompanyInformation = (data) => {
    if (Object.values(data.company_information).join('') == '') delete data.company_information
  }

  const formatPhoneValues = (data) => {
    const contacts = data.contacts;
    const emergency_contacts = data.emergency_contacts;

    contacts?.forEach((contact) => {
      const DDDContactLandline = contact?.DDD_landline?.trim();
      const DDDContactPhone = contact?.DDD_phone_number?.trim();
      const contactLandline = contact?.landline?.trim();
      const contactPhone = contact?.phone_number?.trim();

      contact.landline = `${DDDContactLandline} ${contactLandline}`;
      contact.phone_number = `${DDDContactPhone} ${contactPhone}`;

      delete contact.DDD_landline;
      delete contact.DDD_phone_number;
    });

    emergency_contacts?.forEach((emergency_contact) => {
      const DDDEmergencyLandline = emergency_contact?.DDD_landline?.trim();
      const DDDEmergencyPhone = emergency_contact?.DDD_phone_number?.trim();
      const emergencyLandline = emergency_contact?.landline?.trim();
      const emergencyPhone = emergency_contact?.phone_number?.trim();

      emergency_contact.landline = `${DDDEmergencyLandline} ${emergencyLandline}`;
      emergency_contact.phone_number = `${DDDEmergencyPhone} ${emergencyPhone}`;

      delete emergency_contact.DDD_landline;
      delete emergency_contact.DDD_phone_number;
    });
  };

  const formatDayNightPreference = (postData) => {
    if (postData.personal_preference.day_night_preference === "select_option") {
      postData.personal_preference.day_night_preference = "empty";
    }
  };

  const defineBeneficiaryDocumentType = (data) => {
    data.bank_accounts.forEach((account, index) => {
      const document = account.beneficiary_document_number;
      !!document && (
        document.length > 14 ?
          (data.bank_accounts[index].beneficiary_document_type = "cnpj")
          : (data.bank_accounts[index].beneficiary_document_type = "cpf")
      )
    });
  };

  const formatPayload = (data) => {
    userId && (data.user.id = userId);

    const sentData = {
      ...data.user,
      picture_attributes: data.picture,
      contacts_attributes: data.contacts,
      emergency_contacts_attributes: data.emergency_contacts,
      address_attributes: data.address,
      delivery_address_attributes: data.delivery_address,
      clothing_attributes: data.clothing,
      bank_accounts_attributes: data.bank_accounts,
      company_information_attributes: data.company_information,
      company_history_attributes: data.company_history,
      company_history_records_attributes: data.company_history_records,
      personal_preference_attributes: data.personal_preference,
      descendants_attributes: data.descendants,
    };
    return sentData;
  };

  const requestOptions = () => {
    getFile(postData);
    removeEmptyFile(postData);
    formatPhoneValues(postData);
    defineBeneficiaryDocumentType(postData);
    formatDayNightPreference(postData);
    removeEmptyChild(postData)
    removeEmptyCompanyInformation(postData)

    const csrf = document
      .querySelector("meta[name='csrf-token']")
      .getAttribute("content");

    return {
      method: action,
      headers: { "X-CSRF-Token": csrf },
      data: toFormData(formatPayload(postData)),
    };
  };

  const options = requestOptions();
  
  return axios({url, ...options})
}