import React from "react";
import UploadFile from "../../uploadFile/UploadFile";
import face from "#images/face.svg";
import body from "#images/body.svg";
import DivisionTitle from "../DivisionTitle";
import i18n from "#translate/i18n";

export default function Pictures() {
  const model = "picture";

  return (
    <>
      <div id="pictures">
        <DivisionTitle title={i18n.t("pictures.title")} />

        <div className="grid grid-cols-2 gap-y-3 gap-x-4">
          <UploadFile
            model={model}
            id="face_picture"
            name="face_picture"
            documentImage={face}
            documentType={i18n.t("pictures.type.face")}
            requiredField={ true }
            alt={i18n.t("pictures.alt.face")}
          />

          <UploadFile
            model={model}
            id="body_picture"
            name="body_picture"
            documentImage={body}
            documentType={i18n.t("pictures.type.body")}
            requiredField={ true }
            alt={i18n.t("pictures.alt.body")}
          />
        </div>
      </div>
    </>
  );
}
