import React from "react";
import i18n from "#translate/i18n";

const ReferenceModal = ({
  title,
  image,
  description,
  alt,
  testIdReference,
}) => {
  const [modalActive, setModalActive] = React.useState(false);

  const ModalAction = () => {
    return (
      <span className="pl-1">
        (
        <button
          className="text-rebase-blue underline underline-offset-2 font-normal cursor-pointer text-sm"
          onClick={() => setModalActive(true)}
          id={testIdReference}
        >
          {i18n.t("reference_modal_title")}
        </button>
        )
      </span>
    );
  };

  const Modal = () => {
    return (
      <div
        id="reference-modal"
        className="fixed top-[65%] left-1/2 -translate-y-2/4 -translate-x-2/4 flex justify-center items-center w-screen h-screen bg-black-opacity-0.8"
      >
        <div
          id="Modal"
          className="rounded-2.5xl shadow-lg my-5 w-117.2 h-fit bg-white"
        >
          <img src={image} alt={alt} className="object-contain h-80 w-[95%] mx-auto"/>
          <div className="px-6 py-6">
            <h1 className="text-xl font-bold pb-3">{title}</h1>
            <p className="text-sm font-medium">{description}</p>
            <div className="flex flex-row-reverse">
              <button
                className="text-strong-blue font-semibold underline cursor-pointer hover:opacity-70 tracking-wide-lg"
                onClick={() => setModalActive(false)}
                id="close-modal"
              >
                {i18n.t("modal.actions.close").toUpperCase()}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <ModalAction />
      {modalActive && <Modal />}
    </>
  );
};

export default ReferenceModal;
