import React from "react";
import parentalLeaveIcon from '#images/events_icons/parental_leave.svg';
import i18n from "#translate/i18n";
import { Trans } from "react-i18next"
import userPresentedName from '#services/userPresentedName';

export default function ParentalLeaveReturn({ eventData, userData }) {
  const data = eventData?.data
  const eventUserData = data.user || userData
  const userName = userPresentedName(eventUserData)

  return (
    <>
      <div className="relative flex-1 my-6 bg-white">
        <div className="flex items-center">
          <div className='rounded-full shadow-timeline-icons mr-2 h-fit min-w-fit'>
            <img
              src={parentalLeaveIcon}
              alt="event icon"
              className='w-12 h-12 p-1'
            />
          </div>
          <div className="relative z-20 font-inter text-sm leading-[22px] text-info-gray">
            <p className="font-bold ">
              {`${i18n.t("timelineEvents.parentalLeaveReturn.title")}`}
            </p>
            <p className="font-normal">
              <Trans
                i18nKey={`${i18n.t("timelineEvents.parentalLeaveReturn.description")}`}
                values={{ userName: userName }}
              />
            </p>
          </div>
        </div>
      </div>
    </>
  );
}