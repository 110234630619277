import React from "react";
import CustomizedFieldsOptions from "#components/listings/people/data/drawers/CustomizedFieldsOptions"

export default function BasicPersonalData({fieldsPersonalDataOptions}) {
  return (
    <CustomizedFieldsOptions
      options={fieldsPersonalDataOptions?.basic_personal_data}
      prefix={'personal_data.basic_personal_data'}
    />
  );
}