import React from "react";
import CloseButton from "#images/buttons/button_error_red.svg";
import i18n from "#translate/i18n";

export default function UserFormHeader({
  handleFormHeaderClick,
  selectedForm,
  headersErrors,
}) {
  function hidden(id) {
    return selectedForm === id ? "" : "hidden";
  }
  function actionStyle(header){
    if(headersErrors.includes(header)) { return 'bg-support-error-background' }
    return 'bg-light-gray hover:bg-[#C9D0E4] hover:text-default-gray-2'
  }
  function textStatusStyle(header) {
    if(headersErrors.includes(header)) { return "text-support-error" }
    return `${selectedForm === header ? "text-rebase-blue" : "text-cadet-gray"}`
  }
  function borderBottomStatusStyle(header) {
    if(headersErrors.includes(header)) { return  "border-support-error" }
    return "border-strong-blue";
  }

  return (
    <div className="h-12 bg-light-gray grid grid-cols-5 rounded-t">
      <div className={`${textStatusStyle('personal-data-header')} ${actionStyle('personal-data-header')}`}>
        <button
          onClick={handleFormHeaderClick}
          id="personal-data-header"
          className='text-sm p-3.5 font-sans font-bold flex flex-row w-full'
        >
          <div className="pointer-events-none flex w-full justify-center">
            DADOS PESSOAIS
            <img
              id='close_error_message'
              src={CloseButton}
              alt={i18n.t(`error.messageForm.closeError`)}
              className={`w-3 ml-4 pointer-events-none ${headersErrors.includes('personal-data-header') ? '' : 'hidden'}`}
            />
          </div>
        </button>

        <div>
          <hr
            className={`border-t-2 ${borderBottomStatusStyle('personal-data-header')} ${hidden("personal-data-header")} `}
          />
        </div>
      </div>

      <div className={`${textStatusStyle('bank-company-header')} ${actionStyle('bank-company-header')}`}>
        <button
          onClick={handleFormHeaderClick}
          id="bank-company-header"
          className='text-sm p-3.5 font-sans font-bold flex flex-row w-full'
        >
          <div className="pointer-events-none flex w-full justify-center">
            BANCO E EMPRESA
            <img
              id='close_error_message'
              src={CloseButton}
              alt={i18n.t(`error.messageForm.closeError`)}
              className={`w-3 ml-4 pointer-events-none ${headersErrors.includes('bank-company-header') ? '' : 'hidden'}`}
            />
          </div>
        </button>

        <div>
          <hr
            className={`border-t-2 ${borderBottomStatusStyle('bank-company-header')} ${hidden("bank-company-header")} `}
          />
        </div>
      </div>

      <div className={`${textStatusStyle('history-data-header')} ${actionStyle('history-data-header')}`}>
        <button
          onClick={handleFormHeaderClick}
          id="history-data-header"
          className='text-sm p-3.5 font-sans font-bold flex flex-row w-full'
        >
          <div className="pointer-events-none flex w-full justify-center">
            HISTÓRICO NA REBASE
            <img
              id='close_error_message'
              src={CloseButton}
              alt={i18n.t(`error.messageForm.closeError`)}
              className={`w-3 ml-4 pointer-events-none ${headersErrors.includes('history-data-header') ? '' : 'hidden'}`}
            />
          </div>
        </button>

        <div>
          <hr
            className={`border-t-2 ${borderBottomStatusStyle('history-data-header')} ${hidden("history-data-header")} `}
          />
        </div>
      </div>

      <div className={`${textStatusStyle('personal-document-header')} ${actionStyle('personal-document-header')}`}>
        <button
          onClick={handleFormHeaderClick}
          id="personal-document-header"
          className='text-sm p-3.5 font-sans font-bold flex flex-row w-full'
        >
          <div className="pointer-events-none flex w-full justify-center">
            DOCUMENTOS
            <img
              id='close_error_message'
              src={CloseButton}
              alt={i18n.t(`error.messageForm.closeError`)}
              className={`w-3 ml-4 pointer-events-none ${headersErrors.includes('personal-document-header') ? '' : 'hidden'}`}
            />
          </div>
        </button>

        <div>
          <hr
            className={`border-t-2 ${borderBottomStatusStyle('personal-document-header')} ${hidden("personal-document-header")} `}
          />
        </div>
      </div>

      <div className={`${textStatusStyle('personal-preferences-header')} ${actionStyle('personal-preferences-header')}`}>
        <button
          onClick={handleFormHeaderClick}
          id="personal-preferences-header"
          className='text-sm p-3.5 font-sans font-bold flex flex-row w-full'
        >
          <div className="pointer-events-none flex w-full justify-center">
            PREFERÊNCIAS
            <img
              id='close_error_message'
              src={CloseButton}
              alt={i18n.t(`error.messageForm.closeError`)}
              className={`w-3 ml-4 pointer-events-none ${headersErrors.includes('personal-preferences-header') ? '' : 'hidden'}`}
            />
          </div>
        </button>

        <div>
          <hr
            className={`border-t-2 ${borderBottomStatusStyle('personal-preferences-header')} ${hidden("personal-preferences-header")} `}
          />
        </div>
      </div>
    </div>
  );
}
