import React from "react";
import i18n from "#translate/i18n";
import DivisionTitle from "../../DivisionTitle";
import TextAreaInput from "../../TextAreaInput";

export default function FoodsAndBeveragesFields({ model }) {
  return (
    <>
      <DivisionTitle
        title={i18n.t("personal_preference.foods_and_beverages")}
      />

      <div className="grid grid-cols-2 auto-rows-max gap-y-3 gap-x-4">
        <TextAreaInput
          model={model}
          name="preferred_foods"
          title={i18n.t("personal_preference.preferred_foods")}
          className='w-full'
        />

        <TextAreaInput
          model={model}
          name="preferred_beers"
          title={i18n.t("personal_preference.preferred_beers")}
          className='w-full'
          requiredField={true}
        />

        <TextAreaInput
          model={model}
          name="preferred_juices"
          title={i18n.t("personal_preference.preferred_juices")}
          className='w-full'
        />

        <TextAreaInput
          model={model}
          name="preferred_fruits"
          title={i18n.t("personal_preference.preferred_fruits")}
          className='w-full'
        />

        <TextAreaInput
          model={model}
          name="preferred_beverages"
          title={i18n.t("personal_preference.preferred_beverages")}
          placeholder={i18n.t("placeholders.preferred_beverages")}
          className='w-full'
          requiredField={true}
        />

        <TextAreaInput
          model={model}
          name="avoided_foods"
          title={i18n.t("personal_preference.avoided_foods")}
          className='w-full'
        />
      </div>
    </>
  );
}
