import React from "react";
import updateUserRequest from "#requests/userProfile/updateUserRequest";

export default function UploadBackground({
  displayProfileBackgroundPreview,
  style,
  userId,
  src,
  setUpdated
}) {
  function handleChange(event) {
    const formData = new FormData();
    formData.append("user[profile_background]", event.target.files[0]);
    const preview = window.URL.createObjectURL(event.target.files[0]);
    displayProfileBackgroundPreview(preview);

    setUpdated(true);
    updateUserRequest(formData, userId);
  }

  return (
    <>
      <input
        type="file"
        accept="image/png, image/jpeg, image/jpg, image/png"
        onChange={handleChange}
        className="hidden"
        id="upload_background_button"
        name="upload_background_button"
      />

      <label className={style} htmlFor="upload_background_button">
        <img
          className="w-9.5 h-9.5 cursor-pointer"
          src={src}
          alt="background_icon"
        />
      </label>
    </>
  );
};