import * as Yup from "yup";
import {
  YearEndBonusBaseSchema,
} from "./schemas";

const addYearEndBonusSchema = Yup.object().shape({
  year_end_bonus: YearEndBonusBaseSchema,
});

export default addYearEndBonusSchema;
