import * as Yup from "yup";
import i18n from "#translate/i18n";
import { YupFileValidation } from "#utils/yup/types/file";

Yup.setLocale(i18n.t("yup.schemas", { returnObjects: true }));

const today = new Date();
today.setHours(0, 0, 0, 0)

const sanitizeDate = (value, originalValue) => (
  (originalValue && Object.prototype.toString.call(value) === '[object Date]') ? value : null
)

const WorkLeaveBaseSchema = Yup.object().shape({
  reason: Yup.string().required(i18n.t("work_leave.form_errors.presence")),
  start_date_past: Yup.string().required(),
  start_date: Yup.date()
                  .when("start_date_past", {
                    is: (val) => val !== "true",
                    then: Yup.date()
                             .transform(sanitizeDate)
                             .nullable()
                             .required(i18n.t("work_leave.form_errors.presence"))
                             .min(today, i18n.t("work_leave.form_errors.start_date_error")),
                    otherwise: Yup.date()
                    .transform(sanitizeDate)
                    .required(i18n.t("work_leave.forms_error.presence"))
                    .nullable()
                  }),
  foreseen_return_date: Yup.date()
                           .transform(sanitizeDate)
                           .nullable()
                           .required(i18n.t("work_leave.form_errors.presence"))
                           .when("start_date", (start_date, schema) => {
                             if (start_date) {
                                const nextDay = new Date(start_date)
                                nextDay.setDate(nextDay.getDate() + 1)

                                return schema.min(nextDay, i18n.t("work_leave.form_errors.min_date_error"))
                              }
                           }),
  documentation: YupFileValidation(),
  notes: Yup.string(),
});

export default WorkLeaveBaseSchema;