import React, { useEffect, useState } from "react"
import i18n from "#translate/i18n"
import SearchButton from "#components/buttons/medium/ElementsSearchButton"

export default function Project() {
  const [clients, setClients] = useState([]);

  useEffect(() => {
    const loadFormData = async () => {

      fetch(`/api/v1/clients`, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      })
        .then((response) => response.json())
        .then((parsedResponse) => setClients(parsedResponse))
    };
    loadFormData()
  }, []);

  return (
    <div className="m-5">
      <p className="text-black-report text-lg font-semibold mb-6">{i18n.t('peopleListing.project.title')}</p>
      <div className="flex flex-wrap gap-x-4 gap-y-6">
        {clients.map((client) => {
          return (
            <SearchButton defaultText={client.name} buttonId={`project.${client.name}`} key={client.id} />
          )
        })}

      </div>
    </div>
  )
}