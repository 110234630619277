import React from "react";
import InputFile from "#components/formComponents/InputFile";

export default function FilledUploadFile({
  fileType,
  url,
  fieldName,
  id,
  model,
  name,
  type,
  accept,
  fileError = false,
  onChange = (f) => f ,
  filePresent}) {
  const imgUrl = (fileType?.match(/image|png|jpg|jpeg/))
   if (imgUrl) {
    return (
      <div className="relative w-full flex justify-center items-center rounded">
        <div className="relative w-full h-[201px]">
          <img className="absolute inset-0 object-cover my-0 mx-auto h-full" src={url} alt={fieldName} id={`${fieldName}_image_with_url`} />
          <div
            className="absolute inset-0 flex justify-center items-center cursor-pointer hover:bg-transparent-gray hover:bg-opacity-25 active:bg-transparent-gray group"
          >
            <InputFile
              model={model}
              name={name}
              type={type}
              id={id}
              onChange={onChange}
              accept={accept}
              filePresent={filePresent}
              fileError={fileError}
            />
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div 
        className="relative flex justify-center items-center w-full"
        style={{height: '100%'}}
      >
        <div className="w-full flex justify-between"
         style={{height: '100%'}}
        >
          <iframe
            style={{width: '100%', height: '100%', position: 'absolute'}}
            src={`${url}#view=fit`}
            title="iFrame pdf"
            id='pdf-with-url'
          />
          <div 
            className="absolute inset-0 flex justify-center items-center cursor-pointer hover:bg-transparent-gray hover:bg-opacity-25 active:bg-transparent-gray group"
          >
            <InputFile
              model={model}
              name={name}
              type={type}
              id={id}
              onChange={onChange}
              accept={accept}
              fileError={fileError}
              filePresent={filePresent}
            />
          </div>
        </div>
      </div>
    );
  }
}
