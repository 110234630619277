/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from "react-chartjs-2";
import i18n from "#translate/i18n";
// import EvolutionMenu from '#components/user/profile/remunerationTab/EvolutionMenu';
import userFinancialEvolution from '#requests/userProfile/userFinancialEvolution';

export default function Remunerations({ userData }) {
  const [remunerationsData, setRemunerationsData] = useState([])

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  useEffect(() => {
    userFinancialEvolution({ userId: userData.id })
      .then((response) => response.json())
      .then((parsedResponse) => setRemunerationsData(parsedResponse?.interval_data))
  }, []);

  const barOptions = {
    locale: 'pt-BR',
    layout: {
      padding: {
        top: -15,
      }
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
        title: {
          display: true,
          text: i18n.t('userProfile.remuneration_tab.remunerations.xAxis'),
          font: {
            size: 16
          }
        },
      },
      y: {
        stacked: true,
        title: {
          display: true,
          text: i18n.t('userProfile.remuneration_tab.remunerations.yAxis'),
          font: {
            size: 16
          }
        },
        ticks: {
          beginAtZero: true,
        },
      },
      secondYAxis: {
        type: 'linear',
        position: 'right',
        ticks: { display: false },
        grid: { display: false }
      },
    },
    plugins: {
      legend: {
        display: true,
        labels: {
          usePointStyle: true,
          pointStyle: 'circle',
          boxWidth: 10,
          boxHeight: 10,
          padding: 38.5,
          fullSize: true,
        },
      },
      title: {
        display: false
      }
    }
  };

  const data = {
    labels: remunerationsData.map((x) => i18n.t(`userProfile.remuneration_tab.remunerations.month.${x.month}`)),
    datasets: [
      {
        label: i18n.t('userProfile.remuneration_tab.remunerations.legends.salary'),
        data: remunerationsData.map((x) => x.salary),
        backgroundColor: '#695CFB',
      },
      {
        label: i18n.t('userProfile.remuneration_tab.remunerations.legends.healthcare'),
        data: remunerationsData.map((x) => x.healthcare),
        backgroundColor: '#00C7F2',
      },
      {
        label: i18n.t('userProfile.remuneration_tab.remunerations.legends.meal'),
        data: remunerationsData.map((x) => x.meal),
        backgroundColor: '#0FCA7A',
      },
      {
        label: i18n.t('userProfile.remuneration_tab.remunerations.legends.home_office'),
        data: remunerationsData.map((x) => x.home_office),
        backgroundColor: '#FBC62F',
      },
      {
        label: i18n.t('userProfile.remuneration_tab.remunerations.legends.year_end_bonus'),
        data: remunerationsData.map((x) => x.year_end_bonus),
        backgroundColor: '#F7A23B',
      },
      {
        label: i18n.t('userProfile.remuneration_tab.remunerations.legends.vacation'),
        data: remunerationsData.map((x) => x.vacation),
        backgroundColor: '#F75D5F',
      }
    ]
  };

  const customLegend = {
    beforeInit(chart) {
      // Get a reference to the original fit function
      const originalFit = chart.legend.fit;
      // Override the fit function
      chart.legend.fit = function fit() {
        // Call the original function and bind scope in order to use `this` correctly inside it
        originalFit.bind(chart.legend)();
        // Change the height
        this.height += 40;
      }
    }
  }

  return (
    <section className="gap-4 grid grid-cols-1 py-5 px-0 my-0 mx-5" id='user-remunerations-evolution' >
      <div className="chart-container">
        <div className='border w-full rounded-t-lg h-[92px]'>
          <div className='flex flex-col absolute w-full px-6 pt-6 pb-4 gap-2'>
            <p className='font-sans text-xl/[24px] font-semibold text-left text-black-report w-[90%]' >
              {i18n.t(`userProfile.remuneration_tab.remuneration_evolution.title`)}
            </p>
            <p className='font-sans text-sm font-normal text-left text-default-gray-2 w-[90%]' >
              {i18n.t(`userProfile.remuneration_tab.remuneration_evolution.subtitle`)}
            </p>
          </div>
          {/* <EvolutionMenu /> */}
        </div>
        <div className='border-l border-r border-b pl-5 pr-8 pb-10 rounded-b-lg mt-0' id='chart-remuneration-evolution'>
          <Bar
            options={barOptions}
            data={data}
            plugins={[customLegend]}
          />
        </div>
      </div>
    </section>
  )
}
