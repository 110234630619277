import React from "react"
import i18n from "#translate/i18n"
import notDismissedIcon from "#images/home_page/statuses/notDismissed.svg"
import onVacationIcon from "#images/home_page/statuses/onVacation.svg"
import onParentalLeaveIcon from "#images/home_page/statuses/onParentalLeave.svg"
import onWorkLeaveIcon from "#images/home_page/statuses/onWorkLeave.svg"

export default function StatusComponent({ status, usersStatuses }) {
  const grow = Number(usersStatuses.growth_rate_relative_to_last_month)
  const growAbsPercentage = Math.abs(grow) * 100
  const diplayGrow = growAbsPercentage < 1 && growAbsPercentage > 0 ? growAbsPercentage.toFixed(1) : growAbsPercentage.toFixed(0)
  const icons = {
    total_not_dismissed: notDismissedIcon,
    total_on_parental_leave: onParentalLeaveIcon,
    total_on_vacation: onVacationIcon,
    total_on_work_leave: onWorkLeaveIcon
  }

  return (
    <div id={`users_${status}`} className="w-full h-full py-4 px-0 rounded border border-solitude gap-4 border-[1px] border-solid bg-white" >
      {icons[status] && usersStatuses.status_distribution && (
        <div className="py-0 px-4 gap-3 h-full flex flex-col" >
          <div className="w-full flex h-full">
            <div className="w-full flex flex-col gap-3" >
              <p className="font-sans text-xl/[24px] font-semibold text-left text-black-report" >
                {i18n.t(`homePage.usersStatuses.${status}.title`)}
              </p>
              <p className="font-sans text-xs/[18px] font-semibold text-left text-soft-light-gray" >
                {i18n.t(`homePage.usersStatuses.${status}.subtitle`)}
              </p>
            </div>
            <div className="h-[50px] w-[50px] flex justify-center items-center mt-auto mb-auto" >
              <div className="rounded-full">
                <img
                  src={icons[status]}
                  alt="event icon"
                  className="w-12 h-12"
                />
              </div>
            </div>
          </div>
          <div className="gap-2 flex items-center">
            <div className="gap-2.5 flex items-center">
              <p className="font-sans text-3xl/[40px] font-bold text-left text-black-report" >
                {Number(usersStatuses.status_distribution[status])}
              </p>
              {
                status === 'total_not_dismissed' && (
                  <div
                    className={`w-auto h-[22px] ${grow > 0 ? "bg-support-positive" : grow < 0 ? "bg-support-error" : "bg-support-frozen"} py-px px-1.5 text-sm rounded-3xl items-center font-sans font-semibold text-white`}
                    id="not-dismissed-growth"
                  >
                    {`${grow > 0 ? "+" : grow < 0 ? "-" : ""}${diplayGrow}%`}
                  </div>
                )
              }
            </div>
            {
              status === 'total_not_dismissed' && (
                <p className="items-center text-soft-light-gray font-normal text-xs/[18px]">
                  {i18n.t(`homePage.usersStatuses.growth_rate_relative_to_last_month`)}
                </p>
              )
            }
          </div>
        </div>
      )}
    </div>
  )
}
