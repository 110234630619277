import React from "react"
import { Link } from "react-router-dom"

export default function Button({to, text, showText, icon, exact, onClick}) {
  const currentUri = window.location.pathname;
  const isCurrentLink = (exact && currentUri.endsWith(to)) || (!exact && currentUri.includes(to))
  const imgCurrentLinkClasses = "sidebar-menu-filter"

  return (
    <Link to={to} className="group flex-none h-6 mb-6 mt-6" onClick={onClick}>
      { isCurrentLink && <div className="bg-rebase-green absolute z-auto rounded-full w-32 h-32 -mt-10 -left-[120px]" /> }
      <div className={`ml-3 mr-2 ${isCurrentLink && 'bg-border-blue'} p-3 rounded-lg flex flex-row font-sans text-white font-semibold group-hover:bg-border-blue`}>
        <img src={icon} className={`${showText && 'mx-2'} ${isCurrentLink && imgCurrentLinkClasses}`} alt={text} />
        {
          showText && <span className={`${isCurrentLink && 'text-rebase-green'} ml-1 content-center`}>{text}</span>
        }
      </div>
    </Link>
  )
}