import * as Yup from "yup";
import i18n from "#translate/i18n";
import { validateRejectStartAndEndDatesInterval } from "../../utils/schemas";

Yup.setLocale(i18n.t("yup.schemas", { returnObjects: true }));

const today = new Date();
today.setHours(0, 0, 0, 0)
Yup.addMethod(Yup.date, "validateRejectStartAndEndDatesInterval", validateRejectStartAndEndDatesInterval);

const ParentalLeaveBaseSchema = Yup.object().shape({
  start_date: Yup.date()
                 .typeError(i18n.t("parental_leave.forms_error.presence"))
                 .required(i18n.t("parental_leave.forms_error.presence"))
                 .min(today, i18n.t("parental_leave.forms_error.date_error"))
                 .validateRejectStartAndEndDatesInterval({
                    attributeName: 'start_date',
                    firstStartDateReference: 'parental_leave_last_start_date_input',
                    firstEndDateReference: 'parental_leave_last_return_date_input',
                    currentEndDateReference: 'parental_leave_return_date_input',
                    errorMessage: i18n.t("parental_leave.forms_error.time_interval")
                  }),
  return_date: Yup.string(),
  notes: Yup.string(),
});

export default ParentalLeaveBaseSchema;
