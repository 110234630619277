/* eslint-disable no-unused-vars */
import * as Yup from "yup";
import i18n from "#translate/i18n";
import { validateDateStringMin } from "#utils/schemas";

Yup.setLocale(i18n.t("yup.schemas", { returnObjects: true }));
Yup.addMethod(Yup.string, "validateDateStringMin", validateDateStringMin);

const DismissalAllowancesSchema = Yup.object().shape({
  soft_delete: Yup.string(),
  extension_complete: Yup.string().required(),
  allowance_data: Yup.object()
                     .nullable()
                     .when('soft_delete', {
                       is: 'true',
                       then: Yup.object().nullable().notRequired(),
                       otherwise: Yup.object()
                                     .nullable()
                                     .when(['allowance_data', 'end_date'], {
                                        is: (allowance_data, end_date) => allowance_data || end_date,
                                        then: Yup.object()
                                                 .typeError(i18n.t("user_dismissal.messageForm.error.presence"))
                                                 .required(i18n.t("user_dismissal.messageForm.error.presence")),
                                        otherwise: Yup.object().nullable().notRequired()
                                     }),
                     }),
  end_date: Yup.string()
                .when('soft_delete', {
                  is: 'true',
                  then: Yup.string().notRequired(),
                  otherwise: Yup.string()
                                .when(['allowance_data', 'end_date'], {
                                  is: (allowance_data, end_date) => allowance_data || end_date,
                                  then: Yup.string()
                                           .when("extension_complete", {
                                             is: (val) => String(val) !== "true",
                                             then: Yup.string()
                                                      .nullable()
                                                      .typeError(i18n.t("user_dismissal.messageForm.error.presence"))
                                                      .required(i18n.t("user_dismissal.messageForm.error.presence"))
                                                      .validateDateStringMin({
                                                        attributeName: "end_date",
                                                        shouldAddDays: false,
                                                        docDateReference: 'extended_allowances_attributes_min_end_date_input',
                                                        errorMessage: i18n.t("user_dismissal.messageForm.error.allowance_date_error")
                                                      }),
                                             otherwise:Yup.string()
                                                          .nullable()
                                                          .typeError(i18n.t("user_dismissal.messageForm.error.presence"))
                                                          .required(i18n.t("user_dismissal.messageForm.error.presence"))
                                           }),
                                  otherwise: Yup.string().notRequired()
                                }),
    }),
}).transform((value, originalValue) => {
  const { id, ...rest } = originalValue;
  return rest;
});

const DismissalAllowancesListSchema = Yup.object().shape({
  has_extended_allowances_attributes: Yup.string().required(i18n.t("user_dismissal.messageForm.error.presence")),
  dismissal_allowances: Yup.array()
                           .when('has_extended_allowances_attributes', {
                             is: 'yes',
                             then: Yup.array()
                               .of(DismissalAllowancesSchema)
                               .test('at-least-one-filled', i18n.t("user_dismissal.messageForm.error.presence"), function(value) {
                                 const isValid = value && value.some(item => item.allowance_data || item.end_date);
                                 if (!isValid) {
                                   return this.createError({
                                     path: `${this.path}[0].allowance_data`,
                                     message: i18n.t("user_dismissal.messageForm.error.presence")
                                   });
                                 }
                                 return true;
                               })
                               .test('at-least-one-filled', i18n.t("user_dismissal.messageForm.error.presence"), function(value) {
                                 const isValid = value && value.some(item => item.allowance_data || item.end_date);
                                 if (!isValid) {
                                   return this.createError({
                                     path: `${this.path}[0].end_date`,
                                     message: i18n.t("user_dismissal.messageForm.error.presence")
                                   });
                                 }
                                 return true;
                               }),
                             otherwise: Yup.array().notRequired(),
                           }),
});

export { DismissalAllowancesSchema, DismissalAllowancesListSchema };
