import axios from "axios";
import toFormData from "#requests/helpers/toFormData"
import formatDate from "#services/formatDate";

export default function createOrUpdateSalaryReadjustment({ data, model, user, objectId }) {
  const action = objectId ? "PUT" : "POST";

  const createUrl = `/api/v1/users/${user.id}/salaries/readjustment_request`
  const updateUrl = `/api/v1/users/${user.id}/salaries/${objectId}`

  const url = objectId ? updateUrl : createUrl

  const formatPayload = (data) => {
    let new_data = { salary_update_request: data.salary_update_request }
    let new_date = { valid_from: formatDate(data.salary_update_request.valid_from) }
    let keys = Object.keys(new_date)
    keys.map(x => { new_data.salary_update_request[x] = new_date[x] })

    const sentData = {
      ...new_data.salary_update_request,
      user_id: user.id,
    };

    return sentData;
  };

  const requestOptions = () => {
    const csrf = document
      .querySelector("meta[name='csrf-token']")
      .getAttribute("content");

    return {
      method: action,
      headers: { "X-CSRF-Token": csrf },
      data: toFormData(formatPayload(data), model),
    };
  };

  const options = requestOptions();

  return axios({url, ...options })
}
