/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useWatch, useFormContext, useFieldArray } from "react-hook-form";
import moment from "moment"
import i18n from "#translate/i18n";
import capitalizeFirstLetter from "#services/capitalizeFirstLetter"
import isDateBygone from "#services/isDateBygone"
import isMonthBygone from "#services/isMonthBygone"
import SelectInput from "#components/formComponents/SelectInput";
import BasicComboboxInput from "#components/formComponents/BasicComboboxInput"
import DateCustomInput from "#components/formComponents/DateCustomInput";
import BtnImageAdd from "#components/formComponents/userForm/BtnImageAdd";
import BtnImageRemove from "#components/formComponents/userForm/BtnImageRemove";
import HiddenInput from "#components/formComponents/HiddenInput";
import fetchDismissalBenefitsAvailable from "#requests/dismissals/fetchDismissalBenefitsAvailable";

import psychologicalSupportIcon from '#images/add_allowances/psychological_support/menu_icon.svg';
import healthcareIcon from '#images/add_allowances/healthcare/menu_icon.svg';
import homeOfficeIcon from '#images/add_allowances/home_office/menu_icon.svg';
import mealIcon from '#images/add_allowances/meal/menu_icon.svg';


export default function UserDismissalSelectBenefits({
  formModel,
  user,
  afterSavingButton,
  triggerValidation,
  editObjectId,
  editDismissalData,
}) {
  const model = "extended_allowances_attributes";
  const addExtendableBenefit = useWatch({ name: `${model}.has_extended_allowances_attributes` });
  const showExtendableBenefits = String(addExtendableBenefit) === "yes" ? true : false
  const { control, setValue, getValues } = useFormContext();
  const watchingAllowances = useWatch({ control, name: `${model}.dismissal_allowances` });
  const watchingLeavingDate = useWatch({ control, name: `${formModel}.exit_date` });

  const { fields, append, remove, update } = useFieldArray({
    control,
    name: `${model}.dismissal_allowances`,
  });

  const newBenefit = {
    allowance_data: "",
    end_date: "",
    extension_complete: "false"
  };

  const [shownBenefits, setShownBenefits] = useState({})
  const [allowancesList, setAllowancesList] = useState([]);
  const [loading, setLoading] = useState(true);
  const availableAllowances = allowancesList.filter((benefit) => String(benefit.available) === 'true')
  const isAfterExitMonth = editObjectId && isMonthBygone(watchingLeavingDate)

  const isExtensionComplete = (endDate) => {
    if(!editObjectId) { return false }

    return isDateBygone(endDate)
  }

  const loadEditData = ({allowances}) => {
    const extendedAllowancesData = editDismissalData.extended_allowances
    const extendedAllowancesLength = extendedAllowancesData.length
    const lastAllowanceIndex = extendedAllowancesLength - 1
    const hasExtendedAllowances = extendedAllowancesLength > 0 ? 'yes' : 'no'

    setValue(`${model}.has_extended_allowances_attributes`, hasExtendedAllowances)

    extendedAllowancesData.forEach((data, index) => {
      const allowanceData = {
        allowance_name: data.allowance_name,
        available: true
      }
      setValue(`${model}.dismissal_allowances.${index}.allowance_data`, allowanceData)
      setValue(`${model}.dismissal_allowances.${index}.end_date`, data.end_date)
      setValue(`${model}.dismissal_allowances.${index}.original_end_date`, data.end_date)
      setValue(`${model}.dismissal_allowances.${index}.allowance_id`, data.id)
      setValue(`${model}.dismissal_allowances.${index}.extension_complete`, isExtensionComplete(data.end_date))
      if (index < lastAllowanceIndex) { append(newBenefit) }
    })

    const newAllowanceList = allowances.map((allowance) => {
      const option = extendedAllowancesData.find((extended) => extended.allowance_name === allowance.allowance_name)
      if(option) { return { ...option, available: true }}

      return allowance
    })

    setAllowancesList(newAllowanceList)
    setLoading(false)
  }

  const allowancesNotRemoved = () => {
    return fields.filter((allowance) => !allowance.soft_delete)
  }

  const fetchLastIndexAvailable = () => {
    const removedAllowances = watchingAllowances?.filter((allowance) => allowance.soft_delete) || []
    const lastIndex = removedAllowances.length ? (availableAllowances.length + removedAllowances.length - 1) : (availableAllowances.length - 1)

    return lastIndex
  }

  useEffect(() => {
    setValue(`${model}.has_extended_allowances_attributes`, '')
    if (!fields.length) { append(newBenefit) }

     if(!editObjectId || editDismissalData) {
      fetchDismissalBenefitsAvailable(user.id)
        .then((parsedResponse) => {
          const allowances = parsedResponse["allowances_for_extension"]

          if(editObjectId) {
            loadEditData({allowances: allowances})
          } else {
            setAllowancesList(allowances);
            if (!allowances.length) { setValue(`${model}.has_extended_allowances_attributes`, 'no') }
            setLoading(false)
          }
        });
    }
  }, [editDismissalData]);

  useEffect(() => {
    if (watchingLeavingDate) {
      if(isAfterExitMonth) {
        setValue(`${model}.min_end_date`, moment(new Date()).startOf('month').startOf('day').toDate())
      } else {
        let referenceDate = moment(watchingLeavingDate)
        let nextMonthDate = referenceDate.add(1, 'months');
        let firstDayNextMonth = nextMonthDate.startOf('month').toDate();
        setValue(`${model}.min_end_date`, firstDayNextMonth)
      }
    }
  }, [watchingLeavingDate]);

  useEffect(() => {
    if (!loading && fields.length && showExtendableBenefits) {
      const chosenAllowances = watchingAllowances.map((allowance) => {
        if (allowance.allowance_data?.allowance_name && !allowance.soft_delete) {
          return { ...allowance, allowance_name: allowance.allowance_data?.allowance_name }
        }
      }).filter(item => item);

      if (!chosenAllowances) { return }
      const chosenNames = chosenAllowances.map(item => item.allowance_name)
      const result = {}
      watchingAllowances.forEach((allowance, index) => {
        const notChosenAllowances = allowancesList.filter((benefit) => !chosenNames.includes(benefit.allowance_name));
        const options = [allowance.allowance_data].filter(item => item?.allowance_name);
        options.push(notChosenAllowances)
        result[`index_${index}`] = options.flat()
      })
      setShownBenefits(result)
    }
    if (afterSavingButton) { triggerValidation() }
  }, [watchingAllowances, addExtendableBenefit, loading])

  const displayAddButton = (allowance, index) => {
    if(isAfterExitMonth) { return false }

    const finalIndex = fetchLastIndexAvailable()
    const allowances = allowancesNotRemoved()
    const lastAllowance = allowances[allowances.length - 1]
    const isLastItem = allowances.length <= 1 || lastAllowance.id === allowance.id

    return (!allowance["soft_delete"] && isLastItem && index !== finalIndex)
  };

  const displayRemoveButton = (allowance, index) => {
    if(isAfterExitMonth) { return false }

    const finalIndex = fetchLastIndexAvailable()
    const isNotLastItem = index !== finalIndex
    const totalNotRemoved = allowancesNotRemoved().length
    const lastPossibleSelection = (index === finalIndex && totalNotRemoved === availableAllowances.length)

    return lastPossibleSelection || (!allowance["soft_delete"] && (isNotLastItem) && totalNotRemoved !== 1)
  }

  const removeField = (allowance, index) => {
    if (!allowance.allowance_id) { return remove(index); }

    const updatedAllowance = { ...allowance, soft_delete: true };
    update(index, updatedAllowance);
  };

  const buttonsStyle = (index) => {
    return index ? {  marginTop: 14, width: '4%', height: 30 } : { marginTop: 34, width: '4%', height: 30 }
  }

  let availableIconMap = {
    'psychological_support': psychologicalSupportIcon,
    'meal': mealIcon,
    'home_office': homeOfficeIcon,
    'healthcare': healthcareIcon
  }

  return (
    <>
      <SelectInput
        model={model}
        name={`has_extended_allowances_attributes`}
        disabled={!allowancesList.length || isAfterExitMonth}
        optionsI18nPrefix={`${formModel}.${model}`}
        options={["yes", "no"]}
        title={i18n.t(`${formModel}.${model}.has_extended_allowances_attributes.title`)}
        className='w-full text-default-gray-2'
        requiredField={true}
      />

      <HiddenInput model={model} name={`min_end_date`} id={`${model}_min_end_date_input`} />

      <div className={`grid grid-cols-1 mt-2.5 mb-12`} id='extended_allowances_attributes'>
        {fields.map((allowance, index) => (
          <div
            className={`flex gap-x-4 w-full ${showExtendableBenefits && !allowance.soft_delete ? "" : "hidden"}`}
            key={`${allowance.id}_${allowance.allowance_name}_fields`}
            id={`extended_allowances_${index}`}
          >
            {shownBenefits[`index_${index}`] &&
              <div className='w-[48%]'>
                <BasicComboboxInput
                  model={`${model}.dismissal_allowances`}
                  optionNameKey={'allowance_name'}
                  name={`${index}.allowance_data`}
                  inputOptions={[shownBenefits[`index_${index}`]].flat()}
                  optionsI18nPrefix={`${formModel}.${model}.allowance_name`}
                  title={index ? '' : i18n.t(`${formModel}.${model}.allowance_name.title`)}
                  onlySelection={true}
                  className='flex-1'
                  index={index}
                  classNameTitle={index ? 'hidden' : ''}
                  selectionDisabled={isAfterExitMonth}
                  disabledText={i18n.t(`${formModel}.${model}.allowance_name.disabled`)}
                  availableIconMap={availableIconMap}
                />
              </div>
            }
            <div className='w-[48%]'>
              <DateCustomInput
                fieldId={`${model}_dismissal_allowances_${index}_end_date_input`}
                model={`${model}.dismissal_allowances`}
                name={`${index}.end_date`}
                title={index ? '' : i18n.t(`${formModel}.${model}.end_date`)}
                dateFormat="MM/yyyy"
                placeholderText={'mm/aaaa'}
                showMonthYearPicker
                dateFormatCalendar={"MM/yyyy"}
                className={"flex items-center"}
                divClassName={"py-2 mt-2 text-md block w-full appearance-none border border-solid border-border-gray box-border rounded focus:border-gray-600 h-11 flex items-center"}
                titleClass={'font font-medium font-Inter leading-5 text-sm text-gray-700'}
                renderMonthContent={(month, shortMonth, longMonth, day) => {
                  const fullYear = moment(day).year();
                  const tooltipText = `Tooltip for month: ${longMonth} ${fullYear}`;

                  return <span title={tooltipText}>{capitalizeFirstLetter(shortMonth)}</span>;
                }}
                disabled={isExtensionComplete(getValues(`${model}.dismissal_allowances.${index}.original_end_date`))}
              />
            </div>
            {displayAddButton(allowance, index) && (
              <BtnImageAdd
                model={model}
                id={`add-benefit-${index}`}
                style={buttonsStyle(index)}
                display={true}
                handleOnClick={(e) => {
                  e.preventDefault()
                  append(newBenefit)
                }}
              />
            )}

            {(!displayAddButton(allowance, index) && displayRemoveButton(allowance, index)) && (
              <BtnImageRemove
                model={model}
                id={`remove-benefit-${index}`}
                style={buttonsStyle(index)}
                handleOnClick={(e) => {
                  e.preventDefault()
                  removeField(allowance, index)
                }}
              />
            )}

            <HiddenInput model={`${model}.dismissal_allowances`} name={`${index}.allowance_id`} />
            <HiddenInput model={`${model}.dismissal_allowances`} name={`${index}.extension_complete`} />
            <HiddenInput model={`${model}.dismissal_allowances`} name={`${index}.soft_delete`} />
          </div>
        ))}
      </div>
    </>
  );
}