const translations = {
  expenseAssistanceDrawer: {
    peopleHeader: "Assistência de custo header",
    coins: "mãos e moedas",
    tickSymbol: 'Adicionando'
  },
  expense_assistance: {
    reason: "Motivo",
    assistance_monthly_value: "Valor",
    assistance_monthly_value_recurring: "Valor mensal",
    frequency_title: "Tipo de ajuda de custo",
    duration_title: "Duração da recorrência",
    category: "Categoria",
    payment_start_date: "Data de início do pagamento",
    payment_type: "Forma de pagamento",
    notes: "Observações",
    reason_placeholder: "Ex: Curso de Elixir",
    category_placeholder: "Ex: Educação",
    notes_placeholder: "Se houver, adicione informações relevantes sobre este pedido",
    frequency: {
      single: "Pontual",
      recurring: "Recorrente",
    },
    duration: {
      undetermined_time: "Tempo indeterminado",
      2: "2 meses",
      3: "3 meses",
      4: "4 meses",
      5: "5 meses",
      6: "6 meses",
      7: "7 meses",
      8: "8 meses",
      9: "9 meses",
      10: "10 meses",
      11: "11 meses",
      12: "12 meses",
      24: "24 meses",
    },
    forms_error: {
      presence: "O preenchimento deste campo é obrigatório",
      currency_number: "O preenchimento deste campo é obrigatório e deve ser um número válido",
      date_error: "A data de pagamento deve ser maior ou igual do que hoje",
    },
    messageForm: {
      success: "Ajuda de custo cadastrada",
      successDataCreated: "Uma nova ajuda de custo foi adicionada para",
    },
  },
  create_expense_assistance: {
    title: "Ajuda de custo",
    subtitle: "Adicione uma nova ajuda de custo para",
    submit_button: "Adicionar ajuda de custo",
  },
  update_expense_assistance: {
    title: "Editar ajuda de custo",
    subtitle: "Edite a ajuda de custo para",
    submit_button: "Salvar Edição",
  },
}

export { translations }
