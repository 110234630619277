import * as Yup from "yup";
import {
  BankAccountListSchema,
  ClothingSchema,
  CompanyHistoryRecordListSchema,
  DescendantListSchema,
  CompanyHistorySchema,
  ContactListSchema,
  DeliveryAddressSchema,
  EmergencyContactListSchema,
  PictureSchema,
  UserAddressSchema,
  UserBaseSchema,
  CompanyInformationSchema,
  PersonalPreferenceBaseSchema
} from "./schemas";

const userSchema = Yup.object().shape({
  user: UserBaseSchema,
  picture: PictureSchema,
  contacts: ContactListSchema,
  emergency_contacts: EmergencyContactListSchema,
  address: UserAddressSchema,
  delivery_address: DeliveryAddressSchema,
  clothing: ClothingSchema,
  bank_accounts: BankAccountListSchema,
  company_history: CompanyHistorySchema,
  company_history_records: CompanyHistoryRecordListSchema,
  descendants: DescendantListSchema,
  company_information: CompanyInformationSchema,
  personal_preference: PersonalPreferenceBaseSchema
});

export default userSchema;
