import React from "react";
import i18n from "#translate/i18n"

import AbsencesList from "#components/homePage/absences/AbsencesList"
import AbsencesGraphic from "#components/homePage/absences/AbsencesGraphic"

export default function AbsencesDistributions() {
  return(
    <>
      <p className="font-sans w-full text-2xl font-semibold text-black-report mb-[22px]">
        {i18n.t("homePage.absenceListing.sectionTitle")}
      </p>
      <div className="w-full h-full flex gap-3 flex-wrap">
        <AbsencesList />
        <AbsencesGraphic />
      </div>
    </>
  )
}