// import React, { Fragment } from "react"
// import i18n from "#translate/i18n"
// import { Popover, Transition } from "@headlessui/react"

// import UserCircleAdd from "#images/user-circle-add.svg"
// import PlusSign from "#images/+.svg"
// import SMSIcon from "#images/sms.svg";

import React from "react"
import i18n from "#translate/i18n"
import NewPersonsImg from '#images/buttons/new_person_add.svg'

export default function NewPerson({ setOpenNewUserForm }) { // setShowInviteModal
  return (
    <div className="flex flex-row items-center bg-rebase-blue rounded ml-3">
      <button
        onClick={ () => setOpenNewUserForm(true) }
        className="bg-rebase-blue w-full h-11 py-2 px-4 rounded flex items-center justify-center text-base font-semibold text-white gap-2.5"
      >
          <img
            src={NewPersonsImg}
            className="h-[18px] w-[18px]" alt={i18n.t("peopleListing.newPerson")}
          />
          <span>{ i18n.t("peopleListing.newPerson") }</span>
      </button>
    </div>
  )
  // return (
  //   <Popover>
  //     <Popover.Button className="flex flex-row items-center bg-rebase-blue h-11 py-3 px-4 ml-3 rounded">
  //       <div className="flex bg-white/50 rounded w-4 h-4 items-center mr-2">
  //         <img src={PlusSign} className="mx-auto w-3 brightness-0 saturate-100 invert" alt="" />
  //       </div>
  //       <span className="font-sans font-semibold text-base text-white">{ i18n.t('peopleListing.newPerson') }</span>
  //     </Popover.Button>
  //     <Transition
  //             as={Fragment}
  //             enter="transition ease-out duration-200"
  //             enterFrom="opacity-0 translate-y-1"
  //             enterTo="opacity-100 translate-y-0"
  //             leave="transition ease-in duration-150"
  //             leaveFrom="opacity-100 translate-y-0"
  //             leaveTo="opacity-0 translate-y-1"
  //           >
  //       <Popover.Panel className="absolute z-10">
  //         <div className="absolute w-56 py-2 rounded bg-white flex flex-col items-center shadow-strg mt-1.5 mr-2 z-50 -right-48">
  //           <button
  //             onClick={ () => setOpenNewUserForm(true) }
  //             className="w-full h-8 font-medium text-xs leading-5 flex items-center justify-start pl-[13px]"
  //           >
  //             <img
  //               className="w-6 h-6 mr-[15px]"
  //               src={ UserCircleAdd }
  //               alt={ i18n.t("add_new_person") }
  //             />
  //             <h3>{ i18n.t("buttons.register_new_person") }</h3>
  //           </button>

  //           <button
  //             onClick={ () => setShowInviteModal(true) }
  //             className="w-full h-8 font-medium text-xs leading-5 flex items-center justify-start pl-[13px]"
  //           >
  //             <img
  //               className="w-6 h-6 mr-[15px]"
  //               src={ SMSIcon }
  //               alt={ i18n.t("invite_img") }
  //             />
  //             <h3>{ i18n.t("buttons.invite_new_person") }</h3>
  //           </button>
  //         </div>
  //       </Popover.Panel>
  //     </Transition>
  //   </Popover>
  // )
}