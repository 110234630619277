/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-key */
import React from 'react';
import i18n from '#translate/i18n';
import { useTable } from 'react-table';

import PersonalData from '#components/finances/dashboards/reports/cells/PersonalData';
import BankAccountData from '#components/finances/dashboards/reports/cells/BankAccountData';
import BasicMonetaryValue from '#components/finances/dashboards/reports/cells/BasicMonetaryValue';

function FinanceTable({ data }) {
  const columns = React.useMemo(
    () => [
      {
        Header: () => <span className='flex ml-[5px]'>{i18n.t('reportDashboard.payroll.personal_data.title')}</span>,
        accessor: 'personal_data',
        Cell: PersonalData,
      },
      {
        Header: () => <span className='flex'>{i18n.t('reportDashboard.payroll.bank_data.title')}</span>,
        accessor: 'bank',
        Cell: BankAccountData,
      },
      {
        Header: () => <span className='flex m-auto w-[68%]'>{i18n.t('reportDashboard.payroll.total_invoice')}</span>,
        accessor: 'total_invoice',
        Cell: BasicMonetaryValue,
      },
      {
        Header: () => <span className='flex m-auto w-4/5'>{i18n.t('reportDashboard.payroll.salary')}</span>,
        accessor: 'salary',
        Cell: BasicMonetaryValue,
      },
      {
        Header: () => <span className='flex m-auto ml-2.5 w-4/5' >{i18n.t('reportDashboard.payroll.healthcare')}</span>,
        accessor: 'healthcare',
        Cell: BasicMonetaryValue,
      },
      {
        Header: () => <span className='flex ml-2'>{i18n.t('reportDashboard.payroll.vacation')}</span>,
        accessor: 'vacation',
        Cell: BasicMonetaryValue,
      },
      {
        Header: () => <span className='flex m-auto w-11/12'>{i18n.t('reportDashboard.payroll.year_end_bonus')}</span>,
        accessor: 'year_end_bonus',
        Cell: BasicMonetaryValue,
      },
      {
        Header: () => <span className='flex m-auto w-[85%]'>{i18n.t('reportDashboard.payroll.salary_readjustment')}</span>,
        accessor: 'salary_readjustment',
        Cell: BasicMonetaryValue,
      },
      {
        Header: () => <span className='flex'>{i18n.t('reportDashboard.payroll.dismissal_installment')}</span>,
        accessor: 'dismissal_installment',
        Cell: BasicMonetaryValue,
      }
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    });

  return (
    <table {...getTableProps()} border='1' id='report-table'
      className='bg-white w-full table-auto box-border p-0 rounded drop-shadow-[0_4px_6px_rgba(15,23,42,0.08)]'
    >
      <thead className='contents py-[9px] px-2 bg-light-gray shadow-[inset_0_-1px_0_rgba(212,219,223,1)]'>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()} className='whitespace-nowrap'>
            {headerGroup.headers.map((column) => {
              const headerFixedPosition = 'left-0 z-[160]'
              const columnPositionStyle = column.id === 'personal_data' ? headerFixedPosition : 'z-[150]'
              return (
                <th
                  scope='col'
                  className={`sticky top-0 bg-light-gray shadow-[inset_0_-1px_0_rgba(212,219,223,1)] h-[52px] font-sans
                    not-italic font-semibold text-xs text-default-gray-2 uppercase py-0 px-2.5 ${columnPositionStyle}`}
                  {...column.getHeaderProps()}
                >
                  {column.render('Header')}
                </th>
              )
            })}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()} className='bg-white shadow-[inset_0_-1px_0_rgba(212,219,223,1)]'>
        {data.length > 0 && rows.map((row, i) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()} className='py-0 px-2 mb-4 bg-white shadow-[inset_0_-1px_0_rgba(212,219,223,1)]'>
              {row.cells.map((cell) => {
                const rowFixedPosition = 'sticky left-0 bg-white shadow-[inset_0_-1px_0_rgba(212,219,223,1)]'
                const columnPositionStyle = cell.getCellProps().key.match(/personal_data/) ? rowFixedPosition : ''
                return (
                  <td {...cell.getCellProps()} className={`px-2.5 py-0 whitespace-nowrap ${columnPositionStyle}`}>
                    {cell.render('Cell')}
                  </td>
                )
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default FinanceTable;
