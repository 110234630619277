export default function toFormData(payload, model = "user") {
  const payloadEntries = Object.entries(payload);
  const formData = new FormData();

  function append(formData, key, value) {
    const formDataKey = `${model}[${key}]`;
    setValue(formData, formDataKey, value);
  }

  function setValue(formData, dataKey, value) {
    if (value instanceof Array) return setArrayValue(formData, dataKey, value);

    if (value instanceof File) return formData.append(dataKey, value);

    if (value instanceof Object) return setObjectValue(formData, dataKey, value);

    return formData.append(dataKey, value);
  }

  function setArrayValue(formData, dataKey, values) {
    const newDataKey = `${dataKey}[]`;

    values.map((value) => setValue(formData, newDataKey, value));
  }

  function setObjectValue(formData, dataKey, object) {
    Object.entries(object).forEach(([key, value]) => {
      const newValue = `${dataKey}[${key}]`;

      setValue(formData, newValue, value);
    });
  }

  payloadEntries.forEach(([key, value]) => {
    append(formData, key, value);
  });

  return formData;
}
