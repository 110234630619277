import React from "react";
import CustomizedFieldOption from "./CustomizedFieldOption"
import transformListRowColumn from "#services/transformListRowColumn"

export default function CustomizedFieldsOptions({ options, prefix }) {
  if(!options) { return }
  const transformedOptions = transformListRowColumn(options, 2)
  return (
    <div className="grid grid-cols-2 gap-4 text-sm font-sans not-italic font-medium text-title-gray lg:mb-4 2xl:mb-0">
      {transformedOptions.map((option) => (
        <div className="flex gap-2.5 h-10 lg:h-fit 2xl:h-10" key={`${option}_name`} >
          <CustomizedFieldOption
            option={option}
            prefix={prefix}
          />
        </div>
      ))}
    </div>
  );
}