import React from "react";

const NextStep = ({ text, type, id = "nextStep", onClick, className = "" }) => {
  return (
    <button
      id={id}
      key={type}
      className={`lg:w-52.6 h-10 lg:self-end font-sans Helvetica Neue px-13
                  font-medium text-base bg-strong-blue text-gray-100 rounded-md
                  border-blue-300 ml-auto ${className}`}
      onClick={onClick}
      type={type}
    >
      {text}
    </button>
  );
};

export default NextStep;
