import React from "react";
import i18n from "#translate/i18n";
import InputText from "../../InputText";
import TextAreaInput from "../../TextAreaInput";
import SelectInput from "../../SelectInput";
import DivisionTitle from "../../DivisionTitle";

export default function DetailPreferencesFields({ model }) {
  return (
    <>
      <DivisionTitle title={i18n.t("personal_preference.detail_preferences")} />

      <div className="grid grid-cols-2 auto-rows-max gap-y-3 gap-x-9">
        <TextAreaInput
          model={model}
          name="preferred_movies_series"
          title={i18n.t("personal_preference.preferred_movies_series")}
          requiredField={true}
          className='w-full'
        />

        <TextAreaInput
          model={model}
          name="preferred_bands_singers"
          title={i18n.t("personal_preference.preferred_bands_singers")}
          requiredField={true}
          className='w-full'
        />

        <TextAreaInput
          model={model}
          name="best_trip"
          title={i18n.t("personal_preference.best_trip")}
          requiredField={true}
          className='w-full'
        />

        <TextAreaInput
          model={model}
          name="wish_list"
          placeholder={i18n.t("placeholders.personal_preference.wish_list")}
          title={i18n.t("personal_preference.wish_list.title")}
          subtitle={i18n.t("personal_preference.wish_list.subtitle")}
          requiredField={true}
          className='w-full'
        />

        <TextAreaInput
          model={model}
          name="personal_collections"
          title={i18n.t("personal_preference.personal_collections")}
          requiredField={true}
          className='w-full'
        />

        <TextAreaInput
          model={model}
          name="likes_social_media"
          placeholder={i18n.t("placeholders.personal_preference.likes_social_media")}
          title={i18n.t("personal_preference.likes_social_media")}
          requiredField={true}
          className='w-full'
        />

        <InputText
          model={model}
          name="favorite_color"
          title={i18n.t("personal_preference.favorite_color")}
          requiredField={true}
          className='w-full'
        />

        <SelectInput
          model={model}
          name="day_night_preference"
          title={i18n.t("day_night_preference.day_night_preference")}
          options={["day", "night"]}
          requiredField={true}
          className='w-full'
        />

        <TextAreaInput
          model={model}
          name="about_you"
          title={i18n.t("personal_preference.about_you")}
          requiredField={true}
          className='w-full'
        />
      </div>
    </>
  );
}
