const inviteUserFields = {
  user: {
    full_name: "",
  },
  contacts: [
    {
      personal_email: "",
      corporate_email: "",
      primary: true,
    },
  ],
  company_history: {
    company: "",
    initial_remuneration: "",
  },
};

export { inviteUserFields };
