import React from "react";
import i18n from "#translate/i18n";
import CustomizedFieldsOptions from "#components/listings/people/data/drawers/CustomizedFieldsOptions"

export default function SocioEconomicData({fieldsPersonalDataOptions}) {
  return (
    <>
      <p
        className="text-sm font-semibold text-title-gray mb-5 font-sans not-italic"
      >
        {i18n.t('customizedDataExport.selections.sections.personal_data.contact_data_title')}
      </p>
      <CustomizedFieldsOptions
        options={fieldsPersonalDataOptions?.contact_data}
        prefix={'personal_data.contact_data'}
      />
    </>
  );
}