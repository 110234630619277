import React, { useRef } from "react";
import i18n from "#translate/i18n";
import useOnClickOutside from '#services/useOnClickOutside';

export default function ReferenceTemplate({
  image,
  alt,
  title,
  description,
  closeReferenceTemplate
}) {
  const referenceModal = useRef()

  useOnClickOutside({
    ref: referenceModal,
    handler: () => closeReferenceTemplate()
  });

  return (
    <div
      id="reference-template"
      className="fixed top-0 left-0 right-0 bottom-0 z-[1400] flex justify-center items-center w-screen h-screen bg-black-opacity-0.8"
    >
      <div
        ref={referenceModal}
        className="rounded-2.5xl shadow-lg my-5 w-117.2 h-fit bg-white"
      >
        <img src={image} alt={alt} className="object-contain h-80 w-[95%] mx-auto"/>
        <div className="px-6 py-6">
          <h1 className="text-xl font-bold pb-3">{title}</h1>
          <p className="text-sm font-medium">{description}</p>
          <div className="flex flex-row-reverse">
            <button
              className="text-strong-blue font-semibold underline cursor-pointer hover:opacity-70 tracking-wide-lg"
              onClick={closeReferenceTemplate}
              id="close-reference-template"
            >
              {i18n.t("modal.actions.close").toUpperCase()}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
