import React from 'react';
import i18n from "#translate/i18n";
import ArrowDown from '#images/arrowdown-top.svg';

export default function Collapsible({
  buttonIcon,
  buttonText,
  collapseComponent,
  blockStyle,
  buttonId,
  openSection,
  setOpenSection,
  section,
}) {
  let expanded = openSection?.[section]
  const setExpanded = (value) => {
    const currentSections = { ...openSection }
    currentSections[section] = value
    setOpenSection(currentSections)
  }

  return (
    <div>
      <div className="bg-lighter-gray border border-solitude text-base font-sans not-italic font-bold h-[52px] lg:h-11 2xl:h-[52px] text-default-gray-2">
        <button
          className="flex justify-between items-center w-full h-full py-2.5 px-5"
          id={buttonId}
          onClick={(e) => {
            e.preventDefault()
            setExpanded(!expanded)
          }}
        >
          <div className="flex items-center gap-2.5" id="component-name">
            <img src={buttonIcon} alt="" />
            <p>{buttonText}</p>
          </div>
          <img
            id="arrow-icon"
            className={`w-3 h-[7.2px] ${expanded ? 'transform rotate-180' : ''}`}
            src={ArrowDown}
            alt={i18n.t("dropdown_arrow_img")}
          />
        </button>
      </div>
      <div className={`${expanded ? blockStyle : 'w-0 h-0'}`}>
        {expanded && collapseComponent}
      </div>
    </div>
  );
}
