import React from "react";
import arrowLeft from "#images/arrow_left.svg";

const PreviousStep = ({
  text,
  id = "previousStep",
  display = true,
  onClick,
  className = "",
}) => {
  return (
    display && (
      <button id={id} type="button" className={className} onClick={onClick}>
        <img src={arrowLeft} alt="" className="inline mr-2.5" />
        {text}
      </button>
    )
  );
};

export default PreviousStep;
