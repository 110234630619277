// Entry point for the build script in your package.json

import Turbolinks from "turbolinks";
Turbolinks.start();

Rails.start();
import Rails from "@rails/ujs";

import * as ActiveStorage from "@rails/activestorage";
ActiveStorage.start();


import React from "react"
import { createRoot } from 'react-dom/client'
import App from "#components/App"

document.addEventListener("DOMContentLoaded", () => {
  const container = document.getElementById('app')
  const root = createRoot(container)
root.render(<App />)
});
