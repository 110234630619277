const bonusData = {
  bonus_reward: {
    reward_type: "",
    bonus_value: "",
    payment_type: "",
    notes: "",
    payment_date: "",
    bonus_date: "",
  },
};

export { bonusData };
