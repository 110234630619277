import * as Yup from "yup";
import {
  WorkLeaveBaseSchema,
} from "./schemas";

const workLeaveSchema = Yup.object().shape({
  work_leave: WorkLeaveBaseSchema,
});

export default workLeaveSchema;
