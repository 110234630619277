import React from "react";
import i18n from "#translate/i18n";
import Field from "./Field";
import AddFile from "#images/add_file.svg";

export default function InputFile({ model, id, name, onChange, accept, fileError = false, filePresent }) {
  return (
    <>
      <Field
        model={model}
        id={id}
        name={name}
        type="file"
        className="hidden"
        onChange={onChange}
        accept={accept}
        uploadFileInput={true}
      />

      {filePresent ? (
        <div className="hidden group-hover:block flex flex-col items-center justify-center z-30">
          <img src={AddFile} alt="" className="relative my-0 mx-auto" id={`${model}_${name}_image_with_url`}/>
          <p className="relative text-white font-inter font-bold text-sm leading-[21px]">
            {i18n.t("change_image")}
          </p>
        </div>
      ) : (
        <u className={`${fileError ? "text-support-error" : "text-rebase-blue"} no-underline text-rebase-blue font-inter text-sm font-semibold leading-5`}>
          {i18n.t("select_file")}
        </u>
      )}
    </>
  );
}
