import React, { useEffect, useState } from "react"
import i18n from "#translate/i18n"
import SearchButton from "#components/buttons/medium/ElementsSearchButton"
import fetchStatusList from "#requests/fetchStatusList";

export default function Status() {
  const [statusList, setStatusList] = useState([]);

  useEffect(() => {
    const loadFormData = async () => {

      fetchStatusList()
      .then((parsedResponse) => {
        setStatusList(parsedResponse["status_list"]);
    });
    };
    loadFormData()
  }, []);

  return (
    <div className="m-5">
      <p className="text-black-report text-lg font-semibold mb-6">{i18n.t('peopleListing.personStatus.title')}</p>
      <div className="flex flex-wrap gap-x-4 gap-y-6">
        {statusList.map((status) => {
          return (
            <SearchButton defaultText={i18n.t(`peopleListing.personStatus.${status}`)} buttonId={`status.${status}`} key={status} />
          )
        })}
      </div>
    </div>
  )
}