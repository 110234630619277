const translations = {
  payment_way: "Forma de pagamento",
  payment_date: 'Data do pagamento',
  first_payment_date: 'Data do 1° pagamento',
  second_payment_date: 'Data do 2° pagamento',
  notes: "Observações",
  notes_placeholder: "Se houver, adicione informações relevantes sobre este benefício",
  forms_error: {
    value_number: "Deve ser um número",
    presence: "O preenchimento deste campo é obrigatório",
    firstInstallmentPastDate: "A data do pagamento não pode ser anterior à data de hoje.",
    secondInstallmentMinDate: "A segunda parcela deve ser paga em meses posteriores ao mês de pagamento da primeira parcela."
  }
}

export { translations }