import * as Yup from "yup";
import {
  ParentalLeaveBaseSchema,
} from "./schemas";

const addParentalLeaveSchema = Yup.object().shape({
  parental_leave: ParentalLeaveBaseSchema,
});

export default addParentalLeaveSchema;
