import formatDate from "#services/formatDate"

export default function addDays(date, days) { // return format <Tue Jun 20 2023 00:00:00 GMT-0300>
  if(!date || !days) {return}
  var result = new Date(`${formatDate(date)}T00:00`);
  const daysToAdd = +days
  result.setDate(result.getDate() + daysToAdd);

  return result;
}
