import React from 'react'
import i18n from '#translate/i18n';
import moment from 'moment';
import 'moment/locale/pt-br';

import RoundPicture from "#components/user/RoundPicture";

export default function CompanyRecords({ companyHistoryRecords, clients }) {
  return (
    <>
      {companyHistoryRecords.map(record => {
        const startDate = moment(record.start_date).locale('pt-br').format('MMMM [de] YYYY');
        const endDate = record.end_date ? moment(record.end_date).locale('pt-br').format('MMMM [de] YYYY') : i18n.t('userProfile.about_tab.on_going_project');
        const companyHistoryRecordClient = clients.find(client => client.id === record.client_id);
        return (
          <div
            className="flex mx-5 mt-5 pb-4 border-b"
            key={record.id}
          >
            {companyHistoryRecordClient && (
              <RoundPicture divStyle="w-[43px] h-[43px] flex" imgStyle='object-contain' src={companyHistoryRecordClient.logo_url} alt="company logo" />
            )}
            <div className="pl-6 ">
              <p className="font-bold text-info-gray text-base leading-6">
                {companyHistoryRecordClient.name}
              </p>
              <p className="text-info-gray text-sm leading-6">
                {record.project_name && record.squad_name ? `${record.project_name} - ${record.squad_name}` : (record.project_name || record.squad_name)}
              </p>
              <p className="text-soft-light-gray text-sm leading-6">
                {record.start_date ? `${startDate} - ${endDate}` : (startDate || endDate)}
              </p>
              {record.adjourning_motivation && (
                <p className="text-sm leading-5 text-info-gray mt-4">
                  <span className="font-bold">{i18n.t('userProfile.reason')}:</span> {record.adjourning_motivation}
                </p>
              )}
              {record.tools_and_permissions && (
                <p className="text-sm leading-5 text-info-gray mt-4">
                  <span className="font-bold">{i18n.t('userProfile.tools')}:</span> {record.tools_and_permissions}
                </p>
              )}
            </div>
          </div>
        );
      })}
    </>
  )
}
