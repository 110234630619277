import React from "react";
import i18n from "#translate/i18n";
import exportIconWhite from "#images/export_icon_white.svg";
import exportIconGray from "#images/export_icon_gray.svg";
import { Link } from "react-router-dom";

export default function BtnExport({ disabled, startDate, endDate, type }) {

  const stringifyStartDate = () => (startDate ? startDate.toISOString() : '')
  const stringifyEndDate = () => (endDate ? endDate.toISOString() : '')


  return (
    <div className="relative">
      <button
        disabled={disabled}
        data-testid="export-button"
        className={`w-[127px] h-10 ml-2 flex-none font-sans Helvetica Neue tx-base rounded
        flex items-center px-3 py-2
        ${disabled ? 'bg-border-gray border-border-gray text-placeholder-gray' : 'text-white border-rebase-blue bg-rebase-blue	'}`}
      >
        <Link className="flex items-center justify-center mx-3" to={`/api/v1/reports/payrolls.xlsx?start_date=${stringifyStartDate()}&end_date=${stringifyEndDate()}&type=${type}`} target="_blank" rel="noopener noreferrer">
          <img
            className="w-[18px] h-[18px]"
            src={disabled ? exportIconGray : exportIconWhite}
            alt={i18n.t("dropdown_arrow_img")}
          />
          <h3 className="font-semibold text-base leading-24 ml-2.5">
            {i18n.t("buttons.export")}
          </h3>
        </Link>
      </button>
    </div>
  );
}