import * as Yup from "yup";
import i18n from "#translate/i18n";

Yup.setLocale(i18n.t("yup.schemas", { returnObjects: true }));

const customizedExportSheetSchema = Yup.object().shape({
  file_name: Yup.string().required(),
});

export default customizedExportSheetSchema;
