import axios from "axios";
import toFormData from "#requests/helpers/toFormData"

export default function createOrUpdateHealthcare({ data, user, model, objectId, imagesIds }) {
  const EmptyFile = class {};
  const currentData = { healthcare: { ...data.healthcare } }
  const action = objectId ? "PUT" : "POST";
  const url = objectId ? `/api/v1/users/${user.id}/allowances/${objectId}` : `/api/v1/users/${user.id}/allowances`

  const fetchPayloadData = (payloadData) => {
    const descendants = payloadData.healthcare.descendants.filter(descendant => descendant && descendant.adding)
    const images = arrangeImages(payloadData.healthcare)
    const result = {
      user: payloadData.healthcare.user,
      descendants: descendants.map((descendant) => {
        const currentDescendant = { ...descendant }
        return currentDescendant
      }),
      cards_images: images,
      allowance_name: model,
      persisted_images_ids: imagesIds
    }
    return result
  }

  const formatPayload = (payloadData) => {
    let new_data = { healthcare: payloadData.healthcare }
    const formatedData = fetchPayloadData(payloadData)

    let keys = Object.keys(formatedData)
    keys.map(x => { new_data.healthcare[x] = formatedData[x] })
    removeEmptyFile(new_data);
    const sentData = {
      ...new_data.healthcare,
      user_id: user.id,
    };

    return sentData;
  };

  const getFileData = (source, attribute) => {
    const fileData = source[attribute]
    if (fileData instanceof File) return fileData;
    if (fileData instanceof FileList) return (fileData[0] || new EmptyFile());
    if (fileData instanceof Blob) return fileData;

    return new EmptyFile();
  };

  const removeEmptyFile = (filesData) => {
    Object.values(filesData).forEach((obj) => {
      Object.entries(obj).forEach(([key, value]) => {
        if (value instanceof EmptyFile) delete obj[key];
      });
    });
  };

  const arrangeImages = (healthcaresData) => {
    let images = []

    const userCard = healthcaresData.user.card_image
    if (userCard) { images.push(getFileData(healthcaresData.user, 'card_image')) }

    const currentDescendants = healthcaresData.descendants
    currentDescendants && currentDescendants.forEach((descendant) => {
      const descendantCard = descendant.card_image
      if (descendant.adding && descendantCard) { images.push(getFileData(descendant, 'card_image')) }
    });
    return images
  };

  const requestOptions = () => {
    const csrf = document
      .querySelector("meta[name='csrf-token']")
      .getAttribute("content");

    return {
      method: action,
      headers: { "X-CSRF-Token": csrf },
      data: toFormData(formatPayload(currentData), 'allowance'),
    };
  };

  const options = requestOptions();

  return axios({ url, ...options })
}
