import React, { useCallback, useRef } from "react"
import i18n from "#translate/i18n"
import debounce from 'lodash.debounce'

import MagnifierIcon from "#images/Lupa.svg"
import ProcessingRequestImage from '#images/buttons/processing_request_secondary.svg'

export default function Search({ setSearch, setSignalAbortRequest, loading, setLoading }) {

  const previousController = useRef();
  const searchHandle = (event) => {
    if (previousController.current) { previousController.current.abort(); }

    const controller = new AbortController()
    setSignalAbortRequest(controller.signal);
    previousController.current = controller;

    setSearch(event.target.value);
  };

  const debounceSearchHandle = useCallback(debounce(searchHandle, 300), [])

  return (
    <div className="flex flex-row items-center w-80 h-11 bg-white px-4 border border-gray-300 focus-within:border-rebase-blue rounded">
      <img src={MagnifierIcon} alt={ i18n.t('peopleListing.magnifierGlass') } className="w-5" />
      <input
        aria-labelledby="filter-search"
        type="text"
        onChange={(event) => {setLoading(true); debounceSearchHandle(event)}}
        className="grow h-6 font-sans text-base font-normal text-slate-800 placeholder:text-slate-400 border-0 focus:outline-none focus:border-0 focus:ring-0"
        placeholder={ i18n.t('peopleListing.search') }
      />
      {loading && (
        <div className="absolute w-72">
          <img
            id="loading-people-search"
            className='w-[18px] h-[18px] mt-auto relative left-[95%]'
            src={ProcessingRequestImage}
            alt={i18n.t('buttons.processing')}
          />
        </div>
      )}
      <label hidden id="filter-search">{ i18n.t('peopleListing.search') }</label>
    </div>
  )
}
