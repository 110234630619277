import React from "react"
import i18n from "#translate/i18n"
import pt from "date-fns/locale/pt"
import DatePicker, { registerLocale } from "react-datepicker"
import { useFormContext } from "react-hook-form";

import CalendarIcon from "#images/calendar-light-icon.svg"

registerLocale("pt", pt)

export default function DateInput({ name, ...props }) {
  const { register, setValue } = useFormContext();

  const CalendarInput = React.forwardRef(({ value, onClick }, ref) => (
    <div className="flex flex-row items-center w-[229px] h-11 bg-white px-[14px] py-[10px] border border-border-gray focus-within:border-rebase-blue rounded gap-x-0.5">
      <img
        className="w-4 mr-0.5 flex-none"
        src={CalendarIcon}
        ref={ref}
        alt={ i18n.t('peopleListing.dateSearch.calendarAlt') }
      />
      <input
        onClick={onClick}
        {...register(name)}
        ref={ref}
        id={`${name}_search-daterange-input`}
        aria-labelledby="filter-company-entrance"
        placeholder={ 'dd/mm/aaaa' }
        defaultValue={value}
        className="flex-1 h-6 w-[173px] text-base font-normal placeholder:text-placeholder-gray border-0 focus:outline-none focus:border-0 focus:ring-0"
      />
      <label hidden id="filter-company-entrance">{ i18n.t('peopleListing.dateSearch.companyEntrance') }</label>
    </div>
  ))

  CalendarInput.displayName = "CalendarInput";

  return (
    <DatePicker
      {...props}
      onChange={(date) => {
        setValue(name, date)
        props.onFieldChange(date);
      }}
      name={name}
      locale="pt"
      dateFormat="dd/MM/yyyy"
      isClearable
      showYearDropdown
      customInput={<CalendarInput  />}
    />
  )
}