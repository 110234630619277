import * as Yup from "yup";
import { validateOnlyOne, phoneValidation } from "../../schemas";

const mobileLength = 9;
const landlineLength = 8;

Yup.addMethod(Yup.string, "phoneNumber", phoneValidation);
Yup.addMethod(Yup.string, "phoneList", validateOnlyOne);

Yup.addMethod(
  Yup.string,
  "mobilePhone",
  function ({ dddField = "DDD_phone_number", required = false } = {}) {
    return this.phoneNumber({
      dddField: dddField,
      length: mobileLength,
      required: required,
    });
  }
);

Yup.addMethod(
  Yup.string,
  "landline",
  function ({ dddField = "DDD_landline", required = false } = {}) {
    return this.phoneNumber({
      dddField: dddField,
      length: landlineLength,
      required: required,
    });
  }
);

/*
  landline: YupPhone().validateOne({
    fieldName: "contacts[0].landline",
    dddField: "DDD_landline",
    length: 8
  }),
*/
Yup.addMethod(
  Yup.string,
  "validateOne",
  function ({ fieldName, dddField, length }) {
    const dddName = dddField || `DDD_${fieldName}`;

    return this.phoneList(fieldName, function (schema, context) {
      return phoneValidation({
        dddField: dddName,
        length: length,
        required: true,
        schema: schema,
        context: context,
      });
    });
  }
);

/*
  landline: YupPhone().firstLandline({
    fieldName: "contacts[0].landline",
    dddField: "DDD_landline",
  }),
*/
Yup.addMethod(
  Yup.string,
  "firstLandline",
  function ({ fieldName, dddField = "DDD_landline" } = {}) {
    return this.validateOne({
      fieldName,
      dddField,
      landlineLength,
    });
  }
);

/*
  landline: YupPhone().firstMobilePhone({
    fieldName: "contacts[0].phone_number",
    dddField: "DDD_phone_number",
  }),
*/
Yup.addMethod(
  Yup.string,
  "firstMobilePhone",
  function ({ fieldName, dddField = "DDD_phone_number" } = {}) {
    return this.validateOne({
      fieldName,
      dddField,
      mobileLength,
    });
  }
);

export default Yup.string;