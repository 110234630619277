import React, { useEffect } from "react";
import { useFormContext, useFieldArray, useWatch } from "react-hook-form";
import i18n from "#translate/i18n";
import InputText from "#components/formComponents/InputText";
import PhoneInput from "#components/formComponents/PhoneInput";
import HiddenInput from "#components/formComponents/HiddenInput";
import DivisionTitle from "#components/formComponents/DivisionTitle";
import DivisionLine from "#components/formComponents/DivisionLine";
import TextAreaInput from "#components/formComponents/TextAreaInput";
import BtnAdd from "../BtnAdd";
import BtnRemove from "../BtnRemove";

export default function EmergencyContactFields() {
  const model = "emergency_contacts";
  const { control, setValue } = useFormContext();
  useWatch({ control, name: model });

  const { fields, append, remove, update } = useFieldArray({
    control,
    name: model,
  });

  const emergencyContactRecord = {
    DDD_landline: "",
    DDD_phone_number: "",
    email: "",
    relationship: "",
    landline: "",
    name: "",
    observations: "",
    phone_number: "",
    primary: false,
  };

  const displayRemoveButton = (emergencyContact) => (
    !emergencyContact.emergency_contacts_id || emergencyContact.status !== 'removed'
  )

  const removeField = (emergencyContact, index) => {
    if (!emergencyContact.emergency_contacts_id) return () => remove(index);

    const statusField = `${model}.${index}.status`;

    // TODO: Find better way to hide element and update state
    return () => {
      update(index, { ...emergencyContact, ["status"]: 'removed' });
      setValue(statusField, 'removed');
    };
  };
  useEffect(() => {
    if (fields.length === 0) {
      const firstEmergencyContact = {...emergencyContactRecord}
      firstEmergencyContact.primary = true
      append(firstEmergencyContact);
    }
  }, [fields]);

  const activeFields = fields.filter((field) => field.status !== 'removed');

  return (
    <>
      <DivisionTitle title={i18n.t("emergency_contact")} />

        {activeFields.map((emergencyContact, index) => (
          <div
            key={emergencyContact.id}
            id={`emergency-contact-${index}`}
            className={emergencyContact.status === 'removed'? "hidden" : ""}
          >
            <p className='h-5 font-sans not-italic font-semibold text-sm text-title-gray'>
              {`${i18n.t("emergency_contact_subtitle")} ${index+1}`}
            </p>
            <div className={'grid grid-cols-2 gap-y-3 gap-x-9 mt-5'}>
              <InputText
                model={model}
                title={i18n.t("contact_name")}
                name={`${index}.name`}
                requiredField={true}
                placeholder={i18n.t("placeholders.emergency_contacts.contact_name")}
                maxLength={"50"}
                className='w-full'
              />


              <InputText
                model={model}
                title={i18n.t("relationship")}
                name={`${index}.relationship`}
                requiredField={true}
                placeholder={i18n.t("placeholders.emergency_contacts.relationship")}
                className='w-full'
              />

              <PhoneInput
                model={model}
                title={i18n.t("landline_phone")}
                name='landline'
                index={index}
                type="landline"
              />

              <PhoneInput
                model={model}
                title={i18n.t("phone")}
                name='phone_number'
                requiredField={true}
                index={index}
                type="mobile"
              />

              {emergencyContact.emergency_contacts_id && (
                <>
                  <HiddenInput model={model} name={`${index}.status`} />
                </>
              )}
            </div>

            <div className={'grid gap-y-3 gap-x-9 mt-5'}>
              <TextAreaInput
                model={model}
                title={i18n.t("observations")}
                name={`${index}.observations`}
                placeholder={i18n.t("placeholders.emergency_contacts.observations")}
                className='w-full'
              />
            </div>

            <div className={ `flex ${ (activeFields.length - 1 === index) && 'gap-6' }` }>
              <BtnAdd
                model={model}
                id={`add-new-emergency-contact-button-${index}`}
                name={i18n.t("buttons.new_emergency_contact")}
                style="mt-6"
                display={activeFields.length - 1 === index}
                handleOnClick={() => append(emergencyContactRecord)}
              />

              {index > 1 && displayRemoveButton(emergencyContact) && (
                <BtnRemove
                  model={model}
                  id={`remove-new-emergency-contact-button-${index}`}
                  name={i18n.t("buttons.remove_emergency_contact")}
                  style="mt-6"
                  handleOnClick={removeField(emergencyContact, index)}
                />
              )}
            </div>
            
          </div>
        ))}
        <DivisionLine />
    </>
  );
}
