const healthcareData = {
  healthcare: {
    user: {
      full_name: '',
      healthcare_value: '',
      adding: true
    },
    descendants: []
  }
};

export { healthcareData };
