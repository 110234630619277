import React, { useState, useEffect } from "react";
import YearEndBonusIcon from '#images/events_icons/year_end_bonus.svg';
import i18n from "#translate/i18n";
import { Trans } from "react-i18next";
import ArrowDown from '#images/arrowdown-top.svg';
import EditIconBlue from "#images/edit-blue.svg"
import EditIconGray from "#images/edit-gray.svg"
import DeleteIconRed from "#images/delete-red.svg"
import DeleteIconGray from "#images/delete-gray.svg"
import TooltipMessageHoverButtons from "#components/TooltipMessageHoverButtons"
import userPresentedName from '#services/userPresentedName';

export default function YearEndBonusCreated({
  eventData,
  userData,
  removedArray,
  setOpenRemovalConfirmation,
  setObjectId,
  setChosenEventRemoval,
  showDetails,
  setShowDetails,
  lastUpdates,
  openEditionDrawer,
}) {
  const data = eventData?.data || {}
  const eventUserData = data.user || userData
  const userName = userPresentedName(eventUserData)
  const adminName = data.responsible?.full_name;
  const installments = data.installments_number;
  const firstPaymentDate = data.first_payment_date || '';
  const secondPaymentDate = data.second_payment_date || '';
  const [year, month, day] = firstPaymentDate.split('-');
  const [year_end, month_end, day_end] = secondPaymentDate.split('-');
  const number = installments === 1 ? 'one' : 'two'
  const description = `timelineEvents.yearEndBonusCreated.${number}.description`
  const notesValue = data.notes
  const [click, setClick] = useState(false)

  const todayStart = new Date()
  todayStart.setHours(0, 0, 0, 0);
  const deadline = secondPaymentDate ? secondPaymentDate : firstPaymentDate;
  const isUpdatable = new Date(`${deadline}T00:00`) >= todayStart
  const isRemovable = new Date(`${firstPaymentDate}T00:00`) >= todayStart
  const wasUpdated = lastUpdates.some((update) => update.id === data.id)
  const isRemoved = removedArray.some((removedItem) => removedItem.id === data.id && removedItem.creationEventName === eventData?.name)

  useEffect(() => {
    if (showDetails === 'close') { setClick(false) }
  }, [showDetails])

  return (
    <>
      <div className="relative flex-1 my-6 bg-white">
        <div className="flex items-center">
          <div className='rounded-full shadow-timeline-icons mr-2 h-fit min-w-fit'>
            <img
              src={YearEndBonusIcon}
              alt="event icon"
              className='w-12 h-12 p-1'
            />
          </div>
          <div className="relative z-20 font-inter text-sm leading-[22px] text-info-gray flex-1">
            <p className="font-bold">
              {i18n.t("timelineEvents.yearEndBonusCreated.title")}
            </p>
            <p className="font-normal">
              <Trans
                i18nKey={`${i18n.t(description)}`}
                values={{
                  admin: adminName,
                  person: userName,
                  installments: installments,
                  day: day,
                  month: month,
                  year: year,
                  day_end: day_end,
                  month_end: month_end,
                  year_end: year_end,
                }}
              />
            </p>
          </div>
          <button
            id='year-end-bonus-created-details-button'
            className='ml-6 mb-3 p-2'
            onClick={() => { setClick(!click) }}
          >
            <img
              className={`w-3 h-[7.2px] right-0 ${click && 'transform rotate-180'} min-w-max`}
              src={ArrowDown}
              alt={i18n.t("dropdown_arrow_img")}
            />
          </button>
        </div>
        {click && (
          <>
            <div className="relative font-inter text-sm leading-6 text-info-gray mt-3 ml-14" id='year-end-bonus-created-details-panel'>
              <div>
                <p className="font-bold">{i18n.t('timelineEvents.yearEndBonusCreated.notes')}</p>
                <p id='year-end-bonus-created-notes'>
                  {notesValue || i18n.t('timelineEvents.yearEndBonusCreated.missingNotes')}
                </p>
              </div>
            </div>

            {isUpdatable && !wasUpdated && (
              <div className='flex space-x-4 justify-end mt-5' id="year-end-bonus-created-manage-buttons">
                <div className="flex space-x-4 group">
                  {isRemoved && (
                    <>
                      <TooltipMessageHoverButtons
                        buttonClass="absolute h-11 w-[370px] right-2"
                        tooltipClass="absolute bg-border-blue z-1 gap-1 text-white items-center text-center w-[490px] text-xs px-3 py-1 h-9 font-semibold
                        rounded-lg bg-[var(--complementares-azul-claro, #587CE2)] shadow-tooltip-buttons ml-[-40px]"
                        arrowClass="absolute ml-[-50px]"
                        messageClass="h-5 pt-1"
                        message={i18n.t('timelineEvents.removedMessage')}
                      />
                    </>
                  )}
                  {isRemovable && (
                    <button
                      className={`justify-center font-sans font-semibold text-base bg-white
                      flex items-center px-4 py-2.5
                      ${isRemoved ? 'text-disabled-gray' : 'text-support-error'}`}
                      id="year-end-bonus-created-remove-button"
                      disabled={isRemoved}
                      onClick={() => { setOpenRemovalConfirmation(true), setObjectId(data.id), setChosenEventRemoval(eventData), setShowDetails(null) }}
                    >
                      <img src={isRemoved ? DeleteIconGray : DeleteIconRed} alt="" className="inline mr-2 w-6 h-6" />
                      {i18n.t('timelineEvents.yearEndBonusCreated.removeYearEndBonus')}
                    </button>
                  )}
                  <button
                    className={`justify-center font-sans font-semibold text-base rounded border flex items-center px-4 py-2.5 ${isRemoved ? 'text-disabled-gray bg-disabled-gray-button' : 'text-rebase-blue border-rebase-blue bg-white'}`}
                    id="year-end-bonus-created-edit-button"
                    disabled={isRemoved}
                    onClick={() => {openEditionDrawer(), setObjectId(data.id)}}
                  >
                    <img src={isRemoved ? EditIconGray : EditIconBlue} alt="" className="inline mr-2 w-6 h-6 stroke-rebase-blue" />
                    {i18n.t('timelineEvents.yearEndBonusCreated.editYearEndBonus')}
                  </button>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
}
