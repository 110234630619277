import React, { useState, useEffect } from "react";
import PsychologicalSupport from '#images/events_icons/psychological_support_updated.svg';
import i18n from "#translate/i18n";
import { Trans } from "react-i18next"
import TooltipMessageHoverButtons from "#components/TooltipMessageHoverButtons";
import ArrowDown from '#images/arrowdown-top.svg';
import EditIconBlue from "#images/edit-blue.svg"
import EditIconGray from "#images/edit-gray.svg"
import DeleteIconRed from "#images/delete-red.svg"
import DeleteIconGray from "#images/delete-gray.svg"

export default function PsychologicalSupportUpdated({
  eventData,
  lastUpdates,
  lastCreation,
  removedArray,
  showDetails,
  setChosenEventRemoval,
  setOpenRemovalConfirmation,
  setObjectId,
  setShowDetails,
  openEditionDrawer,
  isDismissed
}) {
  const data = eventData?.data || {}
  const adminName = data.responsible?.full_name;
  const count = data.monthly_sessions;
  const notes = data.notes;
  const eventDateTime = eventData?.date || ''
  const isTheLastUpdate = lastUpdates.some((update) => update.id === data.id && update.lastUpdatedOn === eventDateTime) && lastCreation('PsychologicalSupportCreated').data.id === data.id
  const isRemoved = removedArray.some((removedItem) => removedItem.id === data.id && removedItem.creationEventName === 'PsychologicalSupportCreated')
  const [click, setClick] = useState(false)

  useEffect(() => {
    if (showDetails === 'close') { setClick(false) }
  }, [showDetails]);

  return (
    <div className="relative flex-1 my-5 bg-white">
      <div className="flex items-center">
        <div className='rounded-full mr-1.5 h-fit min-w-fit'>
          <img
            src={PsychologicalSupport}
            alt="event icon"
            className='w-[52px] h-[52px] drop-shadow-[0.3333333432674408px_0.3333333432674408px_1.3333333730697632px_rgba(0,0,0,0.15)]'
          />
        </div>
        <div className="relative z-20 font-inter text-sm leading-[22px] text-info-gray">
          <p className="font-bold" >
            {`${i18n.t("timelineEvents.psychologicalAssistanceUpdated.title")}`}
          </p>
          <p className="font-normal w-full">
            <Trans i18nKey={
              count > 1 ?
                "timelineEvents.psychologicalAssistanceUpdated.descriptionTotalPlural"
                : "timelineEvents.psychologicalAssistanceUpdated.descriptionTotal"
            } values={{ admin: adminName, count: count }}
            />
          </p>
        </div>
        <button
          id='psychological-support-updated-details-button'
          className='ml-6 mb-3 p-2 ml-auto'
          onClick={() => { setClick(!click), setShowDetails(null) }}
        >
          <img
            className={`w-3 h-[7.2px] right-0 ${click && 'transform rotate-180'} min-w-max`}
            src={ArrowDown}
            alt={i18n.t("dropdown_arrow_img")}
          />
        </button>
      </div>
      {click && (
        <>
          <div className="relative font-inter text-sm leading-6 text-info-gray mt-3 ml-14 w-fit"
            id='psychological-support-updated-details-panel'
          >
            <div id="psychological-support-updated-notes">
              <p><span className="font-bold">{`${i18n.t("timelineEvents.psychologicalAssistanceUpdated.observations")}`}</span></p>
              {notes || `${i18n.t("timelineEvents.psychologicalAssistanceUpdated.missingNotes")}`}
            </div>
          </div>

          {isTheLastUpdate && (
            <div className='flex space-x-4 justify-end mt-5' id='psychological-support-updated-manage-buttons'>
              <div className="flex space-x-4 group">
                {isRemoved && (
                  <>
                    <TooltipMessageHoverButtons
                      buttonClass="absolute h-11 w-[390px] right-2"
                      tooltipClass="absolute bg-border-blue z-1 gap-1 text-white items-center text-center w-[490px] text-xs px-3 py-1 h-9 font-semibold
                                    rounded-lg bg-tooltip-color shadow-tooltip-buttons ml-[-40px]"
                      arrowClass='absolute ml-[-50px]'
                      messageClass="h-5 pt-1"
                      message={i18n.t('timelineEvents.removedMessage')}
                    />
                  </>
                )}
                <button
                  className={`justify-center font-sans font-semibold text-base bg-white
                  flex items-center px-4 py-2.5 ${isRemoved || isDismissed ? 'text-disabled-gray' : 'text-support-error'}`}
                  id='psychological-support-updated-remove-button'
                  disabled={isRemoved || isDismissed}
                  onClick={() => { setOpenRemovalConfirmation(true), setObjectId(data.id), setChosenEventRemoval({ name: 'PsychologicalSupportCreated' }), setShowDetails(null) }}
                >
                  <img src={isRemoved || isDismissed ? DeleteIconGray : DeleteIconRed} alt="" className="inline mr-2 w-6 h-6" />
                  {i18n.t('timelineEvents.psychologicalAssistanceUpdated.removeBenefit')}
                </button>
                <button
                  className={`justify-center font-sans font-semibold text-base rounded border flex items-center px-4 py-2.5
                  ${isRemoved || isDismissed ? 'text-disabled-gray bg-disabled-gray-button' : 'text-rebase-blue border-rebase-blue bg-white'}`}
                  id="psychological-support-updated-edit-button"
                  disabled={isRemoved || isDismissed}
                  onClick={() => {openEditionDrawer(), setObjectId(data.id)}}
                >
                  <img src={isRemoved || isDismissed ? EditIconGray : EditIconBlue} alt="" className="inline mr-2 w-6 h-6 stroke-rebase-blue" />
                  {i18n.t('timelineEvents.psychologicalAssistanceUpdated.editBenefit')}
                </button>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}
