import React from "react";
import { useFormContext, get } from "react-hook-form";

const HiddenInput = ({ model, id: inputId, name: inputName, onChange, showError=false, ...props }) => {
  const name = model ? `${model}.${inputName}` : inputName;
  const id = inputId || name;
  const customOnChange = onChange ? { onChange: onChange } : {};

  const {
    register,
    formState: { errors }
  } = useFormContext();

  const fieldError = get(errors, name);
  const ErrorComponent = ({ message, errorId }) => {
    return (
      <span
        className="items-center font-medium tracking-wide text-red-500 text-xs -mt-1.5"
        role="alert"
        name="validationError"
        id={`${errorId}_error`}
      >
        {message}
      </span>
    );
  };
  ErrorComponent.displayName = "ErrorComponent";

  return (
    <>
      <input {...props} id={id} type="hidden" {...register(name, { ...customOnChange })} />
      {showError && fieldError && <ErrorComponent
                                    message={fieldError.message}
                                    errorId={name}
                                  />
      }
    </>
  );
};

HiddenInput.defaultProps = {
  model: "",
};

export default HiddenInput;
