import React from 'react';
import { useFormContext, useFieldArray } from 'react-hook-form';
import HealthcareBeneficiaryInput from './HealthcareBeneficiaryInput'

export default function HealthcareDescendantsInput({ setOnChange, changeReference, model }) {
  const { control } = useFormContext();
  const { fields } = useFieldArray({
    control,
    name: 'healthcare.descendants',
  });

  return (
    <>
      {fields.map((descendant, index) => (
        <div className='grid grid-cols-1'
          key={descendant.id}
          id={`healtcare_descendants_${index}`}
        >
          <HealthcareBeneficiaryInput
            beneficiary={'descendants'}
            indexNumber={index}
            array={true}
            setOnChange={setOnChange}
            changeReference={changeReference}
            model={model}
          />
        </div>
      ))}
    </>
  );
}
