import React, { useEffect, useState } from "react";
import SalaryUpdateRequestIcon from '#images/events_icons/salary_update_request_blue.svg';
import i18n from "#translate/i18n";
import { Trans } from "react-i18next"
import ArrowDown from '#images/arrowdown-top.svg';
import EditIconBlue from "#images/edit-blue.svg"
import EditIconGray from "#images/edit-gray.svg"
import DeleteIconRed from "#images/delete-red.svg"
import DeleteIconGray from "#images/delete-gray.svg"
import TooltipMessageHoverButtons from "#components/TooltipMessageHoverButtons"

export default function SalaryUpdateRequestUpdated({
  eventData,
  removedArray,
  lastUpdates,
  setOpenRemovalConfirmation,
  setObjectId,
  setChosenEventRemoval,
  showDetails,
  setShowDetails,
  openEditionDrawer
}) {
  const todayStart = new Date();
  todayStart.setHours(0, 0, 0, 0)
  const data = eventData?.data || {};
  const validFrom = data.valid_from || ''
  const eventDateTime = eventData?.date || ''
  const isRemoved = removedArray.some((removedItem) => removedItem.id === data.id && removedItem.creationEventName === 'SalaryUpdateRequestCreated')
  const isTheLastUpdate = lastUpdates.some((update) => update.id === data.id && update.lastUpdatedOn === eventDateTime)
  const isValid = new Date(`${validFrom}T00:00`) >= todayStart
  const adminName = data.responsible?.full_name
  const [validYear, validMonth, validDay] = validFrom.split('-')
  const newValue = data.new_value
  const previousValue = data.current_value
  const remunerationDiff = (newValue - previousValue)
  const percentageDiff = (remunerationDiff / previousValue) * 100
  const percentageReturnValue = percentageDiff < 0 ? '0%' : (percentageDiff.toFixed(2) + '%')
  const notesValue = data.notes
  const readjustmentValue = data ? (newValue - previousValue).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 0
  const formattedNewValue = newValue ? Number(newValue).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 0
  const [click, setClick] = useState(false)

  useEffect(() => {
    if (showDetails === 'close') { setClick(false) }
  }, [showDetails])

  return (
    <div className="relative flex-1 my-5 bg-white">
      <div className="flex items-center">
        <div className='rounded-full mr-[6px] h-fit min-w-fit'>
          <img
            src={SalaryUpdateRequestIcon}
            alt="event icon"
            className='w-[52px] h-[52px] drop-shadow-[0.3333333432674408px_0.3333333432674408px_1.3333333730697632px_rgba(0,0,0,0.15)]'
          />
        </div>
        <div className="relative z-20 font-inter text-sm leading-[22px] text-info-gray flex-1">
          <p className="font-bold inline-block">
            {`${i18n.t("timelineEvents.salaryUpdateRequestUpdated.title")}`}
          </p>
          <p className="font-normal">
            <Trans
              i18nKey={`${i18n.t("timelineEvents.salaryUpdateRequestUpdated.description")}`}
              values={{ admin: adminName, readjustmentValue: readjustmentValue, validDay: validDay, validMonth: validMonth, validYear: validYear }}
            />
          </p>
        </div>
        <button
          id='salary-readjustment-updated-details-button'
          className='ml-6 mb-3 p-2'
          onClick={() => { setClick(!click), setShowDetails(null) }}
        >
          <img
            className={`w-3 h-[7.2px] right-0 ${click && 'transform rotate-180'} min-w-max`}
            src={ArrowDown}
            alt={i18n.t("dropdown_arrow_img")}
          />
        </button>
      </div>
      {click && (
        <>
          <div className="relative font-inter text-sm leading-6 text-info-gray mt-3 ml-14" id='salary-readjustment-details-panel'>
            <p id='salary-readjustment-new-value'>
              <span className="font-bold">{i18n.t('timelineEvents.salaryUpdateRequestCreated.newSalaryValue')}</span>
              {formattedNewValue}
            </p>
            <p id='salary-readjustment-percentage-value'>
              <span className="font-bold">{i18n.t('timelineEvents.salaryUpdateRequestCreated.percentageReadjustment')}</span>
              {percentageReturnValue}
            </p>
            <div>
              <p className="font-bold">{i18n.t('timelineEvents.salaryUpdateRequestCreated.observations')}</p>
              <p id='salary-readjustment-notes'>
                {notesValue || i18n.t('timelineEvents.salaryUpdateRequestCreated.missingNotes')}
              </p>
            </div>
          </div>

          {isValid && isTheLastUpdate && (
            <div className='flex space-x-4 justify-end mt-5' id="salary-readjustment-updated-manage-buttons">
              <div className="flex space-x-4 group">
                {isRemoved && (
                  <>
                    <TooltipMessageHoverButtons
                      buttonClass="absolute h-11 w-[370px] right-2"
                      tooltipClass="absolute bg-border-blue z-1 gap-1 text-white items-center text-center w-[490px] text-xs px-3 py-1 h-9 font-semibold
                      rounded-lg bg-[var(--complementares-azul-claro, #587CE2)] shadow-tooltip-buttons ml-[-40px]"
                      arrowClass="absolute ml-[-50px]"
                      messageClass="h-5 pt-1"
                      message={i18n.t('timelineEvents.removedMessage')}
                    />
                  </>
                )}
                <button
                  className={`justify-center font-sans font-semibold text-base bg-white
                  flex items-center px-4 py-2.5
                  ${isRemoved ? 'text-disabled-gray' : 'text-support-error'}`}
                  id="salary-readjustment-remove-button"
                  disabled={isRemoved}
                  onClick={() => { setOpenRemovalConfirmation(true), setObjectId(data.id), setChosenEventRemoval({ name: 'SalaryUpdateRequestCreated' }), setShowDetails(null) }}
                >
                  <img src={isRemoved ? DeleteIconGray : DeleteIconRed} alt="" className="inline mr-2 w-6 h-6" />
                  {i18n.t('timelineEvents.salaryUpdateRequestCreated.removeReadjustment')}
                </button>

                <button
                  className={`justify-center font-sans font-semibold text-base rounded border flex items-center px-4 py-2.5 ${isRemoved ? 'text-disabled-gray bg-disabled-gray-button' : 'text-rebase-blue border-rebase-blue bg-white'}`}
                  id="salary-readjustment-edit-button"
                  disabled={isRemoved}
                  onClick={() => {openEditionDrawer(), setObjectId(data.id)}}
                >
                  <img src={isRemoved ? EditIconGray : EditIconBlue} alt="" className="inline mr-2 w-6 h-6 stroke-rebase-blue" />
                  {i18n.t('timelineEvents.salaryUpdateRequestCreated.editReadjustment')}
                </button>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}
