import i18n from "#translate/i18n";
import * as Yup from "yup";
import { validateFileSize, validateFileType } from "../../schemas";

const { errors: errorLabel } = i18n.t("yup.forms", { returnObjects: true });
const defaultFiles = [
  "application/pdf",
  "image/jpeg",
  "image/jpg",
  "image/png",
];

Yup.addMethod(
  Yup.mixed,
  "fileSize",
  function ({ maxSize = 50, allowEmpty = true } = {}) {
    return this.test(
      "fileSize",
      errorLabel.upload_failure,
      validateFileSize({ maxSize, allowEmpty })
    );
  }
);

Yup.addMethod(
  Yup.mixed,
  "fileType",
  function ({ permittedFiles = defaultFiles, allowEmpty = true } = {}) {
    return this.test(
      "fileType",
      errorLabel.upload_failure,
      validateFileType({ permittedFiles, allowEmpty })
    );
  }
);

Yup.addMethod(Yup.mixed, "filePresence", function () {
  return this.test(
    "filePresence",
    errorLabel.required_warning,
    (value) => !!value
  );
});

Yup.addMethod(Yup.mixed, "oneFile", function () {
  return this.transform((value) => {
    return value[0];
  });
});

Yup.addMethod(Yup.mixed, "requiredOnUpdate", function () {
  return this.when("$update", (update, schema) => {
    if (update) return schema.filePresence();

    return schema;
  });
});

Yup.addMethod(Yup.object, "requiredOnUpdate", function () {
  return this.when("$update", (update, schema) => {
    if (update) return schema.required();

    return schema;
  });
});

const YupFile = Yup.mixed;
const YupURL = Yup.object;
const defaultFileValidation = YupFile().oneFile().fileSize().fileType();
const YupFileValidation = ({
  defaultSchema = defaultFileValidation,
  onUpdate = false,
} = {}) => {
  return Yup.lazy((value) => {
    if (value?.url) return onUpdate ? YupURL().requiredOnUpdate() : YupURL();

    return onUpdate ? defaultSchema.requiredOnUpdate() : defaultSchema;
  });
};

const YupBasicFileValidation = ({
  defaultSchema = defaultFileValidation,
  mandatory = true,
} = {}) => {
  return Yup.lazy((value) => {
    if (value?.url) { return mandatory ? YupURL().required() : YupURL() }

    return defaultSchema;
  });
};

export { YupFile, YupURL, YupFileValidation, YupBasicFileValidation };

export default YupFile;
