import React from "react";
import CustomizedFieldsOptions from "#components/listings/people/data/drawers/CustomizedFieldsOptions"
import i18n from "#translate/i18n";

export default function BasicCompanyData({fieldsCompanyDataOptions}) {
  return (
    <>
      <p
        className="text-sm font-semibold text-title-gray mb-6 font-sans not-italic"
      >
        {i18n.t('customizedDataExport.selections.sections.company_data.addressTitle')}
      </p>
      <CustomizedFieldsOptions
        options={fieldsCompanyDataOptions?.company_address_data}
        optionSource={'company_data'}
        prefix={'company_data.company_address_data'}
      />
    </>
  );
}