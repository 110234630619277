import * as Yup from "yup";
import i18n from "#translate/i18n";

const { errors: errorLabel } = i18n.t("yup.forms", { returnObjects: true });
Yup.setLocale(i18n.t("yup.schemas", { returnObjects: true }));

const SignUpSchema = Yup.object().shape({
  signup: Yup.object().shape(
    {
      name: Yup.string().required(),
      email: Yup.string()
        .email()
        .required()
        .when("email", (value, schema) => {
          if (!value) return schema;

          return schema.matches(
            /^\w+([-+.]\w+)*@{1}(rebase.com.br|campuscode.com.br|brainn.co)$/,
            errorLabel.email_corporate
          );
        }),
      password: Yup.string().required(),
      password_confirmation: Yup.string()
        .required()
        .oneOf([Yup.ref("password")], errorLabel.password_confirmation),
      terms_of_use: Yup.boolean().oneOf([true], errorLabel.terms_of_use),
    },
    ["email", "email"]
  ), // cyclic dependency
});

export default SignUpSchema;
