import React from "react";
import i18n from "#translate/i18n";

export default function Address({ address, deliveryAddress }) {
  const hasDeliveryAddress = address.alternative === true;

  return (
    <div className="flex text-info-gray font-sans text-sm leading-[26px] tracking-normal text-left p-4 px-5">
      {address && (
        <div className="w-[316px]">
          <p className="font-bold">{i18n.t('userProfile.home_address')}</p>
          <p>
            {address.street}, {address.house_number}
            {address.complement ? ` - ${address.complement}` : ""}
          </p>
          <p>{address.neighborhood} - {address.city} - {address.state} - {address.country}</p>
          <p>
            {address.zip_code}
          </p>
          {address.reference_point && (
            <p>{i18n.t('userProfile.reference')}: {address.reference_point}</p>
          )}
        </div>
      )}

      {deliveryAddress && hasDeliveryAddress && (
        <div className=' w-[316px] ml-4'>
          <p className='font-bold'>{i18n.t('userProfile.delivery_address')}</p>
          <p>
            {deliveryAddress.street}, {deliveryAddress.house_number}
            {deliveryAddress.complement ? ` - ${deliveryAddress.complement}` : ""}
          </p>
          <p>{deliveryAddress.neighborhood} - {deliveryAddress.city} - {deliveryAddress.state} - {deliveryAddress.country}</p>
          <p>
            {deliveryAddress.zip_code}
          </p>
          {deliveryAddress.reference_point && (
            <p>{i18n.t('userProfile.reference')}: {deliveryAddress.reference_point}</p>
          )}
        </div>
      )}
    </div>
  )
}