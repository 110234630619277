const translations = {
  bonusDrawer: {
    peopleHeader: "Bonus",
    gift: "Premiacao",
    tickSymbol: 'Adicionando'
  },
  bonus_reward_create: {
    title: "Adicionar premiação",
    subtitle: "Adicione uma premiação para",
    submit_button_text: "Adicionar premiação",
  },
  bonus_reward_update: {
    title: "Editar premiação",
    subtitle: "Edite a premiação para",
    submit_button_text: "Salvar Edição",
  },
  bonus_reward: {
    reward_type: "Tipo da premiação",
    bonus_value: "Valor da premiação",
    notes_placeholder: "Se houver, adicione informações relevantes sobre esta premiação",
    notes: "Observações",
    bonus_date: "Prêmio concedido em:",
    payment_date: "Data do pagamento:",
    payment_type: "Forma de pagamento",
    forms_error: {
      presence: "O preenchimento deste campo é obrigatório",
      time_sequence: "O dia do pagamento não pode ser anterior ao do prêmio",
      currency_number: "O preenchimento deste campo é obrigatório e deve ser um número válido",
      date_error: "A data de pagamento deve ser maior ou igual a data de hoje",
    },
    messageForm: {
      success: "Premiação adicionada",
      successDataCreated: "O prêmio foi concedido com sucesso para",
      successCheck: "Símbolo check",
    },
  },
}

export { translations }
