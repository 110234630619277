const workLeaveData = {
  work_leave: {
    reason: "",
    start_date: null,
    foreseen_return_date: null,
    documentation: "",
    notes: "",
    start_date_past: false,
    foreseen_return_date_past: false,
  },
};

export { workLeaveData };
