/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import i18n from '#translate/i18n';
import CurrencyInput from '#components/formComponents/CurrencyInput';

export default function LoadedCurrencyValueInput({ model, registeredAllowances, fieldName, allowanceName }) {
  const { setValue } = useFormContext()

  useEffect(() => {
    const currentAllowance = registeredAllowances.find(({ name }) => name === allowanceName) || []
    if(currentAllowance['attributes']) {
      setValue(`${model}.${fieldName}`, currentAllowance['attributes'][`${fieldName}`])
    }
  }, []);

  return (
    <div className='grid grid-cols-1 gap-y-3 gap-x-4'>
      <CurrencyInput
        model={model}
        name={fieldName}
        title={i18n.t(`${model}.${fieldName}`)}
        disabled={true}
        className={'w-full'}
      />
    </div>
  );
}
