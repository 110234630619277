export default function dateWithTimeZoneName(date) { // from new Date() to "Sun Sep 01 2024 00:00:00 GMT-0300 (Brasilia Standard Time)"
  const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  
  const dayName = days[date.getDay()];
  const monthName = months[date.getMonth()];
  const day = String(date.getDate()).padStart(2, '0');
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');
  const timezoneOffset = date.getTimezoneOffset()
  const timezoneOffsetHours = String(Math.floor(Math.abs(timezoneOffset) / 60)).padStart(2, '0');
  const timezoneOffsetMinutes = String(Math.abs(timezoneOffset) % 60).padStart(2, '0');
  const timezoneOffsetSign = timezoneOffset > 0 ? '-' : '+';
  const options = { timeZoneName: 'long' };
  const timezoneName = new Intl.DateTimeFormat('en-US', options).formatToParts(date).find(part => part.type === 'timeZoneName').value;

  return `${dayName} ${monthName} ${day} ${year} ${hours}:${minutes}:${seconds} GMT${timezoneOffsetSign}${timezoneOffsetHours}${timezoneOffsetMinutes} (${timezoneName})`
}
