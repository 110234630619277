import * as Yup from "yup";
import i18n from "#translate/i18n";
import { YupFileValidation } from "#utils/yup/types/file";

Yup.setLocale(i18n.t("yup.schemas", { returnObjects: true }));

const { errors: errorLabel } = i18n.t("yup.forms", { returnObjects: true });

const firstDateOfMonth = (originalDate = new Date()) => new Date(originalDate.getFullYear(), originalDate.getMonth(), 1)
const beginningOfCurrentMonth = firstDateOfMonth(new Date())
beginningOfCurrentMonth.setHours(0, 0, 0, 0)

const sanitizeDate = (value, originalValue) => (
  (originalValue && Object.prototype.toString.call(value) === '[object Date]') ? value : null
)

const DismissalBaseSchema = Yup.object().shape({
  hiring_date: Yup.date()
                  .nullable()
                  .typeError(i18n.t("user_dismissal.messageForm.error.presence"))
                  .required(i18n.t("user_dismissal.messageForm.error.presence")),
  past_dismissal: Yup.string().required(),
  exit_date: Yup.date()
                .nullable()
                .when("past_dismissal", {
                  is: (val) => String(val) !== "true",
                  then: Yup.date()
                           .nullable()
                           .transform(sanitizeDate)
                           .typeError(i18n.t("user_dismissal.messageForm.error.presence"))
                           .required(i18n.t("user_dismissal.messageForm.error.presence"))
                           .min(beginningOfCurrentMonth, i18n.t("user_dismissal.messageForm.error.date_error")),
                  otherwise: Yup.date()
                                .nullable()
                                .transform(sanitizeDate)
                                .typeError(i18n.t("user_dismissal.messageForm.error.presence"))
                                .required(i18n.t("user_dismissal.messageForm.error.presence"))
                }),
  exit_meeting_date: Yup.date()
                        .nullable()
                        .typeError(i18n.t("user_dismissal.messageForm.error.presence"))
                        .required(i18n.t("user_dismissal.messageForm.error.presence")),
  reason: Yup.string()
             .typeError(errorLabel.select_option)
             .required(i18n.t("user_dismissal.messageForm.error.presence")),
  notes_reason: Yup.string()
                   .typeError(i18n.t("expense_assistance.forms_error.presence"))
                   .required(i18n.t("expense_assistance.forms_error.presence")),
  general_notes: Yup.string(),
  termination_balance: Yup.number()
                          .typeError(i18n.t("user_dismissal.messageForm.error.presence"))
                          .required(i18n.t("user_dismissal.messageForm.error.presence")),
  installments_number: Yup.number()
                          .typeError(i18n.t("user_dismissal.messageForm.error.presence"))
                          .required(i18n.t("user_dismissal.messageForm.error.presence")),
  installments_sum: Yup.number(),
  documentation: YupFileValidation(),
  invoice_total_value: Yup.number()
                          .typeError(i18n.t("user_dismissal.messageForm.error.presence"))
                          .required(i18n.t("user_dismissal.messageForm.error.presence"))
                          .when(["installments_sum", "installments_number"], (installments_sum, installments_number, schema) => {
                            return schema.test({
                              name: 'equalInstallmentsSum',
                              exclusive: false,
                              message: i18n.t("user_dismissal.messageForm.error.invoice_total_must_equal_installments_sum"),
                              test: function(value) {
                                return installments_number === 1 || Number(value) === Number(installments_sum);
                              },
                            });
                          }),
});

export default DismissalBaseSchema;
