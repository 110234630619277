import axios from "axios";

export default function fetchWorkLeave({ id, user }) {
  const url = `/api/v1/users/${user.id}/work_leaves/${id}`

  const requestOptions = () => {
    const csrf = document
      .querySelector("meta[name='csrf-token']")
      .getAttribute("content");

    return {
      headers: { "X-CSRF-Token": csrf }
    };
  };

  const options = requestOptions()

  return axios.get(url, options)
}