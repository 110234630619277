/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useFormContext, useFieldArray, useWatch } from "react-hook-form";
import i18n from "#translate/i18n";
import { bankAccountFields } from "#data/userData";
import BankAccount from "../BankAccount";
import DivisionTitle from "#components/formComponents/DivisionTitle"
import DivisionLine from "#components/formComponents/DivisionLine"
import BtnAdd from "../BtnAdd";
import BtnRemove from "../BtnRemove";

export default function BankAccountFields({bankingInstitutions}) {
  const model = "bank_accounts";
  const { control, setValue } = useFormContext();
  useWatch({ control, name: model });

  const { fields, append, remove, update } = useFieldArray({
    control,
    name: model,
  });

  const displayRemoveButton = (account) => (
    !account.bank_accounts_id || account.account_status !== 'removed'
  )

  const removeField = (account, index) => {
    if (!account.bank_accounts_id) return () => remove(index);

    const accountStatusField = `${model}.${index}.account_status`;

    // TODO: Find better way to hide element and update state
    return () => {
      update(index, { ...account, ["account_status"]: 'removed' });
      setValue(accountStatusField, 'removed');
    };
  };

useEffect(() => {
  if (fields.length === 0) {
    append(bankAccountFields);
  }
}, [fields]);

const notRemovedFields = fields.filter((field) => field.account_status !== 'removed');

  return (
    <>
      <DivisionTitle title={i18n.t("bank_data")} />

      {notRemovedFields.map((account, index) => (
        <div
          key={account.id}
          id={`bank-account-${index}`}
          className={account.account_status === 'removed' ? "hidden" : ""}
        >
          <BankAccount model={model} index={index} bankingInstitutions={bankingInstitutions}/>

          <div className={ `flex ${ (notRemovedFields.length - 1 === index) && 'gap-6' }` }>
            <BtnAdd
              model={model}
              id={`add-new-bank-account-${index}`}
              name={i18n.t("buttons.new_bank_account")}
              display={notRemovedFields.length - 1 === index}
              style="mt-6"
              handleOnClick={() => append(bankAccountFields)}
            />

            {index > 0 && displayRemoveButton(account) && (
              <BtnRemove
                model={model}
                id={`remove-bank-account-${index}`}
                name={i18n.t("buttons.remove_bank_account")}
                style="mt-6"
                handleOnClick={removeField(account, index)}
              />
            )}
          </div>
          <DivisionLine />
        </div>
      ))}
    </>
  );
}
