import * as Yup from "yup";
import i18n from "#translate/i18n";
import { YupBasicFileValidation, YupFile, YupFileValidation } from "../../utils/yup/types/file";

Yup.setLocale(i18n.t("yup.schemas", { returnObjects: true }));

const HealthcareUserBaseSchema = Yup.object().shape({
  full_name: Yup.string().required(),
  adding: Yup.boolean().required(),
  card_image: Yup.mixed().when('adding', (adding) => {
    if(adding){
      return YupBasicFileValidation({
        defaultSchema: YupFile().oneFile().filePresence().fileSize().fileType(),
      })
    } else {
      return YupFileValidation()
    }
  })
});

const HealthcareDescendantBaseSchema = Yup.object().shape({
  full_name: Yup.string().required(),
  adding: Yup.boolean().required(),
  card_image: Yup.mixed().when('adding', (adding) => {
    if(adding){
      return YupBasicFileValidation({
        defaultSchema: YupFile().oneFile().filePresence().fileSize().fileType(),
      })
    } else {
      return YupFileValidation()
    }
  }),
});

const HealthcareDescendantListSchema = Yup.array().of(
  HealthcareDescendantBaseSchema
)

const HealthcareBaseSchema = Yup.object().shape({
  user: HealthcareUserBaseSchema,
  descendants: HealthcareDescendantListSchema,
});

export default HealthcareBaseSchema;
