import React from "react";
import CustomizedFieldsOptions from "#components/listings/people/data/drawers/CustomizedFieldsOptions"
import i18n from "#translate/i18n";

export default function GeneralObservationsData({fieldsPersonalDataOptions}) {
  return (
    <>
      <p
        className="text-sm font-semibold text-title-gray mb-5 font-sans not-italic"
      >
        {i18n.t('customizedDataExport.selections.sections.personal_data.GeneralObservationsTitle')}
      </p>
      <CustomizedFieldsOptions
        options={fieldsPersonalDataOptions?.general_observations_data}
        prefix={'personal_data.general_observations_data'}
      />
    </>
  );
}