import axios from "axios";
import toFormData from "#requests/helpers/toFormData"
import formatDate from "#services/formatDate";

export default function createOrUpdateBonusReward({ data, model, user, objectId }) {
  const action = objectId ? "PUT" : "POST";
  const url = objectId ? `/api/v1/bonus_rewards/${objectId}` : "/api/v1/bonus_rewards";

  const formatPayload = (data) => {
    let new_data = { bonus_reward: data.bonus_reward }
    let new_dates = {
      bonus_date: formatDate(data.bonus_reward.bonus_date),
      payment_date: formatDate(data.bonus_reward.payment_date)
    }
    let keys = Object.keys(new_dates)
    keys.map(x => { new_data.bonus_reward[x] = new_dates[x] })

    const sentData = {
      ...new_data.bonus_reward,
      user_id: user.id,
    };

    return sentData;
  };

  const requestOptions = () => {
    const csrf = document
      .querySelector("meta[name='csrf-token']")
      .getAttribute("content");

    return {
      method: action,
      headers: { "X-CSRF-Token": csrf },
      data: toFormData(formatPayload(data), model),
    };
  };

  const options = requestOptions();

  return axios({ url, ...options })
}
