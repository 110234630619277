const accessFields = [
  {
    description: "",
    status: "",
    notes: "",
  },
];

const installmentsFields = {
  installment_value: "",
  payment_date: "",
}

const benefitsFields = {
  has_extended_allowances_attributes: "",
}

const dismissalFields = {
  reason: "",
  notes_reason: "",
  exit_date: "",
  exit_meeting_date: "",
  company_feedback: "",
  general_notes: "",
  termination_balance: "",
  invoice_total_value: "",
  installments_number: "",
  documentation: "",
};

const dismissalData = {
  user_dismissal: dismissalFields,
  access_and_assets_attributes: [accessFields],
  invoice_installments_attributes: [installmentsFields],
  extended_allowances_attributes: benefitsFields,
}
export {
  dismissalData
};
