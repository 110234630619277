const translations = {
  messages: {
    removeFile: "Remover arquivo",
    nickname:
      "Esta é a maneira que as pessoas irão se referir a você, por isso, sinta-se \
      livre para colocar seu nome, nome social ou apelido desde que seja a forma \
      como prefere que as pessoas te chamem.",
    has_social_name:
      "De acordo com o Decreto 8.727/2016, pessoas travestis ou \
      transexuais podem optar pela utilização do nome social",
    pronouns: 'Os pronomes pessoais são usados para se <strong>referir</strong> as pessoas no ato da comunicação. Escolha a opção que mais lhe fizer sentido, com ou sem a indicação de gênero.',
    gender_identity: 'A identidade de gênero está relacionada a como uma pessoa se sente em relação a si mesma. (Uma pessoa pode se identificar com um gênero oposto ao seu sexo biológico, ou a nenhum gênero específico).',
    mattermost_username: "Acesse seu perfil no Mattermost e utilize o seu <strong>nome de usuário</strong>."
  },
}

export { translations }
