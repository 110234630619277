import i18n from "#translate/i18n";

export default function updateGuestRequest(values) {
  const url_update = `/api/v1/guests/update_credentials`;

  const formatPayload = () => {
    return {
      name: values.name,
      email: values.email,
      password: values.password,
      password_confirmation: values.password_confirmation,
      token_id: values.token_id
    };
  };

  const requestOptions = () => {
    return {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(formatPayload()),
    };
  };

  const updateRequest = async () => {
    const response = await fetch(url_update, requestOptions());
    if (response.ok) {
      return response;
    } else {
      throw new Error(i18n.t("requests.network_error"));
    }
  };

  return updateRequest();
}
