/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import i18n from '#translate/i18n';
import SaveButton from "#components/buttons/medium/primaryButton"
import CancelButton from "#components/buttons/medium/textButton"
import DivisionLine from '#components/formComponents/DivisionLine';
import { healthcareData as initialValues } from '#data/healthcareData';
import createOrUpdateHealthcare from '#requests/finances/allowances/createOrUpdateHealthcare';
import fetchAllowance from '#requests/userProfile/editions/fetchAllowance'
import addHealthcareSchema from '#validations/addHealthcareSchema';
import { yupResolver } from '@hookform/resolvers/yup';
import CurrencyInput from '#components/formComponents/CurrencyInput';
import HealthcareBeneficiaryInput from './HealthcareBeneficiaryInput'
import HealthcareDescendantsInput from './HealthcareDescendantsInput'
import HealthcareCardsImagesInput from './HealthcareCardsImagesInput'
import ErrorMessage from '#components/messages/ErrorMessage'
import fillBasicFormsFields from '#services/fillBasicFormsFields'

export default function HealthcareDrawer({
  closeAddAllowancesDrawer,
  registeredAllowances,
  user,
  userName,
  openNewDrawer,
  setShowMessage,
  editObjectId,
  setEditObjectId,
}) {
  const formName = 'healthcare_form'
  const model = 'healthcare'
  const [totalValue, setTotalValue] = useState('')
  const [changeBeneficiary, setChangeBeneficiary] = useState(false)
  const [persistedImagesIds, setPersistedImagesIds] = useState([])
  const [refreshing, setRefreshing] = useState([])
  const [creationErrors, setCreationErrors] = useState()
  const [submittingData, setSubmittingData] = useState(false);
  const formMethods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(addHealthcareSchema),
  });

  const loadData = ({ reset: setForm }) => {
    const response = registeredAllowances.find(({ name }) => name === 'healthcare')['attributes']
    response['user']['adding'] = true
    const handleSpecialFields = [{ descendants: { type: 'arrayOfObjects' }, card_image: { type: 'document' } }]
    fillBasicFormsFields({ setForm: setForm, response: { healthcare: { ...response } }, handleSpecialFields })
  };

  const submitData = (data) => {
    setSubmittingData(true)
    createOrUpdateHealthcare({ data: data, user: user, model: model, objectId: editObjectId, imagesIds: persistedImagesIds })
      .then(() => {
        closeAddAllowancesDrawer()
        const newMessage = {
          success: true,
          userName: userName,
          source: editObjectId ? 'healthcare_updated' : 'healthcare_created',
        }
        setShowMessage(messages => [...messages, newMessage])
        setEditObjectId?.(null)
        openNewDrawer?.()
      }).catch(function (requestReturn) {
        setCreationErrors(requestReturn.response.data.errors)
        document.getElementById('healthcare_form').scrollTo({ top: 0, left: 0 })
        setSubmittingData(false)
      })
  };

  useEffect(() => {
    const currentAllowance = registeredAllowances.find(({ name }) => name === 'healthcare')['attributes']
    const startValue = currentAllowance['user']['healthcare_value']
    setTotalValue(startValue)
    loadData(formMethods)

    if (editObjectId) {
      fetchAllowance({ id: editObjectId, user: user, allowanceName: 'healthcare' })
        .then((parsedResponse) => {
          const data = parsedResponse.data

          const descendants = data.beneficiaries_ids.filter((beneficiary) => beneficiary.beneficiary_type === 'Descendant')
          const descendantsIds = descendants.map((descendant) => descendant.beneficiary_id)
          currentAllowance.descendants?.forEach((descendant, index) => {
            if (descendantsIds.includes(descendant.id)) {
              formMethods.setValue(`healthcare.descendants.${index}.adding`, true)
            } else {
              formMethods.setValue(`healthcare.descendants.${index}.adding`, false)
            }
          })

          setTimeout(() => {
            const images = data.healthcare_proof_docs
            let imagesIds = []
            images.forEach((image) => imagesIds.push(image.id))
            setPersistedImagesIds(imagesIds)
            formMethods.setValue(`healthcare.user.card_image`, images[0])
            const imagesLength = images.length
            descendants.forEach((descendant, index) => {
              if (index >= imagesLength - 1) return

              const findIndex = currentAllowance.descendants.findIndex((currentDescendant) => currentDescendant['id'] === descendant['beneficiary_id'])
              formMethods.setValue(`healthcare.descendants.${findIndex}.card_image`, images[index + 1])
            })
          }, 300)
        })
    }
  }, []);

  useEffect(() => {
    let currentTotalValue = +formMethods.getValues('healthcare.user.healthcare_value')
    const currentDescendants = formMethods.getValues('healthcare.descendants')
    currentDescendants?.forEach((descendant, index) => {
      let currentStatus = formMethods.getValues(`healthcare.descendants.${index}.adding`)
      if (currentStatus) {
        currentTotalValue = currentTotalValue + (+descendant.healthcare_value)
      }
    })
    setTotalValue(currentTotalValue)

    if (editObjectId) {
      const userData = formMethods.getValues('healthcare.user')
      let descendantsAdded = []
      let cardsAdded = []

      currentDescendants.forEach((descendant, index) => {
        if (descendant.adding) {
          descendantsAdded.push({ index: index, descendant: descendant })
        }
        if (descendant.card_image?.filename || descendant.card_image?.length > 0) {
          cardsAdded.push({ index: index, id: `descendants.${index}.card_image_uploaded`, image: descendant.card_image, uploaded: descendant.card_image_uploaded })
        }
      })

      const descendantsAddedIndex = descendantsAdded?.map((descendant) => descendant.index)
      const cardsIndex = cardsAdded?.map((card) => card.index)
      const extraCardsIndex = cardsIndex.filter((index) => !descendantsAddedIndex.includes(index))
      const descendantsMissingIndex = descendantsAddedIndex.filter((index) => !cardsIndex.includes(index))

      const userImagePresent = userData.card_image?.filename || document.getElementById('user.card_image_uploaded')?.files?.[0]
      const allImagesCount = userImagePresent ? (cardsAdded.length + 1) : cardsAdded.length
      const allBeneficiariesCount = descendantsAdded.length + 1
      const allCardsPresent = allBeneficiariesCount === allImagesCount

      if (!userImagePresent && allCardsPresent) {
        cardsAdded.forEach((card, index) => {
          const image = document.getElementById(card.id).files[0] || card.image
          const currentImage = image?.url ? image : [image]
          if (index) {
            formMethods.setValue(`healthcare.descendants.${descendantsAddedIndex[index - 1]}.card_image`, currentImage)
            formMethods.setValue(`healthcare.descendants.${descendantsAddedIndex[index - 1]}.card_image_uploaded`, card.uploaded)
          } else {
            formMethods.setValue('healthcare.user.card_image', currentImage)
            formMethods.setValue('healthcare.user.card_image_uploaded', card.uploaded)
          }
        })

        setTimeout(function () {
          currentDescendants.forEach((_descendant, index) => {
            if (!descendantsAddedIndex.includes(index)) {
              formMethods.setValue(`healthcare.descendants.${index}.card_image`, {})
              formMethods.setValue(`healthcare.descendants.${index}.card_image_uploaded`, '')
            }
          })
          setRefreshing(true)
        }, 300)
      }

      descendantsMissingIndex.forEach((descendantIndex) => {
        if (extraCardsIndex.length) {
          const imageIndex = extraCardsIndex.shift()
          const imageData = cardsAdded.find((card) => card.index === imageIndex)
          const image = document.getElementById(imageData.id).files[0] || imageData.image
          const currentImage = image?.url ? image : [image]

          formMethods.setValue(`healthcare.descendants.${descendantIndex}.card_image`, currentImage)
          formMethods.setValue(`healthcare.descendants.${descendantIndex}.card_image_uploaded`, imageData.uploaded)

          setTimeout(function () {
            formMethods.setValue(`healthcare.descendants.${imageIndex}.card_image`, {})
            formMethods.setValue(`healthcare.descendants.${imageIndex}.card_image_uploaded`, {})
            setRefreshing(true)
          }, 300)
        }
      })
    }
  }, [changeBeneficiary]);

  return (
    <FormProvider {...formMethods}>
      <form
        id={formName}
        className='overflow-y-auto max-h-[70vh] bg-white absolute
                   sm:top-[220px] md:top-[230px] lg:top-[255px] xl:top-[275px] 2xl:top-[310px] 2k-monitor:top-[345px]
                   bottom-[100px] lg:bottom-[105px]
                   right-0 left-0 mx-6 px-0.5'
        onSubmit={formMethods.handleSubmit(submitData)}
      >
        <div id='form_healthcare_main_body'>
          {creationErrors && (
            <div className='grid grid-cols-1'>
              <ErrorMessage
                errors={creationErrors}
                removeErrors={setCreationErrors}
                model={model}
                userName={''}
                className={'mt-0'}
              />
            </div>
          )}
          <div className='grid grid-cols-1 gap-y-3 gap-x-4 mb-[15px]'>
            <div id='healthcare_beneficiaries_title'>
              <h1
                className='w-[291px] h-7 font-sans not-italic text-lg font-semibold text-black'
              >Beneficiários</h1>
              <p
                className='w-[451px] h-6 font-sans not-italic text-sm font-normal text-default-gray-2'
              >Selecione todos os beneficiários que terão acesso ao prêmio saúde</p>
            </div>
            <HealthcareBeneficiaryInput
              beneficiary='user'
              indexNumber=''
              checkDisabled={true}
              titlePerson={i18n.t('healthcare.title_person')}
              titleValue={i18n.t('healthcare.title_value')}
              setOnChange={setChangeBeneficiary}
              changeReference={changeBeneficiary}
              model={model}
            />
            <HealthcareDescendantsInput
              setOnChange={setChangeBeneficiary}
              changeReference={changeBeneficiary}
              model={model}
            />
          </div>
          <div className='grid grid-cols-1 gap-y-3 gap-x-4 mb-[15px]'>
            <div>
              <h1
                className='w-[291px] h-7 font-sans not-italic text-lg font-semibold text-black'
              >
                Comprovantes
                <span
                  className="pl-1 text-red-500"
                > * </span>
              </h1>
              <p
                className='h-6 font-sans not-italic text-sm font-normal text-default-gray-2'
              >
                Envie uma foto da carteirinha do plano de saúde. Uma para cada beneficiário
              </p>
            </div>
            <HealthcareCardsImagesInput
              formMethods={formMethods}
              editObjectId={editObjectId}
              model={model}
              persistedImagesIds={persistedImagesIds}
              setPersistedImagesIds={setPersistedImagesIds}
              refreshing={refreshing}
              setRefreshing={setRefreshing}
              setChangeBeneficiary={setChangeBeneficiary}
              changeBeneficiary={changeBeneficiary}
            />
            <CurrencyInput
              model={model}
              name='total_value'
              title={i18n.t('healthcare.total_value')}
              disabled={true}
              filledValue={totalValue}
              automaticFilledValue={true}
              className='w-full'
            />
          </div>
        </div>
        <div id='healthcare-forms-buttons'
          className='fixed bottom-0 right-0 w-1/2 flex flex-row max-h-[15vh] bg-white h-[100px]'
        >
          <div className='absolute right-2.5 left-2.5 bottom-[78px]'>
            <DivisionLine customStyle='mb-2.5' />
            <CancelButton
              buttonId={'healthcare-forms-cancel-button'}
              defaultText={i18n.t('buttons.cancel')}
              onClick={() => { closeAddAllowancesDrawer(), openNewDrawer?.() }}
              disabled={submittingData}
              imagePresent={false}
              innerDivClass={'grid'}
              type={'button'}
              buttonClass={`absolute justify-center flex w-[70px] float-left mt-2.5 ${editObjectId ? 'right-60' : 'right-[275px]'}`}
            />

            <SaveButton
              form={formName}
              defaultText={i18n.t(`${editObjectId ? 'update_allowances' : 'add_allowances'}.submit_button_text`)}
              submittingData={submittingData}
              processingText={i18n.t("buttons.saving")}
              buttonId={"healthcare-forms-submit-button"}
              buttonClass={`absolute right-0 flex justify-center mr-5 flex-row mt-2.5 ${editObjectId ? 'w-44' : 'w-[214px]'}`}
              type={'submit'}
            />
          </div>
        </div>
      </form>
    </FormProvider>
  );
}
