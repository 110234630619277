import React from "react";

export default function IconClose({ alt, customStyle }) {
  return (
    <svg
    alt={alt}
    width="28" 
    height="24" 
    viewBox="0 0 28 24" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="12.4609" cy="12" r="12" className={customStyle} />
    <path d="M13.0867 11.9995L16.3267 8.75947C16.3995 8.67444 16.4376 8.56508 16.4333 8.45322C16.4289 8.34136 16.3826 8.23526 16.3034 8.1561C16.2243 8.07695 16.1182 8.03058 16.0063 8.02626C15.8944 8.02194 15.7851 8.05999 15.7001 8.1328L12.4601 11.3728L9.22006 8.12835C9.13503 8.05554 9.02567 8.0175 8.91381 8.02182C8.80196 8.02614 8.69585 8.07251 8.6167 8.15166C8.53754 8.23081 8.49117 8.33692 8.48685 8.44877C8.48253 8.56063 8.52058 8.67 8.59339 8.75502L11.8334 11.9995L8.58895 15.2395C8.54242 15.2793 8.50463 15.3283 8.47796 15.3835C8.45128 15.4386 8.43629 15.4987 8.43392 15.5599C8.43156 15.6211 8.44187 15.6821 8.46422 15.7392C8.48656 15.7962 8.52046 15.848 8.56377 15.8913C8.60708 15.9346 8.65888 15.9685 8.71591 15.9909C8.77295 16.0132 8.83398 16.0235 8.89519 16.0212C8.9564 16.0188 9.01646 16.0038 9.0716 15.9771C9.12674 15.9504 9.17577 15.9127 9.21561 15.8661L12.4601 12.6261L15.7001 15.8661C15.7851 15.9389 15.8944 15.977 16.0063 15.9727C16.1182 15.9683 16.2243 15.922 16.3034 15.8428C16.3826 15.7637 16.4289 15.6576 16.4333 15.5457C16.4376 15.4339 16.3995 15.3245 16.3267 15.2395L13.0867 11.9995Z" fill="white"
    />
  </svg>
  );
}
