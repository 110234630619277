import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import ImgRebase from "#images/new_logo_rebase.svg";
import { FooterBar } from "#components";
import fetchTokenUser from "#requests/fetchTokenUser";
import i18n from "#translate/i18n";
import FirstGuestAccessForm from "#components/formComponents/guest/FirstGuestAccessForm"

export default function FirstGuestAccess() {
  const [guestParams] = useSearchParams();

  const tokenId = guestParams.get('tokenId');

  const [tokenUser, setTokenUser] = useState(['']);

  useEffect(() => {
    fetchTokenUser(tokenId)
      .then((response)=> {
        setTokenUser(response)
    })
  },[tokenId]);

  return (
    <div id="first-guest-access-page-container" className="flex flex-col">
      <div className="w-[80%] bg-cover text-medium-strong-gray bg-login h-screen"
      />
      <div className="bg-white h-screen right-0 w-[41.2%] fixed">
        <div className={`${tokenUser?.name ? '' : 'hidden'} w-[41.2%] fixed right-0 h-[93%] xl:h-[94%] bg-white inset-y-0 overflow-y-auto`}
        >
          <div className="lg:mt-[4.75%] xl:mt-[6.75%] 2xl:mt-[8.75%] mx-20">
            <img
              id="new-rebase-logo"
              src={ImgRebase}
              className="mx-auto max-h-[15vh] "
              alt="Rebase logo"
            />
            <h1 className="mt-12 text-center text-2xl font-bold">{i18n.t("firstGuestAccess.title")}</h1>
            <p className="text-center">{i18n.t("firstGuestAccess.subtitle")}</p>
          </div>

          <FirstGuestAccessForm tokenUser={tokenUser} tokenId={tokenId} />
          <FooterBar />
        </div>
      </div>
    </div>
  );
}

