import React from "react";
import i18n from "#translate/i18n";
import removeButton from "#images/buttons/button_image_remove.svg";

export default function BtnRemove({
  id,
  style,
  handleOnClick = (f) => f,
}) {

  return (
    <>
      <button
        id={id}
        onClick={handleOnClick}
        style={style}
      >
        <img
          src={removeButton}
          alt={i18n.t("bonusDrawer.tickSymbol")}
          style={{ width: 28 }}
        />
      </button>
    </>
  );
}
