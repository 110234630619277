import React, { useState, useEffect } from "react";
import SelectInput from "../formComponents/SelectInput";
import i18n from "#translate/i18n";
import tShirtImg from "#images/referenceModal/t_shirt_size_reference.png";
// import sweatshirtImg from "#images/referenceModal/sweatshirt_size_reference.png";
// import pantsImg from "#images/referenceModal/pants_size_reference.png";
import ReferenceModal from "../formComponents/userForm/modals/ReferenceModal";
import shoeImg from "#images/referenceModal/shoe_size_reference.png";
import fetchClothings from "#requests/fetchClothings";

const ClothingUser = () => {
  const model = "clothing";
  const [t_shirt, setTshirtSize] = useState(['']);
  const [shoe, setShoeSize] = useState(['']);

  useEffect(() => {
    fetchClothings()
      .then((parsedResponse) => {
        setTshirtSize(parsedResponse["t_shirt_sizes"]);
        setShoeSize(parsedResponse["shoe_sizes"]);
        // setSweatshirtSize(parsedResponse["sweatshirt_sizes"]);
        // setPantsSize(parsedResponse["pants_sizes"]);
    });
  }, []);

  return (
    <>
      <SelectInput
        model={model}
        name="t_shirt_size"
        title={i18n.t("clothing.t_shirt_size.title")}
        options={t_shirt}
        optionsI18nPrefix={"clothing"}
        modalComponent={
          <ReferenceModal
            title={i18n.t("clothing.t_shirt_size.modal.title")}
            description={i18n.t("clothing.t_shirt_size.modal.description")}
            image={tShirtImg}
            alt={i18n.t("clothing.t_shirt_size.alt")}
          />
        }
      />

      <SelectInput
        model={model}
        name="shoe_size"
        title={i18n.t("clothing.shoe_size.title")}
        options={shoe}
        optionsI18nPrefix={"clothing"}
        modalComponent={
          <ReferenceModal
            title={i18n.t("clothing.shoe_size.modal.title")}
            description={i18n.t("clothing.shoe_size.modal.description")}
            image={shoeImg}
            alt={i18n.t("clothing.shoe_size.alt")}
            testIdReference={"reference-modal-shoe"}
          />
        }
      />
      {/* <div className="hidden">
        <SelectInput
          model={model}
          title={i18n.t("clothing.sweatshirt_size.title")}
          name="sweatshirt_size"
          options={sizes.sweatshirt}
          optionsI18nPrefix={"clothing"}
          modalComponent={
            <ReferenceModal
              title={i18n.t("clothing.sweatshirt_size.modal.title")}
              description={i18n.t("clothing.sweatshirt_size.modal.description")}
              image={sweatshirtImg}
              alt={i18n.t("clothing.sweatshirt_size.alt")}
            />
          }
        />
  
        <SelectInput
          model={model}
          title={i18n.t("clothing.pants_size.title")}
          name="pants_size"
          options={sizes.pants}
          optionsI18nPrefix={"clothing"}
          modalComponent={
            <ReferenceModal
              title={i18n.t("clothing.pants_size.modal.title")}
              description={i18n.t("clothing.pants_size.modal.description")}
              image={pantsImg}
              alt={i18n.t("clothing.pants_size.alt")}
              testIdReference={"reference-modal-pants"}
            />
          }
        />
      </div> */}
    </>
  );
};

export default ClothingUser;
