const firstGuestAccessData = {
  firstGuestAccess: {
    name: "",
    email: "",
    password: "",
    password_confirmation: "",
    token_id: "",
    terms_of_use: false
  },
};

export { firstGuestAccessData };
