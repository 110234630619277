import React from "react";
import { useWatch } from "react-hook-form";
import i18n from "#translate/i18n";
import DivisionTitle from "../../DivisionTitle";
import SelectInput from "../../SelectInput";
import DescendantsFields from "./DescendantsFields";
import TextAreaInput from "#components/formComponents/TextAreaInput";

const MAX_MEMBERS = 15;

export default function SocioeconomicFields() {
  const model = "user";

  const human_children = useWatch({ name: `${model}.human_children` });
  const showChildrenList = String(human_children) === "true" ? true : false

  const numbers = [...Array(MAX_MEMBERS).keys()].map((number) => ++number);
  const races = [
    "black",
    "white",
    "yellow",
    "brown",
    "indigenous",
    "without_answer",
  ];

  return (
    <div>
      <DivisionTitle title={i18n.t("socioeconomic_data")} />

      <div className="grid grid-cols-2 gap-y-3 gap-x-9">
        <SelectInput
          model={model}
          name="sharing_home_with"
          options={numbers}
          title={i18n.t("sharing_home_with.sharing_home_with")}
          subtitle={i18n.t("sharing_home_with.subtitle")}
          requiredField={true}
          className='w-full'
        />
        <SelectInput
          model={model}
          name="race_color"
          options={races}
          title={i18n.t("race_color.race_color")}
          requiredField={true}
          className='w-full'
        />
      </div>
      <div className="grid gap-y-3 gap-x-9 mt-2.5">
        <TextAreaInput
          model={model}
          name="medical_follow_up"
          title={i18n.t("medical_follow_up")}
          requiredField={true}
          placeholder={i18n.t("placeholders.medical_follow_up")}
          className='w-full'
        />

        <TextAreaInput
          model={model}
          title={i18n.t("special_medical_condition")}
          name="special_medical_condition"
          requiredField={ true }
          placeholder={i18n.t("placeholders.special_medical_condition")}
          className='w-full'
        />

        <SelectInput
          model={model}
          name="human_children"
          options={["true", "false"]}
          title={i18n.t("human_children.human_children")}
          requiredField={true}
          className='w-full'
        />
      </div>
      <div className={showChildrenList ? '' : 'hidden'} id='descendants-fields'>
        <div className='h-[15px]' />
        <DescendantsFields hasChildren={showChildrenList} />
      </div>
    </div>
  );
}
