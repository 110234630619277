import * as Yup from "yup";
import {
  HomeOfficeAllowanceBaseSchema,
} from "./schemas";

const addAllowancesSchema = Yup.object().shape({
  home_office_allowance: HomeOfficeAllowanceBaseSchema,
});

export default addAllowancesSchema;
