import React from "react";
import ProfileRegistrationIcon from '#images/events_icons/register_profile.svg';
import i18n from "#translate/i18n";
import userPresentedName from '#services/userPresentedName';

export default function NewUserCreatedByAdmin({ eventData, userData }) {
  const data = eventData?.data
  const eventUserData = data || userData
  const userName = userPresentedName(eventUserData)
  const adminName = data?.responsible?.full_name || "Admin";

  return (
    <>
      <div className="relative flex-1 my-6 bg-white">
        <div className="flex items-center">
          <div className='rounded-full shadow-timeline-icons mr-2 h-12 w-12'>
            <img
              src={ProfileRegistrationIcon}
              alt="event icon"
              className='w-12 h-12 px-1.5 py-2'
            />
            </div>
          <div className="relative z-20 font-inter text-sm leading-[22px] text-info-gray">
            <p className="font-bold ">
              {`${i18n.t("timelineEvents.newUserCreatedByAdmin.title")}`}
            </p>
            <p className="font-normal">
              {`${i18n.t("timelineEvents.newUserCreatedByAdmin.description", { admin: adminName, person: userName })}`}
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
