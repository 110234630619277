import moment from 'moment';

export default function groupEventsByDate(allEvents) {
  const groupedData = {};

  allEvents.forEach((event) => {
    const date = moment(event.date).format('DD/MM/YYYY');

    if (!groupedData[date]) {
      groupedData[date] = [];
    }
    groupedData[date].push(event);
  });

  const sortedDates = Object.keys(groupedData).sort((a, b) => {
    const dateA = moment(a, 'DD/MM/YYYY');
    const dateB = moment(b, 'DD/MM/YYYY');
    return dateB - dateA;
  });

  const result = sortedDates.map((date) => ({
    date: date,
    events: groupedData[date],
  }));

  return result;
}
