import React, { useEffect, useState } from "react";
import WorkLeaveIcon from '#images/events_icons/work_leave.svg';
import i18n from "#translate/i18n";
import { Trans } from "react-i18next"
import ArrowDown from '#images/arrowdown-top.svg';
import EditIconBlue from "#images/edit-blue.svg"
import EditIconGray from "#images/edit-gray.svg"
import DeleteIconRed from "#images/delete-red.svg"
import DeleteIconGray from "#images/delete-gray.svg"
import TooltipMessageHoverButtons from "#components/TooltipMessageHoverButtons"
import userPresentedName from '#services/userPresentedName';

export default function WorkLeaveCreated({
  eventData,
  userData,
  removedArray,
  setOpenRemovalConfirmation,
  setObjectId,
  setChosenEventRemoval,
  showDetails,
  setShowDetails,
  lastUpdates,
  openEditionDrawer
}) {
  const todayStart = new Date();
  todayStart.setHours(0, 0, 0, 0)
  const data = eventData?.data || {};
  const eventUserData = data.user || userData
  const person = userPresentedName(eventUserData)
  const isRemoved = removedArray.some((removedItem) => removedItem.id === data.id && removedItem.creationEventName === eventData?.name)

  const startDate = data.start_date || ''
  const returnDate = data.foreseen_return_date || ''
  const isRemovable = new Date(`${startDate}T00:00`) >= todayStart
  const wasUpdated = lastUpdates.some((update) => update.id === data.id)

  const adminName = data.responsible?.full_name
  const reason = data.reason
  const [startYear, startMonth, startDay] = startDate.split('-')
  const [returnYear, returnMonth, returnDay] = returnDate.split('-')
  const notesValue = data.notes

  const [click, setClick] = useState(false)

  useEffect(() => {
    if(showDetails === 'close') {setClick(false)}
  }, [showDetails])

  return (
    <div className="relative flex-1 my-5 bg-white">
      <div className="flex items-center">
        <div className='rounded-full shadow-timeline-icons mr-2 h-fit min-w-fit'>
          <img
            src={WorkLeaveIcon}
            alt="event icon"
            className='w-12 h-12 p-2'
          />
        </div>
        <div className="relative z-20 font-inter text-sm leading-[22px] text-info-gray flex-1">
          <p className="font-bold inline-block">
            {`${i18n.t("timelineEvents.workLeaveCreated.title")}`}
          </p>
          <p className="font-normal">
            <Trans
              i18nKey={`${i18n.t("timelineEvents.workLeaveCreated.description")}`}
              values={{ admin: adminName, person: person, startDay: startDay, startMonth: startMonth, startYear: startYear, returnDay: returnDay, returnMonth: returnMonth, returnYear: returnYear }}
            />
          </p>
        </div>
        <button
          id='work-leave-details-button'
          className='ml-6 mb-3 p-2'
          onClick={() => { setClick(!click), setShowDetails(null) }}
        >
          <img
            className={`w-3 h-[7.2px] right-0 ${click && 'transform rotate-180'} min-w-max`}
            src={ArrowDown}
            alt={i18n.t("dropdown_arrow_img")}
          />
        </button>
      </div>
      { click && (
        <>
          <div className="relative font-inter text-sm leading-6 text-info-gray mt-3 ml-14" id='work-leave-created-details-panel'>
            <p id='work-leave-reason'>
              <span className="font-bold">{ i18n.t('timelineEvents.workLeaveCreated.reason') }</span>
              { reason }
            </p>
            <p id='work-leave-documentation-presence'>
              <span className="font-bold">{ i18n.t('timelineEvents.workLeaveCreated.document') }</span>
              { i18n.t(`timelineEvents.workLeaveCreated.${data.documentation_presence ? 'documentPresent' : 'documentMissing'}`) }
            </p>
            <div>
              <p className="font-bold">{ i18n.t('timelineEvents.workLeaveCreated.observations') }</p>
              <p id='work-leave-notes'>
                { notesValue || i18n.t('timelineEvents.workLeaveCreated.missingNotes') }
              </p>
            </div>
          </div>

          { !wasUpdated && (
            <div className='flex space-x-4 justify-end mt-5' id="work-leave-created-manage-buttons">
              <div className="flex space-x-4 group">
                {isRemoved && (
                  <>
                    <TooltipMessageHoverButtons
                      buttonClass="absolute h-11 w-[370px] right-2"
                      tooltipClass="absolute bg-border-blue z-1 gap-1 text-white items-center text-center w-[490px] text-xs px-3 py-1 h-9 font-semibold
                      rounded-lg bg-[var(--complementares-azul-claro, #587CE2)] shadow-tooltip-buttons ml-[-40px]"
                      arrowClass="absolute ml-[-50px]"
                      messageClass="h-5 pt-1"
                      message={i18n.t('timelineEvents.removedMessage')}
                    />
                  </>
                )}
                {isRemovable && (
                  <button
                    className={`justify-center font-sans font-semibold text-base bg-white
                    flex items-center px-4 py-2.5
                    ${isRemoved ? 'text-disabled-gray' : 'text-support-error' }`}
                    id="work-leave-remove-button"
                    disabled={isRemoved}
                    onClick={() => { setOpenRemovalConfirmation(true), setObjectId(data.id), setChosenEventRemoval({ name: 'WorkLeaveCreated' }), setShowDetails(null) }}
                  >
                    <img src={ isRemoved ? DeleteIconGray : DeleteIconRed } alt="" className="inline mr-2 w-6 h-6" />
                    { i18n.t('timelineEvents.workLeaveCreated.removeWorkLeave') }
                  </button>
                )}

                <button
                  className={`justify-center font-sans font-semibold text-base rounded border flex items-center px-4 py-2.5 ${isRemoved ? 'text-disabled-gray bg-disabled-gray-button' : 'text-rebase-blue border-rebase-blue bg-white'}`}
                  id="work-leave-edit-button"
                  disabled={isRemoved}
                  onClick={() => { openEditionDrawer(), setObjectId(data.id) }}
                >
                  <img src={ isRemoved ? EditIconGray : EditIconBlue } alt="" className="inline mr-2 w-6 h-6 stroke-rebase-blue" />
                  { i18n.t('timelineEvents.workLeaveCreated.editWorkLeave') }
                </button>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}
