import React, { useEffect, useState } from "react"
import i18n from "#translate/i18n"
import { Trans } from "react-i18next"

import TooltipMessageHoverButtons from "#components/TooltipMessageHoverButtons"
import expenseAssistanceIsUpdatable from "#services/finances/expenseAssistanceIsUpdatable"

import EditIconBlue from "#images/edit-blue.svg"
import EditIconGray from "#images/edit-gray.svg"
import ArrowDown from "#images/arrowdown-top.svg"
import DeleteIconRed from "#images/delete-red.svg"
import DeleteIconGray from "#images/delete-gray.svg"
import ExpenseAssistanceIcon from '#images/events_icons/expense_assistance.svg'

export default function ExpenseAssistanceCreated({
  eventData,
  removedArray,
  lastUpdates,
  setOpenRemovalConfirmation,
  setObjectId,
  setChosenEventRemoval,
  showDetails,
  setShowDetails,
  openEditionDrawer
}) {
  const todayStart = new Date()
  todayStart.setHours(0, 0, 0, 0);

  const data = eventData?.data || {}
  const adminName = data.responsible.full_name
  const [paymentYear, paymentMonth, paymentDay] = (data.payment_start_date || '').split('-')

  const frequency = i18n.t(`timelineEvents.expenseAssistanceCreated.frequency.${data.frequency}`)
  const duration = data.duration ? `${data.duration + i18n.t("timelineEvents.expenseAssistanceCreated.duration.months")}`
    : i18n.t("timelineEvents.expenseAssistanceCreated.duration.undetermined_time")
  const monthlyValue = Number(data.assistance_monthly_value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })

  const isUpdatable = expenseAssistanceIsUpdatable({data: data, refDate: todayStart})

  const isRemoved = removedArray.some((removedItem) => removedItem.id === data.id && removedItem.creationEventName === eventData?.name)
  const wasUpdated = lastUpdates.some((update) => update.id === data.id)
  const [click, setClick] = useState(false)

  useEffect(() => {
    if (showDetails === 'close') { setClick(false) }
  }, [showDetails]);

  return (
    <div className="relative flex-1 my-6 bg-white">
      <div className="flex items-center">
        <div className="rounded-full shadow-timeline-icons mr-2 h-fit min-w-fit">
          <img
            src={ExpenseAssistanceIcon}
            alt="event icon"
            className="w-12 h-12 p-1.5"
          />
        </div>

        <div className="relative z-20 font-inter text-sm leading-[22px] text-info-gray flex-1">
          <p className="font-bold inline-block">
            {`${i18n.t("timelineEvents.expenseAssistanceCreated.title")}`}
          </p>

          <p className="font-normal">
            <Trans
              i18nKey={`${i18n.t("timelineEvents.expenseAssistanceCreated.description")}`}
              values={{ admin: adminName, monthlyValue: monthlyValue, paymentDay: paymentDay, paymentMonth: paymentMonth, paymentYear: paymentYear, frequency: frequency }}
            />
          </p>
        </div>

        <button
          id='expense-assistance-event-created-details-button'
          className='ml-6 mb-3 p-1.5 inline-flex'
          onClick={() => { setClick(!click) }}

        >
          <img
            className={`w-3 h-[7.2px] right-0 ${click && 'transform rotate-180'} min-w-max`}
            src={ArrowDown}
            alt={i18n.t("dropdown_arrow_img")}
          />
        </button>
      </div>

      {click && (
        <>
          <div
            className='relative font-inter text-sm leading-6 text-info-gray mt-3 ml-14'
            id='expense-assistance-event-details'
          >
            {data.frequency === 'recurring' && (
              <p id='expense-assistance-duration'>
                <span className="font-bold">
                  {i18n.t('timelineEvents.expenseAssistanceCreated.duration.title')}
                </span>
                {duration}
              </p>
            )
            }
            <p id='expense-assistance-category'>
              <span className="font-bold">
                {i18n.t('timelineEvents.expenseAssistanceCreated.category')}
              </span>
              {data.category ? data.category : i18n.t('userProfile.missing_data')}
            </p>
            <p id='expense-assistance-reason'>
              <span className="font-bold">
                {i18n.t('timelineEvents.expenseAssistanceCreated.reason')}
              </span>
              {data.reason}
            </p>
            <p id='expense-assistance-payment-type'>
              <span className="font-bold">
                {i18n.t('timelineEvents.expenseAssistanceCreated.payment_type.title')}
              </span>
              {i18n.t(`timelineEvents.expenseAssistanceCreated.payment_type.${data.payment_type}`)}
            </p>
            <div id='expense-assistance-notes'>
              <p className="font-bold">
                {i18n.t('timelineEvents.expenseAssistanceCreated.notes')}
              </p>
              <p>
                {data.notes || i18n.t('timelineEvents.expenseAssistanceCreated.missingNotes')}
              </p>
            </div>
          </div>

          {isUpdatable && !wasUpdated && (
            <div
              className='flex space-x-4 justify-end mt-5'
              id='expense-assistance-event-buttons'
            >
              {isRemoved && (
                <>
                  <TooltipMessageHoverButtons
                    buttonClass="absolute h-11 w-[370px] right-2"
                    tooltipClass="absolute bg-border-blue z-1 gap-1 text-white items-center text-center w-[490px] text-xs px-3 py-1 h-9 font-semibold
                                    rounded-lg bg-tooltip-color shadow-tooltip-buttons ml-[-40px]"
                    arrowClass='absolute ml-[-50px]'
                    messageClass="h-5 pt-1"
                    message={i18n.t('timelineEvents.removedMessage')}
                  />
                </>
              )}
              <button
                id='expense-assistance-delete-button'
                className={`justify-center font-sans font-semibold text-base bg-white
                  ${isRemoved ? 'text-disabled-gray' : 'text-support-error'} flex items-center px-4 py-2.5`}
                disabled={isRemoved}
                onClick={() => { setOpenRemovalConfirmation(true), setObjectId(data.id), setChosenEventRemoval(eventData), setShowDetails(null) }}
              >
                <img src={isRemoved ? DeleteIconGray : DeleteIconRed} alt="" className="inline mr-2 w-6 h-6" />
                {i18n.t('timelineEvents.expenseAssistanceCreated.removeAssistance')}
              </button>

              <button
                id='expense-assistance-update-button'
                className={`justify-center font-sans font-semibold text-base rounded border
                  ${isRemoved ? 'text-disabled-gray bg-disabled-gray-button' : 'text-rebase-blue border-rebase-blue bg-white'}
                  flex items-center px-4 py-2.5`}
                disabled={isRemoved}
                onClick={() => {openEditionDrawer(), setObjectId(data.id)}}
              >
                <img src={isRemoved ? EditIconGray : EditIconBlue} alt="" className="inline mr-2 w-6 h-6 stroke-rebase-blue" />
                {i18n.t('timelineEvents.expenseAssistanceCreated.editAssistance')}
              </button>
              <div className="w-0 h-0 text-disabled-gray bg-disabled-gray-button" />
            </div>
          )}
        </>
      )}
    </div>
  )
}
