import React from "react";
import i18n from "#translate/i18n";
import Plus from "#images/Plus.svg";

export default function BtnAdd({
  id,
  name,
  style,
  display = true,
  handleOnClick = (f) => f,
}) {
  return (
    <div className={style}>
      {display && (
        <button
          id={id}
          className={ `justify-center font-sans bg-rebase-blue
                      text-white rounded flex items-center
                      border border-rebase-blue px-4 py-2.5
                      text-base font-semibold` }
          onClick={handleOnClick}
          type="button"
        >
          <img
            className="w-6 h-4 pr-2"
            src={Plus}
            alt={i18n.t("searchInput.imgMagnifier")}
          />
          <span className="whitespace-nowrap">{name}</span>
        </button>
      )}
    </div>
  );
}
