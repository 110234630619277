import React from "react";
import i18n from "#translate/i18n";
import Field from "#components/formComponents/Field";

const SelectOptions = ({
  model,
  name,
  title,
  requiredField,
  defaultValue,
  options,
  subtitle,
  modalComponent,
  index,
  tooltip,
  disabled,
  className,
  ...props
}) => {
  const hasIndex = typeof index === "number";
  const namePrefix = hasIndex ? `${index}_` : "";
  const inputNamePrefix = hasIndex ? `${index}.` : "";
  const modelPrefix = model ? `${model}_` : "";
  const selectId = `${modelPrefix}${namePrefix}${name}`;

  const buildOptionValues = (option) => {
    if (!option && option !== false) {
      return { id: "", name: "", value: "" };
    }
    const optionId = option?.id;
    const optionName = option?.name;
    const optionValue = option?.value || option?.id;
    const optionDisabled = option?.disabled || false

    return {
      id: optionId,
      name: optionName,
      value: optionValue,
      disabled: optionDisabled,
    };
  };

  return (
    <div className="flex flex-col justify-end" id={selectId}>
      <Field
        {...props}
        label={
          <>
            {title}

            { requiredField && (
              <span 
                className="pl-1 text-red-500"
                id="required-field"
              >
                *
              </span>
            )}

            {tooltip}
            {subtitle && (
              <span className="pl-1">
                (<span className="font-medium">{subtitle}</span>)
              </span>
            )}
            {modalComponent}
          </>
        }
        model={model}
        id={`${selectId}_input`}
        name={`${inputNamePrefix}${name}`}
        component="select"
        defaultValue={defaultValue}
        disabled={disabled}
        className={`py-2 px-3 mt-2 w-[101%] text-md block rounded h-11
           bg-white border border-solid border-border-gray rounded
           box-border focus:placeholder-gray-500 focus:bg-white
           focus:border-gray-600 focus:outline-none ${className}`}
      >
        <option value="" disabled={true} hidden>
          {i18n.t("placeholders.select_option")}
        </option>
        {options.map((option) => {
          const { id, name, value, disabled } = buildOptionValues(option);
          return (
            <option key={id} value={value} disabled={disabled}>
              {name}
            </option>
          );
        })}
      </Field>
    </div>
  )
};

SelectOptions.defaultProps = {
  className: "",
  disabled: false,
  index: "",
};

export default SelectOptions;
