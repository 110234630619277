import React from "react";

export default function ErrorComponent({ message, errorId, errorClassName, ...props }) {
  return (
    <span
      {...props}
      className={`items-center font-medium tracking-wide text-red-500 text-xs mt-[-6px] ${errorClassName}`}
      role="alert"
      name="validationError"
      id={errorId}
    >
      {message}
    </span>
  );
};
