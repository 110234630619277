import React from "react";

import ImgBackground from "#images/background.svg";

export default function ProfileBackground({ profileBackground, style }) {
  const image = profileBackground ? profileBackground : ImgBackground;
  return (
    <img
      id="user-background"
      src={image}
      alt="profile-background"
      className={style}
    />
  );
}
