import { useEffect } from 'react';

export default function useOnClickOutside({ref, handler, ignoredIds}) {
  useEffect(
    () => {
      const listener = (event) => {
        if(!ref.current || ref.current.contains(event.target)) {
          return;
        }
        if(ignoredIds?.length && (ignoredIds.includes(event.target.id) ||
                                  (event.target.offsetParent && ignoredIds.includes(event.target.offsetParent.id)))
        ) {
          return
        }

        handler(event);
      };

      document.addEventListener("mousedown", listener);
      document.addEventListener("touchstart", listener);
      return () => {
        document.removeEventListener("mousedown", listener);
        document.removeEventListener("touchstart", listener);
      };
    },
    [ref, handler]
  );
}
