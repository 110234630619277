import React, { createContext, useState } from "react";

import useDrawersSettings from "#services/useDrawersSettings";

export const UserContext = createContext({});

export const UserContextProvider = (props) => {
  const [refreshUserData, setRefreshUserData] = useState(false)
  const [people, setPeople] = useState([{}])
  const [notifications, setNotifications] = useState()
  const [usersLeaveSchedules, setUsersLeaveSchedules] = useState(null)
  const [showMessage, setShowMessage] = useState([])

  const {
    openedDrawers,
    profileTabName,
    setProfileTabName,
    handleDrawersOpen,
    handleDrawersClose,
    menuOrigin,
    setMenuOrigin,
    objectId,
    setObjectId,
    chosenAllowanceName,
    setChosenAllowanceName,
    selectedPerson,
    setSelectedPerson,
  } = useDrawersSettings()

  return (
    <UserContext.Provider
      value={{
        refreshUserData,
        setRefreshUserData,
        openedDrawers,
        profileTabName,
        setProfileTabName,
        handleDrawersOpen,
        handleDrawersClose,
        menuOrigin,
        setMenuOrigin,
        objectId,
        setObjectId,
        chosenAllowanceName,
        setChosenAllowanceName,
        selectedPerson,
        setSelectedPerson,
        people,
        setPeople,
        notifications,
        setNotifications,
        usersLeaveSchedules,
        setUsersLeaveSchedules,
        showMessage,
        setShowMessage,
        ...props
      }}>
      {props.children}
    </UserContext.Provider>
  )
}
