/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react'
import i18n from '#translate/i18n';

import {
  BreadCrumbs,
  // BtnFilter,
  // BtnActions,
  BtnExport
} from '#components';

import LoadingPage from '#components/navigation/LoadingPage'
import ReportDropdown from '#components/finances/dashboards/ReportDropdown'
import ReportRangeDate from '#components/finances/dashboards/ReportRangeDate'
import PayrollTable from '#components/finances/dashboards/reports/PayrollTable'
import FinanceTable from '#components/finances/dashboards/reports/FinanceTable'
import BenefitsTable from '#components/finances/dashboards/reports/BenefitsTable'

import EngDateFormat from '#services/EngDateFormat'
import fetchUsersPayrollData from '#requests/finances/reports/fetchUsersPayrollData'

import { UserFormsContext } from "#providers/UserFormsProvider"

// import bar from '#images/vertical-bar.svg'

const Reports = () => {
  const [report, setReport] = useState('payroll');
  const [filters, setFilters] = useState([]);
  const [loading, setLoading] = useState(true);
  const [usersData, setUsersData] = useState([]);
  const [currentStartYear, setCurrentStartYear] = useState('');
  const [currentEndYear, setCurrentEndYear] = useState('');
  const [currentStartMonth, setCurrentStartMonth] = useState('');
  const [currentEndMonth, setCurrentEndMonth] = useState('');
  const [dateRange, setDateRange] = useState([null, null]);
  const [isDropdownSelected, setIsDropdownSelected] = useState(false);
  const { openUserEditForms, openNewUserForm } = useContext(UserFormsContext)

  const [startDate, endDate] = dateRange;

  const handleDropdownChange = (selectedOption) => {
    setIsDropdownSelected(!!selectedOption)

    setReport(selectedOption.value)
  }

  const reportFromSelection = () => {
    switch (report) {
      case 'financial':
        return <FinanceTable data={usersData} />
      case 'payroll':
        return <PayrollTable data={usersData} />
      case 'benefits':
        return <BenefitsTable data={usersData} />
    }
  }

  function handleDateChange(dates) {
    if (!dates) { return }
    if (dates[0] && dates[1]) {
      setUsersData([])
      setFilters((values) => ({
        ...values,
        ['start_date']: dates[0],
        ['end_date']: dates[1],
      }))
      setLoading(true)
    }
  }

  function updateDateHeader() {
    const dateDoc = document.getElementById('component-range-date-input')
    const dateValue = dateDoc ? dateDoc.value : null
    const today = new Date()
    if (!dateValue) {
      setCurrentStartMonth(today.getMonth())
      setCurrentStartYear(today.getFullYear())
    } else {
      const currentDates = dateValue === '' ? [] : dateValue.split(' - ')
      const currentStart = EngDateFormat(currentDates[0])
      const currentEnd = EngDateFormat(currentDates[1])
      const currentStartDate = new Date(`${currentStart}T00:00`)
      const currentEndDate = new Date(`${currentEnd}T00:00`)
      setCurrentStartMonth(currentStartDate.getMonth())
      setCurrentStartYear(currentStartDate.getFullYear())
      setCurrentEndMonth(currentEndDate.getMonth())
      setCurrentEndYear(currentEndDate.getFullYear())
    }
  }

  const showReportTitle = () => {
    const startMonth = currentStartMonth + 1
    const endMonth = currentEndMonth + 1
    const conditionalWithMonthDiff = endMonth && currentEndYear && (startMonth !== endMonth)
    const conditionalWithYearDiff = endMonth && currentEndYear && (currentStartYear !== currentEndYear)
    if ((!conditionalWithMonthDiff && conditionalWithYearDiff) ||(conditionalWithMonthDiff && !conditionalWithYearDiff) || (conditionalWithMonthDiff && conditionalWithYearDiff)) {
      return `${i18n.t(`reportDashboard.table_title.${report}`)} (${i18n.t(`reportDashboard.months.${currentStartMonth}`)} ${currentStartYear} - ${i18n.t(`reportDashboard.months.${currentEndMonth}`)} ${currentEndYear})`
    } else {
      return `${i18n.t(`reportDashboard.table_title.${report}`)} (${i18n.t(`reportDashboard.months.${currentStartMonth}`)} ${currentStartYear})`
    }
  }

  useEffect(() => {
    async function fetchData() {
      try {
        const responseUsers = await fetchUsersPayrollData(filters);
        responseUsers && setUsersData(responseUsers.filter((person) => person['total_to_receive'] > 0));
        setLoading(false);
        updateDateHeader()
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    }
    fetchData();
  }, [filters]);

  return (
    <div className={`text-neutral-700 px-6 z-0 ${openUserEditForms || openNewUserForm ? 'hidden' : ''}`}>
      <div className='inherit inset-4'>
        <section id='reports-header'>
          <BreadCrumbs name={i18n.t('reportDashboard.title')} />
          <div className='flex justify-between pb-[34px] relative'>
            <h1 className='text-3xl font-bold' data-testid='report-title'>
              {i18n.t('reportDashboard.title')}
            </h1>
            <div className='flex z-40'>
              <ReportDropdown onChange={handleDropdownChange} />
              <ReportRangeDate
                disabled={!isDropdownSelected}
                handleChange={handleDateChange}
                startDate={startDate}
                endDate={endDate}
                setDateRange={setDateRange}
              />
              {/* <BtnFilter disabled={!isDropdownSelected} />
              <img className='mx-3 h-10' src={bar} alt='bar' />
              <BtnActions disabled={!isDropdownSelected} /> */}
              <BtnExport disabled={!isDropdownSelected} startDate={startDate} endDate={endDate} type={report} />
            </div>
          </div>
        </section>
        <div className="flex flex-col justify-start h-[calc(100vh-216px)]">
          <section className='flex'>
            {!loading && (
              <p className='font-sans not-italic font-semibold text-xl text-black-report mb-8'>
                {showReportTitle()}
              </p>
            )}
          </section>
          <section
            id='reports-dashboard'
            className='flex relative h-[calc(100vh-17rem)] overflow-auto
            border-[1px] border-solid border-border-gray'
          >
            {loading ? (
              <div id='reports_loading' className='w-full loading mr-2.5'>
                <LoadingPage />
              </div>
            ) : (
              reportFromSelection()
            )}
          </section>
        </div>
      </div>
    </div>
  )
}

export default Reports
