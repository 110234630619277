export default function fetchTokenUser(tokenId) {
  const url = `/api/v1/guests/fetch_from_token?token_id=${tokenId}`;

  const fetchTokenUser = () => {
    const responseReq = fetch(url).then(async (response) => {
      return await response.json();
    });
    return responseReq;
  };
  return fetchTokenUser();
}
