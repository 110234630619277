import i18n from "#translate/i18n";

export default function UserEventsRequest(userId) {
  const url = `api/v1/users/${userId}/events`;

  const userEventsRequest = () => {
    const response = fetch(url).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(i18n.t("requests.network_error"));
      }
    });

    return response;
  };

  return userEventsRequest();
}
