import React, { useState } from "react";
import { usePopper } from "react-popper";

const TooltipMessageHoverButtons = ({ message, tooltipClass, arrowClass, buttonClass, messageClass }) => {
  const [open, setOpen] = useState(false);
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "top",
    modifiers: [
      {
        name: "arrow",
        enabled: true,
        options: { element: arrowElement },
        phase: "main",
      },
      { name: "flip", options: { fallbackPlacements: ["top"] } },
      { name: "offset", options: { offset: [0, 10] } },
    ],
  });
  const handleClick = () => {
    setOpen((changeOpen) => !changeOpen);
  };
  const defautlArrowClass = "w-2 h-2 before:transform-y-[10px] before:w-2 before:h-2 bg-border-blue arrow-position before:bg-border-blue before:visible invisible before:absolute before:rotate-45 mt-2 mb-4"

  return (
    <>
      <button
        type="button"
        className={buttonClass}
        onClick={handleClick}
        ref={setReferenceElement}
        onMouseEnter={() => setOpen(true)}
        onMouseLeave={() => setOpen(false)}
      />

      <div className="absolute">
        {open && (
          <div
            role="tooltip"
            className={tooltipClass}
            ref={setPopperElement}
            style={styles.popper}
            {...attributes.popper}
          >
            <p className={messageClass}>{message}</p>
            <div className="right-0 absolute">
              <div
                className={`${defautlArrowClass} ${arrowClass}`}
                ref={setArrowElement}
                style={styles.arrow}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

TooltipMessageHoverButtons.defaultProps = {
  message: "",
  tooltipClass:
    "w-80 h-fit py-1 px-3 text-white font-medium leading-6 rounded-lg bg-border-blue tooltip-position",
  arrowClass: "w-2.5 h-2.5 before:transform-y-[10px] before:w-2.5 before:h-2.5 bg-border-blue arrow-position before:bg-border-blue before:visible invisible before:absolute before:rotate-45 mt-2 mb-4",
};

export default TooltipMessageHoverButtons;
