import axios from "axios";

export default function removeSalaryUpdateRequest({ id, user }) {
  const url = `/api/v1/users/${user.id}/salaries/${id}`

  const requestOptions = () => {
    const csrf = document
      .querySelector("meta[name='csrf-token']")
      .getAttribute("content");

    return {
      method: "DELETE",
      headers: { "X-CSRF-Token": csrf }
    };
  };

  const options = requestOptions();

  return axios({ url, ...options });
}
