const translations = {
  title: "Onboarding",
  userName: {
    title: "Olá, que bom ter você conosco!",
    subtitle: "Como podemos te chamar?",
    text: "Valorizamos a individualidade de todas as pessoas, por isso, nos conte como você prefere ser chamado.",
    label: "Como gostaria de ser chamado?*",
  },
  hobbies: {
    title: "{{name}}, conta pra gente quais são seus interesses?",
  },
  pictures: {
    title: "É hora do click, diga Xis! 📸",
    subtitle: "Precisamos de duas fotos suas, tudo bem?",
    text: "Uma de rosto, que será sua foto de perfil. Outra de corpo, em que você esteja o mais parecido possível com como você é no dia a dia.",
  },
  birthDate: {
    title: "Queremos comemorar com você! 🎈",
    subtitle: "Quando é seu aniversário?",
    text: "Compartilhe sua data de nascimento e nós cuidamos da festa!",
    label: "Data de nascimento*",
  },
  clothing: {
    title: "Prepare um espaço no guarda-roupa! 👕👗",
    subtitle: "Precisamos saber suas medidas.",
    text: "Compartilhe o tamanho da sua camiseta, blusa de moletom e calça. Se tiver alguma dúvida sobre o tamanho clique em ver referências.",
  },
  address: {
    title: "Olha o carteiiiroo! 📬",
    subtitle: "Qual é o seu endereço de entrega?",
    text: "Para que nossos mimos não se percam pelo caminho, informe corretamente seu endereço para entrega de encomendas.",
    street: "Rua",
    zip_code: "CEP",
    neighborhood: "Bairro",
    house_number: "Número",
    city: "Cidade",
    state: "Estado",
    reference_point: "Ponto de referência",
    complement: "Complemento",
    country: "País",
  },
  proceed: "avançar",
}

export { translations }
