import React, { useEffect } from "react";
import IndexRoutes from "../routes/Index";

export default function App() {
  useEffect(() => {
    const use = async () => {
      (await import('tw-elements')).default;
    };
    use();
  }, []);

  return <IndexRoutes />;
}