const translations = {
  people: 'Pessoas',
  client: 'Cliente',
  salary: 'Remuneração',
  status: 'Status',
  houseTime: 'Tempo de casa',
  lastRaise: 'Últi. Reajuste',
  startedInCompanyDate: 'Dt. de início',
  workField: 'Atuação',
  birthdate: 'Dt. Nasc.',
  actions: 'Ações',
  days_one: '{{count}} dia',
  days_other: '{{count}} dias',
  months_one: '{{count}} mês',
  months_other: '{{count}} meses',
  noEmail: 'Nenhum email informado',
  filters: {
    filters: 'Filtros',
    select: 'Selecione os filtros desejados',
    cleanFilter: 'Limpar Filtros',
    applyFilter: 'Aplicar Filtros'
  },
  search: 'Pesquise pelo nome...',
  magnifierGlass: 'Lupa',
  selectAll: 'Selecionar tudo',
  newPerson: 'Nova pessoa',
  menu: {
    confirmDeletion: 'Você tem certeza que deseja remover o usuário?',
    userDeleted: 'Usuário removido com sucesso',
    workLeave: "Adicionar período de afastamento",
    expenseAssistance: 'Adicionar ajuda de custo',
    salaryReadjustment: 'Reajuste de remuneração',
    addAllowance: 'Adicionar beneficios',
    bonus: 'Adicionar premiação',
    createUserDismissal: 'Registrar desligamento',
    updateUserDismissal: 'Editar desligamento',
    edit: 'Editar cadastro',
    delete: 'Remover cadastro',
    moreOptions: 'Mais opções'
  },
  personStatus: {
    title: 'Status atual do Rebaser',
    active: 'Ativo',
    medical: 'Licença Médica',
    on_vacation: 'Desc. remunerado',
    dismissed: 'Desligado',
    on_parental_leave: 'Pres. parental',
    on_work_leave: 'Afastado',
  },
  dateSearch: {
    companyEntrance: 'Entrada na Empresa',
    calendarAlt: 'mostra o campo de range de duas datas',
    title: 'Data de início na Rebase',
    startDate: 'De',
    endDate: 'Até'
  },
  project: {
    title: "Projeto atual",
  },
  workFields: {
    title: "Atuação atual",
    DEV: "Pessoa desenvolvedora",
    COMMS: "Comunicação",
    MKT: "Marketing",
    SPE: "Pessoa especialista",
    HR: "Time Pessoas",
    UX: "UX",
    FINANCES: "Financeiro",
    THIRD_PARTY: "Terceirizada",
    INTERN: "Estagiário",
    PROJECTS: "Projetos",
    PRODUCT: "Produto",
    OWNER: "Sócios",
    ALAN: "Sócio - Alan Batista",
    EDERSON: "Sócio - Ederson de Lima",
    JOAO: "Sócio - João Almeida"
  }
}

export { translations }
