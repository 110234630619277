import React from "react";
import i18n from "#translate/i18n";
import Plus from "#images/buttons/button_image_add.svg";

export default function BtnImageAdd({
  id,
  style,
  display = true,
  handleOnClick = (f) => f,
}) {
  return (
    <>
      {display && (
        <button
          id={id}
          onClick={handleOnClick}
          type="button"
          style={style}
        >
          <img
            src={Plus}
            alt={i18n.t("searchInput.imgMagnifier")}
            style={{ width: 28 }}
          />
        </button>
      )}
    </>
  );
}
