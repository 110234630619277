import i18n from "#translate/i18n";
import formatDate from "#services/formatDate";

export default function fetchUsersPayrollData(filters) {
  const startDate = filters.start_date ? formatDate(filters.start_date) : ''
  const endDate = filters.end_date ? formatDate(filters.end_date) : ''

  const url = `/api/v1/reports/payrolls?start_date=${startDate}&end_date=${endDate}`

  const usersPayrollRequest = () => {
    const response = fetch(url).then(async (response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(i18n.t('message_error'));
      }
    });

    return response;
  };

  return usersPayrollRequest();
}
