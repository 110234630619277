import React, { useState } from 'react'
import i18n from '#translate/i18n';
import Select from 'react-select';
import smallReport from '#images/small_report.svg'

const options = [
  {
    value: 'payroll',
    text: i18n.t('reportDashboard.dropdown.management_vision'),
    icon: <img src={smallReport} alt={i18n.t('dropdown_arrow_img')} />
  },
  {
    value: 'financial',
    text: i18n.t('reportDashboard.dropdown.financial_vision'),
    icon: <img src={smallReport} alt={i18n.t('dropdown_arrow_img')} />
  },
  {
    value: 'benefits',
    text: i18n.t('reportDashboard.dropdown.benefits_vision'),
    icon: <img src={smallReport} alt={i18n.t('dropdown_arrow_img')} />
  }
];
// react-select-package customStyle
const customStyles = {
  indicatorSeparator: () => ({ display: 'none' }),
  control: (baseStyles,) => ({
    ...baseStyles,
    border: '2px solid #D4DBDF',
    height: 40,
    }),
  placeholder: (baseStyles) => {
    return {
        ...baseStyles,
        color: '#344254',
    }
},
};

const ReportDropdown = ({onChange}) => {
    const [selectedOption, setSelectedOption] = useState(null);

    const handleChange = (e) => {
      setSelectedOption(e);
      onChange(e);
    };

  return (
    <div data-testid='report-dropdown'>
      <Select
        styles={customStyles}
        isSearchable={false}
        className='w-80 h-11 mr-2 z-50'
        placeholder={i18n.t('reportDashboard.dropdown.select_option')}
        value={selectedOption}
        options={options}
        onChange={handleChange}
        getOptionLabel={e => (
          <div className='flex items-center'>
            {e.icon}
            <div className='ml-[11px]'>{e.text}</div>
          </div>
        )}
      />
    </div>
  );
}

export default ReportDropdown
