import React, { createContext, useState } from "react";

export const UserFormsContext = createContext({});

export const UserFormsProvider = (props) => {
  const [openUserEditForms, setOpenUserEditForms] = useState(false)
  const [userDataEditForms, setUserDataEditForms] = useState(false)
  const [openNewUserForm, setOpenNewUserForm] = useState(false)

  const closeForms = () => {
    setOpenNewUserForm(false)
    setOpenUserEditForms(false)
  }

  return(
    <UserFormsContext.Provider
      value={{ openUserEditForms, setOpenUserEditForms, userDataEditForms, setUserDataEditForms, openNewUserForm, setOpenNewUserForm, closeForms } }
    > 
      {props.children}
    </UserFormsContext.Provider>
  )
}
