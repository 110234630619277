import React from "react";
import { formatFileTypes } from "#services/string";
import InputFile from "../formComponents/InputFile";

export default function EmptyUploadFile({
  model,
  id,
  name,
  type,
  accept,
  maximumSize,
  fileError = false,
  onChange = (f) => f,
  documentImage,
  documentType,
  alt,
  customPb,
  requiredField
}) {
  return (
    <div className="h-[201px] flex flex-col items-center px-4 pt-9 pb-6 rounded">
      <img src={documentImage} alt={alt} className="w-10 h-10" id='empty-image' />
        <p className={`text-default-gray-2 font-inter text-sm font-bold leading-7 uppercase pt-2.5 ${customPb}`}>
          {documentType}
          {requiredField && (
            <span 
              className="pl-1 text-red-500"
              id="required-field"
            >
              *
            </span>
          )}
        </p>
      <InputFile
        model={model}
        name={name}
        type={type}
        id={id}
        onChange={onChange}
        accept={accept}
        fileError={fileError}
      />

      <span className="text-sm text-placeholder-gray mt-1.5">
          {`${formatFileTypes(accept)} - Max. ${maximumSize}MB`}
      </span>
    </div>
  );
}
