import dateWithTimeZoneName from "#services/dates/dateWithTimeZoneName"

export default function isUserDismissed(exitDate) {
  let result = false
  const rawDate = new Date(exitDate)
  rawDate.setDate(rawDate.getDate() + 1)
  const exitDismissalDate = dateWithTimeZoneName(new Date(rawDate))
  const currentDate = dateWithTimeZoneName(new Date())

  if(new Date(currentDate) > new Date(exitDismissalDate)) {
    result = true
  }
  return result
}
