import moment from "moment"
import formatDateString from '#services/formatDateString'
import isDateString from '#services/isDateString'

export default function convertDateToDBString(obj) {
  function isFile(fileData) {
    if (fileData instanceof File || fileData instanceof FileList || fileData instanceof Blob){
      return true;
    }
    return false
  }
  if (typeof obj === 'object' && obj !== null) {
    if(!isFile(obj)) {
      if (Array.isArray(obj)) {
        for (let i = 0; i < obj.length; i++) {
          obj[i] = convertDateToDBString(obj[i]);
        }
      } else {
        for (const key in obj) {
          if (Object.prototype.hasOwnProperty.call(obj, key)) {
            if (obj[key] instanceof Date) {
              obj[key] = moment(obj[key]).format('YYYY-MM-DD');
            } else if (typeof obj[key] === 'string' && isDateString(obj[key])) {
              obj[key] = moment(formatDateString(obj[key])).format('YYYY-MM-DD');
            } else if (typeof obj[key] === 'object' && obj[key] !== null) {
              // Recursively process nested objects/arrays
              obj[key] = convertDateToDBString(obj[key]);
            }
          }
        }
      }
    }
  }
  return obj;
}