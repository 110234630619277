import React from "react"
import i18n from "#translate/i18n"
import DateInput from '#components/listings/people/filters/DateInput'


export default function DateSearch({ startDate, setStartDate, endDate, setEndDate }) {
  return (
    <div className="m-5">
      <p className="text-black-report text-lg font-semibold mb-6">{ i18n.t('peopleListing.dateSearch.title') }</p>
      <div className="grid grid-cols-2 w-[473px] gap-x-4">
        <p className="text-disabled-dark-gray text-sm font-medium mb-1.5">{ i18n.t('peopleListing.dateSearch.startDate') }</p>
        <p className="text-disabled-dark-gray text-sm font-medium mb-1.5">{ i18n.t('peopleListing.dateSearch.endDate') }</p>
        <div className="flex flex-row items-center gap-x-4">
          <DateInput
            name={'dates.hiring_search_start_date'}
            selectsStart
            selected={startDate}
            startDate={startDate}
            endDate={endDate}
            onFieldChange={(date) => setStartDate(date)}
          />
          <DateInput
            name={'dates.hiring_search_end_date'}
            selectsEnd
            selected={endDate}
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            onFieldChange={(date) => setEndDate(date)}
          />
        </div>
      </div>
    </div>
  )
}