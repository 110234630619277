import React, { useEffect } from "react";
import { useFormContext, useFieldArray, useWatch } from "react-hook-form";
import i18n from "#translate/i18n";
import InputText from "#components/formComponents/InputText";
import PhoneInput from "#components/formComponents/PhoneInput";
import HiddenInput from "#components/formComponents/HiddenInput";
import DivisionTitle from "#components/formComponents/DivisionTitle";
import DivisionLine from "#components/formComponents/DivisionLine";
import BtnAdd from "../BtnAdd";
import BtnRemove from "../BtnRemove";

export default function ContactsFields() {
  const model = "contacts";
  const { control, setValue } = useFormContext();
  useWatch({ control, name: model });

  const { fields, append, remove, update } = useFieldArray({
    control,
    name: model,
  });

  const initialValues = {
    DDD_landline: "",
    DDD_phone_number: "",
    landline: "",
    phone_number: "",
    personal_email: "",
    corporate_email: "",
    primary: false,
  };

  const displayRemoveButton = (contact) => (
    !contact.primary
  )

  const removeField = (contact, index) => {
    if (!contact.contacts_id) return () => remove(index);

    const statusField = `${model}.${index}.status`;
    // TODO: Find better way to hide element and update state
    return () => {
      update(index, { ...contact, ["status"]: 'removed' });
      setValue(statusField, 'removed');
    };
  };

  useEffect(() => {
    if (fields.length === 0) {
      const firstContact = {...initialValues}
      firstContact.primary = true
      append(firstContact);
    }
  }, [fields]);

  const activeFields = fields.filter((field) => field.status !== 'removed');

  return (
    <>
      <DivisionTitle title={i18n.t("contact_details")} />

      {activeFields.map((contact, index) => (
        <div
          key={contact.id}
          id={`contact-${index}`}
          className={contact.status === 'removed' ? "hidden" : ""}
        >
          <p className='h-5 font-sans not-italic font-semibold text-sm text-title-gray'>
            {`${i18n.t("contact_subtitle")} ${index+1}`}
          </p>
          <div className={'grid grid-cols-2 gap-y-3 gap-x-9 mt-5'}>
            <PhoneInput
              model={model}
              title={i18n.t("landline_phone")}
              name="landline"
              index={index}
              type="landline"
            />

            <PhoneInput
              model={model}
              title={i18n.t("phone")}
              name="phone_number"
              requiredField={!index}
              index={index}
              type="mobile"
            />

            <InputText
              model={model}
              title={i18n.t("personal_email")}
              name={`${index}.personal_email`}
              requiredField={!index}
              placeholder={i18n.t("personal_email_placeholder")}
              className='w-full'
            />

            <InputText
              model={model}
              title={i18n.t("corporate_email")}
              name={`${index}.corporate_email`}
              subtitle={i18n.t("corporate_email_subtitle")}
              requiredField={!index}
              placeholder={i18n.t("corporate_email_placeholder")}
              className='w-full'
            />

            {contact.contacts_id && (
              <>
                <HiddenInput model={model} name={`${index}.status`} />
              </>
            )}
          </div>

          <div className={ `flex ${ (activeFields.length - 1 === index) && 'gap-6' }` }>
            <BtnAdd
              model={model}
              id={`add-new-contact-${index}`}
              name={i18n.t("buttons.new_contact")}
              style="mt-6"
              display={activeFields.length - 1 === index}
              handleOnClick={() => append(initialValues)}
            />

            {index > 0 && displayRemoveButton(contact) && (
              <BtnRemove
                model={model}
                id={`remove-contact-${index}`}
                name={i18n.t("buttons.remove_contact")}
                style="mt-6"
                handleOnClick={removeField(contact, index)}
              />
            )}
          </div>
          <DivisionLine />
        </div>
      ))}
    </>
  );
}
