import React from "react";
import WorkLeaveIcon from '#images/events_icons/work_leave.svg';
import i18n from "#translate/i18n";
import { Trans } from "react-i18next"
import userPresentedName from '#services/userPresentedName';

export default function WorkLeaveReturn({ eventData, userData }) {
  const data = eventData?.data
  const eventUserData = data.user || userData
  const userName = userPresentedName(eventUserData)

  return (
    <div className="relative flex-1 my-6 bg-white">
      <div className="flex items-center">
        <div className='rounded-full shadow-timeline-icons mr-2 h-fit min-w-fit'>
          <img
            src={WorkLeaveIcon}
            alt="event icon"
            className='w-12 h-12 p-2'
          />
        </div>
        <div className="relative z-20 font-inter text-sm leading-[22px] text-info-gray">
          <p className="font-bold ">
            {`${i18n.t("timelineEvents.workLeaveReturn.title")}`}
          </p>
          <p className="font-normal">
            <Trans
              i18nKey={`${i18n.t("timelineEvents.workLeaveReturn.description")}`}
              values={{ userName: userName }}
            />
          </p>
        </div>
      </div>
    </div>
  );
}
