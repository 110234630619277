import * as Yup from "yup";
import i18n from "#translate/i18n";

Yup.setLocale(i18n.t("yup.schemas", { returnObjects: true }));

const AddressSchema = Yup.object().shape({
  zip_code: Yup.string().required(),
  street: Yup.string().required(),
  house_number: Yup.string().required(),
  country: Yup.string().required(),
  state: Yup.string().required(),
  city: Yup.string().required(),
  empty_complement: Yup.boolean(),
  complement: Yup.string().when('empty_complement', {
    is: (empty_complement) => (empty_complement === false || empty_complement === 'false'),
    then: (schema) => schema.required(),
  }),
  neighborhood: Yup.string().required(),
});

const UserAddressSchema = AddressSchema.shape({
  alternative: Yup.string().nullable(),
});


const DeliveryAddressSchema = Yup.object().when("address.alternative", {
    is: 'true',
    then: Yup.object({ 
      zip_code: Yup.string().required(),
      street: Yup.string().required(),
      house_number: Yup.string().required(),
      country: Yup.string().required(),
      state: Yup.string().required(),
      city: Yup.string().required(),
      empty_complement: Yup.boolean(),
      complement: Yup.string().when('empty_complement', {
        is: (empty_complement) => (empty_complement === false || empty_complement === 'false'),
        then: (schema) => schema.required(),
      }),
      neighborhood: Yup.string().required(),
    }),
    otherwise: (schema) => schema.strip(),
  });

export { AddressSchema, UserAddressSchema, DeliveryAddressSchema };