import * as Yup from "yup";
import i18n from "#translate/i18n";

Yup.setLocale(i18n.t("yup.schemas", { returnObjects: true }));

const { errors: errorLabel } = i18n.t("yup.forms", { returnObjects: true });
const today = new Date();
today.setHours(0, 0, 0, 0)

const ExpenseAssistanceBaseSchema = Yup.object().shape({
  frequency: Yup.string().typeError(errorLabel.select_option).required(i18n.t("expense_assistance.forms_error.presence")),
  reason: Yup.string().required(i18n.t("expense_assistance.forms_error.presence")),
  category: Yup.string(),
  duration: Yup.string()
               .typeError(i18n.t("expense_assistance.forms_error.presence"))
               .when("frequency", (frequency, schema) => {
                 if(frequency === 'recurring'){
                   return schema.required(i18n.t("expense_assistance.forms_error.presence"))
                 }
               }),
  assistance_monthly_value: Yup.number()
                               .typeError(i18n.t("expense_assistance.forms_error.presence"))
                               .required(i18n.t("expense_assistance.forms_error.presence")),
  payment_start_date_past: Yup.string().required(),
  payment_start_date: Yup.date()
                         .when("payment_start_date_past", {
                            is: (val) => val !== "true",
                            then: Yup.date()
                                     .typeError(i18n.t("expense_assistance.forms_error.presence"))
                                     .required(i18n.t("expense_assistance.forms_error.presence"))
                                     .nullable()
                                     .min(today, i18n.t("expense_assistance.forms_error.date_error")),
                            otherwise: Yup.date().typeError(i18n.t("expense_assistance.forms_error.presence")).required(i18n.t("expense_assistance.forms_error.presence")).nullable()
                         }),
  payment_type: Yup.string().typeError(errorLabel.select_option).required(i18n.t("expense_assistance.forms_error.presence")),
  notes: Yup.string(),
});

export default ExpenseAssistanceBaseSchema;
