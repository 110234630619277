import React from 'react';
import i18n from "#translate/i18n";
import Title from '#components/user/profile/Title';
import BankIcon from "#images/bank-icon.svg";
import CompanyInfoIcon from "#images/company-info-icon.svg";
import CompanyAddressIcon from "#images/company-address-icon.svg";
import BankAccount from '#components/user/profile/bankCompanyTab/BankAccount';
import CompanyInformation from '#components/user/profile/bankCompanyTab/CompanyInformation';
import Address from '#components/user/profile/bankCompanyTab/Address';

export default function BankCompanyTab({userData}) {
  const company_information = userData.company_information
  const bankAccounts = userData.bank_accounts

  const companyInfoKeys = [
    "corporate_name",
    "cnpj",
    "state_registration",
    "municipal_registration",
    "type_corporate",
    "collect_iss",
    "zip_code",
    "street",
    "neighborhood",
    "city",
    "state",
    "house_number",
    "complement",
    "country"
  ];

  function checkDataPresence() {
    for (const bankAccount of bankAccounts) {
      if (bankAccount.account_status === "active") {
        return true;
      }
    }
    return false;
  }

  return (
    <div>
      {checkDataPresence() && (
        <>
          <Title
            title={i18n.t('userProfile.bank_data.bank_data')}
            icon={BankIcon}
          />
          <BankAccount
            bankAccounts={bankAccounts}
          />
        </>
      )}

      {companyInfoKeys.slice(0, 6).some((key) => company_information && company_information[key]) && (
        <>
          <Title 
            title={i18n.t('userProfile.company_data.company_data')} 
            icon={CompanyInfoIcon}
          />
          <CompanyInformation
            companyInformation={userData.company_information}
          />
        </>
      )}
      {companyInfoKeys.slice(6, 14).some((key) => company_information && company_information[key]) && (
        <>
          <Title 
            title={i18n.t('userProfile.company_address')} 
            icon={CompanyAddressIcon}
          />
          <Address
            address={userData.company_information} 
          />
        </>
      )}
    </div>
  );
}