import React from "react";
import i18n from "#translate/i18n";

import Collapsible from "#components/listings/people/data/drawers/Collapsible"
import PersonalDataIcon from "#images/personal-data-icon.svg";
import BasicPersonalData from "./BasicPersonalData";
import SocioEconomicData from "./SocioEconomicData";
import ContactData from "./ContactData";
import AddressData from "./AddressData";
import DeliveryAddressData from "./DeliveryAddressData";
import ClothingData from "./ClothingData";
import GeneralObservationsData from "./GeneralObservationsData";
import DivisionLine from "#components/formComponents/DivisionLine";
import FillOrCleanSelection from "./FillOrCleanSelection";

export default function PersonalData({ fieldsPersonalDataOptions, openSection, setOpenSection }) {
	return (
		<Collapsible
			openSection={openSection}
			setOpenSection={setOpenSection}
			section={'personal_data'}
			buttonIcon={PersonalDataIcon}
			buttonText={i18n.t('customizedDataExport.selections.sections.personal_data.title')}
			buttonId={'personal_data_options'}
			collapseComponent={
				<div className="pb-4 lg:pb-2 2xl:pb-4 gap-3 text-title-gray">
					<FillOrCleanSelection sectionName={'personal_data'} sectionDataOptions={fieldsPersonalDataOptions} />
					<BasicPersonalData fieldsPersonalDataOptions={fieldsPersonalDataOptions} />
					<DivisionLine customStyle='mb-6' />
					<SocioEconomicData fieldsPersonalDataOptions={fieldsPersonalDataOptions} />
					<DivisionLine customStyle='mb-6' />
					<ContactData fieldsPersonalDataOptions={fieldsPersonalDataOptions} />
					<DivisionLine customStyle='mb-6' />
					<AddressData fieldsPersonalDataOptions={fieldsPersonalDataOptions} />
					<DivisionLine customStyle='mb-6' />
					<DeliveryAddressData fieldsPersonalDataOptions={fieldsPersonalDataOptions} />
					<DivisionLine customStyle='mb-6' />
					<ClothingData fieldsPersonalDataOptions={fieldsPersonalDataOptions} />
					<DivisionLine customStyle='mb-6' />
					<GeneralObservationsData fieldsPersonalDataOptions={fieldsPersonalDataOptions} />
				</div>
			}
		/>
	);
}
