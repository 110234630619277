import React from "react";
import i18n from "#translate/i18n";

export default function BankAccount({ bankAccounts }) {
  return (
    <>
      {bankAccounts.map((bankAccount) => {
        if (bankAccount.account_status === "active") {
          return (
            <div
              className="text-info-gray font-sans text-sm leading-[26px] tracking-normal text-left p-4 px-5" 
              key={bankAccount.id}
            >
              <p>
                <span className="font-bold">
                  {i18n.t('userProfile.bank_data.bank')}:
                </span>{" "}
                {bankAccount.code} - {bankAccount.name}
              </p>
              <p>
                <span className="font-bold">
                  {i18n.t('userProfile.bank_data.agency')}:
                </span>{" "}
                {bankAccount.agency_number}
              </p>
              <p>
                <span className="font-bold">
                  {i18n.t(`userProfile.bank_data.account.${bankAccount.account_type}`)}:
                </span>{" "}
                {bankAccount.account_number}
              </p>
              {bankAccount.pix_key && (
                <p>
                  <span className="font-bold">
                    {i18n.t('userProfile.bank_data.pix_key')}:
                  </span>{" "}
                  {bankAccount.pix_key}
                </p>
              )}
              <p>
                <span className="font-bold">
                  {i18n.t('userProfile.bank_data.beneficiary_name')}:
                </span>{" "}
                {bankAccount.beneficiary_name}
              </p>
             {bankAccount.rg_of_holder && (
              <p>
                <span className="font-bold">
                  {i18n.t('userProfile.bank_data.rg_of_holder')}:
                </span>{" "}
                {bankAccount.rg_of_holder}
              </p>
              )}
              <p>
                <span className="font-bold">
                  {i18n.t('userProfile.bank_data.cpf_of_holder')}:
                </span>{" "}
                {bankAccount.beneficiary_document_number}
              </p>
            </div>
          );
        }
      })}
    </>
  );
}
