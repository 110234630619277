import React from "react";
import i18n from "#translate/i18n";
import MattermostIcon from '#images/profile_drawer/mattermost-icon.svg';
import GithubIcon from '#images/profile_drawer/github-icon.svg';

export default function Socials({ companyHistory }) {
  companyHistory = companyHistory ? companyHistory : {};
  const { github_user, mattermost_username } = companyHistory;

  return (
    <>
      { companyHistory
        && <>
            {github_user
              &&
              <a
                  className='mr-2'
                  href={github_user}
                  id='github_link'
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    id='github_icon'
                    className='inline'
                    src={GithubIcon}
                    alt={i18n.t('userProfile.github_icon')}
                  />
              </a>
            }
            {mattermost_username
              &&
                <a
                  className='mr-2'
                  href={`https://mm.campuscode.com.br/campus-code/messages/${mattermost_username}`}
                  id='mattermost_link'
                  target="_blank"
                  rel="noreferrer"
                >
                  <img 
                    id='mattermost_icon'
                    className='inline'
                    src={MattermostIcon}
                    alt={i18n.t('userProfile.mattermost_icon')}
                  />
              </a>
            }
          </>
      }
    </>
  );
}