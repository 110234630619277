import React from "react";
import StartedInCompanyIcon from '#images/events_icons/started_in_company.svg';
import i18n from "#translate/i18n";
import userPresentedName from '#services/userPresentedName';

export default function StartedInCompany({ eventData, userData }) {
  const data = eventData?.data
  const eventUserData = data.user || userData
  const userName = userPresentedName(eventUserData)
  const company = data?.company
  const descriptionCompany = i18n.t(`timelineEvents.startedInCompany.companies.${company}`);

  return (
    <>
      <div className="relative flex-1 my-6 bg-white">
        <div className="flex items-center">
          <div className='rounded-full shadow-timeline-icons mr-2 h-12 w-12'>
            <img
              src={StartedInCompanyIcon}
              alt="event icon"
              className='w-12 h-12 pt-3 pb-1'
            />
          </div>
          <div className="relative z-20 font-inter text-sm leading-[22px] text-info-gray">
            <p className="font-bold ">
              {`${i18n.t("timelineEvents.startedInCompany.title")}`}
            </p>
            <p className="font-normal">
              {`${i18n.t("timelineEvents.startedInCompany.description", { userName: userName, descriptionCompany: descriptionCompany })}`}
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
