
import findArrayIndexesForObjectKeyValue from "#services/findArrayIndexesForObjectKeyValue"

export default function insertErrorsIntoArrayInput({formMethods, data, listName, attributeName, additionalChanges, message}) {
  const indexes = []
  data.forEach(entry => {
    const formsData = formMethods.getValues(listName)
    const dataIndexes = findArrayIndexesForObjectKeyValue({ arrayData: formsData, keyName: attributeName, valueRef: entry })
    indexes.push(dataIndexes)
  })
  indexes.forEach(index => {
    formMethods.setError(`${listName}.${index}.${attributeName}`, { type: "custom", message: message })
  })
  additionalChanges?.(indexes)
}
