import React from "react";
import { CompanyHistoryRecordFields, CompanyHistoryFields } from "../fields/";
import DivisionLine from "../../DivisionLine";

export default function CompanyHistoryRecordForm({
  selectedForm,
  clientList,
  graduateClasses,
  userId,
}) {
  const hidden = selectedForm === "history-data-header" ? "" : "hidden";

  return (
    <div id="company_history" className={hidden}>
      <CompanyHistoryFields graduateClasses={graduateClasses} userId={userId} />

      <DivisionLine />

      <CompanyHistoryRecordFields clientList={clientList} />
    </div>
  );
}
