/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import i18n from "#translate/i18n";
import InputText from "../InputText";
import SelectInput from "../SelectInput";
import InputMaskedText from "#components/formComponents/InputMaskedText";
import BankSelectInput from "#components/formComponents/userForm/BankSelectInput";

export default function BankAccount({ model, index, children, bankingInstitutions }) {
  const { setValue, trigger } = useFormContext();
  const accountName = `${model}.${index}`;
  const beneficiary = useWatch({ name: `${accountName}.beneficiary` });
  const notSelectBeneficiaryOption = beneficiary === ""
  const isItself = beneficiary === "is_itself"
  const autoCompleteFields = isItself || notSelectBeneficiaryOption;

  // TODO: find better solution than useWatch + useEffect for updating fields
  const userFields = useWatch({
    name: ["user.full_name", "user.cpf", "user.rg"],
  });

  const beneficiaryDocumentNumber = useWatch({ name: `${accountName}.beneficiary_document_number` })

  const setBeneficiaryFields = ({ target: { value } }) => {
    manageFields(value);
  };

  const manageFields = (value) => {
    const [userFullName, userCpf, userRg] = userFields
    if (value === "is_itself" && (userCpf || userRg)) {
      setValue(`${accountName}.beneficiary_name`, userFullName);
      setValue(`${accountName}.beneficiary_document_number`, userCpf);
      setValue(`${accountName}.rg_of_holder`, userRg);
    } else {
      setValue(`${accountName}.beneficiary_name`, "");
      setValue(`${accountName}.beneficiary_document_number`, "");
      setValue(`${accountName}.rg_of_holder`, "");
    }
    trigger([`${accountName}.beneficiary_name`, `${accountName}.beneficiary_document_number`, `${accountName}.rg_of_holder`])
  };

  useEffect(() => {
    if (autoCompleteFields) {
      manageFields(beneficiary);
    }
  }, [userFields]);

  const handleChange = event => {
    const field = event.target.name
    const result = event.target.value.replace(/[^0-9!@#¨$%^&*)(+=._-]+/gi, '')
    setValue(field, result)
  }

  return (
    <div className="grid grid-cols-1 gap-y-3 gap-x-9">
      <BankSelectInput
        bankingInstitutions={bankingInstitutions}
        name={`${index}.bank_id`}
        model={model}
        index={index}
        title={i18n.t("bank_account.bank_id")}
        requiredField={true}
        className="w-full"
      />
      <div className="grid grid-cols-2 gap-y-3 gap-x-9">
        <InputText
          model={model}
          name={`${index}.agency_number`}
          title={i18n.t("bank_account.agency")}
          requiredField={true}
          className="w-full"
          onChange={handleChange}
        />
        <InputText
          model={model}
          name={`${index}.account_number`}
          title={i18n.t("bank_account.account")}
          requiredField={true}
          className="w-full"
          onChange={handleChange}
        />
        <SelectInput
          model={model}
          name="account_type"
          title={i18n.t("bank_account.account_type")}
          requiredField={true}
          options={["checking_account", "savings"]}
          index={index}
          className="w-full"
        />
        <InputText
          model={model}
          name={`${index}.pix_key`}
          title={i18n.t("bank_account.pix_key")}
          requiredField={true}
          className="w-full"
        />
        <SelectInput
          model={model}
          name="beneficiary"
          title={i18n.t(`beneficiary.beneficiary`)}
          requiredField={true}
          options={["is_itself", "other"]}
          index={index}
          onChange={setBeneficiaryFields}
          className="w-full"
        />
        <InputText
          model={model}
          name={`${index}.beneficiary_name`}
          title={i18n.t("bank_account.beneficiary_name")}
          requiredField={true}
          disabled={autoCompleteFields}
          className="w-full"
        />
        <InputMaskedText
          model={model}
          name={`${index}.rg_of_holder`}
          title={i18n.t("bank_account.rg_of_holder")}
          requiredField={true}
          mask="99.999.999-99"
          disabled={autoCompleteFields}
          className="w-full"
        />
        <InputMaskedText
          model={model}
          name={`${index}.beneficiary_document_number`}
          title={i18n.t(`bank_account.cpf_or_cnpj`)}
          requiredField={true}
          disabled={autoCompleteFields}
          mask={beneficiaryDocumentNumber?.length > 14 ? "99.999.999/9999-99" : "999.999.999-999"}
          maskChar={null}
        />
        <SelectInput
          model={model}
          name="account_status"
          title={i18n.t(`bank_account.account_status`)}
          requiredField={true}
          options={["active", "inactive"]}
          index={index}
          className="w-full"
        />
        {children}
      </div>
    </div>
  )
}
