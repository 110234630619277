import * as Yup from "yup";
import i18n from "#translate/i18n";

Yup.setLocale(i18n.t("yup.schemas", { returnObjects: true }));

const SOCIAL_NAME_REGEX = /^[a-zA-ZáàâãéèêíïóôõöúçÁÀÂÃÉÈÍÏÓÔÕÖÚÇ'\s]*$/;

const { errors: errorLabel } = i18n.t("yup.forms", { returnObjects: true });

const onboardingSchema = Yup.object().shape({
  user: Yup.object().shape({
    nickname: Yup.string()
      .required()
      .matches(SOCIAL_NAME_REGEX, errorLabel.social_name),
    birth_date: Yup.date().typeError(errorLabel.required).required(),
  }),
  clothing: Yup.object().shape({
    t_shirt_size: Yup.string().required(i18n.t("validations.is_required")),
    sweatshirt_size: Yup.string().required(i18n.t("validations.is_required")),
    pants_size: Yup.string().required(i18n.t("validations.is_required")),
  }),
});

export default onboardingSchema;
