const translations = {
  success: {
    check: "Símbolo check",
    bonus_reward_created: {
      title: "Premiação adicionada",
      content: "O prêmio foi concedido com sucesso para <strong>{{ name }}</strong>."
    },
    bonus_reward_updated: {
      title: "Premiação atualizada",
      content: "O <strong>{{ additionalData }}</strong> foi atualizado com sucesso.",
      rewardType: {
        quintalk: 'Prêmio Quintalk',
        live_coding: 'Prêmio Live Coding',
        competitions: 'Prêmio Concursos',
        party_kit: 'Kit Festa',
        onboarding: 'Auxílio onboarding',
      }
    },
    bonus_reward_removed: {
      title: "Premiação removida",
      content: "O <strong>{{ additionalData }}</strong> foi removido com sucesso.",
      rewardType: {
        quintalk: 'Prêmio Quintalk',
        live_coding: 'Prêmio Live Coding',
        competitions: 'Prêmio Concursos',
        party_kit: 'Kit Festa',
        onboarding: 'Auxílio onboarding',
      }
    },
    expense_assistance_created: {
      title: "Ajuda de custo cadastrada",
      content: "Uma nova ajuda de custo foi adicionada para <strong>{{ name }}</strong>."
    },
    expense_assistance_updated: {
      title: "Ajuda de custo atualizada",
      content: "A ajuda de custo de <strong>{{ name }}</strong> foi atualizada com sucesso."
    },
    expense_assistance_removed: {
      title: "Ajuda de custo removida",
      content: "A ajuda de custo foi removida com sucesso."
    },
    salary_readjustment_created: {
      title: "Reajuste de remuneração adicionado",
      content: "A remuneração de <strong>{{ name }}</strong> foi reajustada com sucesso."
    },
    salary_readjustment_updated: {
      title: "Reajuste de remuneração atualizado",
      content: "A remuneração de <strong>{{ name }}</strong> foi atualizada com sucesso."
    },
    salary_readjustment_removed: {
      title: "Reajuste de remuneração removido",
      content: "O <strong>Reajuste de remuneração</strong> foi removido com sucesso."
    },
    vacation_created: {
      title: "Benefício adicionado",
      content: "O <strong>pedido de Descanso remunerado</strong> foi concedido com sucesso para <strong>{{ name }}</strong>."
    },
    vacation_updated: {
      title: "Benefício atualizado",
      content: "O <strong>pedido de Descanso remunerado</strong> foi atualizado com sucesso."
    },
    vacation_removed: {
      title: "Benefício removido",
      content: "O <strong>Descanso remunerado</strong> foi removido com sucesso."
    },
    home_office_allowance_created: {
      title: "Benefício adicionado",
      content: "O <strong>Auxílio Home Office</strong> foi concedido com sucesso para <strong>{{ name }}</strong>."
    },
    home_office_allowance_updated: {
      title: "Benefício atualizado",
      content: "O <strong>Auxílio Home Office</strong> foi atualizado com sucesso."
    },
    home_office_allowance_removed: {
      title: "Benefício removido",
      content: "O <strong>Auxílio Home Office</strong> foi removido com sucesso."
    },
    psychological_support_created: {
      title: "Benefício adicionado",
      content: "O <strong>Auxílio Psicológico</strong> foi concedido com sucesso para <strong>{{ name }}</strong>."
    },
    psychological_support_updated: {
      title: "Benefício atualizado",
      content: "O <strong>Auxílio Psicológico</strong> foi atualizado com sucesso."
    },
    psychological_support_removed: {
      title: "Benefício removido",
      content: "O <strong>Auxílio Psicológico</strong> foi removido com sucesso."
    },
    meal_allowance_created: {
      title: "Benefício adicionado",
      content: "O <strong>Auxílio alimentação</strong> foi concedido com sucesso para <strong>{{ name }}</strong>."
    },
    meal_allowance_updated: {
      title: "Benefício atualizado",
      content: "O <strong>Auxílio alimentação</strong> foi atualizado com sucesso."
    },
    meal_allowance_removed: {
      title: "Benefício removido",
      content: "O <strong>Auxílio alimentação</strong> foi removido com sucesso."
    },
    year_end_bonus_created: {
      title: "Benefício adicionado",
      content: "O <strong>Bônus de fim de ano</strong> foi concedido com sucesso para <strong>{{ name }}</strong>."
    },
    year_end_bonus_updated: {
      title: "Benefício atualizado",
      content: "O <strong>Bônus de fim de ano</strong> foi atualizado com sucesso."
    },
    year_end_bonus_removed: {
      title: "Benefício removido",
      content: "O <strong>Bônus de fim de ano</strong> foi removido com sucesso."
    },
    parental_leave_created: {
      title: "Benefício adicionado",
      content: "A <strong>Presença parental</strong> foi concedida com sucesso para <strong>{{ name }}</strong>."
    },
    parental_leave_updated: {
      title: "Benefício atualizado",
      content: "A <strong>Presença parental</strong> foi atualizada com sucesso."
    },
    parental_leave_removed: {
      title: "Benefício removido",
      content: "A <strong>Presença parental</strong> foi removida com sucesso."
    },
    healthcare_created: {
      title: "Benefício adicionado",
      content: "O <strong>Prêmio saúde</strong> foi concedido com sucesso para <strong>{{ name }}</strong>."
    },
    healthcare_updated: {
      title: "Benefício atualizado",
      content: "O <strong>Prêmio saúde</strong> foi atualizado com sucesso."
    },
    healthcare_removed: {
      title: "Benefício removido",
      content: "O <strong>Prêmio saúde</strong> foi removido com sucesso."
    },
    work_leave_created: {
      title: "Período de afastamento adicionado",
      content: "O <strong>período de afastamento</strong> foi adicionado com sucesso para <strong>{{ name }}</strong>."
    },
    work_leave_updated: {
      title: "Período de afastamento atualizado",
      content: "O <strong>período de afastamento</strong> foi atualizado com sucesso."
    },
    work_leave_removed: {
      title: "Período de afastamento removido",
      content: "O <strong>período de afastamento</strong> foi removido com sucesso."
    },
    created_user: {
      title: "Cadastro adicionado",
      content: "O cadastro de <strong>{{ name }}</strong> foi adicionado com sucesso."
    },
    updated_user: {
      title: "Cadastro atualizado",
      content: "O cadastro de <strong>{{ name }}</strong> foi atualizado com sucesso."
    },
    user_dismissal_created: {
      title: "Desligamento registrado",
      content: "<strong>{{ name }}</strong> foi desligado(a) da empresa."
    },
    user_dismissal_updated: {
      title: "Desligamento atualizado",
      content: "O <strong>Registro de desligamento</strong> de <strong>{{ name }}</strong> foi atualizado com sucesso."
    },
  }
}

export { translations }
