import React, { useEffect, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import i18n from "#translate/i18n";
import InputText from "#components/formComponents/InputText";
import BasicAddressFields from '#components/formComponents/userForm/fields/BasicAddressFields'
import SelectInput from "#components/formComponents/SelectInput";
import DivisionTitle from "#components/formComponents/DivisionTitle";

export default function CompanyOwnerFields() {
  const model = "company_information";
  const { setValue } = useFormContext();
  const ownerData = useWatch({ name: `${model}.owner_data` });
  const noSelectedOwner = ownerData === ""
  const isItself = ownerData === "is_itself";
  const autoCompletedFields = isItself || noSelectedOwner
  const [firstLoading, setFirstLoading] = useState(true)
  const [fillAddressData, setFillAddressData] = useState(true)

  // TODO: find better solution than useWatch + useEffect for updating fields
  const userFields = useWatch({
    name: [
      "contacts.0.personal_email",
      "address.zip_code",
      "address.street",
      "address.house_number",
      "address.complement",
      "address.empty_complement",
      "address.neighborhood",
      "address.city",
      "address.state",
      "address.country",
    ],
  });

  const [
    personalEmail,
    addressZipCode,
    addressStreet,
    addressHouseNumber,
    addressComplement,
    addressEmptyComplement,
    addressNeighborhood,
    addressCity,
    addressState,
    addressCountry,
  ] = userFields;

  const setCompanyOwner = ({ target: { value } }) => {
    setCompanyOwnerData(value);
  };

  useEffect(() => {
    if(!firstLoading) { return setCompanyOwnerData(ownerData) }
    if(firstLoading && ownerData) { setFirstLoading(false) }
  }, [ownerData]);

  useEffect(() => {
    if (isItself){
      updateToAddressData()
    }
  }, [fillAddressData]);

  const updateToAddressData = () => {
    setValue(`${model}.email`, personalEmail);
    setValue(`${model}.street`, addressStreet);
    setValue(`${model}.house_number`, addressHouseNumber);
    setValue(`${model}.complement`, addressComplement);
    setValue(`${model}.empty_complement`, addressEmptyComplement);
    setValue(`${model}.neighborhood`, addressNeighborhood);
    setValue(`${model}.city`, addressCity);
    setValue(`${model}.state`, addressState);
    setValue(`${model}.country`, addressCountry);
    if(addressZipCode) {  setValue(`${model}.zip_code`, addressZipCode) }
  }

  const setCompanyOwnerData = (value) => {
    if (value === "is_itself"){
      updateToAddressData()
    }else{
      setValue(`${model}.email`,"");
      setValue(`${model}.zip_code`, "")
      setValue(`${model}.street`,"");
      setValue(`${model}.house_number`,"");
      setValue(`${model}.complement`,"");
      setValue(`${model}.empty_complement`, false);
      setValue(`${model}.neighborhood`,"");
      setValue(`${model}.city`,"");
      setValue(`${model}.state`,"");
      setValue(`${model}.country`,"");
    }
  };

  useEffect(() => {
    if (autoCompletedFields && !firstLoading) {
      setCompanyOwnerData(ownerData);
    }
  }, userFields);

  return (
    <div className="mx-auto" id="company_owner_data_fields">
      <DivisionTitle title={i18n.t("company_address").toUpperCase()} />

      <div className="grid grid-cols-2 gap-y-3 gap-x-9">
        <SelectInput
          model={model}
          name="owner_data"
          title={i18n.t("company_information.owner_data")}
          options={["is_itself", "other"]}
          onChange={setCompanyOwner}
          requiredField={true}
          className='w-full'
        />

        <InputText
          model={model}
          name="email"
          title={i18n.t("company_information.email")}
          disabled={autoCompletedFields}
          requiredField={true}
          className='w-full'
        />

        <BasicAddressFields
          model={model}
          disabledFields={autoCompletedFields}
          setUpdateData={setFillAddressData}
          updateData={fillAddressData}
        />
      </div>
    </div>
  );
}
