import axios from "axios";

export default function removeAllowance({ id, user, allowanceName }) {
  const url = `/api/v1/users/${user.id}/allowances/${id}`

  const requestOptions = () => {
    const csrf = document
      .querySelector("meta[name='csrf-token']")
      .getAttribute("content");

    return {
      method: "DELETE",
      headers: { "X-CSRF-Token": csrf },
      data: { allowance: { allowance_name: allowanceName }}
    };
  };

  const options = requestOptions();

  return axios({ url, ...options });
}