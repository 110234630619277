import React from 'react';
import i18n from "#translate/i18n";

export default function Restrictions({generalNotes, allergies}) {
  return (
    <div className="text-info-gray font-sans text-sm leading-[26px] tracking-normal text-left p-4 px-5">
      <p>{i18n.t('userProfile.health_notes')}</p>
      {generalNotes &&(
        <p><span>{i18n.t('userProfile.general_notes')}: </span>{generalNotes}</p>
      )}
      {allergies &&(
        <p><span>{i18n.t('userProfile.allergies')}: </span> {allergies}</p>
      )}
    </div>
  )
}