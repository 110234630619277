import React, { useEffect, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import i18n from "#translate/i18n";
// import { Trans } from "react-i18next"
import InputText from "#components/formComponents/InputText";
import InputMaskedText from "#components/formComponents/InputMaskedText";
import SelectInput from "#components/formComponents/SelectInput";
import DateInput from "#components/formComponents/DateInput";
import { TooltipMessage } from "#components";

export default function PersonalDataFields() {
  const { setValue } = useFormContext();
  const model = "user";
  const hasSocialName = useWatch({ name: `${model}.has_social_name` });
  const genderId = useWatch({ name: `${model}.gender_identity` });

  const checkDisableSocialName = (hasSocial) => hasSocial?.toString() === "true" ? false : true;
  const checkGenderDescription = (genderId) => genderId?.toString() === "describe_myself" ? false : true;

  const [disableSocialName, setDisableSocialName] = useState(checkDisableSocialName(hasSocialName));
  const [hideGenderDescription, setHideGenderDescription] = useState(checkGenderDescription(genderId));

  const manageSocialName = () => {
    setValue(`${model}.social_name`, "");
  };
  const manageGenderDescription = () => {
    setValue(`${model}.gender_description`, "");
  };

  useEffect(() => {
    const statusOfDisableSocialName = checkDisableSocialName(hasSocialName);
    setDisableSocialName(statusOfDisableSocialName);

    if (statusOfDisableSocialName) { manageSocialName() }
  }, [hasSocialName]);

  useEffect(() => {
    const statusOfGenderDescription = checkGenderDescription(genderId);
    setHideGenderDescription(statusOfGenderDescription);

    if (statusOfGenderDescription) { manageGenderDescription() }
  }, [genderId]);

  return (
    <>
      <div className="grid grid-cols-2 gap-y-3 gap-x-9">
        <SelectInput
          model={model}
          name="has_social_name"
          options={["true", "false"]}
          title={i18n.t("has_social_name.has_social_name")}
          requiredField={true}
          // tooltip={
          //   <TooltipMessage
          //     imageClass="inline mb-0.75 ml-1"
          //     tooltipClass="w-[241px] py-1 px-3 text-white font-medium leading-6 rounded-lg bg-border-blue tooltip-position mt-4"
          //     message={i18n.t("tooltip.messages.has_social_name")}
          //     arrowClass={"w-2.5 h-2.5 bg-border-blue arrow-position before:bg-border-blue before:visible invisible before:absolute before:rotate-45 before:transform-y-[10px] before:w-2.5 before:h-2.5 mt-[-16px]"}
          //   />
          // }
          className='w-full'
        />

        <InputText
          model={model}
          disabled={disableSocialName}
          name="social_name"
          title={i18n.t("social_name")}
          placeholder={i18n.t("social_name_placeholder")}
          requiredField={true}
          className='w-full'
        />

        <InputText
          model={model}
          name="full_name"
          title={i18n.t("full_name")}
          subtitle={i18n.t("full_name_subtitle")}
          requiredField={true}
          placeholder="Sem abreviações"
          className='w-full'
        />

        <InputText
          model={model}
          name="nickname"
          placeholder={i18n.t("nickname_placeholder")}
          label={
            <>
              {i18n.t("nickname")}

              <TooltipMessage
                imageClass="inline mb-0.75 ml-1"
                message={i18n.t("tooltip.messages.nickname")}
              />
            </>
          }
          className='w-full'
        />

        <SelectInput
          model={model}
          name="pronouns"
          options={["he_him", "she_her", "they_them"]}
          title={i18n.t("pronouns.pronouns")}
          requiredField={true}
          className='w-full'
          // subtitle={
          //   <>
          //     <a
          //       href="https://azmina.com.br/reportagens/manual-para-comunicacao-neutra/?gad_source=1&gclid=Cj0KCQiA35urBhDCARIsAOU7QwlMCopQKShDf6Gc1aHKo5T-IiECgfjyFZ6BIOiBeh9aIHQb6omtL9caAjv2EALw_wcB"
          //       target='_blank'
          //       rel="noreferrer"
          //       className="text-rebase-blue underline underline-offset-2 font-medium cursor-pointer text-sm"
          //       id='pronoun-link'
          //     >
          //       {i18n.t("pronouns.link")}
          //     </a> {i18n.t("pronouns.text")}
          //   </>
          // }
          // tooltip={
          //   <TooltipMessage
          //     imageClass="inline mb-0.75 ml-1 h-2.5 w-2.5"
          //     tooltipClass="w-80 py-1 px-3 text-white leading-6 rounded-lg bg-border-blue tooltip-position font-normal text-xs font-sans not-italic"
          //     message={<Trans i18nKey={i18n.t("tooltip.messages.pronouns")} />}
          //     arrowClass={"w-2.5 h-2.5 bg-border-blue arrow-position before:bg-border-blue before:visible invisible before:absolute before:rotate-45 before:transform-y-[10px] before:w-2.5 before:h-2.5 "}
          //     placement={'bottom'}
          //     offset={[140, 10]}
          //     buttonId={'pronoun-tooltip-button'}
          //   />
          // }
        />

        <SelectInput
          model={model}
          name="marital_status"
          options={["single", "married", "divorced", "widowed"]}
          title={i18n.t("marital_status.marital_status")}
          requiredField={true}
          className='w-full'
        />
      </div>

      <div className="grid gap-y-3 my-3">
        <DateInput
          model={model}
          name="birth_date"
          title={i18n.t("birth_date")}
          requiredField={true}
          classStyle='w-full'
        />

        <SelectInput
          model={model}
          name="gender_identity"
          options={["cis", "trans", "non_binary", "prefer_not_to_answer", "describe_myself"]}
          title={i18n.t("gender_identity.title")}
          requiredField={true}
          className='w-full'
          // subtitle={
          //   <>
          //     <a
          //       href="https://www.prefeitura.sp.gov.br/cidade/secretarias/saude/noticias/?p=325227#:~:text=A%20identidade%20de%20g%C3%AAnero%20se,s%C3%A3o%20chamados%20de%20n%C3%A3o%20bin%C3%A1rios"
          //       target='_blank'
          //       rel="noreferrer"
          //       className="text-rebase-blue underline underline-offset-2 font-medium cursor-pointer text-sm"
          //       id='gender-identity-link'
          //     >
          //       {i18n.t("gender_identity.link")}
          //     </a> {i18n.t("gender_identity.text")}
          //   </>
          // }
          // tooltip={
          //   <TooltipMessage
          //     imageClass="inline mb-0.75 ml-1 h-2.5 w-2.5"
          //     tooltipClass="w-80 py-1 px-3 text-white leading-6 rounded-lg bg-border-blue tooltip-position font-normal text-xs font-sans not-italic"
          //     message={<Trans i18nKey={i18n.t("tooltip.messages.gender_identity")} />}
          //     arrowClass={"w-2.5 h-2.5 bg-border-blue arrow-position before:bg-border-blue before:visible invisible before:absolute before:rotate-45 before:transform-y-[10px] before:w-2.5 before:h-2.5 "}
          //     placement={'bottom'}
          //     offset={[140, 10]}
          //     buttonId={'gender-identity-tooltip-button'}
          //   />
          // }
        />

        { !hideGenderDescription &&
          <InputText
            model={model}
            name="gender_description"
            title={i18n.t("gender_description")}
            className='w-full'
            requiredField={true}
          />
        }
      </div>

      <div className="grid grid-cols-2 gap-y-3 gap-x-9">
        <SelectInput
          model={model}
          name="lgbtqia_plus"
          options={["yes", "no", "no_answer"]}
          title={i18n.t("lgbtqia_plus.lgbtqia_plus")}
          requiredField={true}
          className='w-full'
        />

        <InputMaskedText
          model={model}
          name="rg"
          title={i18n.t("rg_number")}
          requiredField={true}
          mask="99.999.999-99"
          maskChar={null}
        />

        <InputMaskedText
          model={model}
          name="cpf"
          title={i18n.t("cpf_number")}
          requiredField={true}
          mask="999.999.999-99"
        />
      </div>
      <div className="top-[-16px]"/>
    </>
  );
}
