import React from "react"; 
import { HobbyFields, 
         DetailPreferencesFields,
         SportPreferencesFields,
         FoodsAndBeveragesFields
        } from "../fields/";
import DivisionLine from "../../DivisionLine";

export default function PersonalPreferenceForm({ selectedForm }) {
  const model = "personal_preference";
  const hidden = selectedForm === "personal-preferences-header" ? "" : "hidden";

  return (
    <div id="personal_preferences" className={ hidden }>
      <HobbyFields model={model} />

      <DivisionLine />

      <DetailPreferencesFields model={model} />

      <DivisionLine />

      <SportPreferencesFields model={model} />

      <DivisionLine />

      <FoodsAndBeveragesFields model={model} />
    </div>
  );
}
