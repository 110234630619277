import React from "react";
import i18n from "#translate/i18n";

export default function TimeInCompany({ months }) {
  const years = Math.floor(months / 12);
  const extraMonths = Math.floor(months % 12);

  return (
    <span>
      {months <= 1
        ? i18n.t("timeInCompany.month")
        : months < 12
          ? `${i18n.t("timeInCompany.months", {count: months})}`
          : extraMonths == 0
            ? `${i18n.t("timeInCompany.years", {count: years})}`
            : `${i18n.t("timeInCompany.years", {count: years})} ${i18n.t("timeInCompany.and")} ${i18n.t("timeInCompany.months", {count: extraMonths})}`
      }
    </span>
  );
}
