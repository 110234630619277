import axios from "axios";
import toFormData from "#requests/helpers/toFormData"
import convertDateToDBString from "#services/convertDateToDBString";
import getFileData from "#services/getFileData";
import removeEmptyFile from "#services/removeEmptyFile";

export default function createOrUpdateDismissal({ data, model, user, objectId }) {
  const action = objectId ? "PUT" : "POST";
  const url = objectId ? `/api/v1/users/${user.id}/dismissals/${objectId}` : `/api/v1/users/${user.id}/dismissals`;
  const EmptyFile = class { };

  const formatPayload = (data) => {
    const newData = { ...data }
    const convertedData = convertDateToDBString(newData)
    const { user_dismissal, extended_allowances_attributes, invoice_installments_attributes, access_and_assets_attributes, ...otherData } = convertedData
    const accessAssets = access_and_assets_attributes.map((access) => {
      return {
        description: access.description,
        id: access.access_id || '',
        notes: access.notes || '',
        status: access.status || ''
      }
    })
    const accessAssetsAttr = { access_and_assets_attributes: accessAssets }
    const totalInvoice = user_dismissal.invoice_total_value
    const allowances = extended_allowances_attributes.dismissal_allowances.map((extended) => {
      const allowanceData = extended.allowance_data || {}
      if(String(extended.soft_delete) === 'true') { return }
      return {
        allowance_name: allowanceData.allowance_name || '',
        id: extended.allowance_id || '',
        end_date: extended.end_date
      }
    }).filter(n => n)
    const extendedAllowances = { extended_allowances_attributes: allowances }
    const installments = invoice_installments_attributes.map((installment) => {
      const value = user_dismissal.installments_number === 1 ? totalInvoice : installment.installment_value
      return {
        installment_value: value,
        id: installment.installment_id || '',
        payment_date: installment.payment_date
      }
    })
    const invoiceInstallments = { invoice_installments_attributes: installments }
    const hasExtendedAllowances = { has_extended_allowances: extended_allowances_attributes.has_extended_allowances_attributes }

    const currentDocumentation = { documentation: getFileData({ source: user_dismissal, attribute: 'documentation', emptyFile: EmptyFile }) }
    const currentUserDismissal = { ...user_dismissal, ...currentDocumentation }

    const sentData = { ...currentUserDismissal, ...extendedAllowances, ...invoiceInstallments, ...hasExtendedAllowances, ...accessAssetsAttr, ...otherData }

    removeEmptyFile({ data: { user_dismissal: sentData }, emptyFile: EmptyFile })
    return sentData;
  };

  const requestOptions = () => {
    const csrf = document
      .querySelector("meta[name='csrf-token']")
      .getAttribute("content");

    return {
      method: action,
      headers: { "X-CSRF-Token": csrf },
      data: toFormData(formatPayload(data), model),
    };
  };

  const options = requestOptions();

  return axios({ url, ...options })
}
