import React, { useEffect, useState } from "react";
import i18n from "#translate/i18n";
import { Trans } from "react-i18next"
import ArrowDown from '#images/arrowdown-top.svg';
import EditIconBlue from "#images/edit-blue.svg"
import EditIconGray from "#images/edit-gray.svg"
import DeleteIconRed from "#images/delete-red.svg"
import DeleteIconGray from "#images/delete-gray.svg"
import TooltipMessageHoverButtons from "#components/TooltipMessageHoverButtons"
import quintalk from '#images/events_icons/quintalk_updated.svg';
import live_coding from '#images/events_icons/live_code_updated.svg';
import competitions from '#images/events_icons/competitions_updated.svg';
import party_kit from '#images/events_icons/party_kit_updated.svg';
import onboarding from '#images/events_icons/onboarding_updated.svg';

export default function BonusRewardUpdated({
  eventData,
  removedArray,
  lastUpdates,
  updateEvents,
  creationEvent,
  setOpenRemovalConfirmation,
  setObjectId,
  setChosenEventRemoval,
  showDetails,
  setShowDetails,
  openEditionDrawer
}) {
  const todayStart = new Date()
  todayStart.setHours(0, 0, 0, 0);
  const currentData = eventData?.data || {}
  const updates = updateEvents({ id: currentData.id, creationEventName: 'BonusRewardCreated' })
  const currentPosition = updates.indexOf(eventData)
  const updatesAmount = updates.length
  const previousData = updatesAmount > 1 && updates[currentPosition + 1]?.data ? updates[currentPosition + 1].data : creationEvent({ id: currentData.id, creationEventName: 'BonusRewardCreated' }).data
  const currentRewardType = currentData.reward_type
  const previousRewardType = previousData.reward_type
  const changeType = currentRewardType !== previousRewardType
  const paymentType = currentData.payment_type
  const paymentDate = currentData.payment_date
  const notesData = currentData.notes
  const isRemoved = removedArray.some((removedItem) => removedItem.id === currentData.id && removedItem.creationEventName === 'BonusRewardCreated')
  const eventDateTime = eventData?.date || ''
  const isTheLastUpdate = lastUpdates.some((update) => update.id === currentData.id && update.lastUpdatedOn === eventDateTime)
  const isValid = new Date(`${paymentDate}T00:00`) >= todayStart
  const adminName = currentData.responsible?.full_name
  const bonusValue = Number(currentData.bonus_value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
  const [paymentYear, paymentMonth, paymentDay] = paymentDate.split('-')

  const icons = {
    quintalk: quintalk,
    live_coding: live_coding,
    competitions: competitions,
    party_kit: party_kit,
    onboarding: onboarding,
  }
  const [click, setClick] = useState(false)

  useEffect(() => {
    if (showDetails === 'close') { setClick(false) }
  }, [showDetails])

  return (
    <div className="relative flex-1 my-5 bg-white">
      <div className="flex items-center">
        {icons[currentRewardType] && (
          <div className='rounded-full shadow-timeline-icons mr-2 h-fit min-w-fit'>
            <img
              src={icons[currentRewardType]}
              alt="event icon"
              className='w-[52px] h-[52px] drop-shadow-[0.3333333432674408px_0.3333333432674408px_1.3333333730697632px_rgba(0,0,0,0.15)]'
            />
          </div>
        )}
        <div className="relative z-20 font-inter text-sm leading-[22px] text-info-gray flex-1">
          <p className="font-bold inline-block">
            {`${i18n.t("timelineEvents.bonusRewardUpdated.title")}`}
          </p>
          <p className="font-normal">
            <Trans
              i18nKey={`${changeType ? i18n.t("timelineEvents.bonusRewardUpdated.descriptionWithTypeChange") : i18n.t("timelineEvents.bonusRewardUpdated.descriptionWithoutTypeChange")}`}
              values={{
                admin: adminName,
                bonusValue: bonusValue,
                paymentDay: paymentDay,
                paymentMonth: paymentMonth,
                paymentYear: paymentYear,
                currentRewardType: i18n.t(`timelineEvents.bonusRewardUpdated.rewardType.${currentRewardType}`),
                previousRewardType: i18n.t(`timelineEvents.bonusRewardUpdated.rewardType.${previousRewardType}`)
              }}
            />
          </p>
        </div>
        <button
          id={`reward_updated_${currentRewardType}_details_button`}
          className='ml-6 mb-3 p-2'
          onClick={() => { setClick(!click), setShowDetails(null) }}
        >
          <img
            className={`w-3 h-[7.2px] right-0 ${click && 'transform rotate-180'} min-w-max`}
            src={ArrowDown}
            alt={i18n.t("dropdown_arrow_img")}
          />
        </button>
      </div>
      {click && (
        <>
          <div className="relative font-inter text-sm leading-6 text-info-gray mt-3 ml-14" id='reward_updated_details_panel'>
            <p id={`reward_updated_${currentRewardType}_payment_type`}>
              <span className="font-bold">{i18n.t('timelineEvents.bonusRewardUpdated.paymentType.title')}</span>
              {i18n.t(`timelineEvents.bonusRewardUpdated.paymentType.${paymentType}`)}
            </p>
            <div>
              <p className="font-bold">{i18n.t('timelineEvents.bonusRewardUpdated.observations')}</p>
              <p id={`reward_updated_${currentRewardType}_notes`}>
                {notesData || i18n.t('timelineEvents.bonusRewardUpdated.missingNotes')}
              </p>
            </div>
          </div>

          {isValid && isTheLastUpdate && (
            <div className="flex space-x-4 justify-end mt-5" id={`reward_updated_details_buttons_${currentRewardType}`}>
              <div className="flex space-x-4 group">
                {isRemoved && (
                  <>
                    <TooltipMessageHoverButtons
                      buttonClass="absolute h-11 w-[370px] right-2"
                      tooltipClass="absolute bg-border-blue z-1 gap-1 text-white items-center text-center w-[490px] text-xs px-3 py-1 h-9 font-semibold
                                    rounded-lg bg-tooltip-color shadow-tooltip-buttons ml-[-40px]"
                      arrowClass='absolute ml-[-50px]'
                      messageClass="h-5 pt-1"
                      message={i18n.t('timelineEvents.removedMessage')}
                    />
                  </>
                )}
                <button
                  className={`justify-center font-sans font-semibold text-base bg-white flex items-center px-4 py-2.5
                              ${isRemoved ? 'text-disabled-gray' : 'text-support-error'}`}
                  id={`reward_remove_button_${currentRewardType}`}
                  disabled={isRemoved}
                  onClick={() => {
                    setOpenRemovalConfirmation(true),
                      setObjectId(currentData.id),
                      setChosenEventRemoval({ name: 'BonusRewardCreated', data: currentData }),
                      setShowDetails(null)
                  }}
                >
                  <img src={isRemoved ? DeleteIconGray : DeleteIconRed} alt="" className="inline mr-2 w-6 h-6" />
                  {i18n.t('timelineEvents.bonusRewardCreated.removeReward')}
                </button>

                <button
                  className={`justify-center font-sans font-semibold text-base rounded border
                              flex items-center px-4 py-2.5 ${isRemoved ? 'text-disabled-gray bg-disabled-gray-button' : 'text-rebase-blue border-rebase-blue bg-white'}`}
                  id={`reward_edit_button_${currentRewardType}`}
                  disabled={isRemoved}
                  onClick={() => { openEditionDrawer(), setObjectId(currentData.id) }}
                >
                  <img src={isRemoved ? EditIconGray : EditIconBlue} alt="" className="inline mr-2 w-6 h-6 stroke-rebase-blue" />
                  {i18n.t('timelineEvents.bonusRewardCreated.editReward')}
                </button>
              </div>
              <div className="w-0 h-0 text-disabled-gray bg-disabled-gray-button text-disabled-gray" />
            </div>
          )}
        </>
      )}
    </div>
  );
}
