import React from "react";
import Field from "./Field";

const TextAreaInput = ({
  model,
  name,
  title,
  subtitle,
  disabled,
  placeholder,
  className,
  requiredField,
  shouldHide,
  ...props
}) => {
  const id = model ? `${model}_${name}_input` : `${name}_input`;
  const component = shouldHide ? 'p' : 'textarea'

  return (
    <div id={`${model}_${name}`} className="flex flex-col justify-end">
      <Field
        {...props}
        model={model}
        label={
          <div className={`${shouldHide ? "w-0 h-0" : "" }`}>
            { shouldHide ? <></> :
              <>
                { title }
                { requiredField && (
                  <span
                    className="pl-1 text-red-500"
                    id="required-field"
                  >
                    *
                  </span>
                )}
                {subtitle && (
                  <span className="pl-1">
                    (<span className="font-normal">{subtitle}</span>)
                  </span>
                )}
              </>
            }
          </div>
        }
        id={id}
        name={name}
        component={component}
        placeholder={placeholder}
        disabled={disabled}
        className={`py-2 px-3 text-md block rounded mt-2 w-[101%] h-28 bg-white 
                    border-border-gray placeholder-placeholder-gray focus:placeholder-gray-400
                    focus:bg-white focus:border-gray-600 focus:outline-none focus:ring-2
                    resize-none
                    ${shouldHide ? 'w-0 h-0 border-none focus:border-none' : ''}
                    ${className}`}
      />
    </div>
  );
};

TextAreaInput.defaultProps = {
  placeholder: null,
  disabled: false,
  className: "",
};
export default TextAreaInput;
