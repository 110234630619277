import React from "react";

function RoundPicture({ src, imgStyle, divStyle, id, alt, ...props }) {
  return (
    <div className={divStyle} {...props}>
      <div className="relative h-full w-full flex justify-center items-center rounded-full">
        <div className="relative h-full w-full rounded-full" >
          <img
            className={`absolute h-full rounded-full ${imgStyle}`}
            src={src}
            alt={alt}
            id={id}
          />
        </div>
      </div>
    </div>
  );
}

export default RoundPicture;
