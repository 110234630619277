import axios from "axios";
import toFormData from "#requests/helpers/toFormData"

export default function createOrUpdateVacation({ data, user, model, objectId }) {
  const action = objectId ? "PUT" : "POST";
  const url = objectId ? `/api/v1/users/${user.id}/allowances/${objectId}` : `/api/v1/users/${user.id}/allowances`

  const fetchPayloadData = (data) => {
    const result = {
      ...data.vacation,
      allowance_name: model,
      user_id: user.id,
    }
    return result
  }

  const formatPayload = (data) => {
    let new_data = { vacation: data.vacation }
    const maxBusinessDays = +data.vacation.max_business_days
    const bonusDays = +data.vacation.additional_bonus_days
    const maxTotalDays = maxBusinessDays + bonusDays
    const currentTotalDays = data.vacation.total_business_days
    const totalDays = currentTotalDays === `${maxBusinessDays}+${bonusDays}` ? maxTotalDays : currentTotalDays
    let new_dates = {
      start_date: data.vacation.start_date,
      return_date: data.vacation.return_date,
      total_business_days: totalDays,
    }
    let keys = Object.keys(new_dates)
    keys.map(x => { new_data.vacation[x] = new_dates[x] })

    const sentData = fetchPayloadData(new_data)
    return sentData;
  };

  const requestOptions = () => {
    const csrf = document
      .querySelector("meta[name='csrf-token']")
      .getAttribute("content");

    return {
      method: action,
      headers: { "X-CSRF-Token": csrf },
      data: toFormData(formatPayload(data), 'allowance'),
    };
  };

  const options = requestOptions()

  return axios({ url, ...options })
}
