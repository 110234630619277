import React from "react";
import InputText from "../formComponents/InputText";
import InputMaskedText from "../formComponents/InputMaskedText";
import i18n from "#translate/i18n";

const AddressUser = () => {
  return (
    <div className="sm:grid sm:grid-cols-2 sm:gap-y-3 sm:gap-x-4">
      <InputMaskedText
        model="address"
        title={i18n.t("onboarding.address.zip_code")}
        name="zip_code"
        mask="99999-999"
        placeholder="00000-000"
      />

      <InputText
        model="address"
        title={i18n.t("onboarding.address.street")}
        name="street"
      />

      <InputText
        model="address"
        title={i18n.t("onboarding.address.house_number")}
        name="house_number"
      />

      <InputText
        model="address"
        title={i18n.t("onboarding.address.complement")}
        name="complement"
      />

      <InputText
        model="address"
        title={i18n.t("onboarding.address.country")}
        name="country"
      />

      <InputText
        model="address"
        title={i18n.t("onboarding.address.state")}
        name="state"
      />

      <InputText
        name="city"
        title={i18n.t("onboarding.address.city")}
        model="address"
      />

      <InputText
        model="address"
        title={i18n.t("onboarding.address.neighborhood")}
        name="neighborhood"
      />

      <InputText
        model="address"
        title={i18n.t("onboarding.address.reference_point")}
        name="reference_point"
      />
    </div>
  );
};

export default AddressUser;
