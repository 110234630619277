const pictures = { pictures: ["face_picture"] };
const contacts = { contacts: ["landline", "phone_number", "personal_email"] };
const emergency_contacts = {
  emergency_contacts: ["name", "landline", "phone_number"],
};
const clothings = {
  clothings: ["t_shirt_size", "shoe_size"],
};

const users = {
  users: [
    "full_name",
    "nickname",
    "pronouns",
    "marital_status",
    "birth_date",
    "gender_identity",
    "gender_description",
    "rg",
    "cpf",
    "sharing_home_with",
    "race_color",
    "medical_follow_up",
    "special_medical_condition",
    "health_notes",
    "hiring_date",
    "graduate_class_id",
  ],
};

const addresses = {
  addresses: [
    "zip_code",
    "street",
    "house_number",
    "country",
    "state",
    "city",
    "neighborhood",
    "alternative",
  ],
};

const bank_accounts = {
  bank_accounts: [
    "name",
    "agency_number",
    "account_number",
    "account_type",
    "beneficiary",
    "beneficiary_name",
    "pix_key",
    "beneficiary_document_number",
    "account_status",
  ],
};

const company_histories = {
  company_histories: [
    "company",
    "work_field",
    "initial_remuneration",
    "mattermost_username",
  ],
};

const documents = {
  user_attached_docs: [
    "rg_front",
    "rg_back",
    "cpf_front",
    "cpf_back",
    "residence_proof",
  ],
};

const hobbiesAndInterests = {
  personal_preferences: [
    "hobbies",
    "preferred_movies_series",
    "preferred_bands_singers",
    "best_trip",
    "wish_list",
    "likes_social_media",
    "about_you",
    "favorite_color",
    "day_night_preference",
    "preferred_sport",
    "preferred_club",
    "preferred_videogame",
    "preferred_foods",
    "preferred_beers",
    "preferred_juices",
    "preferred_fruits",
    "preferred_beverages",
    "avoided_foods",
    "personal_collections",
  ],
};

const registrationData = Object.assign(
  users,
  contacts,
  pictures,
  emergency_contacts,
  addresses,
  clothings,
  bank_accounts,
  company_histories
);

export { registrationData, hobbiesAndInterests, documents };
