import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from "react-chartjs-2";
import i18n from "#translate/i18n";

export default function RemunerationSumary({remunerationsData}) {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  const barOptions = {
    locale: 'pt-BR',
    layout: {
      padding: {
        top: -20,
        bottom: 5, // Add padding at the bottom
      }
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        stacked: true,
        ticks: {
          color: '#72777B',
          padding: 8
        },
        grid: {
          drawTicks: false,
          color: '#E7EAEE'
        },
        title: {
          display: true,
          text: i18n.t('homePage.remunerations.payroll.xAxis'),
          padding: {
            top: 20
          },
          color: '#485668',
          font: {
            size: 12,
            weight: 'normal'
          }
        },
        border: {
          color: '#E7EAEE'
        },
      },
      y: {
        stacked: true,
        title: {
          display: false,
        },
        ticks: {
          beginAtZero: true,
          color: '#72777B',
          padding: 8
        },
        grid: {
          drawTicks: false
        },
        border: {
          color: '#E7EAEE'
        },
      },
      secondYAxis: {
        type: 'linear',
        position: 'right',
        ticks: { display: false },
        grid: { display: false },
        border: {
          color: '#E7EAEE'
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        align: "start",
        labels: {
          usePointStyle: true,
          pointStyle: 'circle',
          boxWidth: 10,
          boxHeight: 10,
          padding: 38.5,
          fullSize: true,
          color: '#2D2D2D',
        },
      },
      title: {
        display: false
      }
    }
  };
  const data = {
    labels: Object.keys(remunerationsData).map((x) => i18n.t(`homePage.month.${x}`)),
    datasets: [
      {
        label: i18n.t('homePage.remunerations.payroll.legends.salary'),
        data: Object.keys(remunerationsData).map((x) => remunerationsData[x].sum_salary),
        backgroundColor: '#00C7F2',
        barPercentage: 0.5,
      },
      {
        label: i18n.t('homePage.remunerations.payroll.legends.allowances'),
        data: Object.keys(remunerationsData).map((x) => remunerationsData[x].sum_allowances),
        backgroundColor: '#0FCA7A',
        barPercentage: 0.5,
      },
      {
        label: i18n.t('homePage.remunerations.payroll.legends.expense_assistances'),
        data: Object.keys(remunerationsData).map((x) => remunerationsData[x].sum_expense_assistances),
        backgroundColor: '#FBC62F',
        barPercentage: 0.5,
      },
      {
        label: i18n.t('homePage.remunerations.payroll.legends.rewards'),
        data: Object.keys(remunerationsData).map((x) => remunerationsData[x].sum_rewards),
        backgroundColor: '#F75D5F',
        barPercentage: 0.5,
      }
    ]
  };

  const customLegend = {
    beforeInit(chart) {
      // Get a reference to the original fit function
      const originalFit = chart.legend.fit;
      // Override the fit function
      chart.legend.fit = function fit() {
        // Call the original function and bind scope in order to use `this` correctly inside it
        originalFit.bind(chart.legend)();
        // Change the height
        this.height += 10;
      }
    }
  }

  return (
    <div
      className="h-[200px] lg:h-[380px] xl:h-[400px] 2xl:h-[437px] w-hp-payroll-evolution"
      id='users-remunerations-evolution'
    >
      <div className="chart-container">
        <div className='border w-full rounded-t h-[92px] px-6 pt-6 pb-4 bg-white'>
          <p className='font-sans text-xl/[24px] font-semibold text-left text-black-report w-[90%] mb-2' >
            {i18n.t(`homePage.remunerations.payroll.title`)}
          </p>
          <p className='font-sans text-sm/[20px] font-normal text-left text-default-gray-2' >
            {i18n.t(`homePage.remunerations.payroll.subtitle`)}
          </p>
          {/* <EvolutionMenu /> */}
        </div>
        <div className='border-l border-r border-b pl-5 pr-5 pb-5 rounded-b m-0 lg:h-[288px] xl:h-[298px] 2xl:h-[345px] bg-white' id='chart-remuneration-evolution'>
          <Bar
            options={barOptions}
            data={data}
            plugins={[customLegend]}
          />
        </div>
      </div>
    </div>
  )
}
