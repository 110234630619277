import * as Yup from "yup";
import i18n from "#translate/i18n";
import { validateListPresence } from "#utils/schemas";

Yup.setLocale(i18n.t("yup.schemas", { returnObjects: true }));
Yup.addMethod(Yup.array, "validateListPresence", validateListPresence);

const PersonalPreferenceBaseSchema = Yup.object().shape({
  hobby_ids: Yup.array(Yup.number())
                .validateListPresence({
                  attributeName: 'hobby_ids',
                  errorMessage: i18n.t("personal_preference.forms_error.presence")
                }),
  preferred_movies_series: Yup.string().required(),
  preferred_bands_singers: Yup.string().required(),
  best_trip: Yup.string().required(),
  wish_list: Yup.string().required(),
  personal_collections: Yup.string().required(),
  likes_social_media: Yup.string().required(),
  about_you: Yup.string().required(),
  favorite_color: Yup.string().required(),
  day_night_preference: Yup.string().required(),
  preferred_beers: Yup.string().required(),
  preferred_beverages: Yup.string().required()
});

export default PersonalPreferenceBaseSchema;
