import React from "react";

export default function BtnRemove({
  id,
  name,
  style,
  handleOnClick = (f) => f,
}) {
  return (
    <div className={style}>
      <button
        id={id}
        className={ `justify-center font-sans font-semibold text-base bg-white
                    text-rebase-blue rounded border border-rebase-blue
                    flex items-center px-4 py-2.5` }
        onClick={handleOnClick}
      >
        {name}
      </button>
    </div>
  );
}
