import React, { useState } from "react";
import CloseBtn from "#images/close-btn.svg";
import CheckGreenCircle from "#images/check-green-circle.svg";
import i18n from "#translate/i18n";

const MessageInviteSuccess = () => {
  const [hidden, setHidden] = useState("");

  const hideContent = () => {
    setHidden("hidden");
  };

  return (
    <div
      className={`shadow-sms z-10 fixed top-[80%] right-[3%] w-[484px] height-20 rounded flex bg-white ${hidden}`}
    >
      <div className="bg-off-green flex item-center p-[26px]">
        <img src={CheckGreenCircle} alt={i18n.t("invite_success.sent")} />
      </div>

      <div className="pl-4 flex flex-col justify-center">
        <h1 className="font-semibold text-sm">
          {i18n.t("invite_success.heading")}
        </h1>
        <p className="text-sm font-normal leading-5">
          {i18n.t("invite_success.text")}
        </p>
      </div>

      <button
        onClick={hideContent}
        onKeyDown={hideContent}
        className="flex mt-3.5 ml-3.5 pr-3 self-start cursor-pointer"
      >
        <img src={CloseBtn} alt={i18n.t("invite_success.close")} />
      </button>
    </div>
  );
};

export default MessageInviteSuccess;
