import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import i18n from "#translate/i18n";
import FillProgressCard from "./FillProgressCard";
import checkFilledFields from "#requests/checkFilledFieldsRequest";
import Party from "#images/party.svg";
import Preferences from "#images/Hamburguer_menu.svg";
import RegistrationData from "#images/registration_data.svg";
import thumb_up from "#images/thumb_up.svg";
import badge from "#images/badge.svg";
import { SearchInput } from "#components";

const FillProgress = () => {
  const [progress, setProgress] = useState({
    registration: 0,
    preferences: 0,
    documents: 0,
  });

  const { userId } = useParams();

  const updateProgress = ({
    registration,
    preferences,
    documents,
  }) => {
    setProgress({
      registration: registration,
      preferences: preferences,
      documents: documents,
    });
  };

  useEffect(() => {
    (async () => {
      const data = await checkFilledFields(userId);
      updateProgress(data);
    })();
  }, []);

  const cardData = [
    {
      percentage: progress.registration,
      icon: RegistrationData,
      title: i18n.t("FillProgress.registrationData.title"),
      description: i18n.t("FillProgress.registrationData.description"),
    },
    {
      percentage: progress.preferences,
      icon: thumb_up,
      title: i18n.t("FillProgress.hobbiesAndInterests.title"),
      description: i18n.t("FillProgress.hobbiesAndInterests.description"),
    },
    {
      percentage: progress.documents,
      icon: badge,
      title: i18n.t("FillProgress.documents.title"),
      description: i18n.t("FillProgress.documents.description"),
    },
  ];
  const handleColors = (percentage) => {
    const colors = [];

    if (percentage <= 33) {
      colors.push("bg-light-orange", "bg-custom-orange", "text-custom-orange");
    } else if (percentage <= 66) {
      colors.push("bg-light-yellow", "bg-custom-yellow", "text-custom-yellow");
    } else {
      colors.push("bg-light-green", "bg-custom-green", "text-custom-green");
    }
    return colors;
  };

  return (
    <>
      <header className="lg:flex px-6 grid lg:justify-between items-center bg-white h-[72px]">
        <h1 className="grid lg:hidden col-start-1 col-end-2 justify-items-end text-xl text-midnight font-medium">
          {i18n.t("onboarding.title")}
        </h1>
        <button className="grid col-start-2 col-end-3 justify-items-end">
          <img src={Preferences} alt="Preferences" />
        </button>
        <div className="hidden lg:block">
          <SearchInput placeholder={i18n.t("FillProgress.searchPlaceHolder")} />
        </div>
      </header>
      <main className="px-6 py-11">
        <section className="grid justify-items-center">
          <h1 className="font-bold text-2xl text-midnight mb-9">
            {i18n.t("FillProgress.title")}
          </h1>
          <img src={Party} className="w-76.8 h-60.6 mb-9.4" alt="party" />
        </section>
        <div>
          <section className="mb-2.5">
            <h1 className="font-bold mb-5 text-xl text-night-rider">
              {i18n.t("FillProgress.subtitle")}
            </h1>
            <p className="text-night-rider text-2sm">
              {i18n.t("FillProgress.description")}
            </p>
          </section>
          <article className="flex smm:flex-row flex-wrap flex-col items-center gap-6">
            {cardData.map((card, index) => (
              <FillProgressCard
                icon={card.icon}
                title={card.title}
                percentage={card.percentage}
                description={card.description}
                colors={handleColors(card.percentage)}
                key={index}
              />
            ))}
          </article>
        </div>
      </main>
    </>
  );
};

export default FillProgress;
