import axios from "axios";
import i18n from "#translate/i18n";
import {
  registrationData,
  hobbiesAndInterests,
  documents,
} from "./helpers/fillProgressFieldsPayload";

export default function checkFilledFields(userId) {
  const url = `/api/v1/user-data-percentage/${userId}`;

  const request = async () => {
    const { data, status } = await axios(url, {
      params: {
        registration: registrationData,
        preferences: hobbiesAndInterests,
        documents: documents,
      },
    });

    if (status === 200) {
      return data;
    } else {
      throw new Error(i18n.t("requests.network_error"));
    }
  };

  return request();
}
