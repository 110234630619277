import React, { useState, useEffect } from "react";
import { useFormContext, useWatch } from 'react-hook-form';
import i18n from "#translate/i18n";
import InputText from "#components/formComponents/InputText";
import InputMaskedText from "#components/formComponents/InputMaskedText";
import queryByZipCode from '#requests/queryByZipCode'
import CheckboxInput from "#components/formComponents/CheckboxInput";

export default function BasicAddressFields({ model, disabledFields, setUpdateData, updateData, loadedZipCode, ...props }) {
  const states = {
    AC: 'Acre', AL: 'Alagoas', AP: 'Amapá', AM: 'Amazonas', BA: 'Bahia', CE: 'Ceará', DF: 'Distrito Federal',
    ES: 'Espírito Santo', GO: 'Goiás', MA: 'Maranhão', MT: 'Mato Grosso', MS: 'Mato Grosso do Sul', MG: 'Minas Gerais',
    PA: 'Pará', PB: 'Paraíba', PR: 'Paraná', PE: 'Pernambuco', PI: 'Piauí', RJ: 'Rio de Janeiro', RN: 'Rio Grande do Norte',
    RS: 'Rio Grande do Sul', RO: 'Rondônia', RR: 'Roraima', SC: 'Santa Catarina', SP: 'São Paulo', SE: 'Sergipe', TO: 'Tocantins'
  }
  const ZipCodeRelatedFields = ['street', 'neighborhood', 'state', 'city', 'country']
  const watchZipCode = useWatch({ name: `${model}.zip_code` });

  const [invalidZipCode, setInvalidZipCode] = useState(false)
  const [disabledArray, setDisabledArray] = useState([])
  const [zipCodeDisableFields, setZipCodeDisableFields] = useState(false)
  const [disabledComplement, setDisabledComplement] = useState(false)
  const { setValue, trigger, getValues } = useFormContext();

  const zipCodeError = () => {
    cleanData()
    setInvalidZipCode(true)
  }

  const fetchAddressData = (zipCode) => {
    queryByZipCode({zipCode: zipCode}).then(res => res.json().then(json => res.ok ? loadData(json) : zipCodeError()))
    setUpdateData && setUpdateData(!updateData)
  }

  const isDisabled = disabledFields || zipCodeDisableFields || loadedZipCode

  useEffect(() => {
    const zipCode = watchZipCode?.replace(/\D/g, '')
    if(zipCode?.length === 8) {
      if(invalidZipCode) {
        cleanData()
      }
    }
  }, [watchZipCode]);

  const loadData = (data) => {
    if(!data) { return }

    setInvalidZipCode(false)
    if(data.street) { setValue(`${model}.street`, data.street) }
    if(data.neighborhood) { setValue(`${model}.neighborhood`, data.neighborhood) }
    if(data.city) { setValue(`${model}.city`, data.city) }
    if(data.state) { setValue(`${model}.state`, states[data.state]) }
    setValue(`${model}.country`, 'Brasil')

    let disableFields = []
    ZipCodeRelatedFields.forEach(field => {
      const fieldValue = data[field]
      if(fieldValue || field === 'country') {
        disableFields.push(field)
      }
    })
    trigger([`${model}.street`, `${model}.neighborhood`, `${model}.state`, `${model}.city`, `${model}.country`])
    setTimeout(function () {
      setDisabledArray(disableFields)
    }, 200)
  }

  const checkZipCode = (event) => {
    const zipCode = event.target.value.replace(/\D/g, '')
    if(zipCode.length === 8) {
      fetchAddressData(zipCode)
    }
  }

  const handleZipCodeChange = (event) => {
    const zipCode = event.target.value.replace(/\D/g, '')
    if(zipCode.length === 8) {
      fetchAddressData(zipCode)
    } else if(!zipCode.length) {
      cleanData()
      setInvalidZipCode(false)
    }
  }

  const cleanData = () => {
    setValue(`${model}.street`, '')
    setValue(`${model}.neighborhood`, '')
    setValue(`${model}.city`, '')
    setValue(`${model}.state`, '')
    setValue(`${model}.country`, '')
    setZipCodeDisableFields(false)
    setDisabledArray([])
  }

  const stringFilter = event => {
    const field = event.target.name
    const result = event.target.value.replace(/[0-9!@#¨$%^&*)(+=._-]+/gi, '')
    setValue(field, result)
  }

  const handleChecked = (event) => {
    const checked = event.target.checked
    setValue(`${model}.empty_complement`, checked)
    setDisabledComplement(checked)
    if (checked) { setValue(`${model}.complement`, "") }
    trigger([`${model}.complement`])
  };

  return (
    <>
      <div>
        <InputMaskedText
          model={model}
          title={i18n.t(`${model}.zip_code`)}
          name="zip_code"
          requiredField={true}
          mask="99999-999"
          placeholder="00000-000"
          onBlur={checkZipCode}
          onChange={handleZipCodeChange}
          disabled={disabledFields}
          {...props}
        />
        { invalidZipCode &&
          <span
            className="items-center font-medium tracking-wide text-red-500 text-xs mt-1"
            role="alert"
            id={`${model}_zip_code_input_error`}
          >
            {i18n.t('address.zip_code_error')}
          </span>
        }
      </div>

      <InputText
        model={model}
        title={i18n.t(`${model}.street`)}
        name="street"
        requiredField={true}
        className='w-full'
        disabled={isDisabled || disabledArray.includes('street')}
        {...props}
      />

      <InputText
        model={model}
        title={i18n.t(`${model}.house_number`)}
        name="house_number"
        requiredField={true}
        className='w-full'
        disabled={disabledFields}
        {...props}
      />
      <div>
        <InputText
          model={model}
          title={i18n.t(`${model}.complement`)}
          name="complement"
          requiredField={true}
          className='w-full'
          disabled={disabledFields || disabledComplement || getValues(`${model}.empty_complement`)}
        />
        <CheckboxInput
          model={model}
          name="empty_complement"
          label={i18n.t('address.empty_complement')}
          checkboxInputDivClassName={'h-10 w-10 pt-[5px] pl-3'}
          id={`${model}.empty_complement`}
          labelClassName={'text-sm font-normal text-blue-gray font-sans'}
          onClick={handleChecked}
          inputDisabled={disabledFields}
        />
      </div>

      <InputText
        model={model}
        title={i18n.t(`${model}.neighborhood`)}
        name="neighborhood"
        requiredField={true}
        className='w-full'
        disabled={isDisabled || disabledArray.includes('neighborhood')}
        {...props}
      />

      <InputText
        model={model}
        title={i18n.t(`${model}.city`)}
        name="city"
        requiredField={true}
        className='w-full'
        disabled={isDisabled || disabledArray.includes('city')}
        {...props}
        onChange={stringFilter}
      />

      <InputText
        model={model}
        title={i18n.t(`${model}.state`)}
        name="state"
        requiredField={true}
        className='w-full'
        disabled={isDisabled || disabledArray.includes('state')}
        {...props}
        onChange={stringFilter}
      />

      <InputText
        model={model}
        title={i18n.t(`${model}.country`)}
        name="country"
        requiredField={true}
        className='w-full'
        disabled={isDisabled || disabledArray.includes('country')}
        {...props}
        onChange={stringFilter}
      />
    </>
  );
}
