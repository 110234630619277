import React, { useState, useContext } from "react"
import { Link } from "react-router-dom"
import i18n from "#translate/i18n"
import NewRebaseLogo from "#images/NewRebaseLogo.svg"
import PlusSign from "#images/+.svg"
// import ConfigIcon from "#images/setting-2.svg"
import DashBoardIcon from "#images/dashboard-icon.svg"
import ProfileIcon from "#images/profile-icon.svg"
import ChartsIcon from "#images/chart-icon.svg"
import ExpandButton from "#images/arrow-circle-right.svg"
import RebaseLogoText from '#images/rebase_logo_text.svg'
import Button from "./Button"

import { UserFormsContext } from "#providers/UserFormsProvider"

export default function Sidebar() {
  const [sidebarExpanded, setSidebarExpanded] = useState(false)
  const { closeForms, setOpenNewUserForm } = useContext(UserFormsContext)

  const sidebarButtons = [
    {
      to: "/home_page",
      text: i18n.t("navigation.dashboard"),
      icon: DashBoardIcon,
      exact: true
    },
    {
      to: "/pessoas",
      text: i18n.t("navigation.people"),
      icon: ProfileIcon,
      exact: false
    },
    {
      to: "/reports",
      text: i18n.t("navigation.charts"),
      icon: ChartsIcon,
      exact: false
    }
  ]

  return (
    <aside className={`transition-[width] h-screen ${sidebarExpanded ? "w-64" : "w-24"} bg-rebase-blue flex-none flex flex-col items-center sticky top-0 z-[1400]`} >
      <Link id="logo" className="h-8 mb-8 mt-7 flex flex-row" to='/' onClick={closeForms}>
        <img src={NewRebaseLogo} alt="Rebase" />
        {
          sidebarExpanded && <img src={RebaseLogoText} alt="Rebase" className="ml-1" />
        }
      </Link>

      <div className={`h-0 ${sidebarExpanded ? 'w-4/5' : 'w-1/2'} border border-blue-500/50`} />

      <div id="menu-items-wrapper" className={`transition-[width] ${sidebarExpanded && 'w-11/12'} flex-1 flex flex-col`}>
        <div id="pages-wrapper" className="flex-1 flex flex-col">
          {sidebarButtons.map((button) => {
            return <Button
                     to={button.to}
                     text={button.text}
                     showText={sidebarExpanded}
                     icon={button.icon}
                     exact={button.exact}
                     key={button.to}
                     onClick={closeForms}
                   />
          })}
        </div>
        {/* <Button
          to="/config"
          text={i18n.t("navigation.configuration")}
          showText={sidebarExpanded}
          icon={ConfigIcon}
          exact={true}
          onClick={closeForms}
        /> */}
      </div>

      <div className={`h-0 ${sidebarExpanded ? 'w-4/5' : 'w-1/2'} border border-blue-500/50 mt-4`} />

      <button
        className='group flex flex-col items-center mt-8'
        onClick={() => {closeForms(); setOpenNewUserForm(true)}}
        id="add-button-wrapper"
      >
        <div className="flex bg-rebase-green rounded-full w-12 h-12 mb-1 group-hover:add-icon-shaddow">
          <img src={PlusSign} className="mx-auto w-4" alt="" />
        </div>
        <span className="font-sans text-white font-semibold text-base group-hover:text-rebase-green">{i18n.t("navigation.addPerson")}</span>
      </button>

      <button
        id="expand-button"
        onClick={() => setSidebarExpanded(!sidebarExpanded)}
        onKeyDown={() => setSidebarExpanded(!sidebarExpanded)}
        className={`transition-transform z-auto relative left-1/2 bottom-1/4 cursor-pointer ${sidebarExpanded && 'rotate-180'}`}
      >
        <img src={ExpandButton} alt={i18n.t("navigation.expandSidebar")} />
      </button>
    </aside>
  )
}
