const vacationData = {
  vacation: {
    start_date: "",
    return_date: "",
    total_business_days: "",
    bonus_added: "",
    notes: "",
    reference_year: "",
    daily_salary_percentage: "",
    max_salary_percentage: "",
    max_business_days: "",
    additional_bonus_days: "",
    current_available_days: "",
    last_satrt_date: "",
    last_return_date: "",
  },
};

export { vacationData };
