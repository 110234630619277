import React from "react"
import NoResults from '#images/no-results.svg'
import i18n from '#translate/i18n';

export default function NotFound() {

  return (
    <div className="flex flex-col justify-center items-center text-center m-auto w-[652px] h-[80vh]">
      <img className="w-[191px] h-[142px]" src={NoResults} alt='no-results' />
      <p className="font-semibold text-2xl text-black-report">{i18n.t('navigation.notFoundTitle')}</p>
      <p className="font-normal text-base text-default-gray-2">{i18n.t('navigation.notFoundDesc')}</p>
    </div>
  );
}