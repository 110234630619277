export default function expenseAssistanceIsUpdatable({data, refDate}) {
  let isUpdatable = null
  let paymentEnd = data.payment_end_date

  if (paymentEnd) {
    paymentEnd = paymentEnd instanceof Date ? paymentEnd : new Date(`${paymentEnd}T00:00`)
    isUpdatable = paymentEnd >= refDate
  } else {
    isUpdatable = true
  }

  return isUpdatable
}
