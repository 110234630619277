import React from "react";
import ImgRebase from "#images/Rebase_Logotipo_Branco.svg";
import SignUpForm from "./formComponents/SignUpForm";
import { FooterBar } from "#components";

function SignUp() {
  return (
    <div
      id="signup-page-container"
      className="flex flex-col justify-between text-medium-strong-gray bg-rebase-people h-screen"
    >
      <span className="py-0" />
      <img
        id="rebase-logo"
        src={ImgRebase}
        className="fixed px-8 pt-8 inline-block"
        alt="rebase logo"
      />
      <SignUpForm />
      <FooterBar />
    </div>
  );
}

export default SignUp;
