/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useFormContext, get } from "react-hook-form";
import useOutsideClick from "#services/useOutsideClick";
import BtnClose from "./BtnClose";
import FilledUploadFile from "./FilledUploadFile";
import EmptyUploadFile from "./EmptyUploadFile";

export default function UploadFile({
  model,
  id,
  name,
  requiredField,
  documentImage,
  documentType,
  alt,
  type,
  customRules,
  customClass = "",
  isBox = false,
  onChange,
  refreshing,
  setRefreshing,
  uploadTrigger,
}) {
  const {
    trigger,
    formState: { errors },
    getValues,
    setValue,
  } = useFormContext();

  const standardSize = 50;
  const standardAcceptedTypeFiles = ".jpg,.png,.pdf";
  const inputName = `${model}.${name}`;
  const error = get(errors, inputName);

  const { ref, isActive, setIsActive } = useOutsideClick(false);
  const initialState = {
    path: "",
    uploaded: false,
    error: false,
    size: null,
    acceptedTypeFiles:
    customRules?.acceptedTypeFiles || standardAcceptedTypeFiles,
    maximumSize: customRules?.maximumSize || standardSize,
  };

  const [storedFileUrl, setStoredFileUrl] = useState("")
  const [storedFileType, setStoredFileType] = useState("")
  const [uploadedFile, setUploadedFile] = useState("")
  const [url, setUrl] = useState("")
  const [fileInfo, setFileInfo] = useState(initialState);
  const [loadingData, setLoadingData] = useState(true);
  const filePresent = fileInfo?.uploaded || storedFileUrl;
  const idUploaded = filePresent ? `${id}_uploaded` : '';
  const [fileId, setFileId] = useState("")

  useEffect(() => {
    if(loadingData) {
      const initialFieldValue = getValues(inputName);
      setFileId(initialFieldValue?.id)
      if (initialFieldValue?.url) {
        setUrl(initialFieldValue['url'])
        setStoredFileUrl(initialFieldValue['url'])
        const splitedName = initialFieldValue['filename'].split('.')
        setStoredFileType(splitedName[splitedName.length - 1])
        setLoadingData(false)
      }
    }
  }, [getValues(inputName)])

  useEffect(() => {
    if (refreshing) {
      const fieldValue = getValues(inputName);

      if (typeof (fieldValue) === 'object' && !Object.keys(fieldValue).length) {
        setRefreshing?.(false)
        setFileInfo(initialState);
        setStoredFileUrl(false);
        setUrl('')
      }

      setFileId(fieldValue?.id)
      if (fieldValue?.url) {
        setUrl(fieldValue['url'])
        setStoredFileUrl(fieldValue['url'])
        setRefreshing?.(false)
      } else if (fieldValue?.length > 0) {
        if (fieldValue[0] && !fieldValue[0].url) {
          const file = fieldValue[0];
          const size = handleSize(file.size)
          setUploadedFile(file)

          setFileInfo({
            ...fileInfo,
            path: file,
            uploaded: true,
            error: false,
            size: size,
          });
        }
        setRefreshing?.(false)
      }
    }
  }, [getValues(inputName), refreshing])

  const handleSize = (size) => { return (size / Math.pow(1024, 2)).toFixed(1); };

  const handleFile = async (event) => {
    const file = event.target.files[0];
    const triggerFunction = uploadTrigger ? uploadTrigger : trigger
    const valid = await triggerFunction(event.target.name);
    const size = handleSize(file.size)
    setUploadedFile(file)

    setFileInfo({
      ...fileInfo,
      path: file,
      uploaded: valid,
      error: !valid,
      size: size,
    });

    onChange?.({ status: 'removed', id: fileId })
  };

  const removeFile = (event) => {
    event.preventDefault();

    setFileInfo(initialState);
    setStoredFileUrl(false);
    setUrl('')
    setValue(inputName, {})
    setValue(`${inputName}_uploaded`, {})
    onChange?.({ status: 'removed', id: fileId })
  };

  const handleClick = () => { setIsActive(true); };

  useEffect(() => { setFileInfo((values) => ({ ...values, error: error })); }, [error]);

  useEffect(() => {
    if (uploadedFile) {
      const urlToFile = storedFileUrl ||
        fileInfo.path ? URL.createObjectURL(new Blob([fileInfo.path], { type: uploadedFile.type })) : null;
      if (urlToFile) { setUrl(urlToFile) }
    }
  }, [fileInfo, uploadedFile]);

  let customPb = "pb-2.5";

  function handleStyle() {
    if (fileInfo.uploaded || storedFileUrl) {
      return `border-dark-gray border-solid bg-white border box-border`;
    }

    if (fileInfo.error) {
      if (isBox) customPb = "pb-2"
      return `border-amaranth border-dashed border box-border border-2 bg-light-support-error`;
    }

    if (isActive) {
      return `bg-alice-blue pb-4 rounded border-solid
              border-2 box-border border-strong-blue`;
    }

    return `border-border-gray border-dashed bg-white
            border box-border hover:border-dashed
            hover:border-rebase-blue`;
  }

  return (
    <div id={`${inputName}_file`} className='p-2.5 relative min-w-[12.345rem]'>
      {/* eslint-disable jsx-a11y/label-has-associated-control */}
      <label
        htmlFor={idUploaded ? idUploaded : id}
        className={filePresent ? "cursor-auto" : "cursor-pointer"}
      >
        {/* eslint-enable jsx-a11y/label-has-associated-control */}
        <div
          aria-hidden="true"
          id={`${inputName}_upload_file`}
          tabIndex="-1"
          data-testid={id}
          className={`h-[203px] flex flex-col items-center rounded ${handleStyle()} ${customClass}`}
          onClick={handleClick}
          ref={ref}
        >
          <>
            {filePresent ? (
              <FilledUploadFile
                fileInfo={fileInfo}
                fieldName={inputName}
                id={idUploaded}
                fileType={uploadedFile.type || storedFileType}
                url={url}
                model={model}
                name={name}
                type={type}
                accept={fileInfo.acceptedTypeFiles}
                onChange={handleFile}
                fileError={fileInfo.error}
                filePresent={filePresent}
              />
            ) : (
              <EmptyUploadFile
                model={model}
                name={name}
                type={type}
                id={id}
                accept={fileInfo.acceptedTypeFiles}
                maximumSize={fileInfo.maximumSize}
                onChange={handleFile}
                fileError={fileInfo.error}
                requiredField={requiredField}
                documentImage={documentImage}
                documentType={documentType}
                alt={alt}
                customPb={customPb}
              />
            )}
          </>
        </div>
      </label>
      {error && (
        <div
          name="validationError"
          className=
          "font-inter text-sm font-normal leading-5 text-support-error mt-1.5"
          role="alert" id={`${id}_error`}>
          {error.message}
        </div>
      )}
      {filePresent && <BtnClose id={id} removeFile={removeFile} />}
    </div>
  );
}
