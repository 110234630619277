import React from "react";
import CustomizedFieldsOptions from "#components/listings/people/data/drawers/CustomizedFieldsOptions"
import i18n from "#translate/i18n";

export default function AssetControlData({fieldsCompanyHistoryDataOptions}) {
  return (
    <>
      <p
        className="text-sm font-semibold text-title-gray mb-6 font-sans not-italic"
      >
        {i18n.t('customizedDataExport.selections.sections.company_history_data.AssetControlTitle')}
      </p>
      <CustomizedFieldsOptions
        options={fieldsCompanyHistoryDataOptions?.asset_control_data}
        prefix={'company_history_data.asset_control_data'}
      />
    </>
  );
}