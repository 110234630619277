import React, {useEffect} from "react";
import { useFormContext, useWatch } from "react-hook-form";
import i18n from "#translate/i18n";
import SelectInput from "../../SelectInput";
import TextAreaInput from "../../TextAreaInput";
import DivisionTitle from "../../DivisionTitle";

export default function NotesFields() {
  const { setValue } = useFormContext();
  const model = "user";
  const health_notes = useWatch({ name: `${model}.health_notes` });
  const disableField = String(health_notes) !== "true";

  const manageFields = () => {
    setValue(`${model}.general_notes`, "");
    setValue(`${model}.allergies`, "");
  };

  useEffect(() => {
    if (disableField) { manageFields() }
  }, [disableField]);
  return (
    <>
      <DivisionTitle title={i18n.t("general_notes.general_notes")} />

      <div className="grid grid-cols-2 gap-y-3 gap-x-9 mt-6 whitespace-nowrap">
        <SelectInput
          model={model}
          title={i18n.t("health_notes.title")}
          name="health_notes"
          options={["true", "false"]}
          className="w-full !mt-1.5"
          subtitle={i18n.t("health_notes.subtitle")}
        />
      </div>

      <div className="grid grid-cols-2 gap-y-3 gap-x-9 mt-2.5">
        <TextAreaInput
          model={model}
          title={i18n.t("general_notes.general_notes")}
          name="general_notes"
          disabled={disableField}
          placeholder={i18n.t("placeholders.general_notes")}
          className='w-full'
        />

        <TextAreaInput
          model={model}
          title={i18n.t("general_notes.allergies")}
          name="allergies"
          disabled={disableField}
          placeholder={i18n.t("placeholders.allergies")}
          className='w-full'
        />
      </div>
    </>
  );
}
