import { useState, useRef, useEffect } from "react";

export default function useOutsideClick(initialValue) {
  const [isActive, setIsActive] = useState(initialValue);
  const ref = useRef(null);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsActive(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  });

  return { ref, isActive, setIsActive };
}
