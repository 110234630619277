import axios from "axios";

export default function removeBonusReward({ id, user }) {
  const url = `/api/v1/bonus_rewards/${id}`

  const requestOptions = () => {
    const csrf = document
      .querySelector("meta[name='csrf-token']")
      .getAttribute("content");

    return {
      method: "DELETE",
      headers: { "X-CSRF-Token": csrf },
      data: { user_id: user.id }
    };
  };

  const options = requestOptions();

  return axios({ url, ...options });
}