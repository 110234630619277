import * as Yup from "yup";
import {
  MealAllowanceBaseSchema
} from "./schemas";

const addMealAllowancesSchema = Yup.object().shape({
  meal_allowance: MealAllowanceBaseSchema,
});

export default addMealAllowancesSchema;
