import React, { useState, useEffect } from "react";
import vacationIcon from '#images/events_icons/vacation.svg';
import i18n from "#translate/i18n";
import { Trans } from "react-i18next"

import TooltipMessageHoverButtons from "#components/TooltipMessageHoverButtons";

import ArrowDown from '#images/arrowdown-top.svg';
import EditIconBlue from "#images/edit-blue.svg"
import EditIconGray from "#images/edit-gray.svg"
import DeleteIconRed from "#images/delete-red.svg"
import DeleteIconGray from "#images/delete-gray.svg"
import userPresentedName from '#services/userPresentedName';

export default function VacationCreated({
  eventData,
  userData,
  removedArray,
  setOpenRemovalConfirmation,
  setObjectId,
  setChosenEventRemoval,
  showDetails,
  setShowDetails,
  lastUpdates,
  openEditionDrawer
}) {
  const data = eventData?.data || {}
  const eventUserData = data.user || userData
  const userName = userPresentedName(eventUserData)
  const adminName = data.responsible?.full_name
  const startDate = data.start_date || ''
  const returnDate = data.return_date || ''
  const [startYear, startMonth, startDay] = startDate.split('-')
  const [returnYear, returnMonth, returnDay] = returnDate.split('-')
  const bonusAdded = data.bonus_added
  const fullBonus = data.full_bonus
  const totalBusinessDays = data.total_business_days || 0
  const vacationConfiguration = data.configuration || {}
  const dailyBonusPercentage = vacationConfiguration.daily_salary_percentage || 0
  const maxBonusPercentage = vacationConfiguration.max_salary_percentage || 0
  const maxBusinessDays = vacationConfiguration.max_business_days || 0
  const bonusDays = vacationConfiguration.additional_bonus_days || 0
  const bonusPercentage = fullBonus ? Number(maxBonusPercentage) : (Number(dailyBonusPercentage) * Number(totalBusinessDays))
  const salary = Number(data.salary) || 0
  const bonusValue = (bonusPercentage * salary / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })

  const todayStart = new Date()
  todayStart.setHours(0, 0, 0, 0);
  const wasUpdated = lastUpdates.some((update) => update.id === data.id)
  const isRemoved = removedArray.some((removedItem) => removedItem.id === data.id && removedItem.creationEventName === eventData?.name)
  const currentStartDate = new Date(`${startDate}T00:00`) 
  const startInFuture = currentStartDate >= todayStart
  const canRemove = startInFuture && (
                      (currentStartDate.getMonth() === todayStart.getMonth() && todayStart.getDate() === 1) ||
                       currentStartDate.getMonth() > todayStart.getMonth() ||
                       currentStartDate.getYear() > todayStart.getYear()
                    )
  const isUpdatable = new Date(`${returnDate}T00:00`) >= todayStart

  const [click, setClick] = useState(false)

  useEffect(() => {
    if (showDetails === 'close') { setClick(false) }
  }, [showDetails]);

  
  return (
    <div className="relative flex-1 my-5 bg-white">
      <div className="flex items-center">
        <div className='rounded-full shadow-timeline-icons mr-2 h-fit min-w-fit'>
          <img
            src={vacationIcon}
            alt="event icon"
            className='w-12 h-12 p-1'
          />
        </div>
        <div className="relative z-20 font-inter text-sm leading-[22px] text-info-gray flex-1">
          <p className="font-bold inline-block">
            {`${i18n.t("timelineEvents.vacationCreated.title")}`}
          </p>
          <p className="font-normal">
            <Trans
              i18nKey={`${i18n.t("timelineEvents.vacationCreated.description")}`}
              values={{ admin: adminName, userName: userName, startDay: startDay, startMonth: startMonth, startYear: startYear, returnDay: returnDay, returnMonth: returnMonth, returnYear: returnYear }}
            />
          </p>
        </div>
        <button
          id='vacation-created-details-button'
          className='ml-6 mb-3 p-2'
          onClick={() => { setClick(!click), setShowDetails(null) }}
        >
          <img
            className={`w-3 h-[7.2px] right-0 ${click && 'transform rotate-180'} min-w-max`}
            src={ArrowDown}
            alt="menu arrow"
          />
        </button>
      </div>
      {click && (
        <>
          <div className="relative font-inter text-sm leading-6 text-info-gray mt-3 ml-14" id='vacation-created-details-panel' >
            <p id='vacation_created_reference_year' >
              <span className="font-bold"> {i18n.t('timelineEvents.vacationCreated.reference_year')} </span>
              {data.reference_year}
            </p>
            <p id='vacation_created_total_business_days'>
              <span className="font-bold"> {i18n.t('timelineEvents.vacationCreated.total_business_days.title')} </span>
              {fullBonus ?
                `${maxBusinessDays} ${i18n.t('timelineEvents.vacationCreated.total_business_days.max_days_with_bonus', { count: bonusDays })}` :
                i18n.t(`timelineEvents.vacationCreated.total_business_days.days`, { count: totalBusinessDays })
              }
            </p>
            <p id='vacation_created_bonus_added' >
              <span className="font-bold"> {i18n.t('timelineEvents.vacationCreated.bonus_added.title')} </span>
              {i18n.t(`timelineEvents.vacationCreated.bonus_added.${bonusAdded}`)}
            </p>
            {bonusAdded &&
              <>
                <p id='vacation_created_bonus_percentage'>
                  <span className="font-bold"> {i18n.t('timelineEvents.vacationCreated.bonus_percentage.title')} </span>
                  {`${bonusPercentage}%`}
                </p>
                <p id='vacation_created_bonus_value'>
                  <span className="font-bold"> {i18n.t('timelineEvents.vacationCreated.bonus_value.title')} </span>
                  {`${bonusValue}`}
                </p>
              </>
            }
            <p className="font-bold"> {i18n.t('timelineEvents.vacationCreated.observations')} </p>
            <p id='vacation_created_notes'>{data?.notes || i18n.t(`timelineEvents.vacationCreated.missing_notes`)} </p>
          </div>
          {isUpdatable && !wasUpdated && (
            <div className="flex space-x-4 justify-end mt-5" id="vacation-created-manage-buttons">
              <div className="flex space-x-4 group">
                {isRemoved && (
                  <TooltipMessageHoverButtons
                    buttonClass="absolute h-11 w-[370px] right-2"
                    tooltipClass="absolute bg-border-blue z-1 gap-1 text-white items-center text-center w-[490px] text-xs px-3 py-1 h-9 font-semibold
                                  rounded-lg bg-tooltip-color shadow-tooltip-buttons ml-[-40px]"
                    arrowClass='absolute ml-[-50px]'
                    messageClass="h-5 pt-1"
                    message={i18n.t('timelineEvents.removedMessage')}
                  />
                )}      
                {canRemove && (
                  <button
                    className={`justify-center font-sans font-semibold text-base bg-white flex items-center px-4 py-2.5
                                ${isRemoved ? 'text-disabled-gray' : 'text-support-error'}`}
                    id="vacation-created-remove-button"
                    disabled={isRemoved}
                    onClick={() => { setOpenRemovalConfirmation(true), setObjectId(data.id), setChosenEventRemoval(eventData), setShowDetails(null) }}
                    >
                    <img src={isRemoved ? DeleteIconGray : DeleteIconRed} alt="" className="inline mr-2 w-6 h-6" />
                    {i18n.t('timelineEvents.vacationCreated.removeVacation')}
                  </button>
                )}


                <button
                  className={`justify-center font-sans font-semibold text-base rounded border
                              flex items-center px-4 py-2.5 ${isRemoved ? 'text-disabled-gray bg-disabled-gray-button' : 'text-rebase-blue border-rebase-blue bg-white'}`}
                  id="vacation-created-edit-button"
                  disabled={isRemoved}
                  onClick={() => {openEditionDrawer(), setObjectId(data.id)}}
                >
                  <img src={isRemoved ? EditIconGray : EditIconBlue} alt="" className="inline mr-2 w-6 h-6 stroke-rebase-blue" />
                  {i18n.t('timelineEvents.vacationCreated.editVacation')}
                </button>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}
