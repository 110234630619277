import i18n from "#translate/i18n";

// Function to create a flattened hash with i18n translations
export default function flattenAndTranslateHashWithArrayValues({obj, prefix = '', result = {}, i18nPrefix =''}) {
  for (const key in obj) {
    if (Object.hasOwn(obj, key)) {
      const value = obj[key];
      const newPrefix = prefix ? `${prefix}.${key}` : key;
      if (Array.isArray(value)) {
        value.forEach(item => {
          result[`${newPrefix}.${item}`] = i18n.t(`${i18nPrefix}${newPrefix ? `${newPrefix}.${item}` : `${item}`}`);
        });
      } else if (typeof value === 'object' && value !== null) {
        flattenAndTranslateHashWithArrayValues({obj: value, prefix: newPrefix, result: result, i18nPrefix: i18nPrefix});
      }
    }
  }
  return result;
}
