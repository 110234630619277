import React from 'react';
import i18n from "#translate/i18n";
import formatPhoneNumber from '#utils/formatPhoneNumber';

export default function Contacts({ contacts, emergencyContacts }) {
  const contactKeys = [
    "email",
    "landline",
    "name",
    "observations",
    "phone_number",
    "primary",
    "relationship",
    "corporate_email",
    "personal_email",
  ]

  const checkValuesPresence = (obj) => {
    return Object.keys(obj).some((key) => {
      return contactKeys.includes(key) && typeof obj[key] === 'string' && obj[key].trim() !== '';
    });
  };

  return (
    <div className="flex flex-wrap text-info-gray font-sans text-sm leading-[26px] tracking-normal text-left p-4 px-5">
      {contacts?.map((contact, index) => {
        const hasValues = checkValuesPresence(contact);
        return (
          <div key={contact.id} className="w-[316px] mb-4">
            {hasValues && (
              <p className="font-bold">{`${i18n.t('userProfile.principal_contact')} ${index + 1}`}</p>
            )}

            {contact.personal_email && (
              <p>{contact.personal_email}</p>
            )}
            {contact.corporate_email && <p>{contact.corporate_email}</p>}
            {contact.landline && contact.landline.trim() !== '' && (
              <p>
                {formatPhoneNumber(contact.landline)} ({i18n.t('userProfile.landline')})
              </p>
            )}
            {contact.phone_number && contact.phone_number.trim() !== '' && (
              <p>
                {formatPhoneNumber(contact.phone_number)} ({i18n.t('userProfile.mobile')})
              </p>
            )}
          </div>
        );
      })}

      {emergencyContacts?.map((emergency_contact, index) => {
        const hasValues = checkValuesPresence(emergency_contact);
        return (
          <div key={emergency_contact.id} className="w-[316px] mb-4">
          {hasValues && (
            <p className="font-bold">{`${i18n.t('userProfile.emergency_contact')} ${index + 1}`}</p>
          )}
          {emergency_contact.name && emergency_contact.relationship && (
            <p>
              {emergency_contact.name}{' '}
              {emergency_contact.relationship && `(${emergency_contact.relationship})`}
            </p>
          )}
          {emergency_contact.email && <p>{emergency_contact.email}</p>}
          {emergency_contact.landline && emergency_contact.landline.trim() !== '' && (
            <p>{formatPhoneNumber(emergency_contact.landline)}  {`(${i18n.t('userProfile.landline')})`}</p>
          )}
          {emergency_contact.phone_number && emergency_contact.phone_number.trim() !== '' && (
            <p>{formatPhoneNumber(emergency_contact.phone_number)} {`(${i18n.t('userProfile.mobile')})`}</p>
          )}
          <p className='font-bold'>{i18n.t('userProfile.observations')}</p>
          {emergency_contact.observations ? (
            <div key={emergency_contact.id}>
              <p>{emergency_contact.observations}</p>
            </div>
          ) : (
            <p>{i18n.t('userProfile.missingObservations')}</p>
          )}
          </div>
        );
      })}
    </div>
  );
}