/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import CurrencyInput from '#components/formComponents/CurrencyInput';
import InputText from '#components/formComponents/InputText'


export default function HealthcareBeneficiaryInput({
  beneficiary, checkDisabled = false, indexNumber = '', array = false, titlePerson = '', titleValue = '', setOnChange, changeReference, model
}) {
  const namePrefix = array ? `${indexNumber}.` : ''
  const { control, register } = useFormContext();
  const [boxValue, setBoxValue] = useState('')
  const descendantsOptions = useWatch({ control, name: 'healthcare.descendants' })
  useWatch({ control, name: 'healthcare.user' })

  useEffect(() => {
    setOnChange(!changeReference)
  }, [descendantsOptions]);

  function setCheckChanges(event) {
    const value = event.target.chacked
    if (beneficiary === 'user') { return }
    setBoxValue(value)
  }

  let checkboxStyle = ''
  const checkedBox = 'w-[18px] h-[18px] ml-2.5'
  if (checkDisabled) {
    checkboxStyle = `${checkedBox} cursor-not-allowed mt-10`
  } else {
    const uncheckedBox = `outline outline-2 outline-[#CBD5E1] mt-6 ${checkedBox}`
    checkboxStyle = boxValue ? `${checkedBox} mt-6` : uncheckedBox
  }

  return (
    <div id={`${model}.${beneficiary}.${namePrefix}data`}>
      <div className='float-left'>
        <input
          type='checkbox'
          {...register(`${model}.${beneficiary}.${namePrefix}adding`)}
          id={`${model}.${beneficiary}.${namePrefix}adding_input`}
          className={`border-none rounded mr-2 disabled:bg-border-gray ${checkboxStyle}`}
          onClick={setCheckChanges}
          disabled={checkDisabled}
        />
      </div>
      <div className='grid grid-cols-2 gap-y-3 gap-x-4 inherit'>
        <InputText
          model={`${model}`}
          name={`${beneficiary}.${namePrefix}full_name`}
          title={titlePerson}
          disabled
          className={'w-full'}
        />
        <CurrencyInput
          model={`${model}`}
          name={`${beneficiary}.${namePrefix}healthcare_value`}
          title={titleValue}
          disabled
          className={'w-full'}
        />
      </div>
    </div>
  );
}
