import React from "react";
import i18n from "#translate/i18n";
import InputText from "#components/formComponents/InputText";
import BasicAddressFields from '#components/formComponents/userForm/fields/BasicAddressFields'
import DivisionTitle from "#components/formComponents/DivisionTitle";

export default function DeliveryAddressFields() {
  const model = "delivery_address";

  return (
    <>
      <DivisionTitle title={i18n.t("delivery_address.address")} />

      <div className="grid grid-cols-2 gap-y-3 gap-x-9">
        <BasicAddressFields model={model} />

        <InputText
          model={model}
          title={i18n.t("delivery_address.reference_point")}
          name="reference_point"
          className='w-full'
        />
      </div>
    </>
  );
}