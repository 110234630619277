import React from "react";
import DateInput from "../formComponents/DateInput";
import i18n from "#translate/i18n";

const BirthDateUser = () => {
  return (
    <DateInput
      model="user"
      name="birth_date"
      title={i18n.t("onboarding.birthDate.label")}
    />
  );
};

export default BirthDateUser;
