import * as Yup from "yup";
import i18n from "#translate/i18n";

Yup.setLocale(i18n.t("yup.schemas", { returnObjects: true }));

const { errors: errorLabel } = i18n.t("yup.forms", { returnObjects: true });

const today = new Date();
today.setHours(0, 0, 0, 0)

const BonusBaseSchema = Yup.object().shape({
  reward_type: Yup.string().typeError(errorLabel.select_option).required(i18n.t("bonus_reward.forms_error.presence")),
  bonus_value: Yup.number()
                  .typeError(i18n.t("bonus_reward.forms_error.presence"))
                  .required(i18n.t("bonus_reward.forms_error.presence")),
  bonus_date: Yup.date()
                   .nullable()
                   .typeError(i18n.t("bonus_reward.forms_error.presence"))
                   .required(i18n.t("bonus_reward.forms_error.presence")),
  payment_date: Yup.date()
                   .nullable()
                   .typeError(i18n.t("bonus_reward.forms_error.presence"))
                   .required(i18n.t("bonus_reward.forms_error.presence"))
                   .min(today, i18n.t("bonus_reward.forms_error.date_error"))
                   .when("bonus_date", (bonus_date, schema) => {
                      if(bonus_date && !isNaN(Date.parse(bonus_date)) && bonus_date > today){
                        return schema.min(bonus_date, i18n.t("bonus_reward.forms_error.time_sequence"))
                      }
                   }),
  payment_type: Yup.string().typeError(errorLabel.select_option).required(i18n.t("bonus_reward.forms_error.presence")),
  notes: Yup.string(),
});

export default BonusBaseSchema;
