import i18n from "#translate/i18n";
import axios from "axios";

export default function OnboardingRequest(values, userId) {
  const postData = JSON.parse(JSON.stringify(values));
  const url = `/api/v1/users/${userId}`;

  const getFileData = (data) => {
    if (data instanceof FileList) return data[0];

    return "";
  };

  const getFile = (data) => {
    data.picture.face_picture = getFileData(values.picture.face_picture);
    data.picture.body_picture = getFileData(values.picture.body_picture);
  };

  const removeEmptyFile = (data) => {
    Object.values(data).forEach((obj) => {
      Object.entries(obj).forEach(([key, value]) => {
        if (value === "") delete obj[key];
      });
    });
  };

  const formatPayload = (data) => {
    userId && (data.user.id = userId);

    const sentData = {
      ...data.user,
      address_attributes: data.address,
      clothing_attributes: data.clothing,
      personal_preference_attributes: data.personal_preference,
      picture_attributes: data.picture,
    };

    return sentData;
  };

  const requestOptions = () => {
    getFile(postData);
    removeEmptyFile(postData);
    const csrf = document
      .querySelector("meta[name='csrf-token']")
      .getAttribute("content");

    return {
      method: "PATCH",
      headers: { "X-CSRF-Token": csrf },
      data: formatPayload(postData),
    };
  };

  const createRequest = async () => {
    const options = requestOptions();

    const response = await axios({ url, ...options });
    if (response.status === 200) {
      return window.location.replace(`/user/fill-progress/${userId}`);
    }

    throw new Error(i18n.t("requests.network_error"));
  };

  createRequest();
}
