import React, { Fragment } from "react";
import { Outlet, useLocation } from "react-router-dom";
import SideBar from "#components/navigation/Sidebar";
import Header from "#components/navigation/Header";

function PageUser() {
  let location = useLocation();
  const checkLocation = location.pathname !== "/onboarding";

  return (
    <Fragment>
      <div className="min-h-screen flex flex-row font-sans">
        {checkLocation && <SideBar className='flex-none'/>}

        <div className="flex-1 bg-light-gray w-[calc(100vh-96px)]" >
          <div className='fixed left-0 right-0 bg-white z-[1300]' style = {{position: 'relative', display: 'flex', flexDirection: 'column'}}>
            <Header />
          </div>
          <Outlet />
        </div>
    </div>
    </Fragment>
  );
}

export default PageUser;
