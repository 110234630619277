import React, { useEffect, useState } from "react";
import { useFormContext, useFieldArray, useWatch } from "react-hook-form";
import i18n from "#translate/i18n";
import InputText from "#components/formComponents/InputText";
import BtnImageAdd from "#components/formComponents/userForm/BtnImageAdd";
import BtnImageRemove from "#components/formComponents/userForm/BtnImageRemove";
import DateInput from "#components/formComponents/DateInput";

export default function DescendantsFields({ hasChildren }) {
  const model = "descendants";
  const { control, setValue, register, trigger, getValues } = useFormContext();
  useWatch({ control, name: model });

  const { fields, append, remove, update } = useFieldArray({
    control,
    name: model,
  });

  const newDescendant = {
    full_name: "",
    birthdate: "",
    status: "active",
  };

  const [ fullNameChange, setFullNameChange ] = useState(false);

  const descendantStatus = (index) =>{
    return getValues(`descendants.${index}.status`)
  }

  const displayAddButton = (descendant, index) => {
    const isLastItem = index === (fields.length - 1);

    return ((!descendant["status"] || descendantStatus(index) === 'active' ) && isLastItem);
  };


  const displayRemoveButton = (index) => {
    const isNotLastItem = index !== (fields.length - 1);

    return (( descendantStatus(index) === 'active') && (isNotLastItem));
  }

  const removeField = (descendant, index) => {
    if (!descendant.descendants_id) { () => remove(index) }

    update(index, { ...descendant, ["status"]: 'inactive' });
  };

  const hiddenLine = fields.length > 1 ? "" : "hidden";

  useEffect(() => {
    fields.map((_descendant, index) => {
      setValue(`${model}.${index}.status`, hasChildren ? 'active' : 'inactive')
    })
  }, [hasChildren]);

  useEffect(() => {
    fields.map((_descendant, index) => {
      fields.length > 1 && trigger(`${model}.${index}.full_name`);
    })
  }, [fields, hasChildren, fullNameChange]);

  return (
    <>
      <div className={`grid grid-cols-1 gap-y-3`} id='descendants_attributes'>
        {fields.map((descendant, index) => (
          <div
            className={`${descendantStatus(index) == 'inactive' ? "hidden" : ""} grid grid-cols-2 gap-x-9`}
            key={descendant.id}
            id={`descendants_${index}`}
          >
            <input type='hidden' id={`${model}_${index}_status`} {...register(`${model}.${index}.status`)} />

            <InputText
              model={model}
              name={`${index}.full_name`}
              title={i18n.t("descendants.full_name")}
              placeholder={i18n.t("descendants.full_name_placeholder")}
              className='w-full'
              onChange={() => setFullNameChange(!fullNameChange)}
            />
            <div className='flex'>
              <DateInput
                model={model}
                name={`${index}.birthdate`}
                title={i18n.t("descendants.birthdate")}
                style={{ flex: 1 }}
                classStyle='w-full'
              />
              {displayAddButton(descendant, index) && (
                <BtnImageAdd
                  model={model}
                  id={`add-new-user-child-${index}`}
                  style={{ marginTop: 30, marginLeft: 12 }}
                  display={true}
                  handleOnClick={(e) => {
                    e.preventDefault()
                    append(newDescendant)
                  }}
                />
              )}
              {displayRemoveButton(index) && (
                <BtnImageRemove
                  model={model}
                  id={`remove-user-child-${index}`}
                  style={{ marginTop: 30, marginLeft: 12 }}
                  handleOnClick={(e) => {
                    e.preventDefault()
                    removeField(descendant, index)
                  }}
                />
              )}
            </div>
            <div className={`row-start-6 col-start-1 col-end-3 ${hiddenLine}`} />
          </div>
        ))}
      </div>
    </>
  );
}
