import i18n from "#translate/i18n"
import axios from "axios"

export default function InviteUserRequest(values) {
  const postData = JSON.parse(JSON.stringify(values))
  const url = "/api/v1/guests"

  const formatPayload = (data) => {
    const sentData = {
      ...data.user,
      contacts_attributes: data.contacts,
      company_history_attributes: data.company_history,
    }

    return sentData
  }

  const requestOptions = () => {
    const csrf = document
      .querySelector("meta[name='csrf-token']")
      .getAttribute("content")

    return {
      method: "POST",
      headers: { "X-CSRF-Token": csrf },
      data: formatPayload(postData),
    }
  }

  return axios({url, ...requestOptions()}).then(response => {
    if (response.status !== 200) throw new Error(i18n.t("requests.network_error"))

    return true
  })
}
