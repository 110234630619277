import React from "react";
import i18n from "#translate/i18n";
import CurrencyInput from "../CurrencyInput";

const CurrentRemuneration = ({ model, name, setRemuneration, remuneration, disabled=true, userId }) => {
  return (
    <CurrencyInput
      model={model}
      name={name}
      title={i18n.t("company_history.current_remuneration")}
      setOnChange={setRemuneration}
      automaticFilledValue={!userId}
      filledValue={remuneration}
      disabled={disabled}
    />
  );
};

export default CurrentRemuneration;
