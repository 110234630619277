/* eslint-disable no-unused-vars */
import * as Yup from "yup";
import i18n from "#translate/i18n";

Yup.setLocale(i18n.t("yup.schemas", { returnObjects: true }));

const today = new Date();
today.setHours(0, 0, 0, 0)
const sanitizeDate = (value, originalValue) => (
  (originalValue && Object.prototype.toString.call(value) === '[object Date]') ? value : null
)

const DismissalInstallmentSchema = Yup.object().shape({
  past_installment: Yup.string().required(),
  installment_value: Yup.number()
                        .transform((value) => (isNaN(value) ? null : value))
                        .nullable()
                        .when("installments_number", (installments_number, schema) => {
                          if(+installments_number > 1) {
                            return schema.typeError(i18n.t("user_dismissal.messageForm.error.presence"))
                                          .required(i18n.t("user_dismissal.messageForm.error.presence"))
                                          .positive()
                          } else {
                            return schema
                          }
                        }),
  payment_date: Yup.date()
                    .when("past_installment", {
                      is: (val) => val !== "true",
                      then: Yup.date()
                              .transform(sanitizeDate)
                              .nullable()
                              .typeError(i18n.t("user_dismissal.messageForm.error.presence"))
                              .required(i18n.t("user_dismissal.messageForm.error.presence"))
                              .min(today, i18n.t("bonus_reward.forms_error.date_error")),
                      otherwise: Yup.date()
                                    .transform(sanitizeDate)
                                    .nullable()
                                    .typeError(i18n.t("user_dismissal.messageForm.error.presence"))
                                    .required(i18n.t("user_dismissal.messageForm.error.presence"))
                    }),
}).transform((value, originalValue) => {
  const { id, ...rest } = originalValue;
  return rest;
});

const DismissalInstallmentListSchema = Yup.array().of(DismissalInstallmentSchema)

export { DismissalInstallmentSchema, DismissalInstallmentListSchema };
