export default function FormatPhoneReceived(response, setFormValues) {
  // TODO: better regex or check API
  // (11) 1111-1111 | 11 1111-1111
  // group1 group2 | group3 group4
  const PHONE_REGEX = /\((\d{2})\)\s?(\d*-?\d*)|(\d{2})\s?(\d*-?\d*)/;

  function phoneSplit(list, ddd, phone) {
    return list.map((item) => {
      if(item && item[phone] && item[phone] !== ' ') {
        const [, ddd1, phone1, ddd2, phone2] = item[phone].match(PHONE_REGEX);

        const dddValue = ddd1 || ddd2;
        const phoneValue = phone1 || phone2;

        return {
          ...item,
          [ddd]: dddValue,
          [phone]: phoneValue,
        };
      } else {
        return { ...item, [ddd]: '', [phone]: '' }
      }
    });
  }

  function setNumber(property, ddd, phone) {
    setFormValues((values) => ({
      ...values,
      [property]: phoneSplit(values[property], ddd, phone),
    }));
  }

  const buildPhoneNumbers = () => {
    setNumber("contacts", "DDD_landline", "landline");
    setNumber("contacts", "DDD_phone_number", "phone_number");

    setNumber("emergency_contacts", "DDD_landline", "landline");
    setNumber("emergency_contacts", "DDD_phone_number", "phone_number");
  };

  buildPhoneNumbers();
}
