import React from "react";
import i18n from "#translate/i18n";
import removeHobbyButton from "#images/remove_hobby_button.svg";

export default function Hobby({ hobby, handleRemoveHobby }) {
  const handleRemove = (event) => {
    event.preventDefault();
    handleRemoveHobby( {selectedHobby: hobby})
  }
  return (
    <div
      className="flex flex-row gap-2 items-center py-1 px-[0.625rem]
                font-medium text-sm text-hobby-gray h-8
                border border-alice-blue rounded-2xl shadow-custom
                hover:bg-hobby-hover-gray"
    >
      <img
        src={hobby.picture_path}
        alt={`${i18n.t("personal_preference.hobbies.icon")} ${hobby.name}`}
        width="24"
        height="24"
      />
      {hobby.name}

      {handleRemoveHobby && (
        <input
          type="image"
          src={removeHobbyButton}
          alt={i18n.t("personal_preference.hobbies.remove_button_alt")}
          onClick={handleRemove}
        />
      )}
    </div>
  );
}
