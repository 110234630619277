const expenseAssistanceData = {
  expense_assistance: {
    reason: "",
    assistance_monthly_value: "",
    frequency: "",
    duration: "",
    category: "",
    payment_start_date: "",
    payment_type: "",
    notes: "",
  },
};

export { expenseAssistanceData };
