import React, { useState, useEffect } from 'react';
import i18n from "#translate/i18n";

import latestRewardsRequest from '#requests/userProfile/latestRewardsRequest';

import BonusReward from '#components/user/profile/remunerationTab/BonusReward'

export default function LatestRewards({
  userData,
  handleOpenedDrawersFromProfile,
  closeDrawers,
  refreshUserData,
  setOpenRemovalConfirmation,
  setRewardType,
  setChosenObjectRemoval,
}) {
  const [userRewards, setUserRewards] = useState([]);
  const [loading, setloading] = useState(true);

  useEffect(() => {
    latestRewardsRequest({ userId: userData.id, limit: 8 })
      .then((response) => response.json())
      .then((parsedResponse) => {
        setUserRewards(parsedResponse)
        setloading(false)
      });
  }, [userData, refreshUserData]);

  return (
    <>
    {
      !loading && !userRewards?.length ?
        <div className='text-info-gray p-6 text-center'>
          {i18n.t('userProfile.remuneration_tab.latest_rewards.no_latest_rewards')}
        </div>
        :
      <section id='user-latest-rewards' >
        { userRewards && (
          <div className="gap-4 grid grid-cols-2 py-5 px-0 my-0 mx-5" >
            { userRewards?.map((reward, index) => (
              <div key={index}>
                <BonusReward
                  rewardData={reward}
                  setOpenRemovalConfirmation={setOpenRemovalConfirmation}
                  setRewardType={setRewardType}
                  setChosenObjectRemoval={setChosenObjectRemoval}
                  handleOpenedDrawersFromProfile={handleOpenedDrawersFromProfile}
                  userData={userData}
                  closeDrawers={closeDrawers}
                />
              </div>
            ))}
        </div>
        )}
      </section>
    }
    </>
  )
}
