import React, { useState, useContext } from "react";
import fillUserForm from "#components/formComponents/userForm/forms/fillUserForm";
import createOrUpdateUserRequest from "#requests/createOrUpdateUserRequest";
import userPresentedName from '#services/userPresentedName';
import { UserForm } from "#components";

import { UserContext } from "#providers/UserContextProvider"

export default function EditUser({
  user,
  closeDrawers,
  openDrawers,
  setShowMessage,
}) {
  const { profileTabName, setRefreshUserData } = useContext(UserContext)
  const [updateErrors, setUpdateErrors] = useState();
  const [submittingData, setSubmittingData] = useState(false);
  
  const userId = user.id

  function onSubmit(values) {
    setSubmittingData(true)
    createOrUpdateUserRequest(values, userId)
      .then((response) => {
        const updatedUserData = response.data;
        closeDrawers?.(), openDrawers?.('historic')
        const newMessage = {
          success: true,
          userName: userPresentedName(updatedUserData),
          source: 'updated_user',
        }
        setShowMessage(messages => [...messages, newMessage])
        setRefreshUserData(true)
      }).catch(function(requestReturn) {
        setSubmittingData(false)
        setUpdateErrors(requestReturn.response.data.errors)
      })
  }

  const loadUser = ({ reset: setForm }) => {
    return fillUserForm(userId, setForm);
  };

  const [loading, setLoading] = useState(true)

  return(
    <div id='edit-user-form-component' className='inherit flex-auto mt-0 pt-0 h-[calc(100vh-64px)] ml-0 pl-5 bg-light-gray' >
      <UserForm
        loadData={loadUser}
        onSubmit={onSubmit}
        context={{ update: true }}
        loading={loading}
        setLoading={setLoading}
        requestErrors={updateErrors}
        setRequestErrors={setUpdateErrors}
        closeDrawer={(tabName) => {closeDrawers?.(), openDrawers?.(tabName)}}
        profileTabName={profileTabName}
        userId={user.id}
        submittingData={submittingData}
      />
    </div>
  )
}
