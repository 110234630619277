import React from "react";
import i18n from "#translate/i18n";
import IconClose from "./IconClose";
import { Tooltip } from "#components";


export default function BtnClose({ id, removeFile }) {
  return (
    <div className="absolute top-0 right-0">
      <button id={`remove_${id}`} onClick={removeFile} className="group">
        <IconClose
          alt={i18n.t("buttons.removeFile")}
          customStyle="fill-rebase-blue hover:fill-amaranth"
        />
        <Tooltip message={i18n.t("tooltip.messages.removeFile")} />
      </button>
    </div>
  );
}
