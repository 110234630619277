import * as Yup from "yup";
import {
  DismissalBaseSchema,
  AccessAndAssetListSchema,
  DismissalInstallmentListSchema,
  DismissalAllowancesListSchema
} from "./schemas";

const userDismissalSchema = Yup.object().shape({
  user_dismissal: DismissalBaseSchema,
  access_and_assets_attributes: AccessAndAssetListSchema,
  invoice_installments_attributes: DismissalInstallmentListSchema,
  extended_allowances_attributes: DismissalAllowancesListSchema,
});

export default userDismissalSchema;
