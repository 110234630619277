/* eslint-disable no-unused-vars */
import * as Yup from "yup";
import i18n from "#translate/i18n";

Yup.setLocale(i18n.t("yup.schemas", { returnObjects: true }));

const { errors: errorLabel } = i18n.t("yup.forms", { returnObjects: true });
const today = new Date();
today.setHours(0, 0, 0, 0)

const AccessAndAssetSchema = Yup.object().shape({
  description: Yup.string()
              .typeError(errorLabel.select_option)
              .required(i18n.t("user_dismissal.messageForm.error.presence")),
  status: Yup.string()
              .when("description", (description, schema) => {
                if(description !== 'additional_access'){
                  return schema.typeError(errorLabel.select_option)
                              .required(i18n.t("user_dismissal.messageForm.error.presence"))
                }
              }),
  notes: Yup.string()
}).transform((value, originalValue) => {
  const { id, ...rest } = originalValue;
  return rest;
});;

const AccessAndAssetListSchema = Yup.array().of(AccessAndAssetSchema)

export { AccessAndAssetSchema, AccessAndAssetListSchema };
