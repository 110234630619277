import React from "react";
import i18n from "#translate/i18n";

export default function FilePreview({ data, emptyDocumentImage, altEmpty, name, model }) {
  const fieldName = model ? `${model}.${name}` : name

  if (!data || !Object.keys(data).length) {
    return (
      <div
        className='h-[177px] flex flex-col items-center rounded border-border-gray border-dashed bg-white border box-border justify-center'
      >
        <div className="flex flex-col items-center p6 rounded">
          <img src={emptyDocumentImage} alt={altEmpty} className="w-10 h-10" id={`${fieldName}_missing`} />
          <span className="text-sm text-title-gray font-bold mt-1.5">
            {i18n.t('empty_file')}
          </span>
        </div>
      </div>
    );
  }

  const url = data.url
  const splitedName = data['filename'].split('.')
  const fileType = splitedName[splitedName.length - 1]

  const openFile = () => {
    if (!url) return
    window.open(url, "_blank");
  };

  if ((fileType?.match(/image|png|jpg|jpeg/))) {
    return (
      <div className="h-[177px] flex flex-col items-center rounded border-dark-gray border-solid bg-white border box-border ">
        <div className="h-full w-full text-info-gray font-sans text-sm leading-[26px] tracking-normal text-left p-4 px-5" >
          <div className="relative h-full w-full flex justify-center items-center rounded">
            <button className="relative h-[175px] w-full" onClick={openFile}>
              <img className="absolute inset-0 object-cover my-0 mx-auto h-full object-contain" src={url} alt={`${fieldName}_file`} id={fieldName}  />
            </button>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="relative flex justify-center items-center w-full h-[177px]" >
        <div className="h-full w-full flex justify-between" >
          <button className='w-full relaive z-[3000]' onClick={openFile} />
          <iframe
            style={{width: '100%', height: '100%', position: 'absolute'}}
            src={`${url}#view=fit`}
            title="iFrame pdf"
            id={fieldName}
          />
        </div>
      </div>
    );
  }
}
