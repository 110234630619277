import React from "react";
import HealthcareCreditPublishedIcon from '#images/events_icons/healthcare_credit_published.svg';
import i18n from "#translate/i18n";
import { Trans } from "react-i18next";
import userPresentedName from '#services/userPresentedName';

export default function HealthcareCreditPublished({ eventData, userData }) {
  const data = eventData?.data
  const descendantsCount = (data?.descendants_event_data.length)
  const descendantsPresent = descendantsCount === 0 ? 'no_descendants' : descendantsCount === 1 ? 'one_descendant' : 'multiple_descendants'
  const description = `timelineEvents.healthcareCreditPublished.${descendantsPresent}.description`
  const currentValue = Number(data?.healthcares_current_value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
  const eventUserData = data.user || userData
  const userName = userPresentedName(eventUserData)

  return (
    <>
      <div className="relative flex-1 my-6 bg-white">
        <div className="flex items-center">
          <div className='rounded-full shadow-timeline-icons mr-2 h-fit min-w-fit'>
            <img
              src={HealthcareCreditPublishedIcon}
              alt="event icon"
              className='w-[52px] h-[52px] drop-shadow-[0.3333333432674408px_0.3333333432674408px_1.3333333730697632px_rgba(0,0,0,0.15)]
              fill-[var(--suporte-erro-background,#E3F2FD)]'
            />
          </div>
          <div className="relative z-20 font-inter text-sm leading-[22px] text-info-gray">
            <p className="font-bold ">
              {`${i18n.t("timelineEvents.healthcareCreditPublished.title")}`}
            </p>
            <p className="font-normal">
              <Trans
                i18nKey={`${i18n.t(description)}`}
                values={{ userName: userName, currentValue: currentValue, descendantsCount: descendantsCount }}
              />
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
