import React from "react";
import i18n from "#translate/i18n";
import InputText from "../../InputText";
import TextAreaInput from "../../TextAreaInput";
import DivisionTitle from "../../DivisionTitle";

export default function SportPreferencesFields({ model }) {
  return (
    <>
      <DivisionTitle title={i18n.t("personal_preference.sport_preferences")} />

      <div className="grid grid-cols-2 auto-rows-max gap-y-3 gap-x-9">
        <InputText
          model={model}
          name="preferred_sport"
          title={i18n.t("personal_preference.preferred_sport")}
          className='w-full'
        />

        <InputText
          model={model}
          name="preferred_club"
          title={i18n.t("personal_preference.preferred_club")}
          className='w-full'
        />

        <TextAreaInput
          model={model}
          name="preferred_videogame"
          title={i18n.t("personal_preference.preferred_videogame")}
          className='w-full'
        />
      </div>
    </>
  );
}
