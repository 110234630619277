import React from "react";
import { Link } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import i18n from "#translate/i18n";
import createSessionRequest from "#requests/createSessionRequest";
import SignUpSchema from "#validations/SignUpSchema";

import TermsOfUseModal from "../modals/TermsOfUseModal";
import InputText from "./InputText";
import CheckboxInput from "./CheckboxInput";

function SignUpForm() {
  const formMethods = useForm({
    defaultValues: {
      signup: {
        name: "",
        email: "",
        password: "",
        password_confirmation: "",
        terms_of_use: false,
      },
    },
    resolver: yupResolver(SignUpSchema),
  });

  const onSubmit = ({ signup: values }) => createSessionRequest(values);

  return (
    <div className="m-auto text-left flex flex-col items-center pt-7 pl-8 pr-9.4 pb-6 rounded-md bg-light-medium-gray w-148 h-fit">
      <span>Inicie o cadastro com os dados abaixo</span>
      <FormProvider {...formMethods}>
        <form
          onSubmit={formMethods.handleSubmit(onSubmit)}
          className="mx-auto font-bold flex flex-col items-start text-black w-full my-auto "
        >
          <div className="my-1.5 w-full h-fit">
            <InputText
              model="signup"
              name="name"
              title={i18n.t("signup.name")}
              placeholder="João da Silva"
            />
          </div>

          <div className="my-1.5 w-full h-fit">
            <InputText
              model="signup"
              name="email"
              title={i18n.t("signup.email")}
              placeholder="renan@rebase.com.br"
            />
          </div>

          <div className="my-1.5 w-full h-fit">
            <InputText
              type="password"
              model="signup"
              name="password"
              title={i18n.t("signup.password")}
            />
          </div>

          <div className="my-1.5 w-full h-fit">
            <InputText
              type="password"
              model="signup"
              name="password_confirmation"
              title={i18n.t("signup.password_confirmation")}
            />
          </div>

          <div className="flex items-center mt-2.58">
            <CheckboxInput
              model="signup"
              name="terms_of_use"
              label="Li e concordo com os"
            />
            <TermsOfUseModal />
          </div>

          <button
            type="submit"
            id="signup-submit-button"
            className="font-bold w-full rounded uppercase text-sm text-white bg-strong-blue mt-7.8 mx-auto py-2.58"
          >
            {i18n.t("signup.signup")}
          </button>
        </form>
      </FormProvider>

      <p
        id="signin-link"
        className="text-medium-strong-gray font-normal text-sm mt-2.58"
      >
        <Link to={"/login"} className="underline text-blue-700">
          {i18n.t("signup.back_to_login")}
        </Link>
      </p>
    </div>
  );
}

export default SignUpForm;
