import React, { Fragment, useState, useRef, useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import i18n from "#translate/i18n"
import { Popover, Transition } from "@headlessui/react"
import DivisionLine from '#components/formComponents/DivisionLine'
import { fieldsSearchData as initialValues } from '#data/fieldsSearchData';
import useOnClickOutside from '#services/useOnClickOutside';

import { Status, DateSearch, Project, WorkField } from "#components/listings/people/filters"

import FiltersIcon from "#images/tune.svg"

export default function FilterButton({ setDateRange, setStatusFilter, setProjectFilter, setWorkFieldFilter }) {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [filterStatuses, setFilterStatuses] = useState({})
  const [filterClients, setFilterClients] = useState({})
  const [filterWorkFields, setFilterWorkFields] = useState({})
  const filterSearch = useRef()

  const formMethods = useForm({ defaultValues: initialValues })

  useEffect(() => {
    const statuses = formMethods.getValues('status')
    setFilterStatuses(statuses)
    const clients = formMethods.getValues('project')
    setFilterClients(clients)
    const workFields = formMethods.getValues('work_field')
    setFilterWorkFields(workFields)
  }, [formMethods.getValues()]);

  const handleReset = () => {
    setStartDate('')
    setEndDate('')
    setFilterStatuses({})
    setFilterClients({})
    setFilterWorkFields({})
    formMethods.reset()
  }

  const setupArrayFilter = (event) => {
    const filteredData = []
    for (let filter_element in event){
      if(event[filter_element] && event[filter_element] != 'false'){
        filteredData.push(filter_element)
      }
    }
    return filteredData
  }

  const onSubmit = () => {
    setDateRange([startDate, endDate])
    setStatusFilter(setupArrayFilter(filterStatuses))
    setProjectFilter(setupArrayFilter(filterClients))
    setWorkFieldFilter(setupArrayFilter(filterWorkFields))
  }

  useOnClickOutside({
    ref: filterSearch,
    handler: () => {onSubmit()},
    ignoredIds: ['general-success-message', 'general-success-message-close-img']
  });

  return(
    <FormProvider {...formMethods}>
      <form
        id={'search-people'}
        onSubmit={formMethods.handleSubmit(onSubmit)}
      >
        <Popover className="group relative">
          <Popover.Button className="w-11 h-11 ml-2 bg-white border border-border-gray outline-none rounded flex items-center group-has-[:focus]:border-rebase-navy-blue">
            <img src={FiltersIcon} alt={ i18n.t('peopleListing.filters.filters') } className="w-6 h-11 mx-auto" />
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute z-10 right-0" ref={filterSearch}>
              {({ close }) => (
                <div className="rounded-lg shadow-lg bg-white mt-1.5 w-[520px] border border-rebase-navy-blue">
                  <p className="text-black-report text-xl font-semibold p-5">{ i18n.t('peopleListing.filters.select') }</p>
                  <DivisionLine customStyle='m-0 p-0'/>

                  <div className="overflow-y-auto h-[calc(100vh-370px)]">
                    <DateSearch
                      startDate={startDate}
                      setStartDate={setStartDate}
                      endDate={endDate}
                      setEndDate={setEndDate}
                    />
                    <DivisionLine customStyle='m-0 p-0' />

                    <Status />
                    <DivisionLine customStyle='m-0 p-0' />

                    <Project />
                    <DivisionLine customStyle='m-0 p-0' />

                    <WorkField />
                  </div>
                  <DivisionLine customStyle='m-0 p-0' />
                  <div className="grid grid-cols-2 gap-x-3 relative bottom-0 pt-[25px] px-6 pb-6">
                    <button
                      id='clean-filter-button'
                      className='justify-center font-sans font-semibold text-base rounded
                                  border text-rebase-blue border-rebase-blue bg-white flex items-center p-[7px]'
                      onClick={() => {
                        handleReset()
                        close()
                      }}
                    >
                      { i18n.t('peopleListing.filters.cleanFilter') }
                    </button>
                    <button
                      type='submit'
                      id='apply-filter-button'
                      onClick={() => {close()}}
                      className='justify-center font-sans font-semibold text-base rounded border
                                  text-white bg-rebase-blue flex items-center'
                    >
                      { i18n.t('peopleListing.filters.applyFilter') }
                    </button>
                  </div>
                </div>
              )}
            </Popover.Panel>
          </Transition>
        </Popover>
      </form>
    </FormProvider>
  )
}
