import React from "react";
import { useFormContext } from "react-hook-form";
import i18n from "#translate/i18n";

export default function FillOrCleanSelection({sectionName, sectionDataOptions}) {
  const { setValue } = useFormContext();

  const selectData = (value) => {
    if (Array.isArray(sectionDataOptions)) {
      sectionDataOptions.forEach((option) => {
        setValue(`${sectionName}.${option}`, value)
      })
    } else {
      Object.keys(sectionDataOptions).forEach((section) => {
        const sectionOptions = sectionDataOptions[section]
        Object.keys(sectionOptions).forEach((field) => {
          setValue(`${sectionName}.${section}.${sectionOptions[field]}`, value)
        })
      })
    }
	}

  return (
    <div className="gap-2.5 h-14 font-sans text-sm font-medium text-rebase-blue content-center">
      <button
        id={`select_all_${sectionName}`}
        onClick={(e) => {
          e.preventDefault()
          selectData(true)
        }}
        className="underline underline-offset-4"
      >
        <span>{i18n.t('customizedDataExport.selections.select_all')}</span>
      </button>
      <span className="text-black-report"> | </span>
      <button
        id={`unselect_all_${sectionName}`}
        onClick={(e) => {
          e.preventDefault()
          selectData(false)
        }}
        className="underline underline-offset-4"
      >
        <span>{i18n.t('customizedDataExport.selections.select_clean')}</span>
      </button>
    </div>
  );
}