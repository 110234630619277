import React from 'react';
import i18n from "#translate/i18n";
import ClothesIcon from "#images/clothes-icon.svg";
import AddressIcon from "#images/address-icon.svg";
import PersonalDataIcon from "#images/personal-data-icon.svg";
import DescendantsIcon from "#images/descendants-icon.svg";
import ContactIcon from "#images/contact-icon.svg";
import AvoidFoodIcon from "#images/avoid-food-icon.svg";
import Title from '#components/user/profile/Title';
import PersonalData from '#components/user/profile/infoTab/PersonalData';
import Address from '#components/user/profile/infoTab/Address';
import Descendants from '#components/user/profile/infoTab/Descendants';
import Clothes from '#components/user/profile/infoTab/Clothes';
import Restrictions from '#components/user/profile/infoTab/Restrictions';
import Contacts from '#components/user/profile/infoTab/Contacts';

export default function InfoTab({userData}) {
  const personalKeys = ['full_name', 'social_name', 'nickname', 'race_color', 'birth_date', 'marital_status', 'medical_follow_up', 'special_medical_condition', 'gender_identity', 'gender_description', 'lgbtqia_plus'];
  const address = userData.address
  const delivery_address = userData.delivery_address
  const contacts = userData.contacts
  const emergencyContacts = userData.emergency_contacts
  const sharingHomeWith = userData.sharing_home_with
  const clothing = userData.clothing
  const clothingKeys = ['t_shirt_size', 'shoe_size']
  const activeDescendants = userData.descendants.filter(function(descendant) {
    return descendant.status === 'active'
  })

  return (
    <div>
    {personalKeys.some((key) => userData[key] ) && (
         <>
          <Title
            title={i18n.t('userProfile.personal_data')}
            icon={PersonalDataIcon}
          />
          <PersonalData
            userData={userData}
            personalKeys={personalKeys}/>
        </>
      )}

      {(activeDescendants?.length > 0 || sharingHomeWith) && (
      <>
        <Title
          title={i18n.t('userProfile.dependents')}
          icon={DescendantsIcon}
        />
        <Descendants
          descendants={activeDescendants}
          sharingHomeWith={sharingHomeWith}
        />
      </>
      )}

      {(address || delivery_address) && (
        <>
          <Title
            title={i18n.t('userProfile.address')}
            icon={AddressIcon}
          />
          <Address
            address={address}
            deliveryAddress={delivery_address}
          />
        </>
      )}
      {(contacts.length > 0 || emergencyContacts.length > 0) && (
      <>
        <Title
          title={i18n.t('userProfile.contact_data')}
          icon={ContactIcon}
        />
        <Contacts
          contacts={contacts}
          emergencyContacts={emergencyContacts}
        />
      </>
      )}
      {clothing && clothingKeys.some((key) => clothing[key]) && (
      <>
        <Title
          title={i18n.t('userProfile.clothing.clothing')}
          icon={ClothesIcon}
          />
          <Clothes
            clothing={clothing}
          />
      </>
      )}
      {Object.entries(userData).map(([key, value]) => {
        if (key === 'health_notes' && value === true) {
          return (
            <div key={key}>
              <Title
                title={i18n.t('userProfile.restrictions')}
                icon={AvoidFoodIcon}
              />
              <Restrictions
                generalNotes={userData.general_notes}
                allergies={userData.allergies}
              />
            </div>
          );
        }
      })}
    </div>
  )
}