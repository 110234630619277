import React from "react";
import i18n from "#translate/i18n";
import BreadCrumbsNested from '#components/BreadCrumbsNested'

export default function FormTitle({context}) {
  const new_title = i18n.t("userForm.new_user_title");
  const edit_title = i18n.t("userForm.edit_user_title");

  return (
    <div>
      <BreadCrumbsNested name={context.update ? edit_title : new_title} context={context} />
      <h1 id="form-title" className='pt-0 mx-2 text-3xl font-bold ml-0'>
        {context.update ? edit_title : new_title}
      </h1>
    </div>
  );
}
