import React, { useState, useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import HobbySearch from "../formComponents/userForm/HobbySearch";
import HobbyList from "../formComponents/userForm/HobbyList";

const HobbiesUser = ({ model }) => {
  const fieldName = "hobby_ids";
  const inputName = model ? `${model}.${fieldName}` : fieldName;
  const { register, setValue } = useFormContext();
  const userHobbies = useWatch({ name: inputName });
  const [hobbies, setHobbies] = useState([]);
  const [selectedHobbies, setSelectedHobbies] = useState([]);

  const availableHobbies = hobbies.filter((hobby) => {
    const selectedHobbiesIds = selectedHobbies.map((hobby) => hobby.id);

    return !selectedHobbiesIds.includes(hobby.id);
  });

  useEffect(() => {
    const fetchHobbies = async () => {
      const response = await fetch("/api/v1/hobbies");
      const jsonResponse = await response.json();
      setHobbies(jsonResponse);
    };

    fetchHobbies();
  }, []);

  useEffect(() => {
    register(inputName)
  }, [register]);

  useEffect(() => {
    const newSelectedHobbies = hobbies.filter(({ id }) =>
      userHobbies.includes(id)
    );

    sortHobbies(newSelectedHobbies);
    setSelectedHobbies(newSelectedHobbies);
  }, [userHobbies, hobbies]);

  const handleSelectHobby = ({ selectedHobby: { id } }) => {
    const list = new Set([...userHobbies, id]);
    setValue(inputName, [...list]);
  };

  const handleRemoveHobby = ({ selectedHobby: { id } }) => {
    const list = userHobbies.filter((hobby_id) => hobby_id !== id);
    setValue(inputName, [...list]);
  };

  function sortHobbies(hobbies) {
    hobbies.sort((hobby, otherHobby) =>
      String(hobby.name).localeCompare(otherHobby.name)
    );
  }

  return (
    <>
      <HobbySearch
        availableHobbies={availableHobbies}
        handleSelectHobby={handleSelectHobby}
        classNameInputDiv={`mt-2`}
      />

      <HobbyList
        selectedHobbies={selectedHobbies}
        handleRemoveHobby={handleRemoveHobby}
      />
    </>
  );
};

export default HobbiesUser;
