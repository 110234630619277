export default function logoutRequest() {
  const url = "/api/v1/logout";
  const logoutUserRequest = async () => {
    const logoutResponse = await fetch(url).then((response) => {
      if (response.ok) {
        return response;
      }
    });

    return logoutResponse;
  };

  return logoutUserRequest();
}
