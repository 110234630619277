import React from "react";
import { useWatch } from "react-hook-form";
import { PersonalDataFields,  
         SocioeconomicFields, 
         ContactFields,
         EmergencyContactFields,
         AddressFields,
         DeliveryAddressFields,
         ClothingFields,
         NotesFields
        } from "../fields/";
import DivisionLine from "../../DivisionLine";
import Pictures from "../Pictures";

export default function PersonalDataForm({ selectedForm, openShoeReferenceTemplate, openTShirtReferenceTemplate }) {
  const hidden = selectedForm === "personal-data-header" ? "" : "hidden";
  const hasAlternativeAddress = useWatch({ name: "address.alternative" });

  return (
    <div id="personal_data" className={hidden} >
      <PersonalDataFields />

      <DivisionLine />

      <Pictures />

      <DivisionLine />

      <SocioeconomicFields />

      <DivisionLine />

      <ContactFields />

      <EmergencyContactFields />


      <AddressFields />

      {String(hasAlternativeAddress) === "true" && (
        <>
          <DivisionLine />
          <DeliveryAddressFields />
        </>
      )}

      <DivisionLine />

      <ClothingFields
        openShoeReferenceTemplate={openShoeReferenceTemplate}
        openTShirtReferenceTemplate={openTShirtReferenceTemplate}
      />

      <DivisionLine />

      <NotesFields />
    </div>
  );
}
