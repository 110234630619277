export default function transformListRowColumn(list, numCols) {
  const numRows = Math.ceil(list.length / numCols);
  const transformedList = [];
  for (let row = 0; row < numRows; row++) {
    for (let col = 0; col < numCols; col++) {
      const index = col * numRows + row;
      if (index < list.length) {
        transformedList.push(list[index]);
      }
    }
  }
  return transformedList;
}
