/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext } from "react";
import i18n from "#translate/i18n";

import { UserFormsContext } from "#providers/UserFormsProvider";

import Person from "#components/listings/Person";
import LoadingPage from '#components/navigation/LoadingPage';
import NotFound from '#components/navigation/NotFound';

export function People({
  people,
  openNewUserForm,
  setSelectedUsers,
  selectedUsers,
  selectAll,
  setSelectAll,
  unselectedAll,
  setUnselectedAll
}) {
  const { openUserEditForms } = useContext(UserFormsContext);
  const [openMenu, setOpenMenu] = useState('')

  const handleRowSelection = (id, selected) => {
    let newSelectedIds = [...selectedUsers];
    const foundPerson = people.find(person => person.id === id)
    if (selected) {
      newSelectedIds.push(foundPerson);
    } else {
      newSelectedIds = newSelectedIds.filter(person => person.id !== id);
    }

    newSelectedIds = [...new Set(newSelectedIds)];
    setSelectedUsers(newSelectedIds);
    setSelectAll(newSelectedIds.length >= people.length);
  };

  const handleSelectAll = (event) => {
    let checked = event.target.checked;
    setSelectAll(checked);
    setUnselectedAll(!checked);

    if (checked) {
      setSelectedUsers(people);
    } else {
      setSelectedUsers([]);
    }
  };

  const thClasses = "sticky top-0 bg-light-gray uppercase font-semibold font-sans text-xs text-left text-slate-600 p-5";

  return (
    <>
      {people.length && !people[0].id ?
        <div className='loading w-full'><LoadingPage /></div> :
        <>
          {people.length === 0 && <NotFound />}
          <table id="table-people" className={`border-collapse p-0 rounded-lg w-full table-auto drop-shadow-md ${openUserEditForms || openNewUserForm || people.length === 0 ? 'hidden' : ''}`}>
            <thead className="border-collapse h-13 bg-light-gray">
              <tr className="whitespace-nowrap">
                <th className={thClasses + " text-center sticky left-0 px-1 min-w-[64px] z-[60]"}>
                  <input aria-labelledby="select-all" type="checkbox" checked={selectAll} onChange={handleSelectAll} className="w-4 h-4 rounded border-slate-300" id="checkbox_select_all" />
                  <label hidden id="select-all">{i18n.t('peopleListing.selectAll')}</label>
                </th>
                <th className={`${thClasses} sticky left-16 z-[60]`}>
                  {i18n.t('peopleListing.people')}
                </th>
                <th className={`${thClasses} z-50`}>
                  {i18n.t('peopleListing.client')}
                </th>
                <th className={`${thClasses} z-50`}>
                  {i18n.t('peopleListing.birthdate')}
                </th>
                <th className={`${thClasses} z-50`}>
                  {i18n.t('peopleListing.workField')}
                </th>
                <th className={`${thClasses} z-50`}>
                  {i18n.t('peopleListing.status')}
                </th>
                <th className={`${thClasses} z-50`}>
                  {i18n.t('peopleListing.startedInCompanyDate')}
                </th>
                <th className={`${thClasses} z-50`}>
                  {i18n.t('peopleListing.houseTime')}
                </th>
                <th className={`${thClasses} z-50`}>
                  {i18n.t('peopleListing.salary')}
                </th>
                <th className={`${thClasses} z-50`}>
                  {i18n.t('peopleListing.lastRaise')}
                </th>
                <th className={`${thClasses} z-50 right-0`}>
                  {i18n.t('peopleListing.actions')}
                </th>
              </tr>
            </thead>
            <tbody className="bg-white">
              {
                people.map((person, index) => (
                  <Person key={index}
                    person={person}
                    selectAll={selectAll}
                    unselectedAll={unselectedAll}
                    selectedUsers={selectedUsers}
                    handleRowSelection={handleRowSelection}
                    row={index}
                    peopleLength={people.length}
                    openMenu={openMenu}
                    setOpenMenu={setOpenMenu}
                  />
                ))
              }
            </tbody>
            {openMenu !== '' && people.length <= 8 && <tfoot className="h-[400px] absolute w-[400px]"/>}
          </table>
        </>
      }

      <div className="
        w-0 h-0 border-support-frozen bg-support-frozen
        border-light-support-frozen
        border-light-support-positive bg-support-positive
        border-light-support-error bg-support-error
        border-light-support-alert bg-support-alert
      "/>
    </>
  );
}
