import React from "react";
import i18n from "#translate/i18n";
import Hobby from "./Hobby";

export default function HobbyList({ selectedHobbies, handleRemoveHobby, requiredField, fieldError }) {
  return (
    <div role="region" className="space-y-2 mt-2">
      <h3 className="font font-medium font-Inter leading-5 text-sm text-gray-700">
        {i18n.t("personal_preference.hobbies.hobby_list")}
        {
          <>
            { requiredField && (
              <span 
                className="pl-1 text-red-500"
                id="required-field"
              >
                *
              </span>
            )}
          </>
        }
      </h3>

      <div
        className={"flex flex-wrap gap-4 p-4 w-full h-28 overflow-y-auto rounded border border-border-gray bg-white " + (fieldError ? "ring-2 ring-red-500" : "")}
        id="personal_preference_hobby_ids"
      >
        {selectedHobbies.map((hobby) => (
          <Hobby
            key={hobby.id}
            hobby={hobby}
            handleRemoveHobby={handleRemoveHobby}
          />
        ))}
      </div>
    </div>
  );
}
