import React from "react";
import AddPicture from "#images/add_picture.svg";
import updateUserRequest from "#requests/userProfile/updateUserRequest";

function UploadFacePicture({ displayProfilePicturePreview, style, userId, setUpdated }) {
   function handleChange(event) {
    const formData = new FormData();
    formData.append("user[picture_attributes][face_picture]", event.target.files[0]);
    const preview = window.URL.createObjectURL(event.target.files[0]);
    displayProfilePicturePreview(preview);

    setUpdated(true);
    updateUserRequest(formData, userId);
  }

  return (
    <>
      <input
        type="file"
        accept="image/png, image/jpeg, image/jpg, image/png"
        onChange={handleChange}
        className="hidden"
        id="upload_button"
        name="upload_button"
      />

      <label className={style} htmlFor="upload_button">
        <img
          className="cursor-pointer"
          src={AddPicture}
          alt="camera"
        />
      </label>
    </>
  );
}

export default UploadFacePicture;