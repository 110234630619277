import React from "react";
import i18n from "#translate/i18n";
import BasicAddressFields from '#components/formComponents/userForm/fields/BasicAddressFields'
import InputText from "#components/formComponents/InputText";
import DivisionTitle from "#components/formComponents/DivisionTitle";
import SelectInput from "#components/formComponents/SelectInput";

export default function AddressFields() {
  const model = "address";

  return (
    <>
      <DivisionTitle title={i18n.t("address.address")} />

      <div className="grid grid-cols-2 gap-y-3 gap-x-9">
        <BasicAddressFields model={model} requiredField={true} />

        <InputText
          model={model}
          title={i18n.t("address.reference_point")}
          name="reference_point"
          className='w-full'
        />

        <SelectInput
          model={model}
          title={i18n.t("address.alternative.title")}
          name="alternative"
          options={["false", "true"]}
          className='w-full'
        />
      </div>
    </>
  );
}
