import React, { useState } from "react";
import i18n from "#translate/i18n";
import magnifier from "#images/Lupa.svg";
import hobbyNotFound from "#images/hobby_not_found.svg";
import Hobby from "./Hobby";

export default function HobbySearch({ availableHobbies, handleSelectHobby, classNameSearchDiv, classNameInputDiv }) {
  const [searchTerm, setSearchTerm] = useState("");
  const [listVisible, setListVisible] = useState(false);
  const [showAllHobbies, setShowAllHobbies] = useState(false);

  const shownHobbies = showAllHobbies ? availableHobbies :
                                        availableHobbies.filter((hobby) =>
                                          hobby.name.toLowerCase().includes(searchTerm.toLowerCase())
                                        )

  const handleSearchChange = (value) => {
    setSearchTerm(value);
    setListVisible(value ? true : false);
  };

  const handleSearchClick = () => {
    if(showAllHobbies) {
      setListVisible(false);
    }else {
      setListVisible(true);
    }
    setShowAllHobbies(showAllHobbies ? false : true)
  };

  const handleInputClick = () => {
    setListVisible(false)
    setShowAllHobbies(false)
  };

  return (
    <div role="search" className={`font-sans relative ${classNameSearchDiv}`}>
      <label
        htmlFor="hobby_search_input"
        className="font font-medium font-Inter leading-5 text-sm text-gray-700"
      >
        {i18n.t("personal_preference.hobbies.hobbies")}
      </label>

      <div
        className={`flex py-2.5 px-3.5 space-x-2 rounded border border-border-gray
                   focus-within:ring-2 ring-outline-blue ${classNameInputDiv}`}
      >
        <button type='button' onClick={() => handleSearchClick()} id='search-hobbies-button'>
          <img src={magnifier} alt={i18n.t("searchInput.imgMagnifier")} />
        </button>
        <input
          id="hobby_search_input"
          type="search"
          placeholder={i18n.t("placeholders.hobbies.search_box")}
          className="w-full p-0 border-0 placeholder:text-placeholder-gray focus:ring-0"
          style={{zIndex: 15}}
          onChange={(event) => handleSearchChange(event.target.value)}
          onClick={() => handleInputClick()}
        />
      </div>

      {listVisible && (
        <div
          className="flex flex-wrap gap-4 absolute p-4 w-full max-h-[11.875rem] overflow-y-auto
                    rounded border border-alice-blue shadow-custom bg-white"
        >
          {shownHobbies.length > 0 ? (
            shownHobbies.map((hobby) => (
              <button
                key={hobby.id}
                onClick={() => handleSelectHobby({ selectedHobby: hobby })}
                type="button"
              >
                <Hobby hobby={hobby} />
              </button>
            ))
          ) : (
            <div className="flex flex-col items-center py-2 gap-y-4">
              <img
                src={hobbyNotFound}
                alt={i18n.t("personal_preference.hobbies.not_found_alt_text")}
              />

              <p
                role="note"
                className="text-center font-medium text-medium-gray"
              >
                {i18n.t("personal_preference.hobbies.not_found")}
              </p>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
