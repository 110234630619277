import React from "react";
import i18n from "#translate/i18n";

function StatusColumn({ status, userId }) {
  return (
    <div id={`status-user-${userId}`} className="flex items-center text-sm">
      <div
        className={`rp-4 shadow-sm h-3 w-3 rounded-full ${
          status == "active" ? "bg-green-500" : "bg-gray-500 "
        }`}
       />
      <div className="pl-3">{i18n.t(`status.${status}`)}</div>
    </div>
  );
}

export default StatusColumn;
