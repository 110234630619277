import React, {
  createContext,
  useState,
  useEffect,
  useContext,
} from "react";
import fetchFeatureFlags from "#requests/fetchFeatureFlags";

export const FeatureFlagsContext = createContext();

export const useFeatureFlags = () => useContext(FeatureFlagsContext);

export function FeatureFlagsProvider(props) {
  const [featureFlags, setFeatureFlags] = useState({});

  const fetchCurrentFeatureFlags = async () => {
    try {
      const response = await fetchFeatureFlags(); 
      const flagsObject = response.data?.features.map((flags) => {
        const booleans = flags.gates.find((item) => item.key === 'boolean');

        return { [flags.key]: booleans.value };
      });
      setFeatureFlags(Object.assign({}, ...flagsObject));
    } catch (error) {
      setFeatureFlags({});
      console.error(error);
    }
  };

  useEffect(() => { 
    fetchCurrentFeatureFlags();
  }, []);

  return (
    <FeatureFlagsContext.Provider value={{featureFlags}}>
      {props.children}
    </FeatureFlagsContext.Provider>
  );
};
