import React, { useState, useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { Trans } from "react-i18next"
import i18n from "#translate/i18n";
import DateInput from "#components/formComponents/DateInput";
import SelectInput from "#components/formComponents/SelectInput";
import InputText from "#components/formComponents/InputText";
import DivisionTitle from "#components/formComponents/DivisionTitle";
import DivisionLine from "#components/formComponents/DivisionLine";
// import TextAreaInput from "#components/formComponents/TextAreaInput";
import CurrencyInput from "#components/formComponents/CurrencyInput";
import CurrentRemuneration from "#components/formComponents/finances/CurrentRemuneration";
import { TooltipMessage } from "#components";
import fetchCompanies from "#requests/fetchCompanies";
import fetchWorkFields from "#requests/fetchWorkFields";

function CompanyHistoryFields({ graduateClasses, userId }) {
  const { setValue } = useFormContext();
  const [remuneration, setRemuneration] = useState(0)
  const model = "company_history";
  const hasEquipment = useWatch({ name: `${model}.has_equipment` });
  const [companies, setCompanies] = useState(['']);
  const [workFields, setWorkFields] = useState(['']);

  const checkDisableServiceTag = (hasEquipment) => hasEquipment?.toString() === "true" ? false : true;
  const [disableServiceTag, setDisableServiceTag] = useState(checkDisableServiceTag(hasEquipment));

  const manageEquipment = () => {
    setValue(`${model}.service_tag`, "");
  };

  useEffect(() => {
    const statusOfDisableEquipment = checkDisableServiceTag(hasEquipment);
    setDisableServiceTag(statusOfDisableEquipment);

    if (statusOfDisableEquipment) { manageEquipment() }
  }, [hasEquipment]);

  useEffect(() => {
    fetchWorkFields()
      .then((parsedResponse) => {
        setWorkFields(parsedResponse["work_fields"]);
    });
    fetchCompanies()
      .then((parsedResponse) => {
        setCompanies(parsedResponse["companies"]);
    });
  }, []);

  return (
    <>
      <DivisionTitle title={i18n.t("general")} />

      <div className="grid grid-cols-2 gap-y-3 gap-x-9">
        <DateInput
          model="user"
          name="hiring_date"
          title={i18n.t("hiring_date")}
          requiredField={ true }
          classStyle='w-full'
        />

        <SelectInput
          model={model}
          name="company"
          options={companies}
          title={i18n.t("company_history.company")}
          requiredField={ true }
          className='w-full'
        />

        <SelectInput
          model={model}
          name="work_field"
          options={workFields}
          title={i18n.t("company_history.work_field")}
          className='w-full'
          orderAlphabetically={ true }
        />

        <CurrencyInput
          model={model}
          name="initial_remuneration"
          title={i18n.t("company_history.initial_remuneration")}
          requiredField={ true }
          setOnChange={setRemuneration}
          className='w-full'
        />

        <CurrentRemuneration
          model="user"
          name="current_remuneration"
          title={i18n.t("company_history.current_remuneration")}
          setRemuneration={setRemuneration}
          remuneration={remuneration}
          disabled
          className='w-full'
          userId={userId}
        />

        <SelectInput
          model="user"
          name="graduate_class_id"
          options={graduateClasses}
          title={i18n.t("graduate_class_id")}
          className='w-full'
        />

        <InputText
          model={model}
          name="mattermost_username"
          placeholder="@fulanodetal"
          title={i18n.t("company_history.mattermost_username")}
          className='w-full'
          tooltip={
            <TooltipMessage
              imageClass="inline mb-0.75 ml-1 h-2.5 w-2.5"
              tooltipClass="w-[396px] py-1 px-3 text-white leading-6 rounded-lg bg-border-blue tooltip-position font-normal text-xs font-sans not-italic"
              message={<Trans i18nKey={i18n.t("tooltip.messages.mattermost_username")} />}
              arrowClass={"w-2.5 h-2.5 bg-border-blue arrow-position before:bg-border-blue before:visible invisible before:absolute before:rotate-45 before:transform-y-[10px] before:w-2.5 before:h-2.5 top-[-5px]"}
              buttonId={'mattermost-username-tooltip-button'}
            />
          }
        />

        <InputText
          model={model}
          name="github_user"
          title={i18n.t("company_history.github_user")}
          placeholder={i18n.t("company_history.github_placeholder")}
          className='w-full'
        />
      </div>

      <DivisionLine />

      <DivisionTitle title={i18n.t("asset_control")} />

      <div className="grid grid-cols-2 gap-y-3 gap-x-9">
        <SelectInput
            model={model}
            name="has_equipment"
            options={["true", "false"]}
            title={i18n.t("has_equipment.has_equipment")}
            requiredField={true}
            className='w-full'
        />

        <InputText
          model={model}
          disabled={disableServiceTag}
          name="service_tag"
          title={i18n.t("company_history.service_tag")}
          placeholder={disableServiceTag ? "" : "001000" }
          requiredField={true}
          className='w-full'
        />
        {/* <TextAreaInput
          model={model}
          name="company_assets"
          placeholder={i18n.t("placeholders.company_history.company_assets")}
          title={i18n.t("company_history.company_assets")}
          className='w-full'
        />

        <TextAreaInput
          model={model}
          name="client_assets"
          placeholder={i18n.t("placeholders.company_history.client_assets")}
          title={i18n.t("company_history.client_assets")}
          className='w-full'
        /> */}
      </div>
    </>
  );
}

export default CompanyHistoryFields;
