import React from "react";
import { useFormContext } from "react-hook-form";
import fillNameImg from "#images/fill-social-name.svg";
import hobbiesImg from "#images/hobbiesOnboarding.svg";
import picturesImg from "#images/picturesOnboarding.svg";
import BirthDateImg from "#images/BirthDate.svg";
import ClothingImg from "#images/clothingOnboarding.svg";
import Address from "#images/Address.svg";
import i18n from "#translate/i18n";

const OnboardingDetails = ({ count, maxSteps }) => {
  const numbers = [...Array(maxSteps).keys()].map((number) => ++number);
  const color = (number) =>
    count === number ? `bg-strong-blue` : `bg-alice-blue`;
  const textColor = (number) =>
    count === number ? `text-white` : `text-hobby-gray`;
  const verifyText = (position) => (position === count ? `block` : `hidden`);
  const { getValues } = useFormContext();

  const stepsTexts = [
    {
      title: i18n.t("onboarding.userName.title"),
      image: fillNameImg,
      subtitle: i18n.t("onboarding.userName.subtitle"),
      text: i18n.t("onboarding.userName.text"),
    },
    {
      title: i18n.t("onboarding.hobbies.title", {
        name: getValues("user.nickname"),
      }),
      image: hobbiesImg,
      subtitle: "",
      text: "",
    },
    {
      title: i18n.t("onboarding.pictures.title"),
      image: picturesImg,
      subtitle: i18n.t("onboarding.pictures.subtitle"),
      text: i18n.t("onboarding.pictures.text"),
    },
    {
      title: i18n.t("onboarding.birthDate.title"),
      image: BirthDateImg,
      subtitle: i18n.t("onboarding.birthDate.subtitle"),
      text: i18n.t("onboarding.birthDate.text"),
    },
    {
      title: i18n.t("onboarding.clothing.title"),
      image: ClothingImg,
      subtitle: i18n.t("onboarding.clothing.subtitle"),
      text: i18n.t("onboarding.clothing.text"),
    },
    {
      title: i18n.t("onboarding.address.title"),
      image: Address,
      subtitle: i18n.t("onboarding.address.subtitle"),
      text: i18n.t("onboarding.address.text"),
    },
  ];

  return (
    <div className="mt-10 lg:mt-0 mb-2.5">
      <div className="flex justify-between mb-10 before:w-102 before:bg-solitude before:absolute before:h-0.5 before:top-28.6 lg:before:top-28.25">
        {numbers.map((number) => {
          return (
            <div
              key={number}
              className={`${color(
                number
              )} w-6 h-6 rounded-full flex last:mr-0 z-0`}
            >
              <h3
                className={`${textColor(
                  number
                )} font-semibold text-xs m-auto text-center`}
              >
                {number}
              </h3>
            </div>
          );
        })}
      </div>

      {stepsTexts.map((text, index) => {
        return (
          <div key={text.title} className={`${verifyText(++index)}`}>
            <h1 className="font-bold text-2xl text-midnight mb-9">
              {text.title}
            </h1>
            <img
              src={text.image}
              className="w-76.8 h-60.6 mx-auto mb-9.4"
              alt=""
            />
            <h1 className="font-bold mb-5 text-xl text-night-rider">
              {text.subtitle}
            </h1>
            <p className="text-night-rider text-2sm">{text.text}</p>
          </div>
        );
      })}
    </div>
  );
};

export default OnboardingDetails;
