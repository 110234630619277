import React from "react";
import UploadFile from "../uploadFile/UploadFile";
import face from "#images/face.svg";
import body from "#images/body.svg";
import i18n from "#translate/i18n";

export default function PicturesUser() {
  const model = "picture";

  return (
    <div id="pictures" className="grid grid-cols-2 gap-y-3 gap-x-4">
      <UploadFile
        model={model}
        id="face_picture"
        name="face_picture"
        documentImage={face}
        documentType={i18n.t("pictures.type.face")}
        alt={i18n.t("pictures.alt.face")}
      />

      <UploadFile
        model={model}
        id="body_picture"
        name="body_picture"
        documentImage={body}
        documentType={i18n.t("pictures.type.body")}
        alt={i18n.t("pictures.alt.body")}
      />
    </div>
  );
}
