import React, { useState, useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import onboardingSchema from "#validations/onboardingSchema";
import { onboardingFields as initialValues } from "#data/onboardingData";
import OnboardingDetails from "./OnboardingDetails";
import IdentifyUser from "../onboardingFormComponents/IdentifyUser";
import HobbiesUser from "../onboardingFormComponents/HobbiesUser";
import ClothingUser from "../onboardingFormComponents/ClothingUser";
import PicturesUser from "../onboardingFormComponents/PicturesUser";
import BirthDateUser from "../onboardingFormComponents/BirthDateUser";
import AddressUser from "../onboardingFormComponents/AddressUser";
import PreviousStep from "../onboardingFormComponents/PreviousStep";
import NextStep from "../onboardingFormComponents/NextStep";
import OnboardingRequest from "#requests/helpers/OnboardingRequest";
import userSessionRequest from "#requests/userSessionRequest";
import rebaseLogo from "#images/rebase-logotipo.png";
import rebasers from "#images/rebasers.png";
import hamburguerMenu from "#images/hamburguer_menu_onboarding.svg";
import i18n from "#translate/i18n";

const Onboarding = () => {
  const [count, setCount] = useState(1);
  const [userId, setUserId] = useState("");
  const maxSteps = 6;

  const submitData = (values) => {
    if (count === maxSteps) {
      OnboardingRequest(values, userId);
    }
  };

  const formMethods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(onboardingSchema),
  });

  const checkStep = (step) => count === step;
  const nextStep = () => setCount((previousCount) => previousCount + 1);
  const returnStep = () => setCount((currentCount) => currentCount - 1);
  const changeBtnType = () => (count === maxSteps ? "submit" : "button");

  const handleObjectName = (counted) => {
    const names = [
      {
        step: 1,
        name: "user.nickname",
      },
      {
        step: 4,
        name: "user.birth_date",
      },
      {
        step: 5,
        name: "clothing",
      },
    ];

    return names.find((name) => name.step === counted);
  };

  const proceed = async () => {
    const getName = handleObjectName(count);

    if (getName) {
      const valid = await formMethods.trigger(getName.name);

      valid && nextStep();
    } else {
      nextStep();
    }
  };

  useEffect(() => {
    const UserRequest = async () => {
      const user = await userSessionRequest();

      setUserId(user?.id);
    };

    UserRequest();
  }, []);

  return (
    <div className="flex-none lg:flex">
      <div className="bg-white w-screen lg:w-2/4 ">
        <img
          className="px-6 pt-6 mb-2.5 hidden lg:block"
          src={rebaseLogo}
          alt="Logotipo-Rebase"
        />
        <div className="lg:hidden flex justify-center py-5 shadow-sml w-104.3 vsm:w-auto">
          <h1 className="text-xl ml-auto font-medium">
            {i18n.t("onboarding.title")}
          </h1>
          <img src={hamburguerMenu} alt="" className="ml-auto mr-2" />
        </div>
        <div className="mx-auto w-104.3 px-3">
          <div className="lg:w-104.3 mx-auto">
            <FormProvider {...formMethods}>
              <OnboardingDetails count={count} maxSteps={maxSteps} />
              <form
                className="flex flex-col"
                onSubmit={formMethods.handleSubmit(submitData)}
              >
                {checkStep(1) && <IdentifyUser />}
                {checkStep(2) && <HobbiesUser model={"personal_preference"} />}
                {checkStep(3) && <PicturesUser />}
                {checkStep(4) && <BirthDateUser />}
                {checkStep(5) && <ClothingUser />}
                {checkStep(6) && <AddressUser />}
                <div className="flex justify-between items-center mt-14.4 mb-10">
                  <PreviousStep
                    text={"Voltar"}
                    display={!checkStep(1)}
                    onClick={returnStep}
                  />
                  <NextStep
                    id="button"
                    text={i18n.t("onboarding.proceed")}
                    type={changeBtnType()}
                    onClick={() => maxSteps !== count && proceed()}
                    className={checkStep(1) ? "w-full" : ""}
                  />
                </div>
              </form>
            </FormProvider>
          </div>
        </div>
      </div>
      <div className="h-screen w-2/4 hidden lg:block">
        <img className="h-screen w-screen" src={rebasers} alt="Time Rebase" />
      </div>
    </div>
  );
};

export default Onboarding;
