/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import i18n from "#translate/i18n";
import FormTitle from "./formComponents/userForm/FormTitle";
import UserFormHeader from "./formComponents/UserFormHeader";
import SaveButton from "#components/buttons/medium/primaryButton"
import CancelButton from "#components/buttons/medium/secondaryButton"
import CancelConfirmation from '#components/user/profile/CancelConfirmation';
import {
  PersonalDataForm,
  BankAndCompanyForm,
  CompanyHistoryRecordForm,
  PersonalDocumentForm,
  PersonalPreferenceForm
} from "./formComponents/userForm/forms/";
import { newUserFields as initialValues } from "#data/userData";
import userSchema from "#validations/userSchema";
import LoadingPage from '#components/navigation/LoadingPage'
import ErrorMessage from '#components/messages/ErrorMessage'
import ReferenceTemplate from "#components/formComponents/userForm/modals/ReferenceTemplate";

import shoeImg from "#images/referenceModal/shoe_size_reference.png";
import tShirtImg from "#images/referenceModal/t_shirt_size_reference.png";

import cleanObjectKeys from "#services/cleanObjectKeys"
import insertErrorsIntoArrayInput from "#services/insertErrorsIntoArrayInput"

export default function UserForm({
  userData = initialValues,
  loadData = (f) => f,
  onSubmit = (f) => f,
  context = {},
  setLoading,
  loading,
  requestErrors,
  setRequestErrors,
  closeDrawer,
  userId,
  profileTabName,
  submittingData,
} = {}) {
  const scrollDivRef = useRef();
  const [selectedForm, setSelectedForm] = useState("personal-data-header");
  const [clients, setClients] = useState([]);
  const [graduateClasses, setGraduateClasses] = useState([]);
  const [bankingInstitutions, setbankingInstitutions] = useState([]);
  const [firstErrorId, setFirstErrorId] = useState();
  const [foundError, setFoudError] = useState(false);
  const [clickSaveButton, setClickSaveButton] = useState(false);
  const [headersErrors, setHeadersErrors] = useState([]);
  const [openCancelConfirmation, setOpenCancelConfirmation] = useState(false)
  const [stopScroll, setStopScroll] = useState(false)
  const [serverErrors, setServerErrors] = useState()
  const [openShoeReferenceTemplate, setOpenShoeReferenceTemplate] = useState(false);
  const [openTShirtReferenceTemplate, setOpenTShirtReferenceTemplate] = useState(false);
  const formMethods = useForm({
    defaultValues: userData,
    resolver: yupResolver(userSchema),
    context: context,
    shouldFocusError: false,
  });

  function checkYupErrors() {
    const tabsErrors = {
      'personal-data-header': document.getElementById('personal_data')?.querySelectorAll('[role="alert"]'),
      'bank-company-header': document.getElementById('bank_and_company')?.querySelectorAll('[role="alert"]'),
      'history-data-header': document.getElementById('company_history')?.querySelectorAll('[role="alert"]'),
      'personal-document-header': document.getElementById('personal-documents')?.querySelectorAll('[role="alert"]'),
      'personal-preferences-header': document.getElementById('personal_preferences')?.querySelectorAll('[role="alert"]'),
    }

    const filteredErrors = {}
    Object.keys(tabsErrors).forEach((k) => {
      if (tabsErrors[k] && tabsErrors[k][0] && tabsErrors[k] != [] && tabsErrors[k] != {}) {
        filteredErrors[k] = tabsErrors[k]
      }
    });

    if (!Object.keys(filteredErrors).length) {
      setHeadersErrors([])
      return
    }
    const errorsHeaders = Object.keys(filteredErrors)
    const firstErrorSpan = Object.values(filteredErrors).flat()[0][0]
    if (!firstErrorSpan) { return }

    const errorParentNode = firstErrorSpan.parentNode
    let firstErrorInputs = null
    const isFile = errorParentNode.getAttribute('id')?.includes('_file')

    if (isFile) {
      firstErrorInputs = errorParentNode.getElementsByTagName('div')
    } else if (errorParentNode.getElementsByTagName('input').length) {
      firstErrorInputs = errorParentNode.getElementsByTagName('input')
    } else {
      firstErrorInputs = errorParentNode.getElementsByTagName('select')
    }

    const emptyInputsIds = []
    Object.keys(firstErrorInputs).forEach((field) => {
      if (isFile && field === '0') {
        emptyInputsIds.push(firstErrorInputs[Number(field)].id)
      } else if (!firstErrorInputs[Number(field)].value) {
        emptyInputsIds.push(firstErrorInputs[Number(field)].id)
      }
    })

    setFirstErrorId(emptyInputsIds[0] || firstErrorInputs[0]?.id)
    setHeadersErrors(errorsHeaders)
    setTimeout(function () {
      setFoudError(true)
      setSelectedForm(errorsHeaders[0])
    }, 200)
  }

  useEffect(() => {
    setTimeout(function () {
      if (!firstErrorId) { return }
      document.getElementById("personal_data").click();

      const elementToFocus = window.document.getElementById(firstErrorId)
      elementToFocus.focus();
      elementToFocus.scrollIntoView({
        behavior: 'auto',
        block: 'center',
        inline: 'center'
      });
    }, 100);
  }, [foundError, clickSaveButton]);

  useEffect(() => {
    document.activeElement.blur();
    window.scrollTo({ top: 0, left: 0 })
    setTimeout(function () { checkYupErrors() }, 100)
    setFoudError(false)
  }, [clickSaveButton]);

  function handleCancelConfirmationButton() { closeDrawer?.(profileTabName) }
  function handleFormHeaderClick(event) { setSelectedForm(event.target.id); }

  useEffect(() => {
    const loadFormData = async () => {

      fetch("/api/v1/graduate_classes", {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      })
        .then((response) => response.json())
        .then((parsedResponse) => setGraduateClasses(parsedResponse))

      fetch("/api/v1/banks", {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      })
        .then((response) => response.json())
        .then((parsedResponse) => setbankingInstitutions(parsedResponse))

      fetch(`/api/v1/clients`, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      })
        .then((response) => response.json())
        .then((parsedResponse) => setClients(parsedResponse))
        .then(() => loadData(formMethods))
    };

    loadFormData()
  }, []);

  useEffect(() => {
    if (stopScroll) { return }

    if (formMethods.getValues('user.full_name')) {
      if (setLoading) { setLoading(false) }
    }
  }, [formMethods.getValues()])

  useEffect(() => {
    if (formMethods.getValues('user.full_name')) {
      window.scrollTo({ top: 0, left: 0 })
      setStopScroll(true)
    }
  }, [loading])

  const findInvalidEmails = ({data, result}) => {
    const messages = [];
    const emails = [];
    data.forEach(entry => {
      const [email, ...messageParts] = entry.split(' ');
      emails.push(email);
      messages.push(messageParts.join(' '));
    })
    result["contacts.corporate_email"] = [...messages]
    insertErrorsIntoArrayInput({
      formMethods: formMethods, data: emails, listName: "contacts", attributeName: "corporate_email",
      message: i18n.t('userForm.messageForm.error.basic_server_error')
    })
  }

  const findInvalidHealthcareBeneficiaries = ({data, result}) => {
    const messages = [];
    const fullNames = [];
    data.forEach(entry => {
      const [message, name] = entry.split(': ');
      fullNames.push(name);
      messages.push(message);
    })
    result["descendants.healthcare"] = [messages[0]]
    if(String(formMethods.getValues("user.human_children")) == 'false') {
      formMethods.setValue("user.human_children", true)
    }
    setTimeout(function () {
      const activateDescendant = (indexes) => {
        indexes.forEach(index => formMethods.setValue(`descendants.${index}.status`, 'active'))
      }
      insertErrorsIntoArrayInput({
        formMethods: formMethods, data: fullNames, listName: "descendants", attributeName: "full_name", message: "",
        additionalChanges: activateDescendant
      })
    }, 100)
  }

  const filterErrors = (currentErrors) => {
    if(!currentErrors) { return setServerErrors(null) }

    let result = { ...currentErrors }
    let corporateEmailErrors = result["contacts.corporate_email"]
    let healthcareBeneficiary = result["descendants.healthcare"]
    if(corporateEmailErrors) { findInvalidEmails({data: corporateEmailErrors, result: result}); }
    if(healthcareBeneficiary) { findInvalidHealthcareBeneficiaries({data: healthcareBeneficiary, result: result}) }

    const cleanedResult = cleanObjectKeys({...result})
    setSelectedForm("personal-data-header")
    setHeadersErrors("personal-data-header")
    return cleanedResult
  }

  useEffect(() => {
    if(!requestErrors)  { return }

    setTimeout(function () {
      scrollDivRef.current?.scrollTo({ top: 0, left: 0 })
      setServerErrors(filterErrors({...requestErrors}))
    }, 100)
  }, [requestErrors]);

  return (
    <div className='max-h-full overflow-auto' ref={scrollDivRef} id="user-form-scroll-div" >
      <div className='bg-light-gray sticky top-0 left-24 right-0 z-[1100] h-[190px] pt-5'>
        <FormTitle context={context} />

        <div className='p-0 mt-[-30px] mr-2 flex flex-row ml-6' >
          <CancelButton
            buttonId={context.update === true ? "cancel-edit-user-button" : "cancel-new-user-button"}
            defaultText={i18n.t("userForm.buttons.cancel")}
            onClick={() => setOpenCancelConfirmation(true)}
            disabled={submittingData}
            imagePresent={false}
            innerDivClass={'grid'}
            buttonClass={'ml-auto w-[120px]'}
            type={'button'}
            pressed={openCancelConfirmation}
          />

          <SaveButton
            form="requestUserForm"
            defaultText={i18n.t("userForm.buttons.save")}
            onClick={() => {
              setClickSaveButton(`save_${clickSaveButton}`)
            }}
            submittingData={submittingData}
            processingText={i18n.t("userForm.buttons.saving")}
            buttonId={"save-user-button"}
            buttonClass={'w-[120px]'}
            type={'submit'}
          />
        </div>

        <div className='rounded-t mt-4 mr-[15px] mb-[30px] bg-white border-t border-x border-border-gray ml-0' >
          <UserFormHeader
            handleFormHeaderClick={handleFormHeaderClick}
            selectedForm={selectedForm}
            headersErrors={headersErrors}
          />
        </div>
      </div>

      <div className='rounded-b bg-white shadow-xl mr-[15px] mb-32 relative z-[1000] top-0 border-b border-x border-border-gray ml-0'>
        <div className={`w-full relative flex flex-col ${loading ? 'hidden' : ''}`}>
          <FormProvider {...formMethods}>
            <form
              id="requestUserForm"
              className="px-6 py-8"
              onSubmit={formMethods.handleSubmit(onSubmit)}
            >
              {serverErrors && (
                <ErrorMessage
                  errors={serverErrors}
                  removeErrors={setRequestErrors}
                  model={'userForm'}
                  userName={''}
                  className={'absolute mt-0'}
                />
              )}
              <PersonalDataForm
                selectedForm={selectedForm}
                openShoeReferenceTemplate={() => setOpenShoeReferenceTemplate(true)}
                openTShirtReferenceTemplate={() => setOpenTShirtReferenceTemplate(true)}
              />

              <BankAndCompanyForm
                selectedForm={selectedForm}
                bankingInstitutions={bankingInstitutions}
              />

              <CompanyHistoryRecordForm
                selectedForm={selectedForm}
                clientList={clients}
                graduateClasses={graduateClasses}
                userId={userId}
              />

              <PersonalDocumentForm selectedForm={selectedForm} />

              <PersonalPreferenceForm selectedForm={selectedForm} />
            </form>
          </FormProvider>
        </div>
        {loading && <div id='edit_form_loading' className='loading mr-2.5'><LoadingPage customHeight={'h-[700px]'} /></div>}
      </div>

      {openCancelConfirmation &&
        <CancelConfirmation
          giveUpFunction={() => setOpenCancelConfirmation(false)}
          cancelFunction={handleCancelConfirmationButton}
          userData={formMethods.getValues('user')}
          i18nTitle={context.update ? i18n.t("userForm.cancel_edit_user_title") : i18n.t("userForm.cancel_new_user_title")}
          i18nText={context.update ? i18n.t("userForm.cancel_edit_user_confirmation") : i18n.t("userForm.cancel_new_user_confirmation")}
          cancelButtonText={i18n.t('userForm.cancel_user_button')}
          continueButtonText={context.update ? i18n.t('userForm.give_up_cancelation_edit_user_button') : i18n.t('userForm.give_up_cancelation_new_user_button')}
          iconName={context.update ? 'editUserForm' : 'newUserForm'}
        />
      }

      {openShoeReferenceTemplate &&
        <ReferenceTemplate
          title={i18n.t("clothing.shoe_size.modal.title")}
          description={i18n.t("clothing.shoe_size.modal.description")}
          image={shoeImg}
          alt={i18n.t("clothing.shoe_size.alt")}
          closeReferenceTemplate={() => setOpenShoeReferenceTemplate(false)}
        />
      }

      {openTShirtReferenceTemplate &&
        <ReferenceTemplate
          title={i18n.t("clothing.t_shirt_size.modal.title")}
          description={i18n.t("clothing.t_shirt_size.modal.description")}
          image={tShirtImg}
          alt={i18n.t("clothing.t_shirt_size.alt")}
          closeReferenceTemplate={() => setOpenTShirtReferenceTemplate(false)}
        />
      }
    </div>
  );
}
