/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useState, useContext, useEffect } from "react"
import i18n from "#translate/i18n"
import { Menu, Transition } from '@headlessui/react'
import { usePopper } from "react-popper";

import { UserContext } from "#providers/UserContextProvider"

import HealthIcon from '#images/health_and_safety.svg'
import VacationIcon from '#images/beach_access.svg'
// import ArrowDownIcon from "#images/keyboard_arrow_down.svg"
import ActivePersonIcon from '#images/person.svg'
import DismissedPersonIcon from '#images/person_remove.svg'
import OnParentalLeaveIcon from '#images/on_parental_leave.svg'
import OnWorkLeaveIcon from '#images/calendar-status.svg'

import PTBRDateFormat from '#services/PTBRDateFormat'

export default function Status({person, showMenu}) {
  const { usersLeaveSchedules } = useContext(UserContext)
  const [outStartDate, setOutStartDate] = useState('')
  const [outReturnDate, setOutReturnDate] = useState('')
  const [outDismissed, setOutDismissed] = useState('')

  let status = person.status || 'dismissed'
  const personAlreadyDismissed = status === 'dismissed'
  useEffect(() => {
    if(usersLeaveSchedules && person?.id) {
      const personSchedule = usersLeaveSchedules.find((user) => user.id === person.id)
      const outStatus = personSchedule?.vacation_schedule || personSchedule?.parental_leave_schedule || personSchedule?.work_leave_schedule || personSchedule?.dismissal_schedule
      setOutStartDate(outStatus?.start_date || '')
      setOutReturnDate(outStatus?.return_date || '')
      setOutDismissed(outStatus?.exit_date   || '')
    }
  }, [usersLeaveSchedules, person])

  const dropdownFeatureToggle = false

  let statusColorMap = {
    'active': 'support-positive',
    'on_vacation': 'support-frozen',
    'medical': 'support-alert',
    'dismissed': 'support-error',
    'on_parental_leave': 'support-positive',
    'on_work_leave': 'support-alert'
  }

  const personActive = (<>
    <div className={ `flex flex-row items-center rounded-full w-5 h-5 bg-${ statusColorMap[status] } shrink-0` }>
      <img src={ActivePersonIcon} alt="" className="w-3 h-3 mx-auto" />
    </div>
    <span className="font-sans text-sm font-normal ml-1">{ i18n.t('peopleListing.personStatus.active') }</span>
  </>)

  const personMedical = (<>
    <div className={ `flex flex-row items-center rounded-full w-5 h-5 bg-${ statusColorMap[status] } shrink-0` }>
      <img src={HealthIcon} alt="" className="w-3 h-3 mx-auto" />
    </div>
    <span className="font-sans text-sm font-normal ml-1">{ i18n.t('peopleListing.personStatus.medical') }</span>
  </>)

  const personVacation = (<>
    <div className={ `flex flex-row items-center rounded-full w-5 h-5 bg-${ statusColorMap[status] } shrink-0` }>
      <img src={VacationIcon} alt="" className="w-3 h-3 mx-auto" />
    </div>
    <span className="font-sans text-sm font-normal ml-1">{ i18n.t('peopleListing.personStatus.on_vacation') }</span>
  </>)

  const personParentalLeave = (<>
    <div className={ `flex flex-row items-center rounded-full w-5 h-5 bg-${ statusColorMap[status] } shrink-0` }>
      <img src={OnParentalLeaveIcon} alt="" className="w-3 h-3 mx-auto" />
    </div>
    <span className="font-sans text-sm font-normal ml-1">{ i18n.t('peopleListing.personStatus.on_parental_leave') }</span>
  </>)

  const personDismissed = (<>
    <div className={ `flex flex-row items-center rounded-full w-5 h-5 bg-${ statusColorMap[status] } shrink-0` }>
      <img src={DismissedPersonIcon} alt="" className="w-3 h-3 mx-auto" />
    </div>
    <span className="font-sans text-sm font-normal ml-1">{ i18n.t('peopleListing.personStatus.dismissed') }</span>
  </>)

  const personWorkLeave = (<>
    <div className={ `flex flex-row items-center rounded-full w-5 h-5 bg-${ statusColorMap[status] } shrink-0` }>
      <img src={OnWorkLeaveIcon} alt="" className="w-3 h-3 mx-auto" />
    </div>
    <span className="font-sans text-sm font-normal ml-1">{ i18n.t('peopleListing.personStatus.on_work_leave') }</span>
  </>)

  let statusMap = {
    'active': personActive,
    'medical': personMedical,
    'on_vacation': personVacation,
    'dismissed': personDismissed,
    'on_parental_leave': personParentalLeave,
    'on_work_leave' : personWorkLeave
  }

  const [open, setOpen] = useState(false);
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);
  const { styles, attributes } = usePopper(
    referenceElement,
    popperElement, {
      placement: "top",
      modifiers: [
        {
          name: "arrow",
          enabled: true,
          options: { element: arrowElement },
          phase: "main",
        },
        { name: "flip", options: { fallbackPlacements: ["top"] } },
        { name: "offset", options: { offset: [0, 10] } },
      ],
    }
  );

  return(
    <Menu as="div" className="relative">
      <Menu.Button className={`flex flex-row px-2 py-1 items-center border border-light-${ statusColorMap[status] } ${!showMenu ? 'h-[34px] mr-[5px]' : ''} rounded-full bg-white whitespace-nowrap cursor-default`}
        onMouseEnter={() => setOpen(true)}
        onMouseLeave={() => setOpen(false)}
        ref={setReferenceElement}
      >
        { statusMap[status] }
        {/* {showMenu && (<img src={ArrowDownIcon} alt="" className="w-2 brightness-100 saturate-0 ml-2 mr-1"  ref={setReferenceElement}/>)} */}
      </Menu.Button>
      { dropdownFeatureToggle &&
        <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
          <Menu.Items className="absolute right-0 mt-2 w-40 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-50">
            <div className="px-1 py-1 flex flex-col items-start">
              <Menu.Item>
                {({active}) => (
                  <button className={`flex flex-row items-center ${active ? 'bg-rebase-blue text-white' : ''} px-2 py-2 w-full rounded-md`}>
                    { personActive }
                  </button>
                )}
              </Menu.Item>

              <Menu.Item>
                {({active}) => (
                  <button className={`flex flex-row items-center ${active ? 'bg-rebase-blue text-white' : ''} px-2 py-2 w-full rounded-md`}>
                    { personMedical }
                  </button>
                )}
              </Menu.Item>

              <Menu.Item>
                {({active}) => (
                  <button className={`flex flex-row items-center ${active ? 'bg-rebase-blue text-white' : ''} px-2 py-2 w-full rounded-md`}>
                    { personVacation }
                  </button>
                )}
              </Menu.Item>

              <Menu.Item>
                {({active}) => (
                  <button className={`flex flex-row items-center ${active ? 'bg-rebase-blue text-white' : ''} px-2 py-2 w-full rounded-md`}>
                    { personDismissed }
                  </button>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      }
      {showMenu && open && ['on_vacation', 'on_parental_leave', 'on_work_leave', 'dismissed'].includes(status) && (
        <div
          role="tooltip"
          className={`${personAlreadyDismissed ? 'w-[150px]' : 'w-[270px]'} h-fit py-1 px-3 flex items-center justify-center text-white font-medium leading-6 rounded-lg bg-border-blue tooltip-position z-[60]`}
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
        >
          {personAlreadyDismissed ? `Em ${PTBRDateFormat(outDismissed)}` : `De ${PTBRDateFormat(outStartDate)} até ${PTBRDateFormat(outReturnDate)}`}
            
          <div
            className='w-2.5 h-2.5 before:transform-y-[10px] before:w-2.5 before:h-2.5 bg-border-blue before:bg-border-blue before:visible invisible before:absolute before:rotate-45 arrow-position'
            ref={setArrowElement}
            style={styles.arrow}
          />
        </div>
      )}
    </Menu>
  )
}
