import React, { useState, useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import loginValidation from "#requests/loginValidation";
import userSessionRequest from "#requests/userSessionRequest";
import userProfileStatus from "#requests/userProfileStatus";
import Onboarding from "#components/user/Onboarding";
import { PageUser } from  "#components";
import LoadingPage from "#components/navigation/LoadingPage";

const ProtectedRoutes = () => {
  const [isAuth, setIsAuth] = useState(false);
  const [loading, setLoading] = useState(true);
  const [profile, setProfile] = useState("");
  const [userSession, setUserSession] = useState("");

  useEffect(() => {
    (async () => {
      const validator = await loginValidation();
      const profile = await userProfileStatus();

      if (validator.logged_in) {
        const currentUser = await userSessionRequest();

        setUserSession(currentUser);
      }

      setIsAuth(validator.logged_in);
      setLoading(false);
      setProfile(profile);
    })();
  }, []);

  const verifyUserData = (status, role) => {
    return status === "complete" || status === "incomplete" || role === "admin";
  };

  if (loading) {
    return <div className="loading"><LoadingPage/></div>;
  }

  if (!isAuth) {
    return <Navigate to="/login" />;
  }

  return verifyUserData(profile.status, userSession.role) ? (
    <PageUser>
      <Outlet />
    </PageUser>
  ) : (
    <Onboarding />
  );
};

export default ProtectedRoutes;
