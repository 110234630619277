import * as Yup from "yup";
import i18n from "#translate/i18n";

Yup.setLocale(i18n.t("yup.schemas", { returnObjects: true }));

const { errors: errorLabel } = i18n.t("yup.forms", { returnObjects: true });

const PsychologicalSupportBaseSchema = Yup.object().shape({
  monthly_sessions: Yup.string().typeError(errorLabel.select_option).required(i18n.t("psychological_support.forms_error.presence")),
  notes: Yup.string(),
});

export default PsychologicalSupportBaseSchema;

