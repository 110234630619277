const reportsTranslations = {
  title: "Relatórios",
  table_title: {
    payroll: 'Folha Gestão de Pessoas',
    financial: 'Folha Gestão Financeira',
    benefits: 'Folha Gestão Cartão de Benefícios'
  },
  months: {
    0: "Janeiro",
    1: "Fevereiro",
    2: "Março",
    3: "Abril",
    4: "Maio",
    5: "Junho",
    6: "Julho",
    7: "Agosto",
    8: "Setembro",
    9: "Outubro",
    10: "Novembro",
    11: "Dezembro",
  },
  payroll: {
    personal_data: {
      title: "Dados pessoais",
      birth_date: "Dt. nasc:",
      rg: "RG:",
      cpf: "CPF:",
      hiring_date: "Dt. adm:",
      occupation: "Cargo:",
    },
    bank_data: {
      title: "Dados bancários",
      agency: "Ag:",
      account: "Conta:",
      pix: "Chave Pix:",
      beneficiary: "Beneficiário:",
      cpf: "CPF:",
    },
    company_data: {
      title: "Dados da empresa",
      cnpj: "CNPJ:",
      corporate_name: "Razão Social:",
      type_corporate: "Tipo de empresa:",
      city: "Município:",
      state_registration: "Inscrição Estadual:",
      municipal_registration: "Inscrição Municipal:",
      collect_iss: "Recolhe ISS? (Imposto Sobre Serviço):",
    },
    total_to_receive: "Total de receb. do mês",
    total_to_receive_period: "Total de receb. do período",
    total_invoice: "Total em NF",
    total_benefits_card: "Total do cartão benefícios",
    salary: "Remuneração atual",
    healthcare: "Prêmio saúde",
    vacation: "Bônus desc. remunerado",
    year_end_bonus: "Bônus final de ano",
    salary_readjustment: "Reaj. remuneração",
    salary_advance: "Adiant. remuneração",
    meal: "Aux. Alimentação",
    home_office: "Aux. Home Office",
    onboarding: "Aux. Onboarding",
    extra_rewards: "Prêmios extras",
    dismissal_installment: "Parcelas de Rescisão",
    collect_iss: {
      true: 'Sim',
      false: 'Não',
      null: '',
    },
  },
  dropdown: {
    management_vision: "Folha visão Gestão Pessoas",
    financial_vision: "Folha visão Financeira",
    benefits_vision: "Folha visão Cartão de Benef.",
    select_option: "Selecione o relatório desejado",
  },
}

export { reportsTranslations }
