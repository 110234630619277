import * as Yup from "yup";
import i18n from "#translate/i18n";
Yup.setLocale(i18n.t("yup.schemas", { returnObjects: true }));

const { errors: errorLabel } = i18n.t("yup.forms", { returnObjects: true });

const CompanyHistorySchema = Yup.object().shape({
  company: Yup.string().required(),
  initial_remuneration: Yup.number()
    .typeError(errorLabel.required)
    .required()
    .positive(),
  mattermost_username: Yup.string().matches(
    /^[@]{1}[a-z0-9._-]{2,21}$/,
    {message: errorLabel.invalid_username, excludeEmptyString:true}
  ).nullable(),
  github_user: Yup.string().matches(
    /^https:\/\/github\.com\/(?!\.)([a-zA-Z0-9_.-]+)/g,
    {message: errorLabel.github_error, excludeEmptyString:true}
  ).nullable(),
  has_equipment: Yup.string().required().nullable(),
  service_tag: Yup.string()
    .when("has_equipment", {
      is: 'true',
      then: (schema) => schema.required(),
    }),
});

export default CompanyHistorySchema;
