import i18n from "#translate/i18n";
import moment from "moment"
import formatDateString from '#services/formatDateString'

function validateVacationDateStringMin({
  attributeName, docDateReference, standardError='invalid'
}) {
  return this.test(
    attributeName,
    standardError,
    function (value) {
      if (!value) { return true }
      const currentDate = moment(formatDateString(value)).toDate()
      const dayReference = document.getElementById(docDateReference)?.value ?? ''
      const dateToday = new Date()
      dateToday.setHours(0, 0, 0, 0)

      var dateForReference = ''
      
      dateForReference = dayReference ? moment(formatDateString(dayReference)).toDate() : ''

      const isNextMonth = (
        currentDate.getMonth() !== dateForReference.getMonth() && 
        currentDate.getDate() !== 1 && 
        currentDate >= dateToday
      )
      
      let errorMessage = ''
      const currentYear = new Date().getFullYear()
      const nextYear = currentYear + 1
      const firstDayNextYear = new Date(nextYear, 0, 1)

      if(isNextMonth) {
        errorMessage = i18n.t("vacation.forms_error.next_month")
      } else if(dateForReference < firstDayNextYear){
        errorMessage =  i18n.t("vacation.forms_error.current_year_min")
      } else if(dateForReference > firstDayNextYear && currentDate >= firstDayNextYear){
        errorMessage = i18n.t("vacation.forms_error.time_interval")
      } else{
        errorMessage = i18n.t("vacation.forms_error.next_year_min")
      }

      if (currentDate < dateForReference) {
        return this.createError({
          path: this.path,
          message: errorMessage,
        });
      } else {
        return true
      }
    }
  );
};


export { validateVacationDateStringMin }
