import React, { useState, Fragment, useRef, useEffect } from "react"
import i18n from "#translate/i18n"
import { Menu as MenuDropdown, Transition } from "@headlessui/react"

// import DetailsIcon from "#images/profile_drawer/paper-icon.svg"
// import DetailsGrayIcon from "#images/profile_drawer/gray-paper-icon.svg"
import DeleteIcon from "#images/delete.svg"
import DeleteGrayIcon from "#images/delete-gray.svg"
import MenuIcon from '#images/profile_drawer/menu-icon.svg';
import EditIcon from "#images/edit.svg"
import EditGrayIcon from "#images/edit-gray.svg"

export default function OptionsMenu({
  userData,
  handleOpenedDrawersFromProfile,
  closeDrawers,
  setOpenRemovalConfirmation,
  setObjectId,
  setChosenObjectRemoval,
  chosenObjectData,
  setRewardType,
  objectType,
  isValid,
  isRemovable,
  isUpdatable
}) {
  const [openedMenu, setOpenedMenu] = useState(false)
  const menuRef = useRef()
  const isReward = objectType === 'BonusReward' ? true : false
  const isHealthcare = objectType === 'ActiveHealthcares' ? true : false
  const camelToKebabCase = str => str.replace(/[A-Z]/g, letter => `-${letter.toLowerCase()}`);
  const snakeToKebabCase = str => str.replace(/_/g, '-');
  const rewardType = isReward ? `-${chosenObjectData?.reward_type}` : ''

  const optionsTranslations = {
    'BonusReward' : 'latest_rewards',
    'ActiveMeal' : 'active_allowances',
    'ActivePsychologicalSupport' : 'active_allowances',
    'ActiveHomeOffice' : 'active_allowances',
    'ActiveYearEndBonus' : 'active_allowances',
    'ActiveHealthcares' : 'active_allowances',
    'ActiveParentalLeave' : 'active_allowances',
    'ActiveVacation' : 'active_allowances',
    'ExpenseAssistance': 'expense_assistances',
    // 'ActiveAllowance' : 'active_allowances'
  }

  const drawersTranslations = {
    'BonusReward': 'bonus',
    'ActiveHomeOffice': 'homeOffice',
    'ActiveMeal': 'meal',
    'ActivePsychologicalSupport': 'psychologicalSupport',
    'ActiveYearEndBonus': 'yearEndBonus',
    'ActiveParentalLeave': 'parentalLeave',
    'ActiveVacation': 'vacation',
    'ActiveHealthcares' : 'healthcare',
    'ExpenseAssistance': 'expenseAssistance',
    // 'ActiveAllowance' : 'active_allowances'
  }

  useEffect(() => {
    let handleClick = (e) => {
      if (menuRef.current && !menuRef.current.contains(e.target)) {
        setOpenedMenu(false)
      }
    };
    document.addEventListener("mousedown", handleClick)
  })

  return (
    <MenuDropdown as="div" className="relative" id="options-menu">
      <MenuDropdown.Button
        className={`absolute right-0 origin-top-right border rounded p-1 ${(isValid && openedMenu) ? 'border-rebase-navy-blue' : 'border-border-gray'}`}
        id={`options-menu-button${camelToKebabCase(objectType)}${snakeToKebabCase(rewardType)}`}
        ref={menuRef}
        onClick={() => { setOpenedMenu(!openedMenu) }}
      >
        <img src={MenuIcon} alt={ i18n.t('userProfile.remuneration_tab.remuneration.moreOptions') } className="w-6" />
      </MenuDropdown.Button>
      <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
        <MenuDropdown.Items className={`absolute right-0 mt-10 w-80 origin-top-right rounded bg-white shadow-lg border ${isValid ? 'border-rebase-navy-blue' : 'border-border-gray'} z-50`}>
          <div className="py-1">
            {/* <MenuDropdown.Item >
              {({active}) => (
                <button
                  className={`text-default-gray-2 disabled:text-placeholder-gray ${active && 'bg-light-gray font-semibold'} flex w-full items-center p-4 text-sm font-medium`}
                  disabled={!isValid}
                >
                <img src={ isValid ? DetailsIcon : DetailsGrayIcon } alt="Details icon" className='mr-2 w-6 h-6' />

                { i18n.t(`userProfile.remuneration_tab.${ optionsTranslations[objectType] }.menu.details`) }
              </button>
              )}
            </MenuDropdown.Item> */}
            <MenuDropdown.Item >
              {({active}) => (
                <button
                  onClick={() => { closeDrawers?.(), handleOpenedDrawersFromProfile(userData, drawersTranslations[objectType]), setObjectId(chosenObjectData.id) }}
                  className={`text-default-gray-2 disabled:text-placeholder-gray ${active && 'bg-light-gray font-semibold'} flex w-full items-center p-4 text-sm font-medium`}
                  disabled={(isValid !== undefined) && !isHealthcare ? !isValid : !isUpdatable}
                >
                <img src={ isValid || isUpdatable ? EditIcon : EditGrayIcon } alt="Edit icon" className='mr-2 w-6 h-6' />

                { i18n.t(`userProfile.remuneration_tab.${ optionsTranslations[objectType] }.menu.edit`) }
              </button>
              )}
            </MenuDropdown.Item>
            <MenuDropdown.Item >
              {({active}) => (
                <button
                  onClick={() => { setOpenRemovalConfirmation(true), setObjectId(chosenObjectData.id), setChosenObjectRemoval(objectType), isReward && setRewardType(chosenObjectData.reward_type) }}
                  className={`text-default-gray-2 disabled:text-placeholder-gray ${active && 'bg-light-gray font-semibold'} flex w-full items-center p-4 text-sm font-medium`}
                  disabled={isValid !== undefined ? !isValid : !isRemovable}
                >
                  <img src={ isValid || (isUpdatable && !isHealthcare) ? DeleteIcon : DeleteGrayIcon } alt="Remove icon" className='mr-2 w-6 h-6' />

                  { i18n.t(`userProfile.remuneration_tab.${ optionsTranslations[objectType] }.menu.remove`) }
                </button>
              )}
            </MenuDropdown.Item>
          </div>
        </MenuDropdown.Items>
      </Transition>
    </MenuDropdown>
  )
}