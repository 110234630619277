import React from "react";
import Noperson from "#images/Sem_colaborador.svg";
import i18n from "#translate/i18n";

function NoPerson() {
  return (
    <div className="flex flex-col items-center text-center p-[12rem] bg-white">
      <img src={Noperson} alt={i18n.t("noPerson.imgMagnifier")} />
      <div className="pt-6 text-center text-base w-[21rem]">
        {i18n.t("noPerson.message")}
      </div>
    </div>
  );
}

export default NoPerson;
