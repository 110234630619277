/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import Arrow from "#images/arrow-right.svg";

export default function CarouselSlider({
  cardComponent,
  cardsData,
  setSlideCount,
  setCurrentSlide,
  screenWidth,
  currentSlide,
  totalSlides,
  visibleSlides
}) {
  const slidesInScreen = () => {
    if (screenWidth >= 1280) { // xl breakpoint in Tailwind
      return 4;
    } else if (screenWidth >= 1024) { // lg breakpoint in Tailwind
      return 3;
    } else {
      return 1;
    }
  }

  useEffect(() => {
    if(screenWidth > 0) {
      const updateCarouselSlide = () => {
        const slideToBeVisible = slidesInScreen()
        if(visibleSlides === slideToBeVisible) { return }
        setSlideCount(slideToBeVisible);
        // This is a fix to reset currentSlide when screen resizes
        if (
          currentSlide >= totalSlides - visibleSlides ||
          currentSlide >= totalSlides - slideToBeVisible
        ) {
          const calcSlideDif = totalSlides - slideToBeVisible
          setCurrentSlide(calcSlideDif < 0 ? 0 : calcSlideDif);
        }
      };

      updateCarouselSlide();
    }
  }, [screenWidth, setSlideCount, setCurrentSlide]);

  const canGoBack = currentSlide > 0;
  const canGoNext = currentSlide + visibleSlides  < totalSlides;

  const fullScreen = slidesInScreen() >= cardsData.length
  const currentVisibleSlides = () => {
    const slides = cardsData.slice(currentSlide, (currentSlide + visibleSlides))
    if(slides.length < visibleSlides) {
      while (slides.length < visibleSlides) {
        slides.push({});
      }
    }
    return slides
  }

  return (
    <div className="relative" id="carousel-slider">
      <div className="absolute inset-0 flex items-center justify-start">
        <button
          className={`border-none bg-none bg-slate-400 z-[1000] disabled:bg-border-gray ${fullScreen ? 'hidden' : ''}`}
          id="carousel-back-button"
          onClick={() => setCurrentSlide(count => { return count - slidesInScreen() < 0 ? 0 : count - slidesInScreen() })}
          disabled={!canGoBack}
        >
          <img src={Arrow} alt="arrow" className="transform rotate-180 w-5 h-5" />
        </button>
      </div>
      <div className={`flex gap-x-3 ${fullScreen ? '' : 'px-[11px]'}`}>
        {currentVisibleSlides().map((data, index) => (
          <div key={`card-${index}`} className="slide w-full">
            {cardComponent({data: data})}
          </div>
        ))}
      </div>
      <div className="absolute inset-0 flex items-center justify-end" >
        <button
          className={`border-none bg-none bg-slate-400 z-[1000] disabled:bg-border-gray ${fullScreen ? 'hidden' : ''}`}
          id="carousel-next-button"
          onClick={() => setCurrentSlide(count => count + slidesInScreen())}
          disabled={!canGoNext}
        >
          <img src={Arrow} alt="arrow" className="w-5 h-5" />
        </button>
      </div>
    </div>
  );
}
