import * as Yup from "yup";
import {
  SalaryReadjustmentBaseSchema,
} from "./schemas";

const salaryReadjustmentSchema = Yup.object().shape({
  salary_update_request: SalaryReadjustmentBaseSchema,
});

export default salaryReadjustmentSchema;
