import React from "react";
import i18n from "#translate/i18n";
import MattermostLoginModal from "./modals/MattermostLoginModal";
import { loginRequest } from "#requests/loginRequest";
import { UserFormLogin } from "#components";

function LoginUserBox() {
  return (
    <div className="mx-auto text-center flex flex-col items-center pt-9 px-9.4 pb-6 rounded-md bg-light-medium-gray w-96 h-80">
      <div className="font-bold" style={{display: 'none'}}>
        <p className="mb-4 text-medium-strong-gray text-sm">
          {i18n.t("login.access_with")}
        </p>
        <MattermostLoginModal targetRequest={loginRequest} />
      </div>
      <hr className="mt-6 mb-6.6 w-81 border-medium-gray-2" style={{display: 'none'}}/>
      <div className="font-bold">
        <p className="mb-4 text-medium-strong-gray text-sm">
          {i18n.t("login.add_data_info_without_mm")}
        </p>
      </div>
      <UserFormLogin />
    </div>
  );
}

export default LoginUserBox;
