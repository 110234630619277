import React, { useState, useEffect, useContext } from 'react';
import activeAllowancesRequest from '#requests/userProfile/activeAllowancesRequest';
import ActiveMeal from '#components/user/profile/remunerationTab/ActiveMeal';
import ActiveHomeOffice from '#components/user/profile/remunerationTab/ActiveHomeOffice';
import ActiveYearEndBonus from '#components/user/profile/remunerationTab/ActiveYearEndBonus';
import ActiveVacation from '#components/user/profile/remunerationTab/ActiveVacation';
import ActiveHealthcares from '#components/user/profile/remunerationTab/ActiveHealthcares';
import ActiveParentalLeave from '#components/user/profile/remunerationTab/ActiveParentalLeave';
import ActivePsychologicalSupport from '#components/user/profile/remunerationTab/ActivePsychologicalSupport';
import i18n from "#translate/i18n";
import fetchDismissalDetails from "#requests/userProfile/editions/fetchDismissalDetails";
import isUserDismissed from '#services/isUserDismissed';

import { UserContext } from "#providers/UserContextProvider"

export default function ActiveAllowances({
  userData,
  refreshUserData,
  setOpenRemovalConfirmation,
  setChosenObjectRemoval,
  handleOpenedDrawersFromProfile,
  closeDrawers
}) {

  const { setObjectId } = useContext(UserContext)
  const [userActiveAllowances, setUserActiveAllowances] = useState([]);
  const [loading, setLoading] = useState(true);
  const [validOptions, setValidOptions] = useState(false)

  const emptyAllowance = () => {
    const { meal, home_office, year_end_bonus, vacations, healthcare_collection,
            parental_leaves, psychological_support
          } = userActiveAllowances;

    return [meal, home_office, year_end_bonus, vacations, healthcare_collection, parental_leaves, psychological_support]
        .every(allowance => {
          if (Array.isArray(allowance)) {
              return allowance.length === 0
          }
          return !allowance
      });
  }

  useEffect(() => {
    activeAllowancesRequest({ userId: userData.id })
      .then((response) => response.json())
      .then((parsedResponse) => {
        setUserActiveAllowances(parsedResponse)
        setLoading(false)
      })
  }, [userData, refreshUserData]);

  useEffect(() => {
    userData.dismissal_id &&
      (fetchDismissalDetails({ id: userData.dismissal_id, user: userData })
        .then((parsedResponse) => {
          const data = parsedResponse.data
          setValidOptions(isUserDismissed(data.exit_date))
        }))
  }, []);

  return (
    <>
      {
        !loading && emptyAllowance() ?
          <div className='text-info-gray p-6 text-center'>
              {i18n.t('userProfile.remuneration_tab.active_allowances.no_active_allowances')}
            </div>
          : 
          <section className="gap-4 grid grid-cols-2 py-5 px-0 my-0 mx-5" id='user-active-allowances' >
            { userActiveAllowances.meal && <ActiveMeal
                                              allowanceData={userActiveAllowances.meal}
                                              setOpenRemovalConfirmation={setOpenRemovalConfirmation}
                                              setObjectId={setObjectId}
                                              setChosenObjectRemoval={setChosenObjectRemoval}
                                              handleOpenedDrawersFromProfile={handleOpenedDrawersFromProfile}
                                              userData={userData}
                                              closeDrawers={closeDrawers}
                                              validOptions={validOptions}
                                            />
            }
            { userActiveAllowances.home_office && <ActiveHomeOffice
                                                    allowanceData={userActiveAllowances.home_office}
                                                    setOpenRemovalConfirmation={setOpenRemovalConfirmation}
                                                    setObjectId={setObjectId}
                                                    setChosenObjectRemoval={setChosenObjectRemoval}
                                                    handleOpenedDrawersFromProfile={handleOpenedDrawersFromProfile}
                                                    userData={userData}
                                                    closeDrawers={closeDrawers}
                                                    validOptions={validOptions}
                                                  />
            }
            { userActiveAllowances.year_end_bonus && <ActiveYearEndBonus
                                                        allowanceData={userActiveAllowances.year_end_bonus}
                                                        setOpenRemovalConfirmation={setOpenRemovalConfirmation}
                                                        setObjectId={setObjectId}
                                                        setChosenObjectRemoval={setChosenObjectRemoval}
                                                        handleOpenedDrawersFromProfile={handleOpenedDrawersFromProfile}
                                                        userData={userData}
                                                        closeDrawers={closeDrawers}
                                                      />
            }
            { userActiveAllowances.vacations?.map(vacation => <>
                                                                <ActiveVacation
                                                                  allowanceData={vacation}
                                                                  setOpenRemovalConfirmation={setOpenRemovalConfirmation}
                                                                  setObjectId={setObjectId}
                                                                  setChosenObjectRemoval={setChosenObjectRemoval}
                                                                  handleOpenedDrawersFromProfile={handleOpenedDrawersFromProfile}
                                                                  userData={userData}
                                                                  closeDrawers={closeDrawers}
                                                                />
                                                              </>
            )}
            { userActiveAllowances.healthcare_collection && <ActiveHealthcares
                                                              allowanceData={userActiveAllowances.healthcare_collection}
                                                              setOpenRemovalConfirmation={setOpenRemovalConfirmation}
                                                              setObjectId={setObjectId}
                                                              setChosenObjectRemoval={setChosenObjectRemoval}
                                                              handleOpenedDrawersFromProfile={handleOpenedDrawersFromProfile}
                                                              userData={userData}
                                                              closeDrawers={closeDrawers}
                                                              validOptions={validOptions}
                                                            />
            }
            { userActiveAllowances.parental_leaves?.map(parentalLeave => <>
                                                                            <ActiveParentalLeave
                                                                              allowanceData={parentalLeave}
                                                                              setOpenRemovalConfirmation={setOpenRemovalConfirmation}
                                                                              setObjectId={setObjectId}
                                                                              setChosenObjectRemoval={setChosenObjectRemoval}
                                                                              handleOpenedDrawersFromProfile={handleOpenedDrawersFromProfile}
                                                                              userData={userData}
                                                                              closeDrawers={closeDrawers}
                                                                            />
                                                                          </>
            )}
            { userActiveAllowances.psychological_support && <ActivePsychologicalSupport
                                                              allowanceData={userActiveAllowances.psychological_support}
                                                              setOpenRemovalConfirmation={setOpenRemovalConfirmation}
                                                              setObjectId={setObjectId}
                                                              setChosenObjectRemoval={setChosenObjectRemoval}
                                                              handleOpenedDrawersFromProfile={handleOpenedDrawersFromProfile}
                                                              userData={userData}
                                                              closeDrawers={closeDrawers}
                                                              validOptions={validOptions}
                                                            />
            }
          </section>
      }
    </>
  )
}
