import * as Yup from "yup";
import i18n from "#translate/i18n";
import { listUniqueValues, validateDateStringMax, checkListMinimumAttributePresence } from "#utils/schemas";

Yup.setLocale(i18n.t("yup.schemas", { returnObjects: true }));

const today = new Date();
today.setHours(0, 0, 0, 0)

Yup.addMethod(Yup.string, "validateDateStringMax", validateDateStringMax);
Yup.addMethod(Yup.array, "checkListMinimumAttributePresence", checkListMinimumAttributePresence);
Yup.addMethod(Yup.object, "listUniqueValues", listUniqueValues);

const { errors: errorLabel } = i18n.t("yup.forms", { returnObjects: true });

const DescendantSchema = Yup.object()
  .shape(
    {
      full_name: Yup.string()
                    .nullable()
                    .when("birthdate", (birthdate, schema) => {
                      if(birthdate){
                        return schema.required(i18n.t("descendants.forms_error.presence"))
                      }
                    }),
      birthdate: Yup.string()
                    .nullable()
                    .when("full_name", (full_name, schema) => {
                      if(full_name){
                        return schema.required(i18n.t("descendants.forms_error.presence"))
                      }
                    })
                    .validateDateStringMax({
                      attributeName: "birthdate",
                      dateReference: today,
                      errorMessage: i18n.t("descendants.forms_error.date_error")
                    }),
    },
    [["birthdate", "full_name"]]
  )
  .from("descendants_id", "id")

const DescendantListSchema = Yup.array().of(
  DescendantSchema.listUniqueValues("full_name", errorLabel.unique_name_descendant)
).checkListMinimumAttributePresence({
  attributeName: 'full_name',
  errorMessage: i18n.t("descendants.forms_error.presence"),
  shouldValidateReference: "user_human_children_input",
 });

export { DescendantSchema, DescendantListSchema };
