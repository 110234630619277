function validateFileSize({ maxSize = 50, allowEmpty = true } = {}) {
  return (fileList) => {
    if (!fileList) return allowEmpty;

    if (fileList instanceof File) {
      const file = fileList;
      const fileSize = parseInt((file?.size / Math.pow(1024, 2)).toFixed(1));
      return fileSize <= maxSize;
    }

    const [file] = fileList;
    const fileSize = parseInt((file?.size / Math.pow(1024, 2)).toFixed(1));

    return fileSize <= maxSize;
  };
}

const defaultFiles = [
  "application/pdf",
  "image/jpeg",
  "image/jpg",
  "image/png",
];

function validateFileType({
  permittedFiles = defaultFiles,
  allowEmpty = true,
} = {}) {
  return (fileList) => {
    if (!fileList) return allowEmpty;

    if (fileList instanceof File) {
      const file = fileList;

      return permittedFiles.includes(file?.type);
    }

    const [file] = fileList;

    return permittedFiles.includes(file?.type);
  };
}

export { validateFileSize, validateFileType };
