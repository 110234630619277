const translations = {
  email: "Email",
  password: "Senha",
  access: "Acessar",
  email_placeholder: "Digite seu email",
  password_placeholder: "Digite sua senha",
  title: "Olá, boas vindas ao Rebase Pessoas!",
  subtitle: "Entre com os seus dados e acesse"
}

export { translations }
