import React from "react";
import { useFormContext, get } from "react-hook-form";
import InputMask from "react-input-mask";
import Field from "./Field";

const PhoneInput = ({ model, title, name, index, type, requiredField }) => {
  const hasIndex = typeof index === "number";

  const divId = hasIndex ? `${model}_${index}_${name}` : `${model}_${name}`;

  const dddId = hasIndex
    ? `${model}_${index}_DDD_${name}_input`
    : `${model}_DDD_${name}_input`;

  const dddName = hasIndex ? `${index}.DDD_${name}` : `DDD_${name}`;

  const phoneID = hasIndex
    ? `${model}_${index}_${name}_input`
    : `${model}_${name}_input`;
  const phoneName = hasIndex ? `${index}.${name}` : name;

  const landline = type === "landline";

  const {
    formState: { errors },
  } = useFormContext();
  const inputName = model
    ? `${model}.${phoneName}_complete`
    : `${phoneName}_complete`;
  const fieldError = get(errors, inputName);

  const errorClasses = fieldError ? "ring-2 ring-red-500" : "";

  return (
    <div id={divId}>
      <label
        className="p-1 text-sm font-sans font-medium text-gray-700"
        htmlFor={dddId}
      >
        {title}

        { requiredField && (
          <span 
            className="pl-1 text-red-500"
            id="required-field"
          >
            *
          </span>
        )}
      </label>

      <div className="flex pt-1">
        <Field
          component={InputMask}
          model={model}
          id={dddId}
          name={dddName}
          mask="(99)"
          placeholder="DDD"
          className={`mr-2 py-1 px-2 text-md inline rounded w-14 h-11
                      bg-white border border-border-gray placeholder-placeholder-gray
                      focus:placeholder-gray-400 focus:bg-white focus:border-gray-600
                      focus:outline-none focus:ring-2 ${errorClasses}`}
        />

        <Field
          component={InputMask}
          model={model}
          id={phoneID}
          name={phoneName}
          mask={landline ? "9999-9999" : "99999-9999"}
          placeholder={landline ? "1234-5678" : "12345-6789"}
          className={`py-1 px-4 text-md inline rounded w-56 h-11
                      bg-white border border-border-gray placeholder-placeholder-gray
                      focus:placeholder-gray-400 focus:bg-white focus:border-gray-600
                      focus:outline-none focus:ring-2 ${errorClasses}`}
        />
      </div>

      {fieldError && (
        <span className="font-medium tracking-wide text-red-500 text-xs mt-1 px-2 py-1 " name= "validationError" role='alert'>
          {`*${fieldError.message}`}
        </span>
      )}
    </div>
  );
};

export default PhoneInput;
