import React from "react";
import i18n from "#translate/i18n";
import Lupa from "#images/Lupa.svg";

export default function SearchInput({ className, placeholder, ...props }) {
  return (
    <div className={className}>
      <div className="flex items-center h-10 w-80 px-2 rounded bg-white border-2 border-gray-200 ">
        <input
          className="w-64 h-6 border-0 placeholder:text-soft-light-gray"
          id="search-input"
          name="name_or_email"
          type="text"
          onChange={props.handleChange}
          placeholder={placeholder}
        />
        <img
          className="px-4"
          src={Lupa}
          alt={i18n.t("searchInput.imgMagnifier")}
        />
      </div>
    </div>
  );
}
