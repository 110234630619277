const salaryReadjustmentData = {
  salary_update_request: {
    current_value: "",
    new_value: "",
    percentage: "",
    readjustment: "",
    valid_from: "",
    notes: "",
    first_readjustment: "",
  },
};

export { salaryReadjustmentData };
