import formatDate from "#services/formatDate"

export default function addMonths(date, months) { // date props and return formats are like <Tue Jun 20 2023 00:00:00 GMT-0300>
  if(!date || months === undefined) { return }
  if(months === 0) { return date }

  const dateCopy = new Date(`${formatDate(date)}T00:00`)
  const day = dateCopy.getDate()
  let firstDate = new Date(dateCopy.getFullYear(), dateCopy.getMonth(), 1)
  firstDate.setMonth(dateCopy.getMonth() + months)
  const lastDayOfMonth = new Date(firstDate.getFullYear(), firstDate.getMonth() + 1, 0)
  const lastDay = lastDayOfMonth.getDate()
  if(lastDay < day){
    firstDate.setDate(lastDay)
  } else {
    firstDate.setDate(day)
  }

  return firstDate
}

