import * as Yup from "yup";
import i18n from "#translate/i18n";

Yup.setLocale(i18n.t("yup.schemas", { returnObjects: true }));

const { errors: errorLabel } = i18n.t("yup.forms", { returnObjects: true });

const ContactSchema = Yup.object().shape(
  {
    personal_email: Yup.string().email().required(),
    corporate_email: Yup.string()
      .email()
      .when("corporate_email", (value, schema) => {
        if (!value) return schema;

        return schema.matches(
          /^\w+([-+.]\w+)*@{1}(rebase.com.br|campuscode.com.br|brainn.co)$/,
          errorLabel.email_corporate
        );
      })
      .required(),
  },
  ["corporate_email", "corporate_email"] // cyclic dependency
);

const ContactListSchema = Yup.array().of(ContactSchema);

const inviteUserSchema = Yup.object().shape({
  user: Yup.object().shape({
    full_name: Yup.string()
      .label()
      .transform((name) => name.trim())
      .required(),
    birth_date: Yup.string().nullable().required(),
    hiring_date: Yup.string().nullable().required(),
  }),
  contacts: ContactListSchema,
  company_history: Yup.object().shape({
    company: Yup.string().required(),
    initial_remuneration: Yup.number()
      .typeError(errorLabel.required)
      .required()
      .positive(),
  }),
});

export default inviteUserSchema;
