import React from "react";

function FooterBar() {
  const currentYear = new Date().getFullYear()
  return (
    <footer
      id="footer-bar"
      className="w-[41.2%] h-[6.25%] fixed bottom-0 right-0 flex items-center text-rebase-blue text-base font-normal bg-light-gray px-5 2xl:px-4 py-2 xl:py-3 2xl:py-5 "
    >
      <div>
        <h2>Copyright &copy; {currentYear} - Rebase</h2>
      </div>
      <div className="flex space-x-3 2xl:space-x-4 ml-auto">
        <a href="http://rebase.com.br/#/">Rebase</a>
        <a href="http://campuscode.com.br">Campus Code</a>
        <a href="https://www.brainn.co/">Brainn</a>
      </div>
    </footer>
  );
}

export default FooterBar;
