import i18n from "#translate/i18n";

export default function UserRequest(userId) {
  const url = `/api/v1/users/${userId}`;

  const userRequest = () => {

    const response = fetch(url).then(async (response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(i18n.t("requests.network_error"));
      }
    });

    return response;
  };

  return userRequest();
}
