import i18n from "#translate/i18n";

export default function updateUserRequest(formData, userId) {
  const csrf = document.querySelector("meta[name='csrf-token']").content;

  const updateUserRequest = () => {
    const response = fetch(`/api/v1/users/${userId}`, {
      method: "PATCH",
      headers: {
        "X-CSRF-Token": csrf,
      },
      body: formData,
      }).then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error(i18n.t("message_error"));
        }
    });

    return response;
  };

  return updateUserRequest();
}