/* eslint-disable react/jsx-key */
import React from "react";
import i18n from "#translate/i18n";

export default function ReceivedAssets({ userData }) {
  // const formatParagraph = (data) => data ? data.split('\n').map(str => <p>{str}</p>) : i18n.t('userProfile.missing_data')

  return (
    <div
      className="text-info-gray font-sans text-sm leading-[26px] tracking-normal text-left p-4 px-5"
    >
      <div className='w-1/2 inline-block align-top'>
        <p className="font-bold">
          { i18n.t('userProfile.documents_tab.received_assets_data.notebook') }
        </p>
        <div>
          { userData.company_history?.has_equipment ?
            `${i18n.t('userProfile.documents_tab.received_assets_data.service_tag')} - ${userData.company_history?.service_tag}` :
            i18n.t('userProfile.no_notebook') }
        </div>
      </div>
      {/* <div className='w-1/2 inline-block align-top'>
        <p className="font-bold">
          { i18n.t('userProfile.documents_tab.received_assets_data.company_assets') }
        </p>
        <div>
          { formatParagraph(userData.company_history?.company_assets) }
        </div>
      </div>
      <div className='w-1/2 inline-block align-top'>
        <p className="font-bold">
          { i18n.t('userProfile.documents_tab.received_assets_data.client_assets') }
        </p>
        <div>
          { formatParagraph(userData.company_history?.client_assets) }
        </div>
      </div> */}
    </div>
  )
}