import React from "react";

export default function Tooltip({ message }) {
  return (
    <div className="left-2 absolute bottom-full z-20 mb-3 -translate-x-[85%] whitespace-nowrap rounded-lg bg-border-blue py-[6px] px-[0.625rem] text-sm font-semibold text-white opacity-0 group-hover:opacity-100">
      <span className="absolute bottom-[-3px] right-1 -z-10 h-4 w-4 -translate-x-[20%] rotate-45 bg-border-blue" />
      {message}
    </div>
  );
}
