import i18n from "#translate/i18n";
import userPresentedName from "#services/userPresentedName";

export default async function UsersRequest({ filters, signal}) {
  const sanitizeFilters = filters => {
    return Object.fromEntries(Object.entries(filters).filter(([, v]) => !!v))
  }
  const urlFilters = new URLSearchParams(sanitizeFilters(filters)).toString()
  const url = urlFilters ? `/api/v1/users/search?${urlFilters}` : "/api/v1/users";

  return fetch(url, { signal: signal }).then(response => {
    if (!response.ok) throw new Error(i18n.t("message_error"))

    return response.json()
  }).then(users => users.map(user => {
    user.presentedName = userPresentedName(user);
    return user;
  })
  )
}
