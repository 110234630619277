import moment from "moment"
import formatDateString from '#services/formatDateString'

/*
  Cria combinações únicas de dependência
  input: ["a", "b", "c"]
  output: [ ["a", "b"], ["a", "c"], ["b", "c"] ]
*/
function buildDependency(dependencies) {
  const initialValue = [];

  const dependenciesList = dependencies.reduce(
    (previousValue, currentValue) => {
      const currentIndex = dependencies.indexOf(currentValue);
      const list = dependencies
        .slice(currentIndex, dependencies.length)
        .filter((item) => item !== currentValue);
      const combinations = list.map((item) => [currentValue, item]);

      if (currentIndex == dependencies.length - 1) return previousValue;

      if (combinations) return previousValue.concat([combinations]);
    },
    initialValue
  );

  return dependenciesList;
}

function validateDateStringMax({ attributeName, dateReference, docDateReference, errorMessage }) {
  return this.test(
    attributeName,
    errorMessage,
    function (value) {
      if (!value) { return true }
      const currentDate = moment(formatDateString(value)).toDate()
      const maxDate = docDateReference ? (document.getElementById(docDateReference)?.value ?? '') : dateReference

      if (currentDate > moment(formatDateString(maxDate)).toDate()) {
        return this.createError({
          path: this.path,
          message: errorMessage,
        });
      } else {
        return true
      }
    }
  );
};

function validateDateStringMin({
  attributeName, dateReference, errorMessage, shouldAddDays=false, docDateReference, numberOfDaysToAdd, docDaysToAddReference
}) {
  return this.test(
    attributeName,
    errorMessage,
    function (value) {
      if (!value) { return true }

      const currentDate = moment(formatDateString(value)).toDate()
      let dateForReference = ''
      const dayReference = document.getElementById(docDateReference)?.value ?? ''
      const dateDayRef = !dayReference ? '' : moment(formatDateString(dayReference))
      const dateRef = !dateReference ? '' : moment(formatDateString(dateReference))
      if(!dateDayRef && !dateRef) { return true }

      if(shouldAddDays) {
        if(!dateDayRef) { return true }

        const daysToAdd = numberOfDaysToAdd ? numberOfDaysToAdd : (document.getElementById(docDaysToAddReference)?.value ?? 0)
        dateForReference = dateDayRef.add(daysToAdd, 'days').toDate()
      } else {
        dateForReference = dateDayRef ? dateDayRef.toDate() : dateRef.toDate()
      }

      if (currentDate < dateForReference) {
        return this.createError({
          path: this.path,
          message: errorMessage,
        });
      } else {
        return true
      }
    }
  );
};

function validateRejectStartAndEndDatesInterval({
  attributeName, firstStartDateReference, firstEndDateReference, currentEndDateReference, errorMessage
}) {
  return this.test(
    attributeName,
    errorMessage,
    function (value) {
      if (!value) { return true }
      const firstStartDate = document.getElementById(firstStartDateReference)
      const firstEndDate = document.getElementById(firstEndDateReference)
      const currentEndDate = document.getElementById(currentEndDateReference)
      const firstStartDateParsed = firstStartDate ? Date.parse(firstStartDate.value) : ''
      const firstEndDateParsed = firstEndDate ? Date.parse(firstEndDate.value) : ''
      const currentStartDateParsed = value ? Date.parse(value) : ''
      const currentEndDateParsed = currentEndDate ? Date.parse(currentEndDate.value) : ''
      if(isNaN(currentStartDateParsed) || isNaN(currentEndDateParsed) || isNaN(firstStartDateParsed) || isNaN(firstEndDateParsed)) { return true }
      if (
        (firstStartDateParsed >= currentStartDateParsed && firstStartDateParsed < currentEndDateParsed) ||
        (currentStartDateParsed >= firstStartDateParsed && currentStartDateParsed < firstEndDateParsed)
      ){
        return this.createError({
          path: this.path,
          message: errorMessage,
        });
      } else {
        return true
      }
    }
  );
};

function checkListMinimumAttributePresence({ attributeName, errorMessage, shouldValidateReference }) {
  return this.test(
    attributeName,
    errorMessage,
    function (list) {
      const validateReference = document.getElementById(shouldValidateReference)
      const shouldValidate = validateReference ? validateReference.value : ''
      if(String(shouldValidate) !== 'true') {
        return true
      } else {
        const foundElements = []
        list.forEach((element) => {
          if(element) {
            const elementName = element[attributeName]
            const elementDeleted = element._destroy || element.status === 'inactive'
            if(elementName &&  elementDeleted !== true) {
              foundElements.push(elementName)
            }
          }
        })
        if(foundElements.filter(n => n).length === 0) {
          const errorIndex = list.length === 0 ? 0 : (list.length - 1)
          return this.createError({
            path: `${this.path}[${errorIndex}].${attributeName}`,
            message: errorMessage,
          });
        } else {
          return true
        }
      }
    }
  )
};

function validateValueNumber({ attributeName, errorMessage }) {
  return this.test(
    attributeName,
    errorMessage,
    function (value) {
      if (!value) { return true }
      if (isNaN(+value)) {
        return this.createError({
          path: this.path,
          message: errorMessage,
        });
      } else {
        return true
      }
    }
  );
};

function validateListPresence({ attributeName, errorMessage }) {
  return this.test(
    attributeName,
    errorMessage,
    function (list) {
      if(list.length){
        return true
      } else {
        return this.createError({
          path: this.path,
          message: errorMessage,
        });
      }
    }
  )
};

function isValidCPF(cpf) {
  const numericCPF = cpf.replace(/\D/g, '');
  const areAllCharactersEqual = /^(.)\1{10}$/;

  const isFormatInvalid = numericCPF.length !== 11 || areAllCharactersEqual.test(numericCPF);
  if (isFormatInvalid) return false;

  const digits = numericCPF.split('').map(Number);

  const calculateVerificationDigit = (count) => {
    const slicedDigits = digits.slice(0, count - 12);
    const weightedSum = slicedDigits.reduce((sum, digit, index) => (sum + digit * (count - index)), 0);
    const multipliedSum = weightedSum * 10;
    const remainder = multipliedSum % 11;
    const verificationDigit = remainder % 10;
    return verificationDigit;
  };

  const areDigitsValid = calculateVerificationDigit(10) === digits[9] && calculateVerificationDigit(11) === digits[10];

  return areDigitsValid;
};


export {
  buildDependency,
  validateDateStringMax,
  validateDateStringMin,
  checkListMinimumAttributePresence,
  validateValueNumber,
  validateRejectStartAndEndDatesInterval,
  validateListPresence,
  isValidCPF
};
