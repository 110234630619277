import * as Yup from "yup";
import {
  BonusBaseSchema,
} from "./schemas";

const bonusSchema = Yup.object().shape({
  bonus_reward: BonusBaseSchema,
});

export default bonusSchema;
