import * as Yup from "yup";
import i18n from "#translate/i18n";
import YupPhone from "#utils/yup/types/phone";

Yup.setLocale(i18n.t("yup.schemas", { returnObjects: true }));
const { errors: errorLabel } = i18n.t("yup.forms", { returnObjects: true });

const ContactSchema = Yup.object()
  .shape(
    {
      personal_email: Yup.string().email().when("primary", {
        is: true,
        then: Yup.string().email().required(),
        otherwise: Yup.string().email(),
      }),
      corporate_email: Yup.string()
        .email()
        .when(["corporate_email", "primary"], (value, primary, schema) => {

          if (!value && primary === true) return Yup.string().required();

          if (value && primary === true) return schema.matches(
            /^\w+([-+.]\w+)*@{1}(rebase.com.br|campuscode.com.br|brainn.co)$/,
            errorLabel.email_corporate
          ).required();

          if (value && primary === false) return schema.matches(
            /^\w+([-+.]\w+)*@{1}(rebase.com.br|campuscode.com.br|brainn.co)$/,
            errorLabel.email_corporate
          );
      }),
      DDD_landline: Yup.string(),
      landline: YupPhone().landline(),
      DDD_phone_number: Yup.string().when("primary", {
        is: true,
        then: Yup.string().required(""),
      }),
      phone_number: YupPhone().when("primary", {
        is: true,
        then: YupPhone().mobilePhone().required(),
        otherwise: YupPhone().mobilePhone(),
      }),
    },
    ["corporate_email", "corporate_email"] // cyclic dependency
  )
  .from("contacts_id", "id")
  .from("destroy", "_destroy");

const ContactListSchema = Yup.array().of(ContactSchema);

export { ContactSchema, ContactListSchema };