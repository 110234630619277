import React, { useContext } from "react";
import Bonus from '#components/finances/drawers/Bonus'
import SelectionDrawer from '#components/finances/allowances/SelectionDrawer'
import ExpenseAssistance from '#components/finances/drawers/ExpenseAssistance'
import SalaryReadjustment from '#components/finances/drawers/SalaryReadjustment'
import WorkLeave from '#components/finances/drawers/WorkLeave'

import { UserContext } from "#providers/UserContextProvider"
import UserDismissal from "#components/finances/drawers/dismissal/UserDismissal";

export default function FinancialAdditions({
  openNewDrawer,
}) {
  const {
    selectedPerson,
    openedDrawers,
    handleDrawersClose,
    objectId,
    setObjectId,
    chosenAllowanceName,
    setShowMessage
  } = useContext(UserContext)

  return (
    <>
      {
        selectedPerson && openedDrawers['expenseAssistance'] && (
          <ExpenseAssistance
            closeExpenseAssistanceDrawer={() => handleDrawersClose({ drawersToClose: [], clearData: false })}
            userName={selectedPerson.presentedName}
            user={selectedPerson}
            openNewDrawer={openNewDrawer}
            setShowMessage={setShowMessage}
            editObjectId={objectId}
            setEditObjectId={setObjectId}
          />
        )
      }
      {
        selectedPerson && openedDrawers['bonus'] && (
          <Bonus
            closeBonusDrawer={() => handleDrawersClose({ drawersToClose: ['bonus'], clearData: false })}
            userName={selectedPerson.presentedName}
            user={selectedPerson}
            openNewDrawer={openNewDrawer}
            setShowMessage={setShowMessage}
            editObjectId={objectId}
            setEditObjectId={setObjectId}
          />
        )
      }
      {
        selectedPerson && openedDrawers['salaryReadjustment'] && (
          <SalaryReadjustment
            closeSalaryReadjustmentDrawer={() => handleDrawersClose({ drawersToClose: ['salaryReadjustment'], clearData: false })}
            userName={selectedPerson.presentedName}
            user={selectedPerson}
            openNewDrawer={openNewDrawer}
            setShowMessage={setShowMessage}
            editObjectId={objectId}
            setEditObjectId={setObjectId}
          />
        )
      }
      {
        selectedPerson && openedDrawers['allowances'] && (
          <SelectionDrawer
            closeAddAllowancesDrawer={() => handleDrawersClose({ drawersToClose: ['allowances'], clearData: false })}
            userName={selectedPerson.presentedName}
            user={selectedPerson}
            openNewDrawer={openNewDrawer}
            setShowMessage={setShowMessage}
            chosenAllowanceName={chosenAllowanceName}
            editObjectId={objectId}
            setEditObjectId={setObjectId}
          />
        )
      }
      {
        selectedPerson && openedDrawers['workLeave'] && (
          <WorkLeave
            closeWorkLeaveDrawer={() => handleDrawersClose({ drawersToClose: ['workLeave'], clearData: false })}
            userName={selectedPerson.presentedName}
            user={selectedPerson}
            openNewDrawer={openNewDrawer}
            setShowMessage={setShowMessage}
            editObjectId={objectId}
            setEditObjectId={setObjectId}
          />
        )
      }
      {
        selectedPerson && openedDrawers['userDismissal'] && (
          <UserDismissal />
        )
      }
    </>
  );
}
