import React from 'react'
import filterButtonDark from '#images/filter_button_dark.svg'
import filterButtonLight from '#images/filter_button_light.svg'

const BtnFilter = ({ disabled }) => {
  return (
    <div className="ml-2">
      <button
      disabled={disabled}
      data-testid="filter-button"
      className={`flex items-center px-2 rounded border-2 border-border-gray w-10 h-10 ${disabled ? 'bg-border-gray' : 'bg-white'}`}>
      <img className="w-[22px]" src={disabled ? filterButtonLight : filterButtonDark} alt="filter-icon"/>
      </button>
    </div>
  )
}

export default BtnFilter
