import React, { Fragment } from "react"
import i18n from "#translate/i18n"
import { Popover, Transition } from "@headlessui/react"

import useOutsideClick from "#services/useOutsideClick";

import ExportDataPicture from "#images/buttons/export_people_data.svg"
import ExportDataDisabled from "#images/buttons/export_people_data_disabled.svg"
import ArrowLeftIcon from "#images/keyboard_arrow_left.svg"
import TopArrow from '#images/buttons/top_icon_navy_blue_arrow.svg'

export default function Actions({ setOpenExportDataDrawer, selectedUsers }) {
  const { ref, isActive, setIsActive } = useOutsideClick(false);
  const hasNotSelected = selectedUsers.length === 0;

  return (
    <Popover ref={ref}>
      <Popover.Button
        id={'people-action-button'}
        className={`flex flex-row items-center border rounded w-28 h-11 py-3 px-4 font-semibold font-sans text-base outline-none
                   ${isActive ? 'border-rebase-navy-blue text-rebase-navy-blue focus:border-rebase-navy-blue active:border-rebase-navy-blue' : 'border-rebase-blue text-rebase-blue focus:border-rebase-blue active:border-rebase-blue'} `}
        onClick={() => setIsActive(!isActive) }
      >
        <span className="grow text-left">{ i18n.t('peopleListing.actions') }</span>
        <img src={isActive ? TopArrow : ArrowLeftIcon} alt="" className="" />
      </Popover.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <Popover.Panel className="absolute z-10">
          <div className="absolute w-56 rounded bg-white flex flex-col items-center shadow-strg mt-1.5 mr-2 z-50 -right-[122px]
                          border border-rebase-navy-blue text-rebase-navy-blue focus:border-rebase-navy-blue hover:border-rebase-navy-blue">
            <button
              onClick={() => setOpenExportDataDrawer(true)}
              id='open-drawer-people-customized-data'
              disabled={hasNotSelected}
              className={`w-full h-12 font-medium text-sm leading-5 flex items-center justify-start pl-[13px] gap-3 text-default-gray-2 py-2 ${hasNotSelected ? 'border-border-gray text-placeholder-gray' : ''}`}
            >
              <img
                className="w-6 h-6"
                src={ hasNotSelected ? ExportDataDisabled : ExportDataPicture }
                alt={ i18n.t("buttons.export_people_data_picture") }
              />
              <h3>{ i18n.t("buttons.export_people_data") }</h3>
            </button>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  )
}
