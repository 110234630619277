import React from "react";
import CustomizedFieldsOptions from "#components/listings/people/data/drawers/CustomizedFieldsOptions"

export default function BasicCompanyData({fieldsCompanyDataOptions}) {
  return (
    <CustomizedFieldsOptions
      options={fieldsCompanyDataOptions?.basic_company_data}
      optionSource={'company_data'}
      prefix={'company_data.basic_company_data'}
    />
  );
}