/* eslint-disable no-unused-vars */
const setupData = () => {
  let data = {};
  const setData = (value) => {
    typeof value === "function" ? (data = value(data)) : (data = value);
  };
  const getData = () => data;
  return [getData, setData];
};
const handleData = (key, value, addData) => {
  addData((values) => ({ ...values, [key]: value }));
};
const handleArrayOfObjectsFields = (key, response, addData) => {
  const value = response[key].map((item) => {
    const { id: _id, adding: _adding, ...value } = {
      ...item,
      [`${key}_id`]: item["id"],
      [`${key}_adding`]: item["adding"],
      destroy: "",
    };
    return value;
  });
  handleData(key, value, addData);
};
const handleDocument = (key, response, addData) => {
  const value = response[key];

  addData((values) => ({
    ...values,
    ["picture"]: { ...values["picture"], [key]: value },
  }));
};

export default function fillBasicFormsFields({ setForm, response, handleSpecialFields }) {
  const [data, addData] = setupData();
  const fillFields = async () => {
    Object.keys(response).forEach((key) => {
      const value = response[key]

      if (handleSpecialFields) {
        handleSpecialFields.forEach((field) => {
          let currentField = Object.keys(field)[0]
          if(key === currentField) {
            if(field.type === 'arrayOfObjects') {
              return handleArrayOfObjectsFields(key, response, addData)
            } else if(field.type === 'document') {
              return handleDocument(key, response, addData)
            }
          }
        })
      }
      if (value instanceof Array || value instanceof Object) {
        return handleData(key, value, addData);
      }
    });
    setForm(data());
  };
  fillFields();
}
