import React from 'react'
import i18n from "#translate/i18n"

import OptionsMenu from '#components/user/profile/remunerationTab/OptionsMenu';

import homeOfficeIcon from '#images/events_icons/home_office.svg';
import moneyIcon from '#images/profile_drawer/money-icon.svg';

export default function ActiveHomeOffice({
  userData,
  allowanceData,
  closeDrawers,
  handleOpenedDrawersFromProfile,
  setOpenRemovalConfirmation,
  setObjectId,
  setChosenObjectRemoval,
  validOptions
}) {

  return (
    <div id='user-active-home-office' className='w-full py-4 px-0 rounded border border-solitude gap-4 grid' >
      <div className='py-0 px-4 gap-3 flex' >
        <div className='gap-3 flex w-full' >
          <div className='h-[50px] w-[50px] flex justify-center items-center mt-auto mb-auto' >
            <div className='rounded-full shadow-timeline-icons'>
              <img
                src={homeOfficeIcon}
                alt="event icon"
                className='w-11 h-11 p-1'
              />
            </div>
          </div>
          <div className='w-[90%]'>
            <p className='font-sans text-lg/[30px] font-semibold text-left text-midnight w-[90%] absolute' >
              {i18n.t('userProfile.remuneration_tab.active_allowances.home_office.title')}
            </p>
            <OptionsMenu
              setOpenRemovalConfirmation={setOpenRemovalConfirmation}
              setObjectId={setObjectId}
              setChosenObjectRemoval={setChosenObjectRemoval}
              chosenObjectData={allowanceData}
              objectType='ActiveHomeOffice'
              isValid={!validOptions}
              handleOpenedDrawersFromProfile={handleOpenedDrawersFromProfile}
              userData={userData}
              closeDrawers={closeDrawers}
            />
            <div className='flex gap-2 items-center mt-8' >
              <img src={moneyIcon} alt='money_image' />
              <p className='font-sans text-sm/6 font-normal text-left text-soft-light-gray' >
                {Number(allowanceData.value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
