/* eslint-disable react/jsx-key */
import React from 'react';
import i18n from "#translate/i18n";
import Title from '#components/user/profile/Title';
import DocumentsData from '#components/user/profile/documentsTab/DocumentsData'
import DocumentsAttachments from '#components/user/profile/documentsTab/DocumentsAttachments'
import ReceivedAssets from '#components/user/profile/documentsTab/ReceivedAssets'
import DocumentsIcon from "#images/documents-icon.svg";
import AssetsIcon from "#images/assets-icon.svg";
import AttachmentIcon from "#images/attachment-icon.svg";

export default function DocumentsTab({ userData }) {
  return (
    <>
      <div>
        <Title
          title={i18n.t('userProfile.documents')}
          icon={DocumentsIcon}
        />
        <DocumentsData userData={userData} />
      </div>

      <div>
        <Title
          title={ i18n.t('userProfile.received_assets') }
          icon={ AssetsIcon }
        />
        <ReceivedAssets userData={userData} />
      </div>
      
      <div>
        <Title
              title={i18n.t('userProfile.attachments')}
              icon={AttachmentIcon}
            />
        <DocumentsAttachments userData={userData} />
      </div>
    </>
  )
}