import React from "react";

export default function Address({ address }) {
  return (
    <div className="flex text-info-gray font-sans text-sm leading-[26px] tracking-normal text-left p-4 px-5">
      {address && (
        <div>
          <p>
            {address.street}, {address.house_number}
            {address.complement ? ` - ${address.complement}` : ""}
          </p>
          <p>{address.neighborhood} - {address.city} - {address.state} - {address.country}</p>
          <p>{address.zip_code}</p>
        </div>
      )}
    </div>
  )
}