/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import i18n from "#translate/i18n";
import { useFormContext, useFieldArray, useWatch } from "react-hook-form";
import InputText from "#components/formComponents/InputText";
import TextAreaInput from "#components/formComponents/TextAreaInput";
import SelectInput from "#components/formComponents/SelectInput";
import HiddenInput from "#components/formComponents/HiddenInput";
import fetchDismissalAccessStatusOptions from "#requests/dismissals/fetchDismissalAccessesStatusOptions"

export default function UserDismissalAccess({ formModel, editObjectId, editDismissalData }) {
  const model = 'access_and_assets_attributes'

  const [statusList, setSatusList] = useState([])
  const { control, setValue } = useFormContext();
  useWatch({ control, name: model });

  const { fields } = useFieldArray({ control, name: model, });

  const populateAccess = ({access_list}) => {
    let assets = []
    access_list.map((asset) => {
      assets.push(
        {
          description: asset,
          access: asset != 'additional_access' ? i18n.t(`${formModel}.${model}.${asset}`) : '',
        }
      )
    })
    setValue(model, assets)
  }

  useEffect(() => {
    fetchDismissalAccessStatusOptions()
      .then((parsedResponse) => {
        if(!editObjectId) { 
          populateAccess({access_list: parsedResponse["access_list"]})
        }

        setSatusList(parsedResponse["status_list"]);
      });
  }, []);

  useEffect(() => {
    if(editObjectId && editDismissalData) {
      const accessData = editDismissalData.access_and_assets
      populateAccess({access_list: accessData.map((data) => data.description)})
      accessData.map((data, index) => {
        setValue(`${model}.${index}.status`, data.status)
        setValue(`${model}.${index}.notes`, data.notes)
        setValue(`${model}.${index}.access_id`, data.id || '')
      })
    }
  }, [editDismissalData]);

  return (
    <>
      {fields.map((asset, index) => (
        <div key={asset.id}>
          {asset.description != 'additional_access' ?
            <div className="grid grid-cols-2 gap-y-2 gap-x-4 mt-0.5">
              <InputText
                model={model}
                name={`${index}.access`}
                disabled
                className="w-full"
              />
              <SelectInput
                name={'status'}
                index={index}
                model={model}
                optionsI18nPrefix={`${formModel}.${model}`}
                options={statusList}
                className="w-full text-default-gray-2"
              />
            </div>
            :
            <div className="mt-1.5">
              <TextAreaInput
                model={model}
                name={`${index}.notes`}
                placeholder={i18n.t(`${formModel}.placeholder.others_access`)}
                title={i18n.t("user_dismissal.others_access")}
                className='w-full'
              />
            </div>
          }
          <HiddenInput model={model} name={`${index}.description`} />
        </div>
      ))}
    </>
  )
}
