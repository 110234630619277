import * as Yup from "yup";
import i18n from "#translate/i18n";
import { YupFileValidation, YupFile } from "#utils/yup/types/file";

Yup.setLocale(i18n.t("yup.schemas", { returnObjects: true }));

const PictureSchema = Yup.object().shape({
  face_picture: YupFileValidation({
    defaultSchema: YupFile().oneFile().filePresence().fileSize().fileType()
  }),
  body_picture: YupFileValidation({
    defaultSchema: YupFile().oneFile().filePresence().fileSize().fileType()
  }),
});

export default PictureSchema;
