import React from 'react';
import i18n from "#translate/i18n";
import moment from 'moment';
import 'moment/locale/pt-br';

export default function PersonalData({ userData, personalKeys }) {
  const keyOptionsToTranslate = ['race_color', 'marital_status', 'lgbtqia_plus', 'gender_identity'];

  const personalFirstLine = {};
  const personalSecondLine = {};
  const personalThirdLine = {};

  personalKeys.slice(3, 6).forEach((key) => {
    if (userData[key]) {
      personalFirstLine[key] = userData[key];
    }
  });

  personalKeys.slice(6, 8).forEach((key) => {
    if (userData[key] && userData[key] !== 'describe_myself') {
      personalSecondLine[key] = userData[key];
    }
  });

  personalKeys.slice(8, 11).forEach((key) => {
    if (userData[key] && userData[key] !== 'describe_myself') {
      personalThirdLine[key] = userData[key];
    }
  });

  const formatValue = (value) => {
    if (value === null) {
      return null;
    }

    if (moment(value, 'YYYY-MM-DD', true).isValid()) {
      const age = moment().diff(moment(value, 'YYYY-MM-DD'), 'years');
      return `${age} anos (${moment(value).format('DD/MM/YYYY')})`;
    }

    return value;
  };

  const showLine = (data) => {
    const joinedValues = [];
    Object.entries(data).forEach(([key, value]) => {
      if (keyOptionsToTranslate.includes(key)) {
        if (Array.isArray(value)) {
          const formattedValues = value.map((val) => i18n.t(`userProfile.${key}.${val}`));
          joinedValues.push(formattedValues.join(' - '));
        } else {
          joinedValues.push(i18n.t(`userProfile.${key}.${value}`));
        }
      } else {
        const formattedValue = formatValue(value);
        if (formattedValue !== null) {
          joinedValues.push(formattedValue);
        }
      }
    });
    return <p>{joinedValues.join(' - ')}</p>;
  };

  return (
    <div className="text-info-gray font-sans text-sm leading-[26px] tracking-normal text-left p-4 px-5">
      <p><span className="font-bold">{i18n.t('userProfile.full_name')}: </span>{userData.full_name}</p>
      {userData.social_name && (
        <p><span className="font-bold">{i18n.t('userProfile.social_name')}: </span>{userData.social_name}</p>
      )}
      {userData.nickname && (
        <p><span className="font-bold">{i18n.t('userProfile.nickname')}: </span>{userData.nickname}</p>
      )}
      {showLine(personalFirstLine)}
      {showLine(personalSecondLine)}
      {showLine(personalThirdLine)}
    </div>
  );
}