/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect, Fragment, useContext } from 'react';
import { Menu, Transition } from '@headlessui/react'
import logoutRequest from "#requests/logoutRequest";
import ArrowDown from '#images/arrowdown-top.svg';
import Perfil from '#images/see-perfil.svg';
import Edit from '#images/pencil-edit.svg';
import Logout from '#images/logout-page.svg';
import { useNavigate } from "react-router-dom";
import i18n from '#translate/i18n';

import { UserFormsContext } from "#providers/UserFormsProvider"
import { UserContext } from "#providers/UserContextProvider"

export default function HeaderMenu({ userData, }) {
  const { setOpenUserEditForms, setUserDataEditForms  } = useContext(UserFormsContext)
  const { setMenuOrigin, handleDrawersOpen, } = useContext(UserContext)

  const [click, setClick] = useState(false)
  const navigate = useNavigate();
  const menuRef = useRef()
  const handleLogoutClick = () => {
    logoutRequest();
    navigate("/login");
  };

  useEffect(() => {
    let handleClick = (e) => {
      if (menuRef.current && !menuRef.current.contains(e.target)) {
        setClick(false)
      }
    };
    document.addEventListener("mousedown", handleClick)
  })

  return (
    <div className="top-20">
      <Menu as="div" className="relative inline-block text-left z-[1200]">
        <div>
          <Menu.Button
            className='ml-3.5 p-2'
            ref={menuRef}
            onClick={() => { setClick(!click) }}
          >
            <img
              className={`w-3 h-[7.2px] ${click && 'transform rotate-180'}`}
              src={ArrowDown}
              alt="menu arrow"
            />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 mt-4 w-[319px] origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none border border-border-blue">
            <div className="py-1">
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={` text-default-gray-2 ${active && 'bg-light-gray font-semibold'} group flex w-full items-center px-2 py-2 text-sm font-medium`}
                    onClick={() => {
                      setUserDataEditForms(userData)
                      setMenuOrigin['profile'](false)
                      setOpenUserEditForms(true)
                    }}
                  >
                    <img className="w-[18.28px] h[18.25px] mr-[15px]" src={Edit} alt="edit icon" />
                    {i18n.t('navigation.edit')}
                  </button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={` text-default-gray-2 ${active && 'bg-light-gray font-semibold'} group flex w-full items-center px-2 py-2 text-sm font-medium`}
                    onClick={() => { handleDrawersOpen(userData, 'profile'), setMenuOrigin['profile'](true)}}
                  >
                    <img className="w-4 h-4 mr-[15px]" src={Perfil} alt="perfil icon" />
                    {i18n.t('navigation.perfil')}
                  </button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={handleLogoutClick}
                    className={` text-default-gray-2
                      ${active && 'bg-light-gray font-semibold'} group flex w-full items-center px-2 py-2 text-sans text-[14px] font-medium`}
                  >
                    <img className="w-[18px] h-[18px] mr-[15px]" src={Logout} alt="logout icon" />
                    {i18n.t('navigation.logout')}
                  </button>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  )
}
