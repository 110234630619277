import React from "react";
import InputText from "../formComponents/InputText";
import TooltipMessage from "../TooltipMessage";
import i18n from "#translate/i18n";

const IdentifyUser = () => {
  return (
    <>
      <InputText
        label={
          <>
            {i18n.t("onboarding.userName.label")}
            <TooltipMessage message={i18n.t("tooltip.messages.nickname")} />
          </>
        }
        title={i18n.t("onboarding.userName.label")}
        name="nickname"
        model="user"
      />
    </>
  );
};

export default IdentifyUser;
