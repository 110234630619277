import React from "react";
import i18n from "#translate/i18n";
import moment from 'moment';
import 'moment/locale/pt-br';

export default function Descendants({ descendants, sharingHomeWith }) {
  return (
    <div className="text-info-gray font-sans text-sm leading-[26px] tracking-normal text-left p-4 px-5">
      {sharingHomeWith && (
        <p>
          <span className="font-bold">{i18n.t('userProfile.sharing_home_with')}: </span>
          {i18n.t('userProfile.peopleWithCount', { count: sharingHomeWith })}
        </p>
      )}
      {descendants.length > 0 && (
        <>
          <span className="font-bold">{i18n.t('userProfile.children')}: </span>
          {' '}
          <div className="flex w-full">
            <div className="w-fit">
              {descendants?.map((descendant) => (
                <p key={descendant.id}>
                  {descendant.full_name}{' '}
                </p>
              ))}
            </div>
            <div className="w-fit ml-7">
              {descendants?.map((descendant) => (
                <p key={descendant.id}>
                  <span className="font-bold inline-block ml-6">{i18n.t('userProfile.birthdate')}: </span>
                  {' '}
                  {moment(descendant.birthdate).format('DD/MM/YYYY')}
                </p>
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
}
