import React from 'react';
import UploadFile from '#components/uploadFile/UploadFile';
import cardImage from '#images/add_allowances/healthcare/card_image.svg';
import i18n from '#translate/i18n';

export default function HealthcareCardsImagesInput({
  formMethods,
  model,
  editObjectId,
  persistedImagesIds,
  setPersistedImagesIds,
  refreshing,
  setRefreshing,
  setChangeBeneficiary,
  changeBeneficiary
}) {

  const showCard = (descendant, index) => {
    if (descendant.adding) { return true }

    if (editObjectId) {
      const image = formMethods.getValues(`healthcare.descendants.${index}.card_image`)
      if (image?.filename || image?.length > 0) {
        return true
      } else {
        return false
      }
    }
  }

  const onCardFieldChange = ({ status, id }) => {
    if (id && status === 'removed') {
      const keepIds = persistedImagesIds.filter((currentId) => currentId !== id)
      setPersistedImagesIds(keepIds)
    }
    setChangeBeneficiary(!changeBeneficiary)
  }

  return (
    <div className='grid grid-cols-2'>
      <div>
        <UploadFile
          model={model}
          id={'user.card_image'}
          name={'user.card_image'}
          documentImage={cardImage}
          documentType={''}
          alt={i18n.t('healthcare.image.alt.card_image')}
          onChange={onCardFieldChange}
          refreshing={refreshing}
          setRefreshing={setRefreshing}
        />
      </div>
      {formMethods.getValues('healthcare.descendants')?.map((descendant, index) => (
        <div
          key={descendant.id}
          id={`healtcare_card_image_${index}`}
          className={showCard(descendant, index) ? '' : 'hidden'}
        >
          <UploadFile
            model={model}
            id={`descendants.${index}.card_image`}
            name={`descendants.${index}.card_image`}
            documentImage={cardImage}
            documentType={''}
            alt={i18n.t('healthcare.image.alt.card_image')}
            onChange={onCardFieldChange}
            refreshing={refreshing}
            setRefreshing={setRefreshing}
          />
        </div>
      ))}
    </div>
  );
}
