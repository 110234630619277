import React from "react";
import i18n from "#translate/i18n";
import SelectInput from "#components/formComponents/SelectInput";
import CurrencyInput from "#components/formComponents/CurrencyInput";
import InputText from "#components/formComponents/InputText";

export default function RecurringExpenseAssistance({
  paymentTypes,
  durations,
  model,
  customStyles,
  chosenFrequency
}) {

  return (
    <>
      <div className={`${chosenFrequency === 'recurring' ? "" : "hidden"}`}>
        <SelectInput
          model={model}
          name="duration"
          options={durations}
          title={i18n.t("expense_assistance.duration_title")}
          optionsI18nPrefix={model}
          liststyle={customStyles}
          className='w-full'
          requiredField={true}
        />
      </div>

      <div className={`${chosenFrequency === 'recurring' ? "" : "hidden"}`}>
        <InputText
          model={model}
          title={i18n.t("expense_assistance.reason")}
          name={`${chosenFrequency === 'recurring' ? "reason" : "reason_hidden"}`}
          placeholder={i18n.t("expense_assistance.reason_placeholder")}
          className='w-full'
          requiredField={true}
        />
      </div>

      <div className={`${chosenFrequency === 'recurring' ? "" : "hidden"}`}>
        <InputText
          model={model}
          title={i18n.t("expense_assistance.category")}
          name={`${chosenFrequency === 'recurring' ? "category" : "category_hidden"}`}
          placeholder={i18n.t("expense_assistance.category_placeholder")}
          className='w-full'
        />
      </div>

      <div className={`${chosenFrequency === 'recurring' ? "" : "hidden"}`}>
        <CurrencyInput
          model={model}
          name={`${chosenFrequency === 'recurring' ? "assistance_monthly_value" : "assistance_monthly_value_hidden"}`}
          title={i18n.t("expense_assistance.assistance_monthly_value_recurring")}
          className='w-full'
          requiredField={true}
        />
      </div>

      <div className={`${chosenFrequency === 'recurring' ? "" : "hidden"}`}>
        <SelectInput
          model={model}
          name={`${chosenFrequency === 'recurring' ? "payment_type" : "payment_type_hidden"}`}
          options={paymentTypes}
          title={i18n.t("expense_assistance.payment_type")}
          liststyle={customStyles}
          className='w-full'
          requiredField={true}
        />
      </div>
    </>
  );
}
