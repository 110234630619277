// Function to rebuild the object structure based on matching keys
export default function rebuildFlattenedHashWithArrayValues(matchingKeys, reference) {
  const result = {};
  matchingKeys.forEach(key => {
    const parts = key.split('.');
    let current = result;
    for (let i = 0; i < parts.length - 2; i++) {
      const part = parts[i];
      if (!current[part]) { current[part] = {}; }
      current = current[part];
    }
    const finalPart = parts[parts.length - 2];
    if (!current[finalPart]) { current[finalPart] = []; }
    current[finalPart].push(parts[parts.length - 1]);
  });

  fillEmptyArrays(result, reference);
  return result;
}

// Function to ensure empty arrays are preserved in the final structure
function fillEmptyArrays(result, reference) {
  for (const key in reference) {
    if (Object.hasOwn(reference, key)) {
      if (Array.isArray(reference[key])) {
        if (!result[key]) {
          result[key] = [];
        }
      } else if (typeof reference[key] === 'object' && reference[key] !== null) {
        if (!result[key]) {
          result[key] = {};
        }
        fillEmptyArrays(result[key], reference[key]);
      }
    }
  }
}
