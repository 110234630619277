import * as Yup from "yup";
import i18n from "#translate/i18n";
import { validateValueNumber } from "#utils/schemas";

Yup.setLocale(i18n.t("yup.schemas", { returnObjects: true }));
Yup.addMethod(Yup.string, "validateValueNumber", validateValueNumber);

const HomeOfficeAllowanceBaseSchema = Yup.object().shape({
  monthly_value: Yup.string()
                    .validateValueNumber({
                      attributeName: 'monthly_value',
                      errorMessage: i18n.t("home_office_allowance.forms_error.value_number")
                    }),
  notes: Yup.string(),
});

export default HomeOfficeAllowanceBaseSchema;
